import React from "react";
import classnames from "classnames";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import isEmpty from "is-empty";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

class AddNDC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      awp: "",
      acq_cost: "",
      ahfs_code: "",
      bu_per_package: "",
      category: "",
      category_desc: "",
      contract_cost: "",
      dea: "",
      dea_desc: "",
      fdb_package_size: "",
      fine_line: "",
      fine_line_desc: "",
      form_code: "",
      gcn: "",
      gcn_seq: "",
      generic_hicl: "",
      generic_name: "",
      group: "",
      group_desc: "",
      gtin: "",
      hazard: "",
      hazard_desc: "",
      hcpcs: "",
      hic_code: "",
      hic_desc: "",
      hri: "",
      name: "",
      ndc: "",
      niosh_code: "",
      niosh_desc: "",
      omp: "",
      omp_desc: "",
      omp_indicator: "",
      orange_code: "",
      package_awp: "",
      price_per_dose: "",
      retail_price: "",
      route_code: "",
      route_desc: "",
      source: "",
      supplier_name: "",
      unit_dose_code: "",
      unit_dose_product: "",
      unit_size_code: "",
      unit_size_qty: "",
      unit_strength_code: "",
      unit_strength_qty: "",
      uom: "",
      upc_barcode: "",
      ahfs_desc: "",
      website: "",
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.record) {
    //     this.setState({
    //         ...nextProps.record
    //     })
    // }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.user !== undefined &&
      nextProps.auth.user.data !== undefined &&
      nextProps.auth.user.data.message !== undefined
    ) {
      $("#update-drug-modal").modal("hide");

      toast(nextProps.auth.user.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
  componentDidMount() {
    $("#add-purchase-modal").modal("hide");
  }

  onChange = (e) => {
    const currentId = e.target.id;
    let value = e.target.value;
    if (currentId === "ndc") {
      value = value.trim();
    }
    this.setState({ [e.target.id]: value }, () => this.checkNdc(currentId));
  };

  checkNdc = (currentId) => {
    if (
      currentId === "ndc" &&
      this.state.ndc !== "" &&
      this.state.ndc.length === 11
    ) {
      let { errors } = this.state;
      errors.ndc = "";
      this.setState({ errors });
      let filterData = { sort_order: { column: "ndc", order: "desc" } };
      filterData.search = {
        ndc: this.state.ndc,
        exact: true,
      };
      axios.post("/api/drugs", filterData).then((res) => {
        this.setState({ loading: false });
        if (res.data.records.length > 0) {
          let { errors } = this.state;
          errors.ndc = "NDC already exists.";
          this.setState({ errors });
        } else {
          let { errors } = this.state;
          delete errors.ndc;
          this.setState({ errors });
        }
      });
    }
  };

  onDrugUpdate = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.ndc === "") {
      errors.ndc = "This field is required";
    } else if (this.state.ndc.length != 11) {
      errors.ndc = "NDC should be 11 digits  (11111222233)";
    } else if (errors.ndc === undefined) {
      delete errors.ndc;
    }

    if (this.state.group === "") {
      errors.group = "This field is required";
    } else {
      delete errors.group;
    }
    // if (this.state.unit_size_qty === '') {
    //     errors.unit_size_qty = "This field is required"
    // } else {
    //     delete errors.unit_size_qty
    // }
    if (isEmpty(errors)) {
      this.setState({ errors });
      const newDrug = {
        acq_cost: this.state.acq_cost,
        dea: this.state.dea,
        form_code: this.state.form_code,
        group: this.state.group,
        name: this.state.name,
        ndc: this.state.ndc,
        unit_size_qty: this.state.unit_size_qty,
        is_true: true,
        pharmacy_account: this.props.auth.user.final_user,
      };
      axios.post("/api/drug-add", newDrug).then(
        (res) => {
          if (this.props.getData) {
            this.props.getData();
          }
          $("#add-drug-modal").modal("hide");
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({
            ndc: "",
            name: "",
            acq_cost: "",
            dea: "",
            form_code: "",
            group: "",
            unit_size_qty: "",
          });
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.setState({ errors });
    }

    //this.props.addUser(newUser, this.props.history);
  };
  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="add-drug-modal" data-reset="false">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add your NDC</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <form noValidate onSubmit={this.onDrugUpdate} id="add-drug">
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Drug Name</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.name}
                        id="name"
                        type="text"
                        error={errors.name}
                        className={classnames("form-control", {
                          invalid: errors.name,
                        })}
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="ndc">NDC 11 digits</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.ndc}
                        id="ndc"
                        type="text"
                        error={errors.ndc}
                        className={classnames("form-control", {
                          invalid: errors.ndc,
                        })}
                      />
                      <span className="text-danger">{errors.ndc}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="acq_cost">Current Acq Cost($)</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.acq_cost}
                        id="acq_cost"
                        type="text"
                        error={errors.acq_cost}
                        className={classnames("form-control", {
                          invalid: errors.acq_cost,
                        })}
                      />
                      <span className="text-danger">{errors.acq_cost}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="dea">DEA Class</label>
                    </div>
                    <div className="col-md-9">
                      <select
                        onChange={this.onChange}
                        value={this.state.dea}
                        error={errors.dea}
                        id="dea"
                        className={classnames("form-control", {
                          invalid: errors.dea,
                        })}
                      >
                        <option value="">Please Select</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="2N">2N</option>
                        <option value="3N">3N</option>
                        <option value="NC">NC</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="form_code">Form Code</label>
                    </div>
                    <div className="col-md-9">
                      <select
                        onChange={this.onChange}
                        value={this.state.form_code}
                        error={errors.form_code}
                        id="form_code"
                        className={classnames("form-control", {
                          invalid: errors.form_code,
                        })}
                      >
                        <option value="">Please Select</option>
                        <option value="ADV">ADV</option>
                        <option value="AER">AER</option>
                        <option value="AMP">AMP</option>
                        <option value="AOV">AOV</option>
                        <option value="APL">APL</option>
                        <option value="BAG">BAG</option>
                        <option value="BAR">BAR</option>
                        <option value="BTL">BTL</option>
                        <option value="C/J">C/J</option>
                        <option value="CAP">CAP</option>
                        <option value="CHW">CHW</option>
                        <option value="CMB">CMB</option>
                        <option value="CNT">CNT</option>
                        <option value="CON">CON</option>
                        <option value="CPL">CPL</option>
                        <option value="CRM">CRM</option>
                        <option value="CRT">CRT</option>
                        <option value="CRY">CRY</option>
                        <option value="DPK">DPK</option>
                        <option value="DPT">DPT</option>
                        <option value="DRP">DRP</option>
                        <option value="DRS">DRS</option>
                        <option value="ELX">ELX</option>
                        <option value="EML">EML</option>
                        <option value="ENM">ENM</option>
                        <option value="FLK">FLK</option>
                        <option value="FLM">FLM</option>
                        <option value="FOM">FOM</option>
                        <option value="FTV">FTV</option>
                        <option value="GCP">GCP</option>
                        <option value="GEL">GEL</option>
                        <option value="GMY">GMY</option>
                        <option value="GRN">GRN</option>
                        <option value="GUM">GUM</option>
                        <option value="IMP">IMP</option>
                        <option value="INH">INH</option>
                        <option value="INJ">INJ</option>
                        <option value="INJ">INJ</option>
                        <option value="IRR">IRR</option>
                        <option value="IUD">IUD</option>
                        <option value="JEL">JEL</option>
                        <option value="KIT">KIT</option>
                        <option value="LCP">LCP</option>
                        <option value="LIQ">LIQ</option>
                        <option value="LOT">LOT</option>
                        <option value="LOZ">LOZ</option>
                        <option value="MDV">MDV</option>
                        <option value="NDL">NDL</option>
                        <option value="O/O">O/O</option>
                        <option value="O/S">O/S</option>
                        <option value="OIL">OIL</option>
                        <option value="ONT">ONT</option>
                        <option value="ORS">ORS</option>
                        <option value="P-B">P-B</option>
                        <option value="PAD">PAD</option>
                        <option value="PAK">PAK</option>
                        <option value="PAT">PAT</option>
                        <option value="PCH">PCH</option>
                        <option value="PEL">PEL</option>
                        <option value="PFP">PFP</option>
                        <option value="PFS">PFS</option>
                        <option value="PKT">PKT</option>
                        <option value="PMP">PMP</option>
                        <option value="PST">PST</option>
                        <option value="PWD">PWD</option>
                        <option value="SDV">SDV</option>
                        <option value="SET">SET</option>
                        <option value="SGC">SGC</option>
                        <option value="SHM">SHM</option>
                        <option value="SOL">SOL</option>
                        <option value="SPG">SPG</option>
                        <option value="SPY">SPY</option>
                        <option value="STP">STP</option>
                        <option value="SUP">SUP</option>
                        <option value="SUS">SUS</option>
                        <option value="SWB">SWB</option>
                        <option value="SYG">SYG</option>
                        <option value="SYR">SYR</option>
                        <option value="SYS">SYS</option>
                        <option value="T/P">T/P</option>
                        <option value="TAB">TAB</option>
                        <option value="TBX">TBX</option>
                        <option value="TNC">TNC</option>
                        <option value="TPE">TPE</option>
                        <option value="TRA">TRA</option>
                        <option value="TUB">TUB</option>
                        <option value="VL">VL</option>
                        <option value="WAF">WAF</option>
                        <option value="WPE">WPE</option>
                        <option value="WSH">WSH</option>
                      </select>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="group">Product Group</label>
                    </div>
                    <div className="col-md-9">
                      <select
                        onChange={this.onChange}
                        value={this.state.group}
                        error={errors.group}
                        id="group"
                        className={classnames("form-control", {
                          invalid: errors.group,
                        })}
                      >
                        <option value="">Please Select</option>
                        <option value="Brand Rx">Brand Rx</option>
                        <option value="Generic Rx">Generic Rx</option>
                      </select>
                      <span className="text-danger">{errors.group}</span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="add-drug"
                  type="submit"
                  // data-toggle="modal" data-target="#add-drug-modal"
                  className="btn btn-primary"
                >
                  Add NDC
                </button>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(AddNDC);
