import React from 'react'
import classnames from "classnames";
import axios from "axios";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';
import $ from 'jquery';
import isEmpty from 'is-empty';

class UploadInitialInventory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initial_inventory_file: "",
            loading: false,
            errors: {},
        };
    }

    componentDidMount() {
    };

    onSampleAdd = (e) => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.initial_inventory_file === '') {
            errors.initial_inventory_file = "This field is required"
        } else if (this.state.initial_inventory_file.type !== 'text/csv') {
            console.log("here")
            errors.initial_inventory_file = "Please Upload CSV file only"
        } else {
            delete errors.initial_inventory_file
        }
        if (isEmpty(errors)) {
            this.setState({ errors })
            const formData = new FormData();
            if (this.state.initial_inventory_file !== "")
                formData.append("file", this.state.initial_inventory_file);
            formData.append("email", this.props.auth.user.final_user);
            axios
                .post("/api/upload-initial-inventory", formData, {})
                .then(res => {
                    $('#upload-initial-inventory-modal').modal('hide')
                    this.setState({
                        initial_inventory_file: ""
                    })
                    confirmAlert({
                        title: 'Success',
                        message: 'Your upload was successful!',
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => window.location.reload()
                            },
                        ]
                    });
                }).catch(err =>
                    console.log(err)
                )
        } else {
            this.setState({ errors })
        }
    };

    reset = () => {
        this.setState({
            initial_inventory_file: ""
        })
    }

    onCsvChange = event => {
        this.setState({
            [event.target.id]: event.target.files[0],
        });
    };

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="upload-initial-inventory-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Upload Initial Inventory</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                            <p className="text-120">This should only be used for your initial upload of your inventory and not routinely for your purchases,<a href="https://rxreachanalytis.s3.amazonaws.com/Sample_Initial_Inventory.csv" target="_blank">Download Sample File</a></p>
                            <br></br>
                                <form noValidate onSubmit={this.onSampleAdd} id="add-inventory-file" encType="multipart/form-data">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="initial_inventory_file">Select CSV File</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onCsvChange}
                                                id="initial_inventory_file"
                                                type="file"
                                                error={errors.initial_inventory_file}
                                                className={classnames("form-control", {
                                                    invalid: errors.initial_inventory_file
                                                })} />
                                            <span className="text-danger">{errors.initial_inventory_file}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-inventory-file"
                                    type="submit"
                                    className="btn btn-primary">
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(
    mapStateToProps
)(UploadInitialInventory);
