import React, { Component } from "react";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import PrescriberRxDetail from "./View/PrescriberRxDetail";
import { logoutUser } from "../../actions/authActions";
import moment from "moment";
import { Collapse } from "react-collapse";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faMedkit } from "@fortawesome/free-solid-svg-icons";
import usState from "../../utils/state.json";
import { confirmAlert } from "react-confirm-alert";
import $ from "jquery";
import FaxTemplatePlan from "../partials/FaxTemplatePlan";
import SendFaxPrescriberRx from "../partials/SendFax/PrescriberRx";
class PrescriberRx extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "Prscrbr_NPI",
        text: "NPI",
        className: "Prscrbr_NPI",
        align: "left",
        sortable: false,
        show_medication_rx: true,
      },
      {
        key: "Prscrbr_First_Name",
        text: "First Name",
        className: "Prscrbr_First_Name",
        align: "left",
        sortable: false,
      },
      {
        key: "Prscrbr_Last_Org_Name",
        text: "Last Name",
        className: "Prscrbr_Last_Org_Name",
        align: "left",
        sortable: false,
      },
      {
        key: "Prscrbr_Type",
        text: "Type",
        className: "Prscrbr_Type",
        align: "left",
        sortable: false,
      },
      {
        key: "Prscrbr_City",
        text: "City",
        className: "Prscrbr_City",
        align: "left",
        sortable: false,
      },
      {
        key: "Prscrbr_State_Abrvtn",
        text: "State",
        className: "Prscrbr_State_Abrvtn",
        align: "left",
        sortable: false,
      },
    ];

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "Physician List",
      no_data_text: "No record found!",
      button: {
        excel: false,
        print: false,
        csv: false,
        sendFax: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        limit: 20,
        sort_order: { column: "ndc", order: "asc" },
      },
      currentRecord: {},
      state: undefined,
      npi: undefined,
      first_name: undefined,
      last_name: undefined,
      loading: false,
      isFilterOpen: true,
      prescriberRx: undefined,
      fax_records: [],
      fax_points: 0,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getFaxCredits();
    this.getFaxTemplate();
  }

  getFaxCredits() {
    let filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "desc" },
    };
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        if (res.data.records.length) {
          this.setState({
            fax_points: res.data.records[0].fax_points || 0,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  getFaxTemplate() {
    axios
      .post("/api/fax-template", {
        pharmacy_account: this.props.auth.user.email,
      })
      .then((res) => {
        let result = [];
        for (let i = 0; i < res.data.records.length; i++) {
          let each = res.data.records[i];
          if (each.id !== "") {
            result.push(each);
          }
        }
        this.setState({
          fax_records: result,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  getData() {
    this.setState({ loading: true });
    let query = "";
    if (this.state.npi !== "" && this.state.npi !== undefined) {
      query +=
        "filter[npi][condition][path]=Prscrbr_NPI&filter[npi][condition][operator]=%3D&filter[npi][condition][value]=" +
        this.state.npi +
        "&";
    }
    if (this.state.first_name !== "" && this.state.first_name !== undefined) {
      query +=
        "filter[firstName][condition][path]=Prscrbr_First_Name&filter[firstName][condition][operator]=CONTAINS&filter[firstName][condition][value]=" +
        this.state.first_name +
        "&";
    }
    if (this.state.last_name !== "" && this.state.last_name !== undefined) {
      query +=
        "filter[lastName][condition][path]=Prscrbr_Last_Org_Name&filter[lastName][condition][operator]=CONTAINS&filter[lastName][condition][value]=" +
        this.state.last_name +
        "&";
    }
    if (this.state.state !== "" && this.state.state !== undefined) {
      query +=
        "filter[state][condition][path]=Prscrbr_State_Abrvtn&filter[state][condition][operator]=%3D&filter[state][condition][value]=" +
        this.state.state +
        "&";
    }
    let offset = (parseInt(this.state.filterData.page_number) - 1) * 20;
    let url =
      "https://data.cms.gov/data-api/v1/dataset/c1a312b1-acc6-40d0-a630-14e5ddb74fd3/data-viewer?" +
      query +
      "size=20&sort=Prscrbr_NPI&offset=" +
      offset +
      "&_table=lookup&column=Prscrbr_First_Name,Prscrbr_Last_Org_Name,Prscrbr_State_Abrvtn,Prscrbr_NPI,Prscrbr_City,Prscrbr_Type";
    axios
      .get(url)
      .then((res) => {
        let result = [];
        for (let i = 0; i < res.data.data.length; i++) {
          let each = res.data.data[i];
          each.Prscrbr_NPI = each[3];
          each.Prscrbr_First_Name = each[0];
          each.Prscrbr_Last_Org_Name = each[1];
          each.Prscrbr_City = each[4];
          each.Prscrbr_State_Abrvtn = each[2];
          each.Prscrbr_Type = each[5];
          result.push(each);
        }
        this.setState({
          records: result,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });

    axios
      .get(
        "https://data.cms.gov/data-api/v1/dataset/c1a312b1-acc6-40d0-a630-14e5ddb74fd3/data-viewer/stats?" +
          query +
          "sort=Prscrbr_Last_Org_Name,Prscrbr_First_Name,Prscrbr_State_Abrvtn&_table=lookup&column=Prscrbr_First_Name,Prscrbr_Last_Org_Name,Prscrbr_State_Abrvtn,Prscrbr_NPI,Prscrbr_City,Prscrbr_Type"
      )
      .then((res) => {
        this.setState({
          totalCount: res.data.data.found_rows,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  showMedication = (physicianMedication) => {
    this.setState({ prescriberRx: physicianMedication.npi });
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  resetSearch = () => {
    this.setState({
      state: undefined,
      npi: undefined,
      first_name: undefined,
      last_name: undefined,
      filterData: {
        page_number: 1,
        limit: 20,
        // sort_order: { column: "dea", order: "asc" }
      },
    });
  };

  toggleChange = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  showMedication = (physicianMedication) => {
    this.setState({ prescriberRx: physicianMedication.Prscrbr_NPI });
  };

  SendFax = () => {
    // $("#send-faxtemplate-modal").modal("show");
    if (this.state.totalCount === 0) {
      confirmAlert({
        title: "Error",
        message: "No Data found. Please search with valid filters",
        buttons: [
          {
            label: "OK",
            onClick: () => {},
          },
        ],
      });
    } else {
      if (
        this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()
      ) {
        confirmAlert({
          title: "Attention",
          message:
            "This feature is available for TabulaRx Subscribed User. Would you like to subscribe now?",
          buttons: [
            {
              label: "Yes",
              onClick: () =>
                (window.location.href = "/choose-plan?solution=TabulaRx"),
            },
            {
              label: "No",
              onClick: () => {},
            },
          ],
        });
      } else {
        if (this.state.state === undefined && this.state.npi === undefined) {
          toast.error("Please Select State or Enter NPI", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          parseInt(this.state.fax_points || 0) >= this.state.totalCount
        ) {
          $("#send-faxtemplate-modal").modal("show");
        } else {
          confirmAlert({
            title: "Attention",
            message:
              "You need" +
              "    " +
              (parseInt(this.state.totalCount) -
                parseInt(this.state.fax_points || 0)) +
              "  " +
              "More Credits to Send fax",
            buttons: [
              {
                label: "Ok",
                onClick: () =>
                  (window.location.href = "/fax-credits?show-popup=true"),
              },
              {
                label: "Cancel",
                onClick: () => {},
              },
            ],
          });
        }
      }
    }
  };

  render() {
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <PrescriberRxDetail prescriberRx={this.state.prescriberRx} />
        <FaxTemplatePlan />
        <SendFaxPrescriberRx
          record={this.state.fax_records}
          email={this.props.auth.user.final_user}
          totalNpis={this.state.totalCount}
          // totalNpis={1}
          filters={{
            ...(this.state.npi !== "" &&
              this.state.npi !== undefined && { npi: this.state.npi }),
            ...(this.state.first_name !== "" && {
              first_name: this.state.first_name,
            }),
            ...(this.state.last_name !== "" &&
              this.state.last_name !== undefined && {
                last_name: this.state.last_name,
              }),

            ...(this.state.state !== "" &&
              this.state.state !== undefined && {
                state: this.state.state,
              }),
          }}
          apiUrl={"/faxApi/fax-send-prescriberrx"}
          redirectUrl={"/prescriber-rx"}
        />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">Prescriber Rx</h1>
            <p className="help-text font-weight-bold h6">
              Improve your marketing by viewing and downloading prescribing data
              for physicians in your area. The data does not represent cash and
              certain third party plans.
            </p>
            <button className="btn btn-primary float-right mt-3 mr-2">
              <FontAwesomeIcon icon={faMedkit} />{" "}
              <Link to="/drug-utilization"> PrescriberRx by State</Link>
            </button>
            <button className="btn btn-primary float-right mt-3 mr-2">
              <FontAwesomeIcon icon={faMedkit} />{" "}
              <Link to="/prescriberrx-local"> PrescriberRx by Drugs</Link>
            </button>
            {/* <button className="btn btn-primary float-right mt-3 mr-2">
              <FontAwesomeIcon icon={faMedkit} />{" "}
              <Link to="/cash-report"> PrescriberRx Cash Report</Link>
            </button> */}
            <br></br>
            <button className="btn btn-warning">
              <FontAwesomeIcon icon={faBackward} />
              <Link to="/dashboard"> Go to Dashboard</Link>
            </button>
            <br></br>
            <br></br>
            <div>
              <Collapse isOpened={this.state.isFilterOpen}>
                <form noValidate onSubmit={this.onSearch}>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="npi">NPI</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.npi}
                        id="npi"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="first_name">First Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.first_name}
                        id="first_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.last_name}
                        id="last_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="state">State</label>
                      <select
                        name="state"
                        onClick={this.onChangeSelect}
                        className="form-control"
                        id="state"
                      >
                        <option value="" selected="">
                          Please select
                        </option>
                        {Object.keys(usState).map((each, i) => (
                          <option
                            key={i}
                            value={each}
                            selected={this.state.state === each}
                          >
                            {usState[each]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                  <button
                    type="reset"
                    onClick={this.resetSearch}
                    className="btn btn-secordry"
                  >
                    Reset
                  </button>
                </form>
              </Collapse>
            </div>
            <br></br>
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
              exportToCSV={this.exportToCSV}
              showMedication={this.showMedication}
              sendFax={this.SendFax}
              fax_points={this.state.fax_points}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps, { logoutUser })(PrescriberRx);
