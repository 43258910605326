import React, { Component, Fragment } from "react";
import { Row, Container, Col } from 'react-bootstrap';
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { faPlus, faDownload, faCog, faBackward } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import GroupFilter from "./Filter/GroupFilter.js";
import BusinessToolUpdateModal from "../partials/BusinessToolUpdateModal";
import Chart from "react-google-charts";
import BusinessToolAddModal from "../partials/BusinessToolAddModal";
import DatePicker from "react-datepicker";
import moment from "moment"
import ExpandGraph from "./View/ExpandGraph";
import ExportGraph from "./View/ExportGraph";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { logoutUser } from "../../actions/authActions";
class BusinessTool extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "report_date",
                text: "Date",
                className: "report_date",
                align: "left",
                sortable: true,
            },
            {
                key: "revenue",
                text: "Revenue",
                className: "revenue",
                align: "left",
                sortable: true,
            },
            {
                key: "purchase",
                text: "Purchase",
                className: "purchase",
                align: "left",
                sortable: true,
            },
            {
                key: "returns",
                text: "Returns",
                className: "returns",
                align: "left",
                sortable: true,
            },
            {
                key: "ra_number",
                text: "RA Number",
                className: "ra_number",
                align: "left",
                sortable: true,
            },
            {
                key: "estimated_profit",
                text: "Estimated Gross Profit",
                className: "estimated_profit",
                align: "left",
                sortable: true,
            },
            {
                key: "new_rx",
                text: "New RX",
                className: "new_rx",
                align: "left",
                sortable: true,
            },
            {
                key: "refill_rx",
                text: "Refill RX",
                className: "refill_rx",
                align: "left",
                sortable: true,
            },
            {
                key: "total_rx",
                text: "Total RX",
                className: "total_rx",
                align: "left",
                sortable: true,
            }

        ];
        if (this.props.auth.user.superadmin || this.props.auth.user.multistore) {
            this.columns.push({
                key: "pharmacy_account",
                text: "User Email",
                className: "pharmacy_account",
                align: "left",
                sortable: true,
            })
        }
        if (!this.props.auth.user.superadmin && !this.props.auth.user.multistore) {
            this.columns.push({
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-report-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            })
        }

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Report List",
            no_data_text: 'No Report found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                sort_order: { column: "report_date", order: "desc" }
            },
            currentRecord: {
            },
            revenue: undefined,
            purchase: undefined,
            ra_number: undefined,
            returns: undefined,
            new_rx: undefined,
            refill_rx: undefined,
            total_rx: undefined,
            totalNewRx: undefined,
            totalRefillRx: undefined,
            recordsGraph: [],
            rxGraph: [],
            totalRxGraph: [],
            isFilterOpen: true,
            from_date: moment().subtract(30, "days").toDate(),
            to_date: moment().endOf('day').toDate(),
            brand_percent: 80,
            generic_percent: 15,
            otc_percent: 5,
            today: moment(),
            expand: undefined,
            exporting: false,
            rx_group_associated: undefined,
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
        this.getUserData()
    };

    componentDidUpdate(prevProps) {
        if (this.props.multistoreData.length !== prevProps.multistoreData.length) {
            this.getData()
        }
    }

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.from_date !== '' && { from_date: this.state.from_date }),
            ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
            pharmacy_account: this.state.rx_group_associated === undefined ? this.props.auth.user.final_user : this.state.rx_group_associated,
            ...(this.props.multistoreData.length > 0 && (this.state.rx_group_associated === undefined || this.state.rx_group_associated === "") && { multiStoreEmails: this.props.multistoreData.map(a => a.email) }),

        }
        axios
            .post("/api/analytic-tool", filterData)
            .then(res => {
                var result = [];
                let recordsGraph = []
                var totalbrand = 0
                recordsGraph.push(['Date', 'Revenue', 'Purchase', 'Linear(Purchase)'])
                let rxGraph = []
                rxGraph.push(['Date', 'Daily RX'])
                let totalRxGraph = []
                totalRxGraph.push(['Date', 'New RX', 'Refill RX', 'Total RX'])
                var z = 0
                var totalRev = 0
                var totalRet = 0
                var totalest = 0
                var totalgen = 0
                var totalotc = 0
                var totalRx = 0
                var totalNewRx = 0
                var totalRefillRx = 0
                res.data.records.sort((a, b) => (a.report_date) > (b.report_date) ? 1 : -1);
                // add data rows in sheet array
                for (let i in res.data.records) {
                    let each = res.data.records[i]
                    //  delete record.id; delete record.__v
                    if (each.report_date !== '') {
                        each.orig_report_date = each.report_date
                        each.report_date = moment(each.report_date).format('L');

                    }
                    // var z = 0
                    // for (let k = 1; k <res.data.record.length; k++) {
                    z += parseFloat(each.purchase)
                    if (each.revenue === undefined) {
                        console.log('')
                    }
                    else {
                        totalRev += parseFloat(each.revenue)

                    }

                    totalRet += parseFloat(each.returns)
                    totalest += parseFloat(each.estimated_profit)
                    if (each.brand_percent === undefined) {
                        console.log("")
                    }
                    else {
                        totalbrand += parseFloat(each.brand_percent)
                    }
                    if (each.generic_percent === undefined) {
                        console.log('')
                    }
                    else {
                        totalgen += parseFloat(each.generic_percent)
                    }
                    if (each.otc_percent === undefined) {
                        console.log('')
                    }
                    else {
                        totalotc += parseFloat(each.otc_percent)
                    }
                    if (each.total_rx === undefined) {
                        console.log('')

                    }
                    else {
                        totalRx += parseFloat(each.total_rx)

                    }
                    totalNewRx += parseFloat(each.new_rx)
                    totalRefillRx += parseFloat(each.refill_rx)

                    // }


                    recordsGraph.push([
                        each.report_date,
                        parseFloat(each.revenue),
                        parseFloat(each.purchase),
                        parseFloat(each.purchase)
                    ])

                    rxGraph.push([
                        each.report_date,
                        parseFloat(each.total_rx)
                    ])

                    totalRxGraph.push([
                        each.report_date,
                        parseFloat(each.new_rx),
                        parseFloat(each.refill_rx),
                        parseFloat(each.total_rx)
                    ])
                    each.orig_revenue = each.revenue
                    each.orig_purchase = each.purchase
                    each.orig_returns = each.returns
                    each.revenue = each.revenue ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.revenue) : ""
                    each.purchase = each.purchase ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.purchase) : ""
                    each.returns = each.returns ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.returns) : ""
                    each.estimated_profit = each.estimated_profit ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.estimated_profit) : ""
                    each.report_date = moment(each.report_date).format('L')
                    result.push(each);


                }
                let totalavg = totalRev / (totalRx == 0 ? 1 : totalRx);
                console.log(totalavg)

                if (totalavg === 0) {
                    // console.log('')
                    totalavg = "0.00"

                }
                else {
                    totalavg = parseFloat(totalRev / totalRx).toFixed(2)
                }
                let totalgp = totalest / (totalRx == 0 ? 1 : totalRx);
                if (totalgp === 0) {
                    // console.log('')
                    totalgp = "0.00"

                }
                else {
                    totalgp = parseFloat(totalest / totalRx).toFixed(2)
                }
                let totalgpper = (totalest * 100 / (totalRx == 0 ? 1 : totalRx));
                if (totalgpper === 0) {
                    // console.log('')
                    totalgpper = "0.00"

                }
                else {
                    totalgpper = parseFloat(totalest * 100 / totalRev).toFixed(2)
                }


                z = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(z)
                totalRev = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalRev)
                totalRet = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalRet)
                totalest = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalest)
                totalbrand = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalbrand)
                totalgen = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalgen)
                totalotc = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalotc)

                this.setState({
                    records: result,
                    rxGraph: rxGraph,
                    totalRxGraph: totalRxGraph,
                    recordsGraph: recordsGraph,
                    totalCount: res.data.count,
                    loading: false,
                    z: z,
                    totalRev: totalRev,
                    totalRet: totalRet,
                    totalest: totalest,
                    totalbrand: totalbrand,
                    totalgen: totalgen,
                    totalotc: totalotc,
                    totalavg: totalavg,
                    totalgp: totalgp,
                    totalgpper: totalgpper,
                    totalRx: totalRx,
                    totalNewRx: totalNewRx,
                    totalRefillRx: totalRefillRx
                })

            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })

    }
    // disableFutureDt = current => {
    //     return current.isBefore(this.state.today)
    // }


    getUserData() {
        if (this.props.auth.user.rx_group !== "superadmin") {
            this.setState({ loading: true })
            let filterData = {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "report_date", order: "desc" }
            }

            filterData.search = {
                rx_group: this.props.auth.user.rx_group
            }
            axios
                .post("/api/user-data", filterData)
                .then(res => {
                    this.setState({
                        brand_percent: res.data.records[0].brand_percent ? res.data.records[0].brand_percent : this.state.brand_percent,
                        generic_percent: res.data.records[0].generic_percent ? res.data.records[0].generic_percent : this.state.generic_percent,
                        otc_percent: res.data.records[0].otc_percent ? res.data.records[0].otc_percent : this.state.otc_percent,
                        loading: false,
                    })
                })

                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 403) {
                            window.location.href = "./login"
                        }
                    }
                })
        }
    }


    editRecord(record) {
        this.setState({ currentRecord: record });
    }
    setFromDate = (date) => {
        this.setState({ from_date: date });
    }
    setToDate = (date) => {
        this.setState({ to_date: date });
    }
    deleteRecord(record) {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.finalDelete(record)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });

    }

    finalDelete = (record) => {
        axios
            .post("/api/analytic-tool-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                    this.getData();
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            });
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            from_date: moment().startOf('month').startOf('day').toDate(),
            to_date: moment().endOf('day').toDate(),
            rx_group_associated: undefined,
            filterData: {
                sort_order: { column: "_id", order: "asc" }
            }
        }, () => this.getData())
    }

    exportToCSV = () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            "report_date": "Date",
            "revenue": "Revenue",
            "purchase": "Purchase",
            "ra_number": "RA Number",
            "returns": "Return",
            "new_rx": "New RX",
            "refill_rx": "Refill RX",
            "total_rx": "Total RX",
            "estimated_profit": "Estimated Gross Profit",
            "brand_percent": "Brand",
            "generic_percent": "Generic",
            "otc_percent": "OTC",
            "rx_group": "Rx Group"
        };
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false })
        for (let i in this.state.records) {
            let record = this.state.records[i]
            delete record._id; delete record.id; delete record.__v

            record.revenue = '"' + record.revenue + '"'
            record.purchase = '"' + record.purchase + '"'
            record.returns = '"' + record.returns + '"'
            record.estimated_profit = '"' + record.estimated_profit + '"'
            record.brand_percent = "$" + record.brand_percent
            record.generic_percent = "$" + record.generic_percent
            record.otc_percent = "$" + record.otc_percent
            records.push(record);
        }
        if (headers) {
            records.unshift(headers);
        }
        // Convert Object to JSON
        // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
        // var universalBOM = "\uFEFF";
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
        // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
        var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'application/csv;charset=UTF-8' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

    }

    convertToCSV(objArray, headers) {
        const keys = Object.keys(headers);

        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in keys) {
                line += array[i][keys[index]] === undefined ? "," : array[i][keys[index]] + ",";
                line.trim(",")


            }
            str += line + '\r\n';
        }
        return str;
    }

    openDatepicker = (e) => {
        if (e.target.id === "toD") {
            this.toDatePicker.setOpen(true);
        } else {
            this.fromDatePicker.setOpen(true);
        }
    }

    expand = (type) => {
        this.setState({ expand: type })
    }

    exportPdf = () => {
        this.setState({
            exporting: true
        }, () => this._exportPdf())
    }

    _exportPdf = () => {
        html2canvas(document.querySelector("#graphPDF")).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'JPEG', 0, 0);
            // pdf.output('dataurlnewwindow');
            pdf.save("download.pdf");
            this.setState({
                exporting: false
            })
        });

    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        if ((this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula === undefined || this.props.auth.user.expiration_tabula < moment().format()) && this.props.auth.user.superadmin !== true) {
            window.location.href = ("/choose-plan?solution=TabulaRx");
        }
        return (
            <>
                <div className="d-flex" id="wrapper">
                    {this.state.loading &&
                        <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                    <BusinessToolAddModal getData={this.getData} brand_percent={this.state.brand_percent} generic_percent={this.state.generic_percent} otc_percent={this.state.otc_percent} />
                    <BusinessToolUpdateModal record={this.state.currentRecord} getData={this.getData} brand_percent={this.state.brand_percent} generic_percent={this.state.generic_percent} otc_percent={this.state.otc_percent} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            {!this.props.auth.user.superadmin && !this.props.auth.user.multistore &&
                                <>
                                    <button className="btn btn-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-report-modal"><FontAwesomeIcon icon={faPlus} /> Add new Records</button>
                                    <button className="btn btn-primary float-right mt-3 mr-2" onClick={this.exportPdf}><FontAwesomeIcon icon={faDownload} /> Export Report</button>
                                    {!this.props.auth.user.superadmin && <Link to="/user-settings"><button className="btn btn-primary float-right mt-3 mr-2"><FontAwesomeIcon icon={faCog} /> Settings</button></Link>}
                                </>
                            }

                            <h1 className="mt-2 text-primary">Business Analytics Tool</h1>
                            <p className="help-text font-weight-bold h6" style={{ width: "60%" }}>Keep track of your business activity that you can download. You can also include Return Authorizations Memos that you can download and keep track of accurately.</p>
                            <button className="btn btn-warning"><FontAwesomeIcon icon={faBackward} /><Link to="/dashboard"> Go to Dashboard</Link></button>
                            <br></br>
                            <br></br>
                            <div className="form-row">
                                <GroupFilter onChangeSelect={this.onChangeSelect} rx_group_associated={this.state.rx_group_associated} />
                                <div className="form-group col-md-3">
                                    <label htmlFor="npi">From Date</label>
                                    <DatePicker selected={this.state.from_date} onChange={(date) => this.setFromDate(date)} className="form-control" ref={(c) => this.fromDatePicker = c} />
                                    <i aria-hidden="true" className="fa fa-calendar" id="fromD" onClick={this.openDatepicker}></i>
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="first_name">To Date</label>
                                    <DatePicker selected={this.state.to_date} maxDate={moment().toDate()} onChange={(date) => this.setToDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                    <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <button
                                    onClick={this.onSearch}
                                    type="button"
                                    className="btn btn-primary">
                                    Search
                                </button>
                                <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>
                            </div>
                            <Container>
                                <Row>
                                    <Col md={6}>
                                        <div className="card shadow-lg">
                                            <div className="card-body">
                                                <div className="form-group col-md-6  mr-4 text-center py-2 px-4 font-weight-bold text-white bg-success">
                                                    Revenue: {this.state.totalRev}
                                                </div>
                                                <div className="form-group col-md-6  mr-4 text-center py-2 px-4 font-weight-bold text-white">
                                                    Purchases: {this.state.z}
                                                </div>
                                                <div className="form-group col-md-6  mr-4 text-center py-2 px-4 font-weight-bold text-white bg-success">
                                                    Returns: {this.state.totalRet}
                                                </div>
                                                <div className="form-group col-md-6 mr-4 text-center py-2 px-4 font-weight-bold text-white bg-success">
                                                    Gross Profit: {this.state.totalest}
                                                </div>
                                                <div className="form-group col-md-6  mr-4 text-center py-2 px-4 font-weight-bold text-white bg-primary">
                                                    Rx Count: {this.state.totalRx}
                                                </div>
                                                <table class="table table-striped col-md-5 table-light">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" colSpan="2" className="text-align-center">Purchases</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Brand</th>
                                                            <th>{this.state.totalbrand}({this.state.brand_percent !== undefined ? this.state.brand_percent : 80}%)</th>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Generic</th>
                                                            <th>{this.state.totalgen}({this.state.generic_percent !== undefined ? this.state.generic_percent : 15}%)</th>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">OTC</th>
                                                            <th>{this.state.totalotc}({this.state.otc_percent !== undefined ? this.state.otc_percent : 5}%)</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-striped col-md-5 table-light">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Avg Rx Price</th>
                                                            <th>$
                                                                {this.state.totalavg}</th>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Avg GP</th>
                                                            <th>${this.state.totalgp}</th>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">GP %</th>
                                                            <th>{this.state.totalgpper}%</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </Col>
                                    {this.state.recordsGraph.length > 1 && (
                                        <Col md={6}>
                                            <div className="card shadow-lg">
                                                <div className="card-body">
                                                    <h5>Daily Reports
                                                        {/* <a href="#" style={{ "float": "right", "fontSize": "14px" }} data-toggle="modal" data-target="#show-graph" onClick={(e) => this.expand("daily_report")}>Expand</a> */}
                                                        <Link to="/expand"><a style={{ "float": "right", "fontSize": "19px" }}>Expand</a></Link>
                                                    </h5>
                                                    <Chart
                                                        width={'100%'}
                                                        height={'240px'}
                                                        chartType="ComboChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={this.state.recordsGraph}
                                                        options={{
                                                            // hAxis: { title: 'Date' },
                                                            // vAxis: { title: 'Dollars', format: "currency" },
                                                            seriesType: 'bars',
                                                            series: { 2: { type: 'line' } },
                                                            legend: { position: 'top' },
                                                        }}
                                                        rootProps={{ 'data-testid': '7' }}
                                                        formatters={[
                                                            {
                                                                type: 'NumberFormat',
                                                                column: 2,
                                                                options: {
                                                                    prefix: '$',
                                                                    negativeColor: 'red',
                                                                    negativeParens: true,
                                                                },
                                                            },
                                                            {
                                                                type: 'NumberFormat',
                                                                column: 1,
                                                                options: {
                                                                    prefix: '$',
                                                                    negativeColor: 'red',
                                                                    negativeParens: true,
                                                                },
                                                            },
                                                        ]}
                                                    />

                                                </div>
                                            </div>
                                        </Col>
                                    )}

                                    {this.state.rxGraph.length > 1 && (
                                        <Col md={6}>
                                            <div className="card shadow-lg">
                                                <div className="card-body">
                                                    <h5>Daily RX: {this.state.totalRx}
                                                        <Link to="/expand-rx"><a style={{ "float": "right", "fontSize": "19px" }}>Expand</a></Link>

                                                        {/* <a href="#" style={{ "float": "right", "fontSize": "14px" }} data-toggle="modal" data-target="#show-graph" onClick={(e) => this.expand("daily_rx")}>Expand</a> */}
                                                    </h5>
                                                    <Chart
                                                        width={'100%'}
                                                        height={'240px'}
                                                        chartType="AreaChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={this.state.rxGraph}
                                                        options={{
                                                            legend: { position: 'top' },
                                                        }}
                                                        rootProps={{ 'data-testid': '6' }}
                                                    />

                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                    {this.state.totalRxGraph.length > 1 && (
                                        <Col md={6}>
                                            <div className="card shadow-lg">
                                                <div className="card-body">
                                                    <h5>New RX: {this.state.totalNewRx}
                                                        <Link to="/expand-new"><a style={{ "float": "right", "fontSize": "19px" }}>Expand</a></Link>

                                                        {/* <a href="#" style={{ "float": "right", "fontSize": "14px" }} data-toggle="modal" data-target="#show-graph" onClick={(e) => this.expand("total_rx")}>Expand</a> */}
                                                        <span className="right">  Refill RX: {this.state.totalRefillRx}</span>
                                                    </h5>
                                                    <Chart
                                                        width={'100%'}
                                                        height={'240px'}
                                                        chartType="LineChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={this.state.totalRxGraph}
                                                        options={{
                                                            legend: { position: 'top' },
                                                        }}
                                                        rootProps={{ 'data-testid': '6' }}
                                                    />

                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </Container>
                            <br></br>
                            <br></br>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={false}
                                total_record={this.state.totalCount}
                                loading={true}
                                exportToCSV={this.exportToCSV}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                    <ExpandGraph totalRx={this.state.totalRx} expand={this.state.expand} recordsGraph={this.state.recordsGraph} rxGraph={this.state.rxGraph} totalRxGraph={this.state.totalRxGraph} />

                </div>
                <div id="graphPDF" style={{ display: this.state.exporting ? "block" : "none" }}>
                    <ExportGraph
                        totalRx={this.state.totalRx}
                        recordsGraph={this.state.recordsGraph}
                        rxGraph={this.state.rxGraph}
                        totalRxGraph={this.state.totalRxGraph}
                        z={this.state.z}
                        totalRev={this.state.totalRev}
                        totalRet={this.state.totalRet}
                        totalest={this.state.totalest}
                        // totalRx={this.state.totalRx}
                        totalbrand={this.state.totalbrand}
                        totalgen={this.state.totalgen}
                        totalotc={this.state.totalotc}
                        totalavg={this.state.totalavg}
                        totalgp={this.state.totalgp}
                        totalgpper={this.state.totalgpper}
                    // z={this.state.z}
                    />
                </div>
            </>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    multistoreData: state.multistoreData,
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(BusinessTool);
