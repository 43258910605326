import React, { Component } from "react";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";
import { logoutUser } from "../../actions/authActions";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "react-collapse";
import { Link } from "react-router-dom";
import usState from "../../utils/state.json";
const selectFilter = ["state"];
class OtpDirectory extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "program_name",
        text: "Program Name",
        className: "program_name",
        align: "left",
        sortable: true,
      },
      {
        key: "dba",
        text: "DBA",
        className: "dba",
        align: "left",
        sortable: true,
      },
      {
        key: "street",
        text: "Street",
        className: "street",
        align: "left",
        sortable: true,
      },
      {
        key: "city",
        text: "City",
        className: "city",
        align: "left",
        sortable: true,
      },
      {
        key: "state",
        text: "State",
        className: "state",
        align: "left",
        sortable: true,
      },
      {
        key: "zip",
        text: "Zip",
        className: "zip",
        align: "left",
        sortable: true,
      },
    ];

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "DEA List",
      no_data_text: "No record found!",
      button: {
        excel: false,
        // print: false,
        // print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "program_name", order: "asc" },
      },
      currentRecord: {},
      state: undefined,
      program_name: undefined,
      zip: undefined,
      loading: false,
      isFilterOpen: true,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.program_name !== "" && {
        program_name: this.state.program_name,
      }),
      ...(this.state.zip !== "" && { zip: this.state.zip }),
      ...(this.state.state !== "" && { state: this.state.state }),
    };
    axios
      .post("/api/otps", filterData)
      .then((res) => {
        let result = [];
        for (let i = 0; i < res.data.records.length; i++) {
          let each = res.data.records[i];
          result.push(each);
        }
        this.setState({
          records: result,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChange = (e) => {
    const id = e.target.id;
    this.setState({ [e.target.id]: e.target.value }, () =>
      this.checkFilter(id)
    );
  };

  checkFilter = (id) => {
    if (selectFilter.includes(id)) {
      this.getData();
    }
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  resetSearch = () => {
    this.setState(
      {
        program_name: undefined,
        zip: undefined,
        state: undefined,
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "program_name", order: "asc" },
        },
      },
      () => this.getData()
    );
  };

  toggleChange = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  exportToCSV = () => {
    toast.success("Dowloading...Please Wait!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    var headers = {
      program_name: "Program Name",
      dba: "DBA",
      street: "Street",
      city: "City",
      state: "State",
      zip: "ZIP",
      certification: "Certification",
      full_certification: "Full Certification",
    };
    let filterData = {
      page_number: 1,
      page_size: 80000,
      sort_order: { column: "program_name", order: "asc" },
    };
    filterData.search = {
      ...(this.state.last_name !== "" && { last_name: this.state.last_name }),
      ...(this.state.first_name !== "" && {
        first_name: this.state.first_name,
      }),
      ...(this.state.state !== "" && { state: this.state.state }),
      ...(this.state.zip !== "" && { zip: this.state.zip }),
      ...(this.state.npi !== "" && { npi: this.state.npi }),
      ...(this.state.city !== "" && { city: this.state.city }),
    };
    axios
      .post("/api/otps", filterData)
      .then((res) => {
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false });
        for (let i in res.data.records) {
          let record = res.data.records[i];
          delete record._id;
          delete record.id;
          delete record.__v;

          record.city = '"' + record.city + '"';
          record.address = '"' + record.address + '"';
          record.specialty = '"' + record.specialty + '"';
          record.general = '"' + record.general + '"';
          // record.additional_info = '"' + record.additional_info + '"'
          // record.dba = '"' + record.dba + '"'
          // record.ndc_label = '"' + record.ndc_label + '"'

          records.push(record);
        }
        if (headers) {
          records.unshift(headers);
        }
        // Convert Object to JSON
        // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
        // var universalBOM = "\uFEFF";
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
        var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
          type: "application/csv;charset=UTF-8",
        });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  convertToCSV(objArray) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }
  exportToPDF = (from, to) => {
    let filterData = {
      page_number: 1,
      page_size: 2000,
      sort_order: { column: "_id", order: "asc" },
      search: {},
    };
    let sTable = "";
    sTable += "<table>";
    sTable += "<thead>";
    sTable += "<tr>";
    for (let column of this.columns) {
      sTable += "<th>" + column.text + "</th>";
    }
    sTable += "</tr>";
    sTable += "</thead>";
    sTable += "<tbody>";
    for (let i in this.state.records) {
      let record = this.state.records[i];
      sTable += "<tr>";
      for (let column of this.columns) {
        if (column.cell && typeof column.cell === "function") {
          sTable += "<td></td>";
        } else if (record[column.key]) {
          sTable += "<td>" + record[column.key] + "</td>";
        } else {
          sTable += "<td></td>";
        }
      }
      sTable += "</tr>";
    }
    sTable += "</tbody>";
    sTable += "</table>";

    var style = "<style>";
    style = style + "table {width: 100%;font: 17px Calibri;}";
    style =
      style +
      "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;textAlign:left;}";
    style = style + "</style>";

    var win = window.open("_blank");
    win.document.write("<html><head>");
    win.document.write("<title>" + this.config.filename + "</title>");
    win.document.write(style);
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write("<h1>" + this.config.filename + "</h1>");
    win.document.write(sTable);
    win.document.write("</body></html>");
    win.print();
    win.close();
  };

  render() {
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">OTP Directory</h1>
            <button className="btn btn-warning">
              <FontAwesomeIcon icon={faBackward} />
              <Link to="/dashboard"> Go to Dashboard</Link>
            </button>
            <br></br>
            <br></br>
            {/* <p className="help-text font-weight-bold h6">This is the fastest, most comprehensive search tool of the DEA controlled substances act database</p> */}

            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={this.state.isFilterOpen}
                  onClick={this.toggleChange}
                  id="isFilterOpen"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Show Filter
                </label>
              </div>
              <Collapse isOpened={this.state.isFilterOpen}>
                <form noValidate onSubmit={this.onSearch}>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="program_name">Program Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.program_name}
                        id="program_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.last_name}
                        id="last_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="state">State</label>
                      <select
                        name="state"
                        onClick={this.onChange}
                        className="form-control"
                        id="state"
                      >
                        <option value="" defaultValue>
                          Any
                        </option>
                        {Object.keys(usState).map((each, i) => (
                          <option
                            key={i}
                            value={each}
                            selected={this.state.state === each}
                          >
                            {usState[each]}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="zip">Zip Code</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.zip}
                        id="zip"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Filter
                  </button>
                  <button
                    type="reset"
                    onClick={this.resetSearch}
                    className="btn btn-secordry"
                  >
                    Reset
                  </button>
                </form>
              </Collapse>
            </div>
            <br></br>
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
              exportToCSV={this.exportToCSV}
              exportToPDF={this.exportToPDF}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps, { logoutUser })(OtpDirectory);
