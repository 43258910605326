import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { toast } from 'react-toastify';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import isEmpty from 'is-empty';



import 'react-toastify/dist/ReactToastify.css';

class AddNpi extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            address_city: "",
            address_fax: "",
            address_first_line: "",
            address_postal: "",
            address_second_line: "",
            address_state: "",
            address_telephone: "",
            first_name: "",
            last_name: "",
            middle_name: "",
            npi: "",
            errors: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-doctor-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    setFromDate = (date) => {
        this.setState({ enumeration_date: date });
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onDoctorAdd = e => {
        e.preventDefault();
        let {errors} = this.state
        if(this.state.address_city===''){
            errors.address_city = "This field is required"
        }else{
            delete errors.address_city
        }
        if(this.state.address_first_line===''){
            errors.address_first_line = "This field is required"
        }else{
            delete errors.address_first_line
        }
        if(this.state.address_postal===''){
            errors.address_postal = "This field is required"
        }else{
            delete errors.address_postal
        }
        if(this.state.address_second_line===''){
            errors.address_second_line = "This field is required"
        }else{
            delete errors.address_second_line
        }
        if(this.state.address_state===''){
            errors.address_state = "This field is required"
        }else{
            delete errors.address_state
        }
        if(this.state.address_telephone===''){
            errors.address_telephone = "This field is required"
        }else{
            delete errors.address_telephone
        }
        if(this.state.first_name===''){
            errors.first_name = "This field is required"
        }else{
            delete errors.first_name
        }
        if(this.state.last_name===''){
            errors.last_name = "This field is required"
        }else{
            delete errors.last_name
        }
        if(this.state.npi===''){
            errors.npi = "This field is required"
        }else{
            delete errors.npi
        }if(this.state.taxonomy===''){
            errors.taxonomy = "This field is required"
        }else{
            delete errors.taxonomy
        }
        if(isEmpty(errors)){
        const newDoctor = {
            address_city: this.state.address_city,
            address_fax: this.state.address_fax,
            address_first_line: this.state.address_first_line,
            address_postal: this.state.address_postal,
            address_second_line: this.state.address_second_line,
            address_state: this.state.address_state,
            address_telephone: this.state.address_telephone,
            first_name: this.state.first_name,
            middle_name: this.state.middle_name,
            last_name: this.state.last_name,
            npi: this.state.npi,
        };
        axios.post('/api/doctor-add', newDoctor)
            .then((response) => {
                if (this.props.getData) {
                    this.props.getData();
                }
                $('#add-doctor-modal').modal('hide');
            }, (error) => {
                console.log(error);
            });
        }else{
            this.setState({errors})
        }

        //this.props.addUser(newUser, this.props.history);
    };
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-doctor-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add NPI</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onDoctorAdd} id="add-doctor">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="npi">NPI</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.npi}
                                                id="npi"
                                                type="text"
                                                error={errors.npi}
                                                className={classnames("form-control", {
                                                    invalid: errors.npi
                                                })} />
                                            <span className="text-danger">{errors.npi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="first_name">First Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.first_name}
                                                error={errors.first_name}
                                                id="first_name"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.first_name
                                                })}
                                            />
                                            <span className="text-danger">{errors.first_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="middle_name">Middle Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.middle_name}
                                                id="middle_name"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.middle_name
                                                })}
                                            />
                                            <span className="text-danger">{errors.middle_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="last_name">Last Name (Legal Name)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.last_name}
                                                id="last_name"
                                                type="text"
                                                error={errors.last_name}
                                                className={classnames("form-control", {
                                                    invalid: errors.last_name
                                                })} />
                                            <span className="text-danger">{errors.last_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="address_first_line">First Line Business Mailing Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address_first_line}
                                                id="address_first_line"
                                                type="text"
                                                error={errors.address_first_line}
                                                className={classnames("form-control", {
                                                    invalid: errors.address_first_line
                                                })} />
                                            <span className="text-danger">{errors.address_first_line}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="address_second_line">Second Line Business Mailing Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address_second_line}
                                                id="address_second_line"
                                                type="text"
                                                error={errors.address_second_line}
                                                className={classnames("form-control", {
                                                    invalid: errors.address_second_line
                                                })} />
                                            <span className="text-danger">{errors.address_second_line}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="address_city">Business Mailing Address City Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address_city}
                                                id="address_city"
                                                type="text"
                                                error={errors.address_city}
                                                className={classnames("form-control", {
                                                    invalid: errors.address_city
                                                })} />
                                            <span className="text-danger">{errors.address_city}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="address_state">Business Mailing Address State Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address_state}
                                                id="address_state"
                                                type="text"
                                                error={errors.street}
                                                className={classnames("form-control", {
                                                    invalid: errors.address_state
                                                })} />
                                            <span className="text-danger">{errors.address_state}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="address_postal"> Business Mailing Address Postal Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address_postal}
                                                id="address_postal"
                                                type="text"
                                                error={errors.address_postal}
                                                className={classnames("form-control", {
                                                    invalid: errors.address_postal
                                                })} />
                                            <span className="text-danger">{errors.address_postal}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="address_telephone"> Business Mailing Address Telephone Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address_telephone}
                                                id="address_telephone"
                                                type="text"
                                                error={errors.state}
                                                className={classnames("form-control", {
                                                    invalid: errors.address_telephone
                                                })} />
                                            <span className="text-danger">{errors.address_telephone}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="practice_postal"> Business Practice Location Address Postal Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.practice_postal}
                                                id="practice_postal"
                                                type="text"
                                                error={errors.practice_postal}
                                                className={classnames("form-control", {
                                                    invalid: errors.practice_postal
                                                })} />
                                            <span className="text-danger">{errors.practice_postal}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-doctor"
                                    type="submit"
                                    // data-toggle="modal" data-target="#add-doctor-modal"
                                    className="btn btn-primary">
                                    Add NPI
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AddNpi;
