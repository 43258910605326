import React, { Component, Fragment } from "react";
import ReactDatatable from '../../../utils/ReactDatatable';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import "react-datepicker/dist/react-datepicker.css";
import '../../../App.css';
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment"
import $ from 'jquery';

class CompoundView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // record: undefined,
            totalCount: 0,
            records: 0,

        };
    }

    onPrint = () => {
        var style = "<style>";
        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;textAlign:left;}";
        style = style + "</style>";
        var win = window.open('', '_blank');
        win.document.write('<html><head>');
        win.document.write('<title>Compound Detail</title>');
        win.document.write(style);
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write($("#show-physician-medication").html());
        win.document.write('</body></html>');
        win.print();
        win.close();
    }


    render() {
        return (

            <div>
                <div className="modal" id="show-physician-medication" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        {this.props.record &&
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Date: {this.props.record.date}</h5><br />
                                    <h5 className="float-right-padding">RX: {this.props.record.rx}</h5>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">INGREDIENT</th>
                                                <th scope="col">AMOUNT USED</th>
                                                <th scope="col">SUPPLIER</th>
                                                <th scope="col">LOT</th>
                                                <th scope="col">EXPIRY DATE</th>
                                                <th scope="col">FORMULA</th>
                                                <th scope="col">SIZE</th>
                                                <th scope="col">FORM</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.props.record.ingrediants !== undefined && this.props.record.ingrediants.map(each => {
                                                if (each.expiry_date !== '') {
                                                    each.expiry_date = moment(each.expiry_date).format('L');
                                                }
                                                return (
                                                    <tr key={each} className="form group">
                                                        <td><label htmlFor={each.ingre_name}><h6>{each.ingre_name}</h6></label></td>

                                                        <td><label htmlFor={each.amt_used}><h6>{each.amt_used}</h6></label></td>
                                                        <td><label htmlFor={each.supplier}><h6>{each.supplier}</h6></label></td>
                                                        <td><label htmlFor={each.lot}><h6>{each.lot}</h6></label></td>
                                                        <td><label htmlFor={each.expiry_date}><h6>{each.expiry_date}</h6></label></td>
                                                        <td><label htmlFor={each.formula}><h6>{each.formula}</h6></label></td>
                                                        <td><label htmlFor={each.size}><h6>{each.size}</h6></label></td>
                                                        <td><label htmlFor={each.type}><h6>{each.type}</h6></label></td>
                                                        {/* <td><label htmlFor={this.props.record.bud}><h6>{this.props.record.bud}</h6></label></td> */}

                                                        {/* <td><label htmlFor={this.props.record.qty}><h6>{this.props.record.qty}</h6></label></td> */}



                                                    </tr>
                                                )

                                            })}



                                        </tbody>
                                    </table>
                                    {this.props.record.qty !== undefined && (<h5 className="modal-title">RX Quantity: {this.props.record.qty}</h5>)}
                                    {this.props.record.bud !== undefined && (<h5 className="modal-title">BUD: {this.props.record.bud}</h5>)}
                                    {this.props.record.comment !== undefined && (<h5 className="modal-title">Comment: {this.props.record.comment}</h5>)}
                                    <button
                                        onClick={this.onPrint}
                                        type="button"
                                        style={{ float: "right" }}
                                        className="btn btn-primary">
                                        Print
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

}
// const mapStateToProps = state => ({
//     auth: state.auth,
// });
// export default connect(
//     mapStateToProps
// )(CompoundView);

export default (CompoundView)
