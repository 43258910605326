import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import ReactDatatable from '../../../utils/ReactDatatable';
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import '../../../App.css';

class LoginHistory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            totalCount: 0,
            records: [],
            userEmail: undefined
        };

        this.columns = [
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: true,
            },
            {
                key: "pharmacy_account",
                text: "Owner Email",
                className: "pharmacy_account",
                align: "left",
                sortable: true,
            },
            {
                key: "location",
                text: "Location",
                className: "location",
                align: "left",
                sortable: true,
            },
            {
                key: "ip",
                text: "IP",
                className: "ip",
                align: "left",
                sortable: true,
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                align: "left",
                sortable: true,
            },{
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
            }
        ];


        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Stats",
            no_data_text: 'No result found!',
            button: {
                excel: false,
                print: false,
                csv: false
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };
    }

    componentDidMount() {
        this.setState({loading: true})
        const filterDataUser = {
            page_number: 1,
            page_size: 2000,
            sort_order: { column: "_id", order: "desc" },
            search: { _id: this.props.match.params._id }
        }

        axios
            .post(`/api/user-data`, filterDataUser)
            .then(user => {
                if (user.data.records[0]) {
                    const filterData = {
                        page_number: 1,
                        page_size: 2000,
                        sort_order: { column: "_id", order: "desc" }
                        , search: { pharmacy_account: user.data.records[0].email }
                    }
                    axios
                        .post(`/api/user-logs`, filterData)
                        .then(res => {
                            for (let i = 0; i < res.data.records.length; i++) {
                                res.data.records[i].date = res.data.records[i].date ? moment(res.data.records[i].date).format('L, h:mm:ss a') : ""
                                let ipArr = res.data.records[i].ip.split(":")
                                res.data.records[i].ip = ipArr[ipArr.length-1]
                            }
                            this.setState({
                                records: res.data.records,
                                totalCount: res.data.count,
                                loading: false
                            })
                        })
                        .catch(function (error) {
                            if (error.response) {
                                if (error.response.status === 403) {
                                    window.location.href = "./login"
                                }
                            }
                        })
                }


            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })


    }

    exportToCSV = () => {
        var headers = {
            "dispense_date": "Dispense Date",
            "ndc": "NDC",
            "drug_name": "Drug Name",
            "qty_dispensed": "Qty Dispensed",
            "rx_number": "Rx Number",
            "patient_name": "Patient Name",
            "patient_dob": "Patient DOB",
            "patient_zip": "Patient Zip",
        };
        // // add columns in sheet array

        var records = [

        ];
        // add data rows in sheet array
        this.setState({ loading: false })
        for (let i in this.props.data.records) {
            let record = this.props.data.records[i]
            records.push(record);
        }
        if (headers) {
            records.unshift(headers);
        }
        // Convert Object to JSON
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    convertToCSV(objArray, headers) {
        const keys = Object.keys(headers);

        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in keys) {
                line += array[i][keys[index]] === undefined ? "," : array[i][keys[index]] + ",";
                line.trim(",")
            }
            str += line + '\r\n';
        }
        return str;
    }

    render() {
        return (
            <div className="d-flex group-list" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}

                <div id="page-content-wrapper">
                    <div className="container-fluid">

                        <h1 className="mt-2 text-primary">User Login History</h1>
                        <button className="btn btn-warning" ><FontAwesomeIcon icon={faBackward} /><Link to="/users"> Go to User List</Link></button>
                        <br></br>

                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onChange={this.filter}
                            dynamic={false}
                            total_record={this.state.totalCount}
                            loading={true}
                        />
                    </div>
                </div>
            </div>
        )
    }

}
export default (LoginHistory)
