import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { toast } from 'react-toastify';
import $ from 'jquery';
import isEmpty from 'is-empty';
import { connect } from "react-redux";

import 'react-toastify/dist/ReactToastify.css';

class CouponUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            brand: "",
            copay: "",
            bin: "",
            copay_max: "",
            rxpcn: "",
            max_saving_transaction: "",
            description: "",
            description: "",
            link: "",
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                ...nextProps.record
            })
        }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors

            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#update-coupon-modal').modal('hide');

            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onCouponUpdate = e => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.brand === '') {
            errors.brand = "This field is required"
        } else {
            delete errors.brand
        }
        // if (this.state.time === '') {
        //     errors.time = "This field is required"
        // } else {
        //     delete errors.time
        // }
        if (this.state.link === '') {
            errors.link = "This field is required"
        } else {
            delete errors.link
        }


        if (isEmpty(errors)) {
            const newCoupon = {
                _id: this.state._id,
                brand: this.state.brand,
                copay: this.state.copay,
                copay_max: this.state.copay_max.replace(/\$/g, ''),
                lifetime_uses: this.state.lifetime_uses,
                max_saving_transaction: this.state.max_saving_transaction.replace(/\$/g, ''),
                bin: this.state.bin,
                rxpcn: this.state.rxpcn,
                description: this.state.description,
                link: this.state.link,
            };
            axios.post('/api/coupon-update', newCoupon)
                .then((response) => {
                    //this.props.getData();
                    $('#update-coupon-modal').modal('hide');
                    this.setState({
                        brand: "",
                        copay: "",
                        bin: "",
                        copay_max: "",
                        rxpcn: "",
                        max_saving_transaction: "",
                        description: "",
                        link: "",
                    })
                    this.props.getData();
                }, (error) => {
                    console.log(error);
                });

        } else {
            this.setState({ errors })
        }

        //this.props.addUser(newUser, this.props.history);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-coupon-modal" data-reset="false">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Coupon</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCouponUpdate} id="update-coupon">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="temperture">Brand</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.brand}
                                                id="brand"
                                                type="text"
                                                error={errors.brand}
                                                className={classnames("form-control", {
                                                    invalid: errors.brand
                                                })} />
                                            <span className="text-danger">{errors.brand}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="temperture">Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.link}
                                                id="link"
                                                type="text"
                                                error={errors.link}
                                                className={classnames("form-control", {
                                                    invalid: errors.link
                                                })} />
                                            <span className="text-danger">{errors.link}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="temperture">Copay</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.copay}
                                                id="copay"
                                                type="text"
                                                error={errors.copay}
                                                className={classnames("form-control", {
                                                    invalid: errors.copay
                                                })} />
                                            <span className="text-danger">{errors.copay}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="temperture">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.description}
                                                id="description"
                                                type="text"
                                                error={errors.description}
                                                className={classnames("form-control", {
                                                    invalid: errors.description
                                                })} />
                                            <span className="text-danger">{errors.description}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="temperture">RXPCN</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.rxpcn}
                                                id="rxpcn"
                                                type="text"
                                                error={errors.rxpcn}
                                                className={classnames("form-control", {
                                                    invalid: errors.rxpcn
                                                })} />
                                            <span className="text-danger">{errors.rxpcn}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="temperture">BIN</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.bin}
                                                id="bin"
                                                type="text"
                                                error={errors.bin}
                                                className={classnames("form-control", {
                                                    invalid: errors.bin
                                                })} />
                                            <span className="text-danger">{errors.bin}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="temperture">Copay Max($)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.copay_max.replace(/\$/g, '')}
                                                id="copay_max"
                                                type="text"
                                                error={errors.copay_max}
                                                className={classnames("form-control", {
                                                    invalid: errors.copay_max
                                                })} />
                                            <span className="text-danger">{errors.copay_max}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="temperture">Lifetime Uses</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.lifetime_uses}
                                                id="lifetime_uses"
                                                type="text"
                                                error={errors.lifetime_uses}
                                                className={classnames("form-control", {
                                                    invalid: errors.lifetime_uses
                                                })} />
                                            <span className="text-danger">{errors.lifetime_uses}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="temperture">Max Saving Transaction</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.max_saving_transaction}
                                                id="max_saving_transaction"
                                                type="text"
                                                error={errors.max_saving_transaction}
                                                className={classnames("form-control", {
                                                    invalid: errors.max_saving_transaction
                                                })} />
                                            <span className="text-danger">{errors.max_saving_transaction}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-coupon"
                                    type="submit"
                                    // data-toggle="modal" data-target="#add-drug-modal"
                                    className="btn btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(
    mapStateToProps
)(CouponUpdateModal);
