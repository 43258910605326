import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { toast } from 'react-toastify';
import $ from 'jquery';
import isEmpty from 'is-empty';
import { connect } from "react-redux";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class GroupEmailTemplatesAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: undefined,
            solution: undefined,
            type: "image",
            text: undefined,
            image: undefined,
            subject: undefined,
            frequency: undefined,
            threshold: undefined,
            email_excluded: undefined,
            status: "enable",
            errors: {},
            editorState: EditorState.createEmpty(),
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors

            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-groupEmailTemplates-modal').modal('hide');

            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onAdd = e => {
        let text = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        e.preventDefault();
        let { errors } = this.state
        if (this.state.name === '' || this.state.name === undefined) {
            errors.name = "This field is required"
        } else {
            delete errors.name
        }
        if (this.state.frequency === '' || this.state.frequency === undefined) {
            errors.frequency = "This field is required"
        } else {
            delete errors.frequency
        }
        if (this.state.threshold === '' || this.state.threshold === undefined) {
            errors.threshold = "This field is required"
        } else {
            delete errors.threshold
        }
        if (this.state.solution === '' || this.state.solution === undefined) {
            errors.solution = "This field is required"
        } else {
            delete errors.solution
        }
        if (this.state.type === "image" && this.state.image === undefined) {
            errors.image = "This field is required"
        } else {
            delete errors.image
        }
        if (this.state.type === "text" && text === "<p></p>") {
            errors.text = "This field is required"
        } else {
            delete errors.text
        }
        if (isEmpty(errors)) {
            const formData = new FormData();
            if (this.state.image !== "" && this.state.image !== undefined)
                formData.append("image", this.state.image);
            if (this.state.name !== "" && this.state.name !== undefined)
                formData.append("name", this.state.name);
            if (this.state.type === "text")
                formData.append("text", text);
            if (this.state.type === "subject")
                formData.append("text", this.state.subject);

            formData.append("status", this.state.status === "enable" ? true : false);
            formData.append("solution", this.state.solution);
            formData.append("type", this.state.type);
            formData.append("subject", this.state.subject);
            formData.append("frequency", this.state.frequency);
            formData.append("threshold", this.state.threshold);
            formData.append("email_excluded", this.state.email_excluded);


            axios.post('/groupEmail/add-emailTemplates', formData)
                .then((response) => {
                    
                    this.setState({
                        name: undefined,
                        type: "image",
                        image: undefined,
                        solution: undefined,
                        subject: undefined,
                        frequency: undefined,
                        threshold: undefined,
                        email_excluded: undefined
                    })
                    this.props.getData();
                    $('#add-groupEmailTemplates-modal').modal('hide');
                }, (error) => {
                    console.log(error);
                });

        } else {
            this.setState({ errors })
        }
    };

    onImageChange = event => {
        this.setState({
            [event.target.id]: event.target.files[0],
        });
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    render() {

        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-groupEmailTemplates-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Template</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <form noValidate onSubmit={this.onAdd} id="add-groupEmail">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })} />
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="frequency">Frequency</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.frequency}
                                                id="frequency"
                                                type="text"
                                                error={errors.frequency}
                                                className={classnames("form-control", {
                                                    invalid: errors.frequency
                                                })} />
                                            <span className="text-danger">{errors.frequency}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="threshold">Threshold</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.threshold}
                                                id="threshold"
                                                type="text"
                                                error={errors.threshold}
                                                className={classnames("form-control", {
                                                    invalid: errors.threshold
                                                })} />
                                            <span className="text-danger">{errors.threshold}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="subject">Subject</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.subject}
                                                id="subject"
                                                type="text"
                                                error={errors.subject}
                                                className={classnames("form-control", {
                                                    invalid: errors.subject
                                                })} />
                                            <span className="text-danger">{errors.subject}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="solution">Solution</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} selected={this.state.solution} error={errors.solution} id="solution" className={classnames("form-control", { invalid: errors.solution })}>
                                                <option value="">Please Select</option>
                                                <option value="rxpodcast" selected={this.state.solution === "rxpodcast"}>RxPodcast</option>
                                                <option value="rxminder" selected={this.state.solution === "rxminder"}>Rxminder</option>
                                                <option value="tabularx" selected={this.state.solution === "tabularx"}>TabulaRx</option>
                                                <option value="bonarx" selected={this.state.solution === "bonarx"}>BonaRx</option>
                                                <option value="doorsteprx" selected={this.state.solution === "doorsteprx"}>DoorstepRx</option>
                                                <option value="rxreach" selected={this.state.solution === "rxreach"}>RxReach</option>
                                                <option value="xpedicare" selected={this.state.solution === "xpedicare"}>Xpedicare</option>
                                                <option value="alererx" selected={this.state.solution === "alererx"}>AlereRx</option>
                                            </select>
                                            <span className="text-danger">{errors.solution}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="type">Type</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} selected={this.state.type} error={errors.type} id="type" className={classnames("form-control", { invalid: errors.type })}>
                                                <option value="image">Image</option>
                                                <option value="text">Text</option>
                                            </select>
                                            <span className="text-danger">{errors.type}</span>
                                        </div>
                                    </div>
                                    {this.state.type === "text" ?
                                        (<div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="text">Text</label>
                                            </div>
                                            <div className="col-md-9">
                                                <Editor
                                                    editorState={this.state.editorState}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={this.onEditorStateChange}
                                                />;
                                                <span className="text-danger">{errors.text}</span>
                                            </div>
                                        </div>)
                                        : this.state.type === "image" ?
                                            (<div className="row mt-2">
                                                <div className="col-md-3">
                                                    <label htmlFor="image">Image</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input
                                                        onChange={this.onImageChange}
                                                        id="image"
                                                        type="file"
                                                        error={errors.image}
                                                        className={classnames("form-control", {
                                                            invalid: errors.image
                                                        })} />
                                                    <span className="text-danger">{errors.image}</span>
                                                </div>
                                            </div>)
                                            : ""}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="status">Status</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} selected={this.state.status} error={errors.status} id="status" className={classnames("form-control", { invalid: errors.status })}>
                                                <option value="enable">Enable</option>
                                                <option value="disable">Disable</option>
                                            </select>
                                            <span className="text-danger">{errors.status}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email_excluded">Email Excluded</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.email_excluded}
                                                id="email_excluded"
                                                type="text"
                                                error={errors.email_excluded}
                                                className={classnames("form-control", {
                                                    invalid: errors.email_excluded
                                                })} />
                                            <span className="text-danger">{errors.email_excluded}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-groupEmail"
                                    type="submit"
                                    // data-toggle="modal" data-target="#add-drug-modal"
                                    className="btn btn-primary">
                                    Add Email
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(
    mapStateToProps
)(GroupEmailTemplatesAdd);
