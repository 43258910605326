import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddVendor from "../partials/AddVendor";

const CompareRx = () => {
  return (
    <div className="d-flex" id="wrapper">
        <AddVendor/>
      <button
        className="btn btn-primary float-right mt-3 mr-2"
        data-toggle="modal"
        data-target="#add-comparerx-modal"
      >
        <FontAwesomeIcon icon={faPlus} /> Add Vendor
      </button>
    </div>
  );
};
export default CompareRx;
