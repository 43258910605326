import React, { Component, Fragment } from "react";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { faPlus, faBackward } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import DispenseUpdateModal from "../partials/DispenseUpdateModal";
import UploadDispenseBefore from "../partials/UploadDispenseBefore";
import GroupFilter from "./Filter/GroupFilter.js";
import DispenseAddModal from "../partials/DispenseAddModal";
import DatePicker from "react-datepicker";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import dashboard from "../../utils/dashboard.png";
import { logoutUser } from "../../actions/authActions";

class Dispense extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "dispense_date",
        text: "Dispense Date",
        className: "dispense_date",
        align: "left",
        sortable: true,
      },
      {
        key: "drug_name",
        text: "Drug Name",
        className: "drug_name",
        align: "left",
        sortable: true,
      },
      {
        key: "ndc",
        text: "NDC",
        className: "ndc",
        align: "left",
        sortable: true,
      },
      {
        key: "dea",
        text: "DEA",
        className: "dea",
        align: "left",
        sortable: true,
      },
      {
        key: "rx_number",
        text: "RX number",
        className: "rx_number",
        align: "left",
        sortable: true,
      },
      {
        key: "qty_dispensed",
        text: "Qty Disp",
        className: "qty_dispensed",
        align: "left",
        sortable: true,
        number: true,
      },
      {
        key: "day_supply",
        text: "Day Supply",
        className: "day_supply",
        align: "left",
        sortable: true,
        number: true,
      },
      {
        key: "patient_name",
        text: "Patient Initials",
        className: "patient_name",
        align: "left",
        sortable: true,
      },
      {
        key: "patient_dob",
        text: "Patient DOB",
        className: "patient_dob",
        sortable: true,
      },
      {
        key: "payment_type",
        text: "Cash",
        className: "payment_type",
        align: "left",
        sortable: true,
      },
      {
        key: "doctor_npi",
        text: "NPI",
        className: "doctor_npi",
        align: "left",
        sortable: true,
      },
      {
        key: "on_hand_qty",
        text: "On-Hand Qty",
        className: "on_hand_qty",
        align: "left",
        sortable: true,
        number: true,
      },
      {
        key: "on_hand_initials",
        text: "On-Hand Initials",
        className: "on_hand_initials",
        align: "left",
        sortable: true,
      },
      {
        key: "pharmacy_initial",
        text: "Pharmacist",
        className: "pharmacy_initial",
        align: "left",
        sortable: true,
        number: true,
      },
      {
        key: "notes",
        text: "Notes",
        className: "notes",
        align: "left",
        sortable: true,
      },
      {
        key: "is_reverse_distribution",
        text: "Rev Dist",
        className: "is_reverse_distribution",
        align: "left",
        sortable: true,
        rev_des: true,
      },
      {
        key: "adjusted",
        text: "Quick Adj",
        className: "adjusted",
        align: "left",
        sortable: true,
        rev_des: true,
      },
    ];
    if (this.props.auth.user.user_type !== "Technician") {
      this.columns.push({
        key: "user_created",
        text: "Created By",
        className: "user_created",
        align: "left",
        sortable: true,
      });
    }
    if (!this.props.auth.user.superadmin && !this.props.auth.user.multistore) {
      this.columns.push({
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              {this.props.auth.user.user_type !== "Technician" && (
                <button
                  data-toggle="modal"
                  data-target="#update-dispense-modal"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.editRecord(record)}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-edit"></i>
                </button>
              )}
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      });
    } else {
      this.columns.push({
        key: "pharmacy_account",
        text: "Pharmacy Email",
        className: "pharmacy_account",
        align: "left",
        sortable: true,
      });
    }

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100, 500],
      filename: "Dispense List",
      no_data_text: "No Dispense found!",
      button: {
        excel: false,
        print: false,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
      sort: {
        column: "dispense_date",
        order: "desc",
      },
      name: "dispense",
    };

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "dispense_date", order: "desc" },
      },
      currentRecord: {},
      ncDrugs: undefined,
      drug_name: undefined,
      ndc: undefined,
      rx_group_associated: "",
      plainArray: ["2", "3", "4", "5", "2N", "3N", "NC"],
      deaSelected: [],
      rx_number: undefined,
      from_date: moment().subtract(30, "days").startOf("day").toDate(),
      to_date: moment().endOf("day").toDate(),
      is_reverse_distribution: undefined,
      adjusted: undefined,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getDrugData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.multistoreData.length !== prevProps.multistoreData.length) {
      this.getData();
    }
  }

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.from_date !== "" && { from_date: this.state.from_date }),
      ...(this.state.to_date !== "" && { to_date: this.state.to_date }),
      ...(this.state.ndc !== "" && { ndc: this.state.ndc }),
      ...(this.state.drug_name !== "" && { drug_name: this.state.drug_name }),
      ...(this.state.rx_number !== "" && { rx_number: this.state.rx_number }),
      ...(this.state.is_reverse_distribution !== "" && {
        is_reverse_distribution: this.state.is_reverse_distribution,
      }),
      ...(this.state.adjusted !== "" && { adjusted: this.state.adjusted }),
      ...(this.state.deaSelected.length > 0 && { dea: this.state.deaSelected }),
      pharmacy_account:
        this.state.rx_group_associated !== undefined &&
        this.state.rx_group_associated !== ""
          ? this.state.rx_group_associated
          : this.props.auth.user.final_user,
      ...(this.props.multistoreData.length > 0 &&
        (this.state.rx_group_associated === undefined ||
          this.state.rx_group_associated === "") && {
          multiStoreEmails: this.props.multistoreData.map((a) => a.email),
        }),
      exact: true,
    };
    axios
      .post("/api/dispense", filterData)
      .then((res) => {
        var result = [];
        // add data rows in sheet array
        for (let i in res.data.records) {
          let each = res.data.records[i];
          if (each.is_reverse_distribution !== undefined) {
            each.is_reverse_distribution = "Yes";
          }
          if (each.adjusted !== undefined) {
            each.adjusted = "Yes";
          }

          each.orig_dispense_date = each.dispense_date;
          each.dispense_date = each.dispense_date
            ? moment(each.dispense_date).add(5, "hours").format("L")
            : "";
          each.patient_dob = each.patient_dob
            ? moment(each.patient_dob).add(5, "hours").format("L")
            : "";
          result.push(each);
        }
        this.setState({
          records: result,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  getDrugData() {
    this.setState({ loading: true });
    axios
      .post("/api/nc-drugs")
      .then((res) => {
        this.setState({
          ncDrugs: res.data.records,
          loading: false,
        });
      })

      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  editRecord(record) {
    this.setState({ currentRecord: record });
  }
  setFromDate = (date) => {
    this.setState({ from_date: date });
  };
  setToDate = (date) => {
    this.setState({ to_date: date });
  };
  deleteRecord(record) {
    if (this.props.auth.user.user_type !== "Technician") {
      confirmAlert({
        title: "Records will be deleted from dispense record",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.finalDelete(record),
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } else {
      confirmAlert({
        title: "Record will delete only after approval from admin.",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.finalDelete(record),
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    }
  }

  finalDelete = (record) => {
    axios
      .post("/api/dispense-delete", {
        _id: record._id,
        delete: this.props.auth.user.user_type === "Technician" ? false : true,
        email: this.props.auth.user.email,
      })
      .then((res) => {
        if (res.status === 200) {
          this.getData();
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    this.getData();
  };

  onChange = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    this.setState({ [e.target.id]: e.target.value }, () =>
      this.checkUPC(id, value.trim())
    );
  };

  checkUPC = (id, value) => {
    if (id === "ndc" && value.length > 11) {
      this.getData();
    }
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  resetSearch = () => {
    this.setState(
      {
        ndc: undefined,
        drug_name: undefined,
        rx_group_associated: "",
        deaSelected: [],
        rx_number: undefined,
        from_date: moment().subtract(7, "days").startOf("day").toDate(),
        to_date: moment().endOf("day").toDate(),
        is_reverse_distribution: undefined,
        adjusted: undefined,
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "_id", order: "asc" },
        },
      },
      () => this.getData()
    );
  };

  exportToCSV = () => {
    toast.success("Dowloading...Please Wait!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    var headers = {
      pharmacyDEA: "PharmacyDEA",
      pharmacyNPI: "PharmacyNPI",
      pharmacyName: "PharmacyName",
      pharmacyState: "PharmacyState",
      ndc: "NDC",
      drug_name: "ItemDescription",
      dispense_date: "PrescriptionSoldDate",
      qty_dispensed: "QuantityDispensed",
      day_supply: "PrescriptionLength",
      doctor_lastname: "PrescriberLastName",
      doctor_firstname: "PrescriberFirstName",
      doctor_middlename: "PrescriberMiddleName",
      doctor_npi: "PrescriberNPI",
      doctor_dea: "PrescriberDEA",
      doctor_add1: "PrescriberAddress1",
      doctor_add2: "PrescriberAddress2",
      doctor_city: "PrescriberCity",
      doctor_state: "PrescriberState",
      doctor_zip: "PrescriberZip",
      payment_type: "IsCashSale",
      patient_zip: "PatientZip",
      patient_name: "PatientInitials",
      patient_dob: "Patient DOB",
      rx_number: "RX Number",
      dea: "DEA Class",
      notes: "Notes",
      pharmacy_initial: "Pharmacy Initial",
      pharmacy_account: "Pharmacy Account",
      user_created: "User Created",
      is_reverse_distribution: " Is Reverse Distribution",
      adjusted: "Quick Adjust",
    };
    var records = [];
    // add data rows in sheet array
    this.setState({ loading: false });
    let filterData = {
      page_number: 1,
      page_size: 20000,
      sort_order: { column: "dispense_date", order: "desc" },
    };
    filterData.search = {
      ...(this.state.from_date !== "" && { from_date: this.state.from_date }),
      ...(this.state.to_date !== "" && { to_date: this.state.to_date }),
      ...(this.state.ndc !== "" && { ndc: this.state.ndc }),
      ...(this.state.drug_name !== "" && { drug_name: this.state.drug_name }),
      ...(this.state.rx_number !== "" && { rx_number: this.state.rx_number }),
      ...(this.state.is_reverse_distribution !== "" && {
        is_reverse_distribution: this.state.is_reverse_distribution,
      }),
      ...(this.state.adjusted !== "" && { adjusted: this.state.adjusted }),
      ...(this.state.deaSelected.length > 0 && { dea: this.state.deaSelected }),
      pharmacy_account:
        this.state.rx_group_associated !== undefined &&
        this.state.rx_group_associated !== ""
          ? this.state.rx_group_associated
          : this.props.auth.user.final_user,
      ...(this.props.multistoreData.length > 0 &&
        (this.state.rx_group_associated === undefined ||
          this.state.rx_group_associated === "") && {
          multiStoreEmails: this.props.multistoreData.map((a) => a.email),
        }),
    };
    axios
      .post("/api/dispense", filterData)
      .then((res) => {
        let emailIds = [];
        for (let i in res.data.records) {
          let record = res.data.records[i];
          record.dispense_date = record.dispense_date
            ? moment(record.dispense_date).add(5, "hours").format("L")
            : "";
          record.patient_dob = record.patient_dob
            ? moment(record.patient_dob).add(5, "hours").format("L")
            : "";
          if (record.is_reverse_distribution !== undefined) {
            record.is_reverse_distribution = "Yes";
          }
          if (record.adjusted !== undefined) {
            record.adjusted = "Yes";
          }
          emailIds.push(record.pharmacy_account);
          records.push(record);
        }

        let filterData = {};
        filterData.search = {
          _ids: [...new Set(emailIds)],
        };
        filterData.sort_order = {
          order: "asc",
          column: "_id",
        };
        filterData.find = "all";

        axios.post("/api/user-data", filterData).then((resp) => {
          let result = resp.data.records;
          for (let i in records) {
            let userFilter = result.find(
              (eachF) => eachF.email === records[i].pharmacy_account
            );
            if (userFilter) {
              records[i].pharmacyDEA = userFilter.dea;
              records[i].pharmacyNPI = userFilter.npi;
              records[i].pharmacyName = userFilter.name;
              records[i].pharmacyState = userFilter.state;
            }
          }

          if (headers) {
            records.unshift(headers);
          }
          let jsonObject = JSON.stringify(records);
          let csv = this.convertToCSV(jsonObject, headers);
          let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
          var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
            type: "application/csv;charset=UTF-8",
          });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
          } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
              // feature detection
              let url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", exportedFilenmae);
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : `"${array[i][keys[index]]}",`;
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }

  openDatepicker = (e) => {
    if (e.target.id === "toD") {
      this.toDatePicker.setOpen(true);
    } else {
      this.fromDatePicker.setOpen(true);
    }
  };

  expand = (type) => {
    this.setState({ expand: type });
  };

  exportPdf = () => {
    this.setState(
      {
        exporting: true,
      },
      () => this._exportPdf()
    );
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  onSelectMultiple = (selectedList, selectedItem) => {
    this.setState({ deaSelected: selectedList });
  };

  redirectToUpload = () => {
    window.location.href = "/upload-dispense";
  };

  _exportPdf = () => {
    html2canvas(document.querySelector("#graphPDF")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
      this.setState({
        exporting: false,
      });
    });
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  render() {
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    return (
      <>
        <div className="d-flex" id="wrapper">
          {this.state.loading && (
            <div className="loading-wrapper">
              <PulseLoader color="#007bff" loading={true} size={20} />
            </div>
          )}
          <DispenseAddModal
            getData={this.getData}
            ncDrugs={this.state.ncDrugs}
          />
          <DispenseUpdateModal
            record={this.state.currentRecord}
            getData={this.getData}
          />
          <UploadDispenseBefore />
          <div id="page-content-wrapper">
            {this.props.auth.user.tabulaRx ||
            this.props.auth.user.superadmin ||
            this.props.auth.user.expiration_tabula === "" ||
            this.props.auth.user.expiration_tabula >= moment().format() ? (
              <div className="container-fluid">
                {!this.props.auth.user.superadmin &&
                  !this.props.auth.user.multistore && (
                    <>
                      <button
                        className="btn btn-primary float-right mt-3 mr-2"
                        data-toggle="modal"
                        data-target="#add-dispense-modal"
                      >
                        <FontAwesomeIcon icon={faPlus} /> Add new Dispense
                        Records
                      </button>
                      {this.props.auth.user.allow_dispensing_sheet ? (
                        <button
                          className="btn btn-primary float-right mt-3 mr-2"
                          onClick={() => this.redirectToUpload()}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Upload Dispense Data
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary float-right mt-3 mr-2"
                          data-toggle="modal"
                          data-target="#upload-dispense-before-modal"
                        >
                          <FontAwesomeIcon icon={faPlus} /> Upload Dispense Data
                        </button>
                      )}
                    </>
                  )}
                <h1 className="mt-2 text-primary">Dispense</h1>
                <button className="btn btn-warning">
                  <FontAwesomeIcon icon={faBackward} />
                  <Link to="/dashboard"> Go to Dashboard</Link>
                </button>
                <br></br>
                <br></br>
                <p>
                  <h5 style={{ fontWeight: "bold" }}>
                    Note: Please only use patient initials for added security.
                  </h5>
                </p>
                <form>
                  <div className="form-row">
                    <GroupFilter
                      onChangeSelect={this.onChangeSelect}
                      type="dispense"
                    />
                    <div className="form-group col-md-3">
                      <label htmlFor="npi">From Date</label>
                      <DatePicker
                        selected={this.state.from_date}
                        onChange={(date) => this.setFromDate(date)}
                        className="form-control"
                        ref={(c) => (this.fromDatePicker = c)}
                      />
                      <i
                        aria-hidden="true"
                        className="fa fa-calendar"
                        id="fromD"
                        onClick={this.openDatepicker}
                      ></i>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="first_name">To Date</label>
                      <DatePicker
                        selected={this.state.to_date}
                        maxDate={moment().toDate()}
                        onChange={(date) => this.setToDate(date)}
                        className="form-control"
                        ref={(c) => (this.toDatePicker = c)}
                      />
                      <i
                        aria-hidden="true"
                        className="fa fa-calendar"
                        id="toD"
                        onClick={this.openDatepicker}
                      ></i>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="ndc">
                        Search Dispense by NDC or Scan Barcode
                      </label>
                      <input
                        onChange={this.onChange}
                        value={this.state.ndc}
                        id="ndc"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="drug_name">Drug Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.drug_name}
                        id="drug_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="drug_name">Class</label>
                      <Multiselect
                        selectedValues={this.state.deaSelected}
                        options={this.state.plainArray}
                        isObject={false}
                        onSelect={this.onSelectMultiple} // Function will trigger on select event
                        onRemove={this.onSelectMultiple} // Function will trigger on remove event
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="rx_number">Rx Number</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.rx_number}
                        id="rx_number"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="is_reverse_distribution">
                        Reverse Distribution
                      </label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="is_reverse_distribution"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="adjusted">Adjusted</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="adjusted"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-3">
                    <button
                      onClick={this.onSearch}
                      type="button"
                      className="btn btn-primary"
                    >
                      Search
                    </button>
                    <button
                      type="reset"
                      onClick={this.resetSearch}
                      className="btn btn-secordry"
                    >
                      Reset
                    </button>
                  </div>
                </form>
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.totalCount}
                  loading={true}
                  exportToCSV={this.exportToCSV}
                  onChange={this.filter}
                />
              </div>
            ) : (
              <div className="container-fluid static-card">
                <div className="card">
                  <div className="card-body">
                    {this.props.auth.user.expiration_tabula <
                    moment().format() ? (
                      <h6 class="font-weight-bold text-danger">
                        Your Subscription is ended<br></br>
                        <Link to="/subscribe">
                          <button
                            className="btn btn-success"
                            onClick={this.Subscribe}
                          >
                            Subscribe Now
                          </button>
                        </Link>
                      </h6>
                    ) : !this.props.auth.user.tabulaRx ? (
                      <h6 class="font-weight-bold text-danger">
                        You accounts is inactive!! To activate you account
                        please{" "}
                        <Link to="/support" className="">
                          Contact
                        </Link>{" "}
                        Us.
                      </h6>
                    ) : (
                      <h6 class="font-weight-bold text-danger">
                        You accounts is inactive!! To activate you account
                        please{" "}
                        <Link to="/support" className="">
                          Contact
                        </Link>{" "}
                        Us.
                      </h6>
                    )}
                  </div>
                </div>
                <img className="static-image" src={dashboard} alt="Static" />
              </div>
            )}
          </div>
          <ToastContainer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  multistoreData: state.multistoreData,
});

export default connect(mapStateToProps, { logoutUser })(Dispense);
