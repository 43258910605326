import React, { Component, Fragment } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { faPlus, faBackward } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import moment from "moment"
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import red from "../../utils/red.jpg"
import { logoutUser } from "../../actions/authActions";
import { Link } from "react-router-dom";
class DispenseDeleteRequest extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "dispense_date",
                text: "Dispense Date",
                className: "dispense_date",
                align: "left",
                sortable: true,
            },
            {
                key: "drug_name",
                text: "Drug Name",
                className: "drug_name",
                align: "left",
                sortable: true,
            },
            {
                key: "ndc",
                text: "NDC",
                className: "ndc",
                align: "left",
                sortable: true,
            },
            {
                key: "rx_number",
                text: "RX number",
                className: "rx_number",
                align: "left",
                sortable: true,
            },
            {
                key: "qty_dispensed",
                text: "Qty Dispensed",
                className: "qty_dispensed",
                align: "left",
                sortable: true,
            },
            {
                key: "day_supply",
                text: "Day Supply",
                className: "day_supply",
                align: "left",
                sortable: true,
            },
            {
                key: "patient_name",
                text: "Patient Name",
                className: "patient_name",
                align: "left",
                sortable: true,
            },
            {
                key: "patient_zip",
                text: "Patient Zip Code",
                className: "patient_zip",
                align: "left",
                sortable: true,
            },
            {
                key: "payment_type",
                text: "Payment Type",
                className: "payment_type",
                align: "left",
                sortable: true,
            },
            {
                key: "doctor_npi",
                text: "NPI",
                className: "doctor_npi",
                align: "left",
                sortable: true,
            },
            // {
            //     key: "notes",
            //     text: "Notes",
            //     className: "notes",
            //     align: "left",
            //     sortable: true,
            // },
            // {
            //     key: "user_initial",
            //     text: "User Initials",
            //     className: "user_initial",
            //     align: "left",
            //     sortable: true,
            // },
            {
                key: "delete_requested",
                text: "Requested By",
                className: "delete_requested",
                align: "left",
                sortable: true,
            },
            {
                key: "delete_requested_date",
                text: "Requested Date",
                className: "delete_requested_date",
                align: "left",
                sortable: true,
            },

        ];
        if (this.props.auth.user.superadmin) {
            this.columns.push(
                {
                    key: "pharmacy_account",
                    text: "Pharmacy",
                    className: "pharmacy_account",
                    align: "left",
                    sortable: true,
                },
            )
        }
        // if (!this.props.auth.user.superadmin) {
            this.columns.push({
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            })
        // }

        this.columnsDeleted = [
            {
                key: "dispense_date",
                text: "Dispense Date",
                className: "dispense_date",
                align: "left",
                sortable: true,
            },
            {
                key: "drug_name",
                text: "Drug Name",
                className: "drug_name",
                align: "left",
                sortable: true,
            },
            {
                key: "ndc",
                text: "NDC",
                className: "ndc",
                align: "left",
                sortable: true,
            },
            {
                key: "rx_number",
                text: "RX number",
                className: "rx_number",
                align: "left",
                sortable: true,
            },
            {
                key: "qty_dispensed",
                text: "Qty Dispensed",
                className: "qty_dispensed",
                align: "left",
                sortable: true,
            },
            {
                key: "day_supply",
                text: "Day Supply",
                className: "day_supply",
                align: "left",
                sortable: true,
            },
            {
                key: "patient_name",
                text: "Patient Name",
                className: "patient_name",
                align: "left",
                sortable: true,
            },
            {
                key: "patient_zip",
                text: "Patient Zip Code",
                className: "patient_zip",
                align: "left",
                sortable: true,
            },
            {
                key: "payment_type",
                text: "Payment Type",
                className: "payment_type",
                align: "left",
                sortable: true,
            },
            {
                key: "doctor_npi",
                text: "NPI",
                className: "doctor_npi",
                align: "left",
                sortable: true,
            }
        ];

        if (this.props.auth.user.superadmin) {
            this.columnsDeleted.push(
                {
                    key: "pharmacy_account",
                    text: "Pharmacy",
                    className: "pharmacy_account",
                    align: "left",
                    sortable: true,
                },
            )
        }

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Request List",
            no_data_text: 'No Request found!',
            button: {
                excel: false,
                print: false,
                csv: false
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
            sort: {
                column: "dispense_date",
                order:"desc"
            }
        };

        this.state = {
            records: [],
            recordsDeleted: [],
            totalCount: 0,
            totalCountDeleted: 0,
            filterData: {
                sort_order: { column: "report_date", order: "desc" }
            },
            currentRecord: {
            },
            delete_requested: undefined
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
        this.getDataDeleted()
    };

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.delete_requested !== '' && { delete_requested: this.state.delete_requested }),
            pharmacy_account: this.props.auth.user.final_user,
        }
        axios
            .post("/api/dispense-delete-request", filterData)
            .then(res => {
                var result = [];
                // add data rows in sheet array
                for (let i in res.data.records) {
                    let each = res.data.records[i]
                    each.orig_dispense_date = each.dispense_date
                    each.dispense_date = each.dispense_date ? moment(each.dispense_date).add(5, 'hours').format('L') : ""
                    each.delete_requested_date = each.delete_requested_date ? moment(each.delete_requested_date).add(5, 'hours').format('L') : ""
                    result.push(each);
                }
                this.setState({
                    records: result,
                    totalCount: res.data.count,
                    loading: false,
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })

    }

    getDataDeleted() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            pharmacy_account: this.props.auth.user.final_user,
        }
        axios
            .post("/api/dispense-deleted", filterData)
            .then(res => {
                var result = [];
                // add data rows in sheet array
                for (let i in res.data.records) {
                    let each = res.data.records[i]
                    each.orig_dispense_date = each.dispense_date
                    each.dispense_date = each.dispense_date ? moment(each.dispense_date).add(5, 'hours').format('L') : ""
                    each.delete_requested_date = each.delete_requested_date ? moment(each.delete_requested_date).add(5, 'hours').format('L') : ""
                    result.push(each);
                }
                this.setState({
                    recordsDeleted: result,
                    totalCountDeleted: res.data.count,
                    loading: false,
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })

    }

    deleteRecord(record) {
        confirmAlert({
            title: 'Records will be deleted from purchase record',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => this.finalDelete(record)
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
        
    }

    finalDelete = (record) => {
        axios
            .post("/api/dispense-delete", 
                { 
                    _id: record._id, 
                    delete: this.props.auth.user.user_type!=="Technician"?true:false,
                    email: this.props.auth.user.email
                }
            )
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
                this.getData();
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            });
        
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            from_date: undefined,
            to_date: undefined,
            ndc: undefined,
            drug_name: undefined,
            filterData: {
                sort_order: { column: "_id", order: "asc" }
            }
        }, () => this.getData())
    }

    expand = (type) => {
        this.setState({ expand: type })
    }
    render() {
        if((this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula === undefined ||this.props.auth.user.expiration_tabula < moment().format()) && this.props.auth.user.superadmin!==true) {
            window.location.href = ("/choose-plan?solution=TabulaRx");
        }
        return (
            <>
                <div className="d-flex" id="wrapper">
                    {this.state.loading &&
                        <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            {this.props.auth.user.rx_group !== "superadmin" &&
                                <button className="btn btn-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-dispense-modal"><FontAwesomeIcon icon={faPlus} /> Add new Records</button>}
                            <br></br>
                            <button className="btn btn-warning"><FontAwesomeIcon icon={faBackward} /><Link to="/dashboard"> Go to Dashboard</Link></button>
                            <br></br>
                            <h1 className="mt-2 text-primary">Dispense Delete Request</h1>
                            <p className="help-text font-weight-bold h6"><img src={red} style={{width:"30px"}}/>Dispense Records can only be deleted by Pharmacy admin or manager.</p>
                            <br></br>
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label htmlFor="delete_requested">Requested By</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.delete_requested}
                                        id="delete_requested"
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <button
                                    onClick={this.onSearch}
                                    type="button"
                                    className="btn btn-primary">
                                    Search
                                </button>
                                <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>
                            </div>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={false}
                                total_record={this.state.totalCount}
                                loading={true}
                            />
                            <br></br>
                            <hr></hr>
                            <br></br>
                            <h1 className="mt-2 text-primary">Deleted Dispense Records</h1>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.recordsDeleted}
                                columns={this.columnsDeleted}
                                dynamic={false}
                                total_record={this.state.totalCountDeleted}
                                loading={true}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps,
    {logoutUser}
)(DispenseDeleteRequest);
