import React, { Component, Fragment } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { faPlus, faBackward } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import GroupEmailTemplatesAdd from "../partials/GroupEmailTemplatesAdd";
import moment from "moment"
import { confirmAlert } from 'react-confirm-alert';
import { logoutUser } from "../../actions/authActions";
import 'react-confirm-alert/src/react-confirm-alert.css';
import GroupEmailTemplatesUpdate from "../partials/GroupEmailTemplatesUpdate";

const options = [
    { name: 'RxPodcast', id: "rxpodcast" },
    { name: 'Rxminder', id: "rxminder" },
    { name: 'TabulaRx', id: "tabularx" },
    { name: 'BonaRx', id: "bonarx" },
    { name: 'DoorstepRx', id: "doorsteprx" },
    { name: 'RxReach', id: "rxreach" },
    { name: 'Xpedicare', id: "xpedicare" },
    { name: 'AlereRx', id: "alererx" }
];

class GroupEmailTemplates extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            
            {
                key: "name",
                text: "Name",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "frequency",
                text: "Frequency",
                className: "frequency",
                align: "left",
                sortable: true,
            },
            {
                key: "threshold",
                text: "Threshold",
                className: "threshold",
                align: "left",
                sortable: true,
            },
            {
                key: "threshold_left",
                text: "Threshold Left",
                className: "threshold_left",
                align: "left",
                sortable: true,
            },
            {
                key: "last_run",
                text: "Last Run",
                className: "last_run",
                align: "left",
                sortable: true,
            },
            {
                key: "subject",
                text: "Subject",
                className: "subject",
                align: "left",
                sortable: true,
            },
            {
                key: "solutionLabel",
                text: "Solution",
                className: "solutionLabel",
                align: "left",
                sortable: true,
            },
            {
                key: "typeLabel",
                text: "Type",
                className: "typeLabel",
                align: "left",
            },
            {
                key: "statusLabel",
                text: "Status",
                className: "statusLabel",
                align: "left",
            },
            {
                key: "date",
                text: "Created Date",
                className: "date",
                align: "left",
                sortable: true,
            }
        ];
        this.columns.push({
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        <button
                            data-toggle="modal"
                            data-target="#update-groupEmailTemplates-modal"
                            className="btn btn-primary btn-sm"
                            onClick={() => this.editRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit"></i>
                        </button>
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => this.deleteRecord(record)}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </Fragment>
                );
            }
        })

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Record List",
            no_data_text: 'No Record found!',
            button: {
                excel: false,
                print: false,
                csv: false
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
            sort: {
                column: "date",
                order: "desc"
            }
        };

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "date", order: "desc" }
            },
            currentRecord: {
            },
            name: "",
            solution: undefined,
            record: undefined

        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    editRecord(record) {
        this.setState({ currentRecord: record });
    }

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.name !== undefined && { name: this.state.name }),
            ...(this.state.solution!==undefined && { solution: this.state.solution })
        }
        axios
            .post("/groupEmail/get-emailTemplates", filterData)
            .then(res => {
                var result = [];
                // add data rows in sheet array
                for (let i in res.data.records) {
                    let each = res.data.records[i]
                    if (each.date !== '') {
                        each.date = moment(each.date).format('LLL');
                    }
                    if (each.last_run !== '') {
                        each.last_run = moment(each.last_run).format('LLL');
                    }
                    let found = options.find(eachOption => eachOption.id === each.solution)
                    if (found) {
                        each.solutionLabel = (found.name)
                    }
                    each.statusLabel = each.status ? "Enable" : "Disable"
                    each.typeLabel = each.type === "image" ? "Image" : "Text"
                    each.threshold_left = parseInt(each.threshold) - parseInt(each.threshold_left || 0)
                    result.push(each);
                }
                this.setState({
                    records: result,
                    totalCount: res.data.count,
                    loading: false,
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })

    }

    deleteRecord(record) {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.finalDelete(record)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });

    }

    finalDelete = (record) => {
        axios
            .post("/groupEmail/emailTemplates-delete",
                {
                    _id: record._id,
                }
            )
            .then(res => {
                if (res.status === 200) {
                    this.getData();
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            });
        
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }
    resetSearch = () => {
        this.setState({
            name: "",
            solution: undefined,
            filterData: {
                sort_order: { column: "_id", order: "asc" }
            }
        }, () => this.getData())
    }

    exportToCSV = () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            "date": "Date & Time",
            "name": "Name",
            "subject": "Subject",
            "frequency": "Frequency",
            "threshold": "Threshold",
            "threshold_left": "Threshold Left",
            "solutionList": "Solutions"
        };
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false })
        let filterData = {
            page_number: 1,
            page_size: 20000,
            sort_order: { column: "date", order: "desc" }
        }
        filterData.search = {
            ...(this.state.email !== '' && { email: this.state.email }),
            ...(this.state.solutions.length && { solutions: this.state.solutions.map(each => each.id) })
        }
        axios.post("/GroupEmailTemplates/get-list", filterData)
            .then(res => {
                // add data rows in sheet array
                for (let i in res.data.records) {
                    let each = res.data.records[i]
                    if (each.date !== '') {
                        each.date = moment(each.date).format('LLL');
                    }
                    let solutionList = [];
                    (each.solutions || []).map(eachS => {
                        let found = options.find(eachOption => eachOption.id === eachS)
                        if (found) {
                            solutionList.push(found.name)
                        }
                        return ''
                    })
                    each.solutionList = solutionList.join(",")
                    each.threshold_left = parseInt(each.threshold) - parseInt(each.threshold_left || 0)
                    records.push(each);
                }
                if (headers) {
                    records.unshift(headers);
                }
                // Convert Object to JSON
                // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
                // var universalBOM = "\uFEFF";
                let jsonObject = JSON.stringify(records);
                let csv = this.convertToCSV(jsonObject, headers);
                let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
                // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
                var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'application/csv;charset=UTF-8' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })


    }

    convertToCSV(objArray, headers) {
        const keys = Object.keys(headers);

        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in keys) {
                line += array[i][keys[index]] === undefined ? "," : `"${array[i][keys[index]]}",`;
                line.trim(",")


            }
            str += line + '\r\n';
        }
        return str;
    }

    onSelectMultiple = (selectedList, selectedItem) => {
        this.setState({ solutions: selectedList })
    }

    render() {
        return (
            <>
                <div className="d-flex" id="wrapper">
                    {this.state.loading &&
                        <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                    <GroupEmailTemplatesAdd getData={this.getData} />
                    <GroupEmailTemplatesUpdate getData={this.getData} record={this.state.currentRecord} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-groupEmailTemplates-modal"><FontAwesomeIcon icon={faPlus} /> Add New Template</button>
                            <h1 className="mt-2 text-primary">Template Lists</h1>
                            <button className="btn btn-warning"><FontAwesomeIcon icon={faBackward} /><Link to="/dashboard"> Go to Dashboard</Link></button>
                            <br></br>
                            <br></br>
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.name}
                                        id="name"
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="name">Solution</label>
                                    <select onChange={this.onChange} value={this.state.solution} id="solution" className={"form-control"}>
                                        <option value="">Please Select</option>
                                        <option value="rxpodcast" selected={this.state.solution === "rxpodcast"}>RxPodcast</option>
                                        <option value="rxminder" selected={this.state.solution === "rxminder"}>Rxminder</option>
                                        <option value="tabularx" selected={this.state.solution === "tabularx"}>TabulaRx</option>
                                        <option value="bonarx" selected={this.state.solution === "bonarx"}>BonaRx</option>
                                        <option value="doorsteprx" selected={this.state.solution === "doorsteprx"}>DoorstepRx</option>
                                        <option value="rxreach" selected={this.state.solution === "rxreach"}>RxReach</option>
                                        <option value="xpedicare" selected={this.state.solution === "xpedicare"}>Xpedicare</option>
                                        <option value="alererx" selected={this.state.solution === "alererx"}>AlereRx</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <button
                                    onClick={this.onSearch}
                                    type="button"
                                    className="btn btn-primary">
                                    Search
                                </button>
                                <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>
                            </div>
                            <br></br>
                            <br></br>

                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onChange={this.filter}
                                dynamic={true}
                                total_record={this.state.totalCount}
                                loading={true}
                                exportToCSV={this.exportToCSV}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    multistoreData: state.multistoreData,
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(GroupEmailTemplates);
