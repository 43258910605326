import React, { Component, Fragment } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment"
import { logoutUser } from "../../actions/authActions";
import { Link } from "react-router-dom";
import { Collapse } from 'react-collapse';
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Hcpcs extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "ndc",
                text: "NDC",
                className: "ndc",
                align: "left",
                sortable: true,
            },
            {
                key: "hcpcs",
                text: "HCPCS",
                className: "hcpcs",
                align: "left",
                sortable: true,
            },
            {
                key: "relation_start_date",
                text: "Relation Start date",
                className: "relation_start_date",
                align: "left",
                sortable: true,
            },
            {
                key: "relation_end_date",
                text: "Relation End date",
                className: "relation_end_date",
                align: "left",
                sortable: true,
            },
            {
                key: "hcpcs_desc",
                text: "HCPCS Description",
                className: "hcpcs_desc",
                align: "left",
                sortable: true,
            },
            {
                key: "ndc_label",
                text: "NDC Name",
                className: "ndc_label",
                align: "left",
                sortable: true,
            },
            {
                key: "package_items",
                text: "No. of Items in NDC Package",
                className: "package_items",
                align: "left",
                sortable: true,
            },
            {
                key: "package_measure",
                text: "NDC Package Measure",
                className: "package_measure",
                align: "left",
                sortable: true,
            },
            {
                key: "package_type",
                text: "NDC Package Type",
                className: "package_type",
                align: "left",
                sortable: true,
            },
            {
                key: "billing_units",
                text: "Billing Units",
                className: "billing_units",
                align: "left",
                sortable: true,
            },
            {
                key: "route",
                text: "Route",
                className: "route",
                align: "left",
                sortable: true,
            },
            {
                key: "start_date",
                text: "Start Date",
                className: "start_date",
                align: "left",
                sortable: true,
            },
            {
                key: "end_date",
                text: "End Date",
                className: "end_date",
                align: "left",
                sortable: true,
            }

        ];
        if (this.props.auth.user.superadmin) {

            this.config = {
                page_size: 20,
                length_menu: [20, 50, 100],
                filename: "HCPCS List",
                no_data_text: 'No record found!',
                button: {
                    excel: false,
                    print: false,

                    csv: true
                },
                language: {
                    length_menu: "Show _MENU_ result per page",
                    filter: "Filter in records...",
                    info: "Showing _START_ to _END_ of _TOTAL_ records",
                    pagination: {
                        first: "First",
                        previous: "Previous",
                        next: "Next",
                        last: "Last"
                    }
                },
                show_length_menu: true,
                show_filter: false,
                show_pagination: true,
                show_info: true,
            };
        }
        else {
            this.config = {
                page_size: 20,
                length_menu: [20, 50, 100],
                filename: "HCPCS List",
                no_data_text: 'No record found!',
                button: {
                    excel: false,
                    print: false,

                    csv: false
                },
                language: {
                    length_menu: "Show _MENU_ result per page",
                    filter: "Filter in records...",
                    info: "Showing _START_ to _END_ of _TOTAL_ records",
                    pagination: {
                        first: "First",
                        previous: "Previous",
                        next: "Next",
                        last: "Last"
                    }
                },
                show_length_menu: true,
                show_filter: false,
                show_pagination: true,
                show_info: true,
            };


        }

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "ndc", order: "asc" }
            },
            currentRecord: {
            },
            ndc: undefined,
            hcpcs: undefined,
            ndc_label: undefined,
            loading: false,
            isFilterOpen: true
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.ndc !== '' && { ndc: this.state.ndc }),
            ...(this.state.hcpcs !== '' && { hcpcs: this.state.hcpcs }),
            ...(this.state.ndc_label !== '' && { ndc_label: this.state.ndc_label }),
        }
        axios
            .post("/api/hcpcs", filterData)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    if (each.pharmacy_name !== "") {
                        each.relation_start_date = each.relation_start_date ? moment(each.relation_start_date).format('L') : ""
                        each.relation_end_date = each.relation_end_date ? moment(each.relation_end_date).format('L') : ""
                        each.start_date = each.start_date ? moment(each.start_date).format('L') : ""
                        each.end_date = each.end_date ? moment(each.end_date).format('L') : ""
                        result.push(each)
                    }
                }
                this.setState({
                    records: result,
                    totalCount: res.data.count,
                    loading: false
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })
    }

    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            ndc: undefined,
            hcpcs: undefined,
            ndc_label: undefined,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "ndc", order: "asc" }
            }
        }, () => this.getData())
    }

    toggleChange = () => {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen,
        });
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    exportToCSV = () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            "ndc": "NDC",
            "hcpcs": "HCPCS",
            "relation_start_date": "Relation Start Date",
            "relation_end_date": "Relation End Date",
            "hcpcs_desc": "HCPCS Description",
            "ndc_label": "NDC Name",
            "package_items": "No. of Items in NDC Package",
            "package_measure": "NDC Package Measure",
            "package_type": "NDC Package Type",

            "route": "Route",
            "billing_units": "Billing Units",
            "start_date": "Start Date",
            "end_date": "End Date"
        };
        let filterData = {
            page_number: 1,
            page_size: 20000,
            sort_order: { column: "_id", order: "asc" }
        }
        filterData.search = {
            ...(this.state.ndc !== '' && { ndc: this.state.ndc }),
            ...(this.state.hcpcs !== '' && { hcpcs: this.state.hcpcs }),
            ...(this.state.ndc_label !== '' && { ndc_label: this.state.ndc_label }),
        }
        axios
            .post("/api/hcpcs", filterData)
            .then(res => {
                var records = [];
                // add data rows in sheet array
                this.setState({ loading: false })
                for (let i in res.data.records) {
                    let record = res.data.records[i]
                    delete record._id
                    delete record.id
                    delete record.__v
                    delete record.conversion_factor

                    record.hcpcs_desc = '"' + record.hcpcs_desc + '"'
                    record.ndc_label = '"' + record.ndc_label + '"'
                    if (record.relation_start_date !== '') {
                        record.relation_start_date = moment(record.relation_start_date).format('L');
                    }
                    if (record.relation_end_date !== '') {
                        record.relation_end_date = moment(record.relation_end_date).format('L');
                    }
                    if (record.start_date !== '') {
                        record.start_date = moment(record.start_date).format('L');
                    }
                    if (record.end_date !== '') {
                        record.end_date = moment(record.end_date).format('L');
                    }
                    records.push(record);
                }
                if (headers) {
                    records.unshift(headers);
                }
                // Convert Object to JSON
                // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
                // var universalBOM = "\uFEFF";
                let jsonObject = JSON.stringify(records);
                let csv = this.convertToCSV(jsonObject);
                let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
                // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
                var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'application/csv;charset=UTF-8' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })


    }

    convertToCSV(objArray) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    render() {
        if ((this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula === undefined || this.props.auth.user.expiration_tabula < moment().format()) && this.props.auth.user.superadmin !== true) {
            window.location.href = ("/choose-plan?solution=TabulaRx");
        }
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">NDC HCPCS CROSS</h1>
                        <button className="btn btn-warning"><FontAwesomeIcon icon={faBackward} /><Link to="/dashboard"> Go to Dashboard</Link></button>
                        <br></br>
                        <br></br>
                        <div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" defaultChecked={this.state.isFilterOpen} onClick={this.toggleChange} id="isFilterOpen" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Show Filter
                                </label>
                            </div>
                            <Collapse isOpened={this.state.isFilterOpen}>
                                <form noValidate onSubmit={this.onSearch}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="ndc">NDC</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ndc}
                                                id="ndc"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="gcn_seq">HCPCS</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.hcpcs}
                                                id="hcpcs"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="ndc_label">Medication</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ndc_label}
                                                id="ndc_label"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>

                                    </div>
                                    <button type="submit" className="btn btn-primary">Filter</button>
                                    <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>
                                </form>
                            </Collapse>
                        </div>
                        <br></br>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onChange={this.filter}
                            dynamic={true}
                            total_record={this.state.totalCount}
                            loading={true}
                            exportToCSV={this.exportToCSV}
                        />
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Hcpcs);
