export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_MULTISTOREDATA = "SET_MULTISTOREDATA";

export const USER_ADD = "USER_ADD";
export const USER_UPDATE = "USER_UPDATE";
export const SET_PHARMACY = "SET_PHARMACY";
export const SET_GROUPS = "SET_GROUPS";
export const SET_FILTER = "SET_FILTER";
