import React, { Component } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import moment from "moment"
import { Link } from "react-router-dom";
import { logoutUser } from "../../actions/authActions";

class CompoundLogList extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "logs",
                text: "List",
                className: "logs",
                align: "left",
                sortable: true,
                html: true
            },
        ];

        this.config = {
            page_size: 100,
            length_menu: [20, 50, 100],
            filename: "Log List",
            no_data_text: 'No Log found!',
            button: {
                excel: false,
                print: false,
                csv: false
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: false,
            show_filter: false,
            show_pagination: false,
            show_info: false,
            sort: {
                column: "purchase_date",
                order: "desc"
            }
        };

        this.state = {
            records: []
        };

    }

    componentDidMount() {
        let records = [
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Acetazolamide+Oral.pdf">Acetazolamide Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Amiodarone+Oral.pdf">Amiodarone Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Amitriptyline+Oral.pdf">Amitriptyline Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Amphotericin+B+Oral.pdf">Amphotericin B Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Atenolol+Oral.pdf">Atenolol Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Azathioprine+Oral.pdf">Azathioprine Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Baclofen+Oral.pdf">Baclofen Oral.pdf</a>` },
           
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Beclomethasone+Oral.pdf">Beclomethasone Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Bethanechol+Oral.pdf">Bethanechol Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Captopril+Oral.pdf">Captopril Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/CarbidopaLevodopaexternal.pdf">CarbidopaLevodopaexternal.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Carvedilol+Oral.pdf">Carvedilol Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Celecoxib+Oral.pdf">Celecoxib Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Chloroquine+Oral.pdf">Chloroquine Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Chlorothiazide+Oral+30+Day.pdf">Chlorothiazide Oral 30 Day.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Cinacalcet+Oral.pdf">Cinacalcet Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Clonazepam+Oral.pdf">Clonazepam Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Clonidine+Oral+I.pdf">Clonidine Oral I.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Clopidogrel+Oral.pdf">Clopidogrel Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Dapsone+Oral.pdf">Dapsone Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Desmopressinexternal.pdf">Desmopressinexternal.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Dexamethasone+1mg+per+mL+Oral.pdf">Dexamethasone 1mg per mL Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Diltiazem+External.pdf">Diltiazem External.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Dipyridamole+Oral.pdf">Dipyridamole Oral.pdf

            </a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Flecainide+Oral_.pdf">Flecainide Oral_.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Folic+Acid+Oral+1mg.pdf">Folic Acid Oral 1mg.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Hydrochlorothiazide+Oral.pdf">Hydrochlorothiazide Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Hydroxychloroquine+Oral.pdf">Hydroxychloroquine Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Hydroxyurea+Oral.pdf">Hydroxyurea Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Labetalol+40mgmLexternal.pdf">Labetalol 40mgmLexternal.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Lamotrigine+Oral.pdf">Lamotrigine Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Losartan+Oral.pdf">Losartan Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Metolazone+Oral.pdf">Metolazone Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Metoprolol+Oral.pdf">Metoprolol Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Metronidazole+Oral.pdf">Metronidazole Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Moxifloxacin+Oral.pdf">Moxifloxacin Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Omeprazole+Oral.pdf">Omeprazole Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Pentoxifylline+Oral.pdf">Pentoxifylline Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Prograf+Oral.pdf">Prograf Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Pyridoxine+Hydrochloride+Oral.pdf">Pyridoxine Hydrochloride Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Pyrimethamine+Oral.pdf">Pyrimethamine Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Quinidine+Oral.pdf">Quinidine Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Rifabutin+Oral.pdf">Rifabutin Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Rifampin+Oral.pdf">Rifampin Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Rifaximin+Oral.pdf">Rifaximin Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Sodium+Bicarbonate+Oral.pdf">Sodium Bicarbonate Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/SulfaDIAZINE+Oral.pdf">SulfaDIAZINE Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Sulfasalazine+Oral.pdf">Sulfasalazine Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Tacrolimus+Oral.pdf">Tacrolimus Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Tadalafil+External.pdf">Tadalafil External.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Temozolomide+Oral.pdf">Temozolomide Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Terbinafine+Oral.pdf">Terbinafine Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Tetracycline+Oral.pdf">Tetracycline Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Thioguanine+Oral.pdf">Thioguanine Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Tiagabine+Oral.pdf">Tiagabine Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Tinidazole+Oral.pdf">Tinidazole Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Topiramate+Oral.pdf">Topiramate Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Ursodiol+Oral.pdf">Ursodiol Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Valacyclovir+External.pdf">Valacyclovir External.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Venlafaxine+External.pdf">Venlafaxine External.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Verapamil+External.pdf">Verapamil External.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Vorinostatexternal.pdf">Vorinostatexternal.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Zinc+Oral.pdf">Zinc Oral.pdf</a>` },
            { "logs": `<a target="_blank" href="https://rxreachanalytis.s3.amazonaws.com/pediatriccompoundformulas/Zonisamide+Oral.pdf">Zonisamide Oral.pdf</a>` },


        ]
        this.setState({ records })
    };

    render() {
        if ((this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula === undefined || this.props.auth.user.expiration_tabula < moment().format()) && this.props.auth.user.superadmin !== true) {
            window.location.href = ("/choose-plan?solution=TabulaRx");
        }

        return (
            <>
                <div className="d-flex" id="wrapper">


                    <div id="page-content-wrapper">
                        {this.props.auth.user.tabulaRx || this.props.auth.user.superadmin || this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula >= moment().format() ? (
                            <div className="container-fluid">
                                <h1 className="mt-2 text-primary">Saved Compound Formulas</h1>
                                <button className="btn btn-warning"><FontAwesomeIcon icon={faBackward} /><Link to="/dashboard"> Go to Dashboard</Link></button>
                                <br></br>
                                <br></br>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={false}
                                    showMedication={this.showMedication}
                                    total_record={this.state.totalCount}
                                    loading={true}
                                    exportToCSV={this.exportToCSV}
                                />
                            </div>
                        ) : (
                            <div className="container-fluid static-card">
                                <div className="card">
                                    <div className="card-body">
                                        {this.props.auth.user.expiration_tabula < moment().format() ? (
                                            <h6 class="font-weight-bold text-danger">Your Subscription is ended<br></br>
                                                <Link to="/subscribe"><button className="btn btn-success" onClick={this.Subscribe}>Subscribe Now</button></Link>
                                            </h6>
                                        ) : !this.props.auth.user.tabulaRx ? (
                                            <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="">Contact</Link> Us.</h6>
                                        ) : (
                                            <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="">Contact</Link> Us.</h6>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>
                    <ToastContainer />
                </div>
            </>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    multistoreData: state.multistoreData,
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(CompoundLogList);
