import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser, refreshUser } from "../../actions/authActions";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TermCondition from "../partials/TermCondition";
import { availableSolutions as solutions } from "./../../utils/constants";
import $ from "jquery";
const termC = ["RxPodcast"];
const rxpodcastPrice = {
  Basic: 99.99,
  Plus: 149.99,
  Pro: 199.99,
};
class ChoosePlanInitial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPlan: "",
      selectedSolution: [],
      finalPrice: 0,
      userInfo: {},
      rxpodcastPlan: undefined,
    };
  }

  componentDidMount() {
    // this.markLogin();
    this.getUserInfo();
  }

  getUserInfo = () => {
    let filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "desc" },
    };
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        if (res.data.records.length) {
          this.setState({ userInfo: res.data.records[0] });
          if (res.data.records[0].quickMembership === 1) {
            this.setState({ selectedSolution: ["TabulaRx"] });
            this.calculatePrice(["TabulaRx"]);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  markLogin() {
    const newUser = {
      id: this.props.auth.user.id,
      login_flow: false,
    };
    axios
      .post("/api/user-update", newUser)
      .then((res) => {})
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  choosePlanRxPodcast = (plan) => {
    confirmAlert({
      title: "Awesome!",
      message: `You have selected ${plan} Plan`,
      buttons: [
        {
          label: "Back",
          onClick: () => $("#rxpodcast-plan-modal").modal("hide"),
        },
        {
          label: "Continue",
          onClick: () =>
            this.setState({ rxpodcastPlan: plan }, () =>
              this.calculatePrice(this.state.selectedSolution)
            ),
        },
      ],
      overlayClassName: "confirm-plan",
    });
  };

  updateCheckbox = (e) => {
    let { selectedSolution } = this.state;
    if (e.target.checked) {
      selectedSolution.push(e.target.id);
    } else {
      selectedSolution = selectedSolution.filter(
        (each) => each !== e.target.id
      );
    }
    if (e.target.id === "RxPodcast") {
      if (e.target.checked) {
        this.setState({ selectedSolution }, () =>
          $("#rxpodcast-plan-modal").modal("show")
        );
      } else {
        this.setState({ rxpodcastPlan: "" });
      }
    }

    this.calculatePrice(selectedSolution);
  };

  // updateCheckboxAfter = (selectedSolution) => {
  //   let { selectedSolution } = this.state;
  //   if (e.target.checked) {
  //     selectedSolution.push(e.target.id);
  //   } else {
  //     selectedSolution = selectedSolution.filter(
  //       (each) => each !== e.target.id
  //     );
  //   }
  //   this.calculatePrice(selectedSolution);
  // };

  calculatePrice = (selectedSolution) => {
    $("#rxpodcast-plan-modal").modal("hide");
    let finalPrice = 0;
    let { rxpodcastPlan } = this.state;
    solutions.map((eachSol) => {
      if (
        (selectedSolution.includes(eachSol.name) && !eachSol.class) ||
        (eachSol.name === "TabulaRx" &&
          this.state.userInfo.quickMembership === 1)
      ) {
        let solPrice =
          eachSol.name === "RxPodcast"
            ? rxpodcastPlan === ""
              ? 0
              : rxpodcastPrice[rxpodcastPlan]
            : eachSol.price;
        finalPrice = finalPrice + parseFloat(solPrice);
      }
    });
    finalPrice = finalPrice.toFixed(2);
    this.setState({ selectedSolution, finalPrice });
  };

  navigateTo = () => {
    let { selectedSolution, finalPrice } = this.state;
    let growth = ["BonaRx", "AlereRx", "RxReach", "XPedicareRx"];
    let premium = ["RxCreator", "RxPodcast", "RxMinder"];
    if (finalPrice > 0) {
      const otherPlans = selectedSolution.filter(
        (eachP) => !["TabulaRx", "RxMinder", "DoorstepRx"].includes(eachP)
      );
      if (
        selectedSolution.includes("TabulaRx") &&
        growth.filter((n) => selectedSolution.indexOf(n) !== -1).length > 0 &&
        premium.filter((n) => selectedSolution.indexOf(n) !== -1).length === 0
      ) {
        confirmAlert({
          title: "Suggestion!",
          message: `You have selected ${selectedSolution.join(
            ","
          )}. Select Growth Plan and Save!`,
          buttons: [
            {
              label: "No Thanks",
              onClick: () =>
                (window.location.href = `/card-info?solution=${otherPlans.join(
                  "|"
                )}`),
            },
            {
              label: "Choose Plan",
              onClick: () => {},
            },
          ],
          overlayClassName: "confirm-plan",
        });
      } else if (
        selectedSolution.includes("RxMinder") &&
        premium.filter((n) => selectedSolution.indexOf(n) !== -1).length > 0
      ) {
        confirmAlert({
          title: "Suggestion!",
          message: `You have selected ${selectedSolution.join(
            ", "
          )}. Select Premium Plan and Save!`,
          buttons: [
            {
              label: "No Thanks",
              onClick: () =>
                (window.location.href = `/card-info?solution=${otherPlans.join(
                  "|"
                )}`),
            },
            {
              label: "Choose Plan",
              onClick: () => {},
            },
          ],
          overlayClassName: "confirm-plan",
        });
      } else {
        window.location.href = `/card-info?solution=${otherPlans.join("|")}`;
      }
    } else {
      window.location.href = "/landing";
    }
  };

  confirmPlan = (plan) => {
    confirmAlert({
      title: "Awesome!",
      message: `You have selected ${
        plan === "growth" ? "Growth" : "Premium"
      } Plan`,
      buttons: [
        {
          label: "Back",
          onClick: () => {},
        },
        {
          label: "Continue",
          onClick: () =>
            (window.location.href = `/card-info?plan=${
              plan === "growth" ? "Growth" : "Premium"
            }`),
        },
      ],
      overlayClassName: "confirm-plan",
    });
  };

  checkTerms = () => {
    let { selectedSolution } = this.state;
    const termArr = selectedSolution.filter((value) => termC.includes(value));
    if (termArr.length) {
      this.setState({ termArr }, () => $("#term-condition").modal("show"));
    } else {
      this.navigateTo();
    }
  };

  render() {
    return (
      <>
        <header data-bs-theme="dark" style={{ paddingLeft: "0" }}>
          <div className="navbar navbar-dark bg-dark shadow-sm">
            <div className="container">
              <a
                href="/landing"
                className="navbar-brand d-flex align-items-center"
              >
                <img
                  src={
                    "https://tabula.psgarx.com/static/media/RxConnexion.80ee8ffc.png"
                  }
                  alt="Logo"
                  width={300}
                />
              </a>
              <button
                type="button"
                className="btn btn-dark me-2 right"
                onClick={() => this.props.logoutUser()}
              >
                Logout ({this.props.auth.user.name})
              </button>
            </div>
          </div>
        </header>
        <main
          style={{ paddingLeft: "0" }}
          className="choose-plan-initial container"
        >
          <section className="text-center container">
            <div className="row py-lg-3">
              <div className="col-lg-6 col-md-8 mx-auto choose">
                <h1 className="fw-light">
                  <b>Select Solutions</b>
                </h1>
              </div>
            </div>
          </section>
          <div className="row row-cols-1 row-cols-md-3 mb-3 text-left">
            {solutions.map((each, i) => {
              return (
                <div
                  className={`col-4 solutions ${each.class}`}
                  key={i}
                  data-toggle="tooltip"
                  title={each.tooltip}
                >
                  <div>
                    <input
                      type="checkbox"
                      onChange={(e) => this.updateCheckbox(e)}
                      name={each.name}
                      id={each.name}
                      checked={this.state.selectedSolution.includes(each.name)}
                      disabled={
                        each.name === "TabulaRx" &&
                        this.state.userInfo.quickMembership === 1
                      }
                    />
                    {each.name} - ${each.price} /mo
                    {each.class ? <span>**</span> : ""}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="clear" />
          <div className="row">
            <div className={`col-12`}>
              <span className="font-red" style={{ fontSize: "24px" }}>
                ** - Free 14-Day Trial
              </span>
              <button
                type="button"
                className="btn btn-success btn-lg float-right"
                onClick={() => this.checkTerms()}
              >
                Continue
              </button>
              {this.state.finalPrice > 0 ? (
                <button
                  type="button"
                  className="btn btn-lg btn-outline-danger float-right"
                >
                  <b>Total - ${this.state.finalPrice}</b>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <hr />
          <section className="text-center container">
            <div className="row py-lg-3 ">
              <div className="col-lg-12 col-md-8 mx-auto choose">
                <h1 className="fw-light">
                  <b>Choose a Plan and Save</b>
                </h1>
              </div>
            </div>
          </section>
          <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
            <div className="col">
              <img
                src={"https://d3l9g5ay50k79j.cloudfront.net/logo/GrowthP.png"}
                alt="plan"
                width={"80%"}
                onClick={() => this.confirmPlan("growth")}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="col">
              <img
                src={"https://d3l9g5ay50k79j.cloudfront.net/logo/PremiumP.png"}
                alt="plan"
                width={"80%"}
                onClick={() => this.confirmPlan("premium")}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </main>
        <div className="container">
          <footer className="py-3 my-4" style={{ paddingLeft: "0" }}>
            <ul className="nav justify-content-center border-bottom pb-3 mb-3">
              <li className="nav-item">
                <a
                  href="https://www.rxconnexion.com/privacy"
                  className="nav-link px-2 text-body-secondary"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.rxconnexion.com/pricing"
                  className="nav-link px-2 text-body-secondary"
                >
                  Pricing
                </a>
              </li>
            </ul>
            <p className="text-center text-body-secondary">
              © 2024 RxConnexion, Inc
            </p>
          </footer>
        </div>
        <TermCondition
          userInfo={this.state.userInfo}
          solutions={this.state.selectedSolution.filter((each) =>
            termC.includes(each)
          )}
        />
        <div className="modal fade" id="rxpodcast-plan-modal" data-reset="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header" style={{ display: "block" }}>
                <div style={{ float: "left" }}>
                  <img
                    height="60"
                    alt="logo"
                    src={
                      "https://d3l9g5ay50k79j.cloudfront.net/logo/RxPodcast.png"
                    }
                  />
                </div>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h3 className="modal-title center" style={{ clear: "both" }}>
                  Select Solutions
                </h3>
              </div>
              <div className="modal-body center">
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="center">
                        <img
                          src={
                            "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/RxPodcast_Basic.png"
                          }
                          alt="Plan"
                          className="pointer"
                          onClick={() => this.choosePlanRxPodcast("Basic")}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="center">
                        <img
                          src={
                            "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/RxPodcast_Plus.png"
                          }
                          alt="Plan"
                          className="pointer"
                          onClick={() => this.choosePlanRxPodcast("Plus")}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="center">
                        <img
                          src={
                            "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/RxPodcast_Pro.png"
                          }
                          alt="Plan"
                          className="pointer"
                          onClick={() => this.choosePlanRxPodcast("Pro")}
                        />
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <div className="modal-footer">
                <button
                  onClick={this.close}
                  type="button"
                  className="btn btn-success btn-lg"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser, refreshUser })(
  ChoosePlanInitial
);
