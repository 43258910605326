import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import moment from "moment";
import GroupFilter from "../Filter/GroupFilter.js";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";

class FilterDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      from_date: moment().startOf("year").toDate(),
      to_date: moment().toDate(),
      rx_group_associated: "",
    };
  }

  setFromDate = (date) => {
    this.setState({ from_date: date });
  };

  setToDate = (date) => {
    this.setState({ to_date: date });
  };

  onSearch = () => {
    this.setState(
      {
        formData: {
          from_date: this.state.from_date,
          to_date: this.state.to_date,
          pharmacy_account:
            this.state.rx_group_associated === ""
              ? this.props.auth.user.final_user
              : this.state.rx_group_associated,
        },
      },
      () =>
        this.props.onSearch(
          this.state.from_date,
          this.state.to_date,
          this.state.rx_group_associated === ""
            ? this.props.auth.user.final_user
            : this.state.rx_group_associated,
          this.state.rx_group_associated
        )
    );
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  openDatepicker = (e) => {
    if (e.target.id === "toD") {
      this.toDatePicker.setOpen(true);
    } else {
      this.fromDatePicker.setOpen(true);
    }
  };

  render() {
    return (
      <div className="row px-4">
        <div className="card shadow-lg">
          <div className="card-body dashboard">
            <div className="form-row">
              <GroupFilter onChangeSelect={this.onChangeSelect} />
              <div className="form-group col-md-3">
                <label>From Date</label>
                <DatePicker
                  selected={this.state.from_date}
                  placeholderText="MM/DD/YYYY"
                  onChange={(date) => this.setFromDate(date)}
                  className="form-control"
                  ref={(c) => (this.fromDatePicker = c)}
                />
                <i
                  aria-hidden="true"
                  className="fa fa-calendar"
                  id="fromD"
                  onClick={this.openDatepicker}
                ></i>
              </div>
              <div className="form-group col-md-3">
                <label>To Date</label>
                <DatePicker
                  selected={this.state.to_date}
                  placeholderText="MM/DD/YYYY"
                  onChange={(date) => this.setToDate(date)}
                  className="form-control"
                  ref={(c) => (this.toDatePicker = c)}
                />
                <i
                  aria-hidden="true"
                  className="fa fa-calendar"
                  id="toD"
                  onClick={this.openDatepicker}
                ></i>
              </div>
              <div className="form-group col-md-4">
                <button
                  onClick={this.onSearch}
                  type="button"
                  style={{ marginTop: "28px" }}
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(FilterDate);
