import React from 'react'
import PulseLoader from "react-spinners/PulseLoader";
import axios from 'axios';
import { withRouter } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';

class User extends React.Component {

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.state = {
            record: undefined,
            errors: {},
        };
    }
    componentDidMount() {
        const filterData = {
            page_number: 1,
            page_size: 20,
            sort_order: { column: "_id", order: "asc" }
            , search: { _id: this.props.match.params._id }
        }
        axios
            .post(`/api/user-data`, filterData)
            .then(res => {
                this.setState({
                    record: res.data.records[0],
                    totalCount: res.data.count,
                    loading: false
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })
    }
    goBack(){
        this.props.history.goBack();
    }
    render() {
        const { errors } = this.state;
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                {this.state.record !== undefined && (
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Values</th>
                            </tr>
                        </thead>
                        <tr>
                            <td>Account Name</td>
                            <td>{this.state.record.name}</td>
                        </tr>
                        <tr>
                            <td>NPI</td>
                            <td>{this.state.record.npi}</td>
                        </tr>
                        <tr>
                            <td>RxGroup</td>
                            <td>{this.state.record.rx_group}</td>
                        </tr>
                        <tr>
                            <td>RxBin</td>
                            <td>{this.state.record.rx_bin}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{this.state.record.first_name + " " + this.state.record.first_name}</td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>{this.state.record.email}</td>
                        </tr>
                        <tr>
                            <td>Upline</td>
                            <td>{this.state.record.upline}</td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>{this.state.record.street + ' ' + this.state.record.city + ' ' + this.state.record.state + ' ' + this.state.record.zip}<br />{"United State"}<br />{"Phone: " + this.state.record.phone + ' ' + " | " + "Website: " + this.state.record.website}</td>
                        </tr>
                        <button type="button" class="btn btn-dark btn-lg" onClick={this.goBack}>Go Back</button>
                    </table>
                )}
            </div>
        )
    }
}
export default withRouter(User)
