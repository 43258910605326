import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { connect } from "react-redux";
import $ from 'jquery';
import isEmpty from 'is-empty';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"
import PulseLoader from "react-spinners/PulseLoader";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class QuickDispense extends React.Component {

    constructor() {
        super();
        this.state = {
            dispense_date: "",
            _id: "",
            drug_name: "",
            rx_number: "",
            qty_used: "",
            notes: "",
            dea: "",
            errors: {},
            on_hand: undefined,
            errs: {},
            on_hand_qty: "",
            on_hand_initials: "",
            type: ""
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                ...nextProps.record,
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    hide = () => {
        $('#quick-adjust-modal').modal('hide');
    }

    onReportAdd = e => {
        e.preventDefault();
        let { errors } = this.state

        if (this.state.ndc === '') {
            errors.ndc = "This field is required"
        } else {
            delete errors.ndc
        }
        if (this.state.type === '') {
            errors.type = "This field is required"
        } else {
            delete errors.type
        }
        if (this.state.qty_used === '') {
            errors.qty_used = "This field is required"
        } else if (isNaN(this.state.qty_used)) {
            errors.qty_used = "This field should be number"
        } else {
            delete errors.qty_used
        }
        if (isEmpty(errors)) {
            this.setState({ errors })
            if (this.state.type === "dispense") {
                const newWaiver = {
                    dispense_date: new Date(),
                    ndc: this.state._id,
                    drug_name: this.state.drug_name,
                    dea: this.state.dea,
                    qty_dispensed: this.state.qty_used,
                    notes: this.state.notes,
                    adjusted: true,
                    pharmacy_account: this.props.auth.user.final_user,
                    user_created: this.props.auth.user.email,
                };
                axios
                    .post("/api/dispense-add", newWaiver)
                    .then(res => {
                        this.props.getData();
                        $('#quick-adjust-modal').modal('hide');
                        this.setState({
                            dispense_date: "",
                            drug_name: "",
                            qty_used: "",
                            notes: "",
                        })
                        toast(res.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }).catch(err =>
                        console.log(err)
                    )
            } else {
                const formData = new FormData();
                formData.append("invoiceimage", "");
                formData.append("purchase_date", new Date());
                formData.append("ndc", this.state._id);
                formData.append("drug_name", this.state.drug_name);
                formData.append("dea", this.state.dea);
                formData.append("total_qty", (this.state.qty_used));
                formData.append("pharmacy_account", this.props.auth.user.final_user);
                formData.append("user_created", this.props.auth.user.email);
                formData.append("notes", this.state.notes);
                formData.append("adjusted", true);
                axios
                    .post("/api/purchase-add", formData,{})
                    .then(res => {
                        this.props.getData();
                        $('#quick-adjust-modal').modal('hide');
                        this.setState({
                            dispense_date: "",
                            drug_name: "",
                            qty_used: "",
                            notes: "",
                            type: ""
                        })
                        toast(res.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }).catch(err =>
                        console.log(err)
                    )
            }
        } else {
            this.setState({ errors })
        }
    };

    render() {
        const { errors, errs } = this.state;
        return (
            <div>
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div className="modal fade" id="quick-adjust-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Quick Adjust Dispense</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onReportAdd} id="add-report">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="searchDrug">NDC</label>
                                        </div>
                                        <div className="col-md-9">
                                            {this.state._id}
                                        </div>
                                    </div>
                                    {this.state.drug_name && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="rx_number">Medication</label>
                                        </div>
                                        <div className="col-md-9">
                                            {this.state.drug_name}
                                        </div>
                                    </div>}
                                    {this.state.dea && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="rx_number">DEA Class</label>
                                        </div>
                                        <div className="col-md-9">
                                            {this.state.dea}
                                        </div>
                                    </div>}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="dea">Adjust Type</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} value={this.state.type} error={errors.type} id="type" className={classnames("form-control", { invalid: errors.type })}>
                                                <option value="">Please Select</option>
                                                <option value="dispense">Remove Qty</option>
                                                <option value="purchase">Add Qty</option>
                                            </select>
                                            <span className="text-danger">{errors.type}</span>

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="qty_used">Qty Adjust</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.qty_used}
                                                id="qty_used"
                                                type="text"
                                                disabled={this.state.drug_name === '' ? true : false}

                                                className={classnames("form-control", {
                                                    invalid: errors.qty_used
                                                })}
                                            />
                                            <span className="text-danger">{errors.qty_used}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="notes">Notes</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.notes}
                                                id="notes"
                                                type="text"
                                                disabled={this.state.drug_name === '' ? true : false}

                                                error={errors.notes}
                                                className={classnames("form-control", {
                                                    invalid: errors.notes
                                                })} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-report"
                                    type="submit"
                                    disabled={this.state.drug_name === '' || this.state.doctor_firstname === "" ? true : false}
                                    className="btn btn-primary">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps
)(QuickDispense);
