import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../utils/dibuddy.png";

class Diabuddy extends React.Component {
  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title m-3">
                <div className="card">
                  <div className="card-header">
                    <div className="center">
                      <img src={logo} height={80} />
                      <button
                        type="button"
                        style={{ borderRadius: "20px" }}
                        className="btn btn-success btn-lg float-right"
                      >
                        <a
                          href="/support"
                          target="_blank"
                          style={{ color: "#fff", textDecoration: "none" }}
                        >
                          Get More Info
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <h1
                      className="card-title text-primary center"
                      style={{ fontWeight: "bold", fontSize: "36px" }}
                    >
                      Here's how The DiaBuddy Experience can benefit your
                      pharmacy and your patients
                    </h1>
                    <br></br>
                    <div className="row">
                      <div className="col-md-12">
                        <p
                          className="card-text"
                          style={{ fontSize: "20px", marginBottom: "10px" }}
                        >
                          <strong>Telehealth Consultations</strong>: Through our
                          partnership with CareSync Concierge, you will have
                          access to a network of experienced healthcare
                          professionals and pharmacy students who can provide
                          telehealth consultations to patients on behalf of your
                          pharmacy. This means your patients can receive expert
                          guidance and support without leaving the comfort of
                          your community.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <p
                              className="card-text"
                              style={{ fontSize: "20px", textAlign: "justify" }}
                            >
                              <strong>Improved Patient Outcomes</strong>: By
                              offering telehealth consultations for chronic
                              conditions such as diabetes, hypertension, and
                              obesity, you can actively contribute to improving
                              patient outcomes. The DiaBuddy Experience helps
                              patients better manage their conditions, adhere to
                              treatment plans, and lead healthier lives.
                            </p>
                            <p
                              className="card-text"
                              style={{ fontSize: "20px", textAlign: "justify" }}
                            >
                              <strong>Personalized Care</strong>: Our program is
                              designed to enable you to offer personalized care
                              plans to your patients. You can work
                              collaboratively with healthcare professionals to
                              create treatment plans that meet each patient's
                              unique needs and goals.
                            </p>
                            <p
                              className="card-text"
                              style={{
                                fontSize: "20px",
                                textAlign: "justify",
                                marginBottom: "10px",
                              }}
                            >
                              <strong>Enhanced Pharmacy Services</strong>: The
                              DiaBuddy Experience not only benefits patients but
                              also enhances your pharmacy's services. It sets
                              your pharmacy apart as a trusted healthcare
                              destination, strengthening customer loyalty and
                              attracting new patients seeking high-quality care.
                              <br></br>
                            </p>
                          </div>
                          <div className="col-md-6">
                            <img
                              src="https://d3l9g5ay50k79j.cloudfront.net/diabuddy.png"
                              width="90%"
                              style={{ border: "2px solid #e2e2e2" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <p className="card-text" style={{ fontSize: "20px" }}>
                          <strong>Support from RxConnexion</strong>: We are
                          committed to providing you with the tools, resources,
                          and support you need to seamlessly integrate The
                          DiaBuddy Experience into your pharmacy's offerings.
                          Our team is here to assist you every step of the way.
                        </p>
                        <p className="card-text" style={{ fontSize: "20px" }}>
                          We believe that this partnership and The DiaBuddy
                          Experience will bring immense value to your pharmacy
                          and, more importantly, to the patients you serve.
                          Together, we can make a positive impact on the health
                          and well-being of your community.
                        </p>
                        <p className="card-text" style={{ fontSize: "20px" }}>
                          If you're interested in learning more about how to
                          implement The DiaBuddy Experience in your pharmacy or
                          have any questions, please do not hesitate to reach
                          out to our dedicated support team. We are excited
                          about the potential of this partnership and look
                          forward to working closely with you to deliver
                          exceptional care to your patients. Thank you for your
                          continued partnership and dedication to improving
                          healthcare in your community.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Diabuddy;
