import React, { Component } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import Multiselect from 'multiselect-react-dropdown';
import { setPharmacies } from "../../actions/authActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import '../../App.css';
import PulseLoader from "react-spinners/PulseLoader";
import GroupFilter from "./Filter/GroupFilter.js";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment"
import dashboard from "../../utils/dashboard.png"
import { Link } from "react-router-dom";
import { logoutUser } from "../../actions/authActions";
import QuickDispense from "../partials/QuickDispense";
class InventoryReconciliation extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "NDC",
                className: "_id",
                align: "left",
                sortable: true,
            },
            {
                key: "drug_name",
                text: "Drug Name",
                className: "drug_name",
                align: "left",
                sortable: true,
            },
            {
                key: "avg_dispense",
                text: "Avg Monthly Dispense (90days)",
                className: "avg_dispense",
                align: "left",
                sortable: true,
            },
            {
                key: "avg_purchase",
                text: "Avg Monthly Purchase (90days)",
                className: "avg_purchase",
                align: "left",
                sortable: true,
            },
            {
                key: "total",
                text: "Total Purchase",
                className: "total",
                align: "left",
                sortable: true,
            },
            {
                key: "qty_dispensed",
                text: "Total Dispense",
                className: "qty_dispensed",
                align: "left",
                sortable: true,
            },
            {
                key: "balance",
                text: "OnHand Quantity",
                className: "balance",
                align: "left",
                sortable: true,
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 120,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        ["3", "3N", "4", "5"].includes(record.dea) &&
                        <button
                            data-toggle="modal"
                            data-target="#quick-adjust-modal"
                            className="btn btn-primary btn-sm"
                            onClick={() => this.adjustDispense(record)}
                            style={{ marginRight: '5px' }}>
                            Quick Adjust
                        </button>

                    );
                }
            }
        ];


        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Stats",
            no_data_text: 'No result found!',
            button: {
                excel: false,
                print: true,
                csv: true,
                range: false
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
            name: "inventoryRecon"
        };

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "qty_dispensed", order: "desc" }
            },
            currentRecord: {
            },
            name: undefined,
            from_date: moment().subtract(90, "days").startOf('day').toDate(),
            to_date: moment().endOf('day').toDate(),
            rx_group_associated: undefined,
            drug_name: undefined,
            ndc: undefined,
            loading: false,
            plainArray: ["2", "3", "4", "5", "2N", "3N", "NC"],
            deaSelected: [],
            adjustRow: undefined
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
    };

    componentDidUpdate(prevProps) {
        if (this.props.multistoreData.length !== prevProps.multistoreData.length) {
            this.getData()
        }
    }

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            // ...(this.state.from_date !== '' && { from_date: this.state.from_date }),
            // ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
            ...(this.state.ndc !== '' && { ndc: this.state.ndc }),
            ...(this.state.drug_name !== '' && { drug_name: this.state.drug_name }),
            ...(this.state.deaSelected.length > 0 && { dea: this.state.deaSelected }),
            pharmacy_account: this.state.rx_group_associated !== undefined && this.state.rx_group_associated !== "" ? this.state.rx_group_associated : this.props.auth.user.final_user,
            ...(this.props.multistoreData.length > 0 && (this.state.rx_group_associated === undefined || this.state.rx_group_associated === "") && { multiStoreEmails: this.props.multistoreData.map(a => a.email) }),
            exact: true
        }
        axios
            .post("/api/inventory-reconciliation", filterData)
            .then(res => {

                let result = []
                let despenseArr = {}
                let drugsUPC = {}
                let deaArr = {}
                for (let i = 0; i < res.data.drugs.length; i++) {
                    let each = res.data.drugs[i]
                    drugsUPC[each.ndc] = each.upc_barcode
                    deaArr[each.ndc] = each.dea
                }
                for (let i = 0; i < res.data.dispense.length; i++) {
                    let each = res.data.dispense[i]
                    despenseArr[each._id] = each.qty_dispensed
                }
                for (let i = 0; i < res.data.purchase.length; i++) {
                    let each = res.data.purchase[i]
                    if (despenseArr[each._id]) {
                        each.qty_dispensed = despenseArr[each._id]
                        each.balance = each.total - despenseArr[each._id]
                        each.balance = each.balance === 0 || each.balance === undefined ? "0" : each.balance
                    } else {
                        each.qty_dispensed = "0"
                        each.total = each.total === 0 ? "0" : each.total
                        each.balance = each.balance === 0 || each.balance === undefined ? each.total : each.balance
                    }
                    each.dea = deaArr[each._id]
                    result.push(each)
                }
                for (let i = 0; i < res.data.dispense.length; i++) {
                    let each = res.data.dispense[i]
                    let count = result.filter(loop => loop._id === each._id);
                    if (!count.length) {
                        each["balance"] = '-' + each.qty_dispensed
                        each["total"] = "0"
                        each.dea = deaArr[each._id]
                        result.push(each)
                    }

                }
                let filtered = []
                if (this.state.ndc) {
                    let filtered1 = result.filter(loop => loop._id === this.state.ndc);
                    let filtered2 = result.filter(loop => {
                        if (drugsUPC[loop._id])
                            return drugsUPC[loop._id].includes(this.state.ndc)
                    })
                    filtered = filtered1.concat(filtered2)
                } else if (this.state.drug_name) {
                    filtered = result.filter(loop => loop.drug_name.toLowerCase().includes(this.state.drug_name.toLowerCase()));
                } else {
                    filtered = result
                }
                let npis = result.map(loop => loop._id);
                axios
                    .post("/api/inventory-reconciliation-avg", {
                        npis: npis,
                        pharmacy_account: this.state.rx_group_associated !== undefined && this.state.rx_group_associated !== "" ? this.state.rx_group_associated : this.props.auth.user.final_user,
                        ...(this.props.multistoreData.length > 0 && (this.state.rx_group_associated === undefined || this.state.rx_group_associated === "") && { multiStoreEmails: this.props.multistoreData.map(a => a.email) }),
                    })
                    .then(resposeD => {
                        filtered.map(loop => {
                            let eachA = resposeD.data.purchase.filter(each => each._id === loop._id)
                            if (eachA.length > 0)
                                loop.avg_purchase = parseInt((eachA[0].total) / 3)

                            let eachb = resposeD.data.dispense.filter(each => each._id === loop._id)
                            if (eachb.length > 0)
                                loop.avg_dispense = parseInt((eachb[0].qty_dispensed) / 3)
                        });
                        filtered.sort((a, b) => a.avg_dispense - b.avg_dispense);

                        this.setState({
                            records: filtered,
                            totalCount: filtered.length,
                            loading: false
                        })
                    }).catch(function (error) {
                        if (error.response) {
                            if (error.response.status === 403) {
                                window.location.href = "./login"
                            }
                        }
                    })
                this.setState({
                    records: filtered,
                    totalCount: filtered.length,
                    loading: false
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })
    }

    adjustDispense = (each) => {
        this.setState({ adjustRow: each })
    }

    onSearch = e => {
        this.setState({
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "retail", order: "desc" }
            }
        }, () => this.getData());
    }

    reset = () => {
        this.setState({
            drug_name: undefined,
            ndc: undefined,
            from_date: undefined,
            to_date: undefined,
            rx_group_associated: "",
            deaSelected: [],
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "asc" }
            }
        }, () => this.getData());
    }

    onSelectMultiple = (selectedList, selectedItem) => {
        this.setState({ deaSelected: selectedList })
    }

    onChange = e => {
        let id = e.target.id
        let value = e.target.value
        this.setState({ [e.target.id]: e.target.value }, () => this.checkUPC(id, value.trim()));
    };

    checkUPC = (id, value) => {
        if (id === "ndc" && value.length > 11) {
            this.getData()
        }
    }

    setFromDate = (date) => {
        this.setState({ from_date: date });
    }
    setToDate = (date) => {
        this.setState({ to_date: date });
    }

    shouldItemRender = (item, value) => {
        return item.toLowerCase().indexOf(value.toLowerCase()) > -1
    }
    exportToCSV = (from, to) => {
        let filterData = {}
        this.setState({ loading: true })
        filterData.search = {
            ...(this.state.ndc !== '' && { ndc: this.state.ndc }),
            ...(this.state.drug_name !== '' && { drug_name: this.state.drug_name }),
            ...(this.state.deaSelected.length > 0 && { dea: this.state.deaSelected }),
            pharmacy_account: this.props.auth.user.final_user,
        }
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            ndc: "NDC",
            total: "Total Purchase",
            drug_name: "Drug Name",
            qty_dispensed: "Total Dispense",
            balance: "Qty in Hand"
        };
        // // add columns in sheet array
        // for (let column of this.props.columns) {
        //     headers[column.key] = '"' + column.text + '"';
        // }
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false })
        for (let i in this.state.records) {
            let record = this.state.records[i]
            records.push(record);
        }
        if (headers) {
            records.unshift(headers);
        }
        let top = [
            "Pharmacy Name - " + this.props.auth.user.name, '', 'Date - ' + moment(new Date()).format("DD/MM/YYYY")
        ]
        records.unshift(top);
        let footer = [
            "Sign", '', 'Date - ' + moment(new Date()).format("DD/MM/YYYY")
        ]
        records.push([])
        records.push(footer)
        // Convert Object to JSON
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject);
        let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }


    }

    convertToCSV(objArray) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    exportToPDF = (from, to) => {
        let filterData = {
            page_number: 1,
            page_size: 2000,
            sort_order: { column: "_id", order: "asc" },
            search: {}
        }
        let sTable = "";
        sTable += "<table>";
        sTable += "<thead>";
        sTable += "<tr>";
        for (let column of this.columns) {
            sTable += "<th>" + column.text + "</th>";
        }
        sTable += "</tr>";
        sTable += "</thead>";
        sTable += "<tbody>";
        for (let i in this.state.records) {
            let record = this.state.records[i];
            sTable += "<tr>";
            for (let column of this.columns) {
                if (column.cell && typeof column.cell === "function") {
                    sTable += "<td></td>";
                } else if (record[column.key]) {
                    sTable += "<td>" + record[column.key] + "</td>";
                } else {
                    sTable += "<td></td>";
                }
            }
            sTable += "</tr>";
        }
        sTable += "</tbody>";
        sTable += "</table>";

        var style = "<style>";
        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;textAlign:left;}";
        style = style + "</style>";

        var win = window.open('_blank');
        win.document.write('<html><head>');
        win.document.write('<title>' + this.config.filename + '</title>');
        win.document.write(style);
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write('<h1>' + this.config.filename + '</h1>');
        win.document.write(sTable);
        win.document.write('</body></html>');
        win.print();
        win.close();
    }


    openDatepicker = (e) => {
        if (e.target.id === "toD") {
            this.toDatePicker.setOpen(true);
        } else {
            this.fromDatePicker.setOpen(true);
        }
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        if ((this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula === undefined || this.props.auth.user.expiration_tabula < moment().format()) && this.props.auth.user.superadmin !== true) {
            window.location.href = ("/choose-plan?solution=TabulaRx");
        }
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <QuickDispense record={this.state.adjustRow} getData={this.getData} />
                <div id="page-content-wrapper">
                    {this.props.auth.user.tabulaRx || this.props.auth.user.superadmin || this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula >= moment().format() ? (
                        <div className="container-fluid">
                            <div className="float-right info" style={{fontSize:"16px", marginTop:"20px"}}>
                                <div className="color-green"><FontAwesomeIcon icon={faCheckCircle} /> Green - You may Order</div>
                                <div className="color-red"><FontAwesomeIcon icon={faCheckCircle} /> Red - Out of Stock</div>
                                <div className="color-orange"><FontAwesomeIcon icon={faCheckCircle} /> Orange - Do not order</div>
                            </div>
                            <h1 className="mt-2 text-primary">Inventory Reconciliation</h1>
                            <button className="btn btn-warning"><FontAwesomeIcon icon={faBackward} /><Link to="/dashboard"> Go to Dashboard</Link></button>
                            <br></br>
                            <br></br>
                            <div>
                                <form noValidate >
                                    <div className="form-row">
                                        <GroupFilter onChangeSelect={this.onChangeSelect} rx_group_associated={this.state.rx_group_associated} />
                                        <div className="form-group col-md-3">
                                            <label htmlFor="ndc">Search List by NDC or Scan Barcode</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ndc}
                                                id="ndc"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="drug_name">Drug Name</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.drug_name}
                                                id="drug_name"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="drug_name">Select Class/Classes</label>
                                            <Multiselect
                                                selectedValues={this.state.deaSelected}
                                                options={this.state.plainArray}
                                                isObject={false}
                                                onSelect={this.onSelectMultiple} // Function will trigger on select event
                                                onRemove={this.onSelectMultiple} // Function will trigger on remove event
                                            />
                                        </div>
                                    </div>
                                    <button
                                        onClick={this.onSearch}
                                        type="button"
                                        className="btn btn-primary">
                                        Search
                                    </button>
                                    <button type="reset" className="btn btn-secondary" onClick={this.reset} style={{ marginLeft: "10px" }}>Clear</button>
                                </form>
                            </div>
                            <br></br>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={false}
                                total_record={this.state.totalCount}
                                loading={true}
                                exportToCSV={this.exportToCSV}
                                exportToPDF={this.exportToPDF}
                            />
                        </div>
                    ) : (
                        <div className="container-fluid static-card">
                            <div className="card">
                                <div className="card-body">
                                    {this.props.auth.user.expiration_tabula < moment().format() ? (
                                        <h6 class="font-weight-bold text-danger">Your Subscription is ended<br></br>
                                            <Link to="/subscribe"><button className="btn btn-success" onClick={this.Subscribe}>Subscribe Now</button></Link>
                                        </h6>
                                    ) : !this.props.auth.user.tabulaRx ? (
                                        <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="">Contact</Link> Us.</h6>
                                    ) : (
                                        <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="">Contact</Link> Us.</h6>
                                    )}
                                </div>
                            </div>
                            <img className="static-image" src={dashboard} />
                        </div>
                    )
                    }
                </div>

                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    multistoreData: state.multistoreData,
});

export default connect(
    mapStateToProps, { setPharmacies, logoutUser }
)(InventoryReconciliation);
