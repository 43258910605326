/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import RxPodcastVideo from "../welcome/RxPodcast";
import { confirmAlert } from "react-confirm-alert";
class RxPodcast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
    };
  }

  componentDidMount() {
    this.getUserInfo();
  }

  getUserInfo = () => {
    let filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "desc" },
    };
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        this.setState({
          userInfo: res.data.records[0],
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  openLink = (link) => {
    const newUser = {
      id: this.props.auth.user.id,
      rxPodcast_started: true,
    };
    axios
      .post("/api/user-update", newUser)
      .then((res) => {})
      .catch((err) => console.log(err));

    window.open(link, "_blank");
  };

  choosePlan = (plan) => {
    confirmAlert({
      title: "Awesome!",
      message: `You have selected ${plan} Plan`,
      buttons: [
        {
          label: "Back",
          onClick: () => {},
        },
        {
          label: "Continue",
          onClick: () =>
            (window.location.href = `/choose-plan?solution=RxPodcast&subPlan=${plan}`),
        },
      ],
      overlayClassName: "confirm-plan",
    });
  };

  render() {
    return (
      <div className="section">
        <div className="container">
          <button
            className="d-none"
            id="rxpodcast-video"
            data-toggle="modal"
            data-target="#rxpodcast-modal"
          >
            Show Video
          </button>
          <RxPodcastVideo />
          <div className="row">
            <div className="col-md-12">
              <div className="section-title m-3">
                <img
                  src={
                    "https://d3l9g5ay50k79j.cloudfront.net/logo/RxPodcast.png"
                  }
                  height={80}
                />
                <div className="card">
                  <div className="card-body">
                    <h1
                      className="card-title text-primary"
                      style={{ fontWeight: "bold", fontSize: "47px" }}
                    >
                      Welcome to RxPodcast
                    </h1>
                    <div className="row">
                      <div className="col-md-12">
                        <p
                          className="card-text"
                          style={{ fontSize: "24px", textAlign: "justify" }}
                        >
                          RxPodcast enables your pharmacy to expand its reach
                          and amplify your message to your listeners. Podcasting
                          can be rewarding as well as magnify your image as a
                          healthcare provide in your community. Its easy to
                          start your podcast. Simply download a new account form
                          and email or fax back to our team. Once a new account
                          is created, a member of out podcast team will contact
                          you to get you started!
                        </p>
                      </div>
                    </div>
                    <br></br>
                    {this.state.userInfo.rxpodcast_subscriptionId ? (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="center">
                            <button
                              type="button"
                              onClick={(e) =>
                                this.openLink(
                                  "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/RxPodcast+New+Account+Form+V.3.pdf"
                                )
                              }
                              style={{ marginRight: "20px", width: "400px" }}
                              className="btn btn-success btn-lg "
                            >
                              DOWNLOAD NEW ACCOUNT FORM
                            </button>
                            <button
                              type="button"
                              onClick={(e) =>
                                this.openLink(
                                  "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/Podcast+Best+Practices+V2.pdf"
                                )
                              }
                              style={{ marginLeft: "20px", width: "400px" }}
                              className="btn btn-success btn-lg "
                            >
                              DOWNLOAD BEST PRACTICES
                            </button>
                          </div>

                          <div className="center">
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <img
                              src="https://d3l9g5ay50k79j.cloudfront.net/logo/Podcasting+streaming+icons+(2).png"
                              width="40%"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <section className="text-center container">
                          <div className="row py-lg-3">
                            <div className="col-lg-6 col-md-8 mx-auto">
                              <h1 className="fw-light">
                                <b>Select Solutions</b>
                              </h1>
                            </div>
                          </div>
                        </section>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="center">
                              <img
                                src={
                                  "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/RxPodcast_Basic.png"
                                }
                                width="80%"
                                alt="Plan"
                                className="pointer"
                                onClick={() => this.choosePlan("Basic")}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="center">
                              <img
                                src={
                                  "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/RxPodcast_Plus.png"
                                }
                                width="80%"
                                alt="Plan"
                                className="pointer"
                                onClick={() => this.choosePlan("Plus")}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="center">
                              <img
                                src={
                                  "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/RxPodcast_Pro.png"
                                }
                                width="80%"
                                alt="Plan"
                                className="pointer"
                                onClick={() => this.choosePlan("Pro")}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(RxPodcast);
