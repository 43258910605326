import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { setPharmacies } from "../../actions/authActions";
import "react-datepicker/dist/react-datepicker.css";
import '../../App.css';
import PulseLoader from "react-spinners/PulseLoader";
import { toast, ToastContainer } from "react-toastify";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import moment from "moment"
import dashboard from "../../utils/dashboard.png"
import { Link } from "react-router-dom";
import { logoutUser } from "../../actions/authActions";

const mmeData = [
    {
        name: "Benzhydrocodone",
        conversion_factor: "1.22",
        type: "mg"
    },
    {
        name: "Buprenorphine film* (mcg)",
        conversion_factor: "0.03",
        type: "mcg"
    },
    {
        name: "Buprenorphine transdermal* (mcg/hr)",
        conversion_factor: "1.8",
        type: "mcg"
    },
    {
        name: "Butorphanol (nasal)",
        conversion_factor: "7",
        type: "mg"
    },
    {
        name: "Codeine",
        conversion_factor: "0.15",
        type: "mg"
    },
    {
        name: "Dihydrocodeine",
        conversion_factor: "0.25",
        type: "mg"
    },
    {
        name: "Fentanyl transdermal (mcg/hr) ",
        conversion_factor: "2.4",
        type: "mcg"
    },
    {
        name: "Fentanyl buccal tablet (mcg)",
        conversion_factor: "0.13",
        type: "mcg"
    },
    {
        name: "Fentanyl nasal spray (mcg/spray)",
        conversion_factor: "0.16",
        type: "mcg"
    },
    {
        name: "Fentanyl oral spray (mcg/spray)",
        conversion_factor: "0.18",
        type: "mcg"
    },
    {
        name: "Hydrocodone",
        conversion_factor: "1",
        type: "mg"
    },
    {
        name: "Hydromorphone",
        conversion_factor: "4",
        type: "mg"
    },
    {
        name: "Levomethadyl ",
        conversion_factor: "8",
        type: "mg"
    },
    {
        name: "Levorphanol ",
        conversion_factor: "11",
        type: "mg"
    },
    {
        name: "Meperidine (IM, IV)",
        conversion_factor: "0.3",
        type: "mg"
    },
    {
        name: "Meperidine (oral)",
        conversion_factor: "0.1",
        type: "mg"
    },
    {
        name: "Methadone",
        conversion_factor: "0.1",
        type: "mg"
    },
    {
        name: "Morphine",
        conversion_factor: "1",
        type: "mg"
    },
    {
        name: "Oxycodone",
        conversion_factor: "1.5",
        type: "mg"
    },
    {
        name: "Oxymorphone",
        conversion_factor: "3",
        type: "mg"
    },
    {
        name: "Pentazocine",
        conversion_factor: "0.37",
        type: "mg"
    },
    {
        name: "Propoxyphene",
        conversion_factor: "0.23",
        type: "mg"
    },
    {
        name: "Tapentadol ",
        conversion_factor: "0.4",
        type: "mg"
    },
    {
        name: "Tramadol",
        conversion_factor: "0.1",
        type: "mg"
    },
]

class MmeCaclulator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "retail", order: "desc" }
            },
            currentRecord: {
            },
            values: [],
            convertedValues: [],
            loading: false
        };

    }

    changeText = (e) => {
        const {values, convertedValues} = this.state
        values[[e.target.id]] = e.target.value
        let obj = mmeData.find(a => a.name===e.target.id)
        convertedValues[[e.target.id]] = (e.target.value*obj.conversion_factor)
        this.setState({ values: values });
    }

    render() {
        if((this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula === undefined ||this.props.auth.user.expiration_tabula < moment().format()) && this.props.auth.user.superadmin!==true) {
            window.location.href = ("/choose-plan?solution=TabulaRx");
        }
        let sum = 0
        Object.values(this.state.convertedValues).map(each => {
            sum = sum + each
        })
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                {this.props.auth.user.tabulaRx || this.props.auth.user.superadmin || this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula >= moment().format() ? (

                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">Morphine Milligram Equivalent (MME) Calculator</h1>
                        <p>
                            <b>Purpose</b>: This calculator is intended to understand how MME are calculated for a client taking one or more opioid medications under the Analgesics: Opioid Agonists (65.10.00) for Apple Health. Please note transdermal opioids are calculated per day. 							
                        </p>
                        <button className="btn btn-warning"><FontAwesomeIcon icon={faBackward} /><Link to="/dashboard"> Go to Dashboard</Link></button>
                        <br></br>
                        <br></br>
                        <div>
                            <table className="table table-bordered table-striped mme-calculator">
                                <tr>
                                    <th>Opioid</th>
                                    <th>MG per day</th>
                                    <th>Morphine equivalents</th>
                                </tr>
                                {
                                    mmeData.map(each => {
                                        return(
                                            <tr>
                                                <td>{each.name}</td>
                                                <td><input type="text" id={each.name} value={this.state.values[each.name]} onChange={this.changeText}/> {each.type}</td>
                                                <td>{(this.state.convertedValues[each.name])!==undefined?this.state.convertedValues[each.name].toFixed(2):""}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <th></th>
                                    <th>Total</th>
                                    <th>
                                        {
                                            sum.toFixed(2)
                                        }
                                    </th>
                                </tr>
                            </table>
                            <br></br>
                            <p>*Only buprenorphine products indicated for pain</p>
                            <p style={{"color":"red"}}>**A sliding scale is used to calculate the MME of methadone depending on its daily dose.			</p>
                        </div>
                    </div>
                     ): (
                        <div className="container-fluid static-card">
                            <div className="card">
                                <div className="card-body">
                                    {this.props.auth.user.expiration_tabula < moment().format() ? (
                                        <>Your Subscription is ended<br></br>
                                            <Link to="/subscribe"><button className="btn btn-success" onClick={this.Subscribe}>Subscribe Now</button></Link></>
                                    ) : !this.props.auth.user.tabulaRx ? (
                                        <>Something Went Wrong !! Please <Link to="/support">Contact</Link> to Administration</>
                                    )   : (
                                        <>Something Went Wrong !! Please <Link to="/support">Contact</Link> to Administration</>
                                    )}
                                </div>
                            </div>
                            <img className="static-image" src={dashboard} />
                        </div>
                    )
                    }
                </div>

                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    pharmacy: state.pharmacy,
    groups: state.groups,
});

export default connect(
    mapStateToProps, { setPharmacies, logoutUser }
)(MmeCaclulator);
