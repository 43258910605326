import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { connect } from "react-redux";
import $ from 'jquery';
import isEmpty from 'is-empty';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';


class PurchaseAddModal extends React.Component {

    constructor() {
        super();
        this.state = {
            purchase_date: "",
            expiration_date: "",
            ndc: "",
            drug_name: "",
            package_size: "",
            qty_received: "",
            form_number: "",
            invoice_number: "",
            supplier: "",
            notes: "",
            lot: "",
            dea: "",
            errors: {},
            errs: {},
            invoiceimage: "",
        };
    }

    onChange = e => {
        const currentId = e.target.id
        let value = e.target.value
        if (currentId === "ndc") {
            value = value.trim()
        }
        this.setState({ [e.target.id]: value },
            () => this.checkNdc(currentId));
    };
    hide = e => {
        $('#add-purchase-modal').modal('hide');

    }

    checkNdc = (currentId) => {

        if (currentId === "ndc"){
            this.setState({drug_name: "", dea: "" });
        }

        if (currentId === "ndc" && (this.state.ndc !== "" && this.state.ndc.length === 11)) {
            let { errors, errs } = this.state
            errors.ndc = ""
            errs.ndc = ""
            this.setState({ errors, errs, drug_name: "" });
            let filterData = { sort_order: { column: "ndc", order: "desc" } }
            filterData.search = {
                ndc: this.state.ndc,
                exact: true
            }

            axios
                .post("/api/drugs", filterData)
                .then(res => {
                    this.setState({ loading: false });
                    if (res.data.records.length > 0) {
                        let { errors } = this.state
                        delete errors.ndc
                        this.setState({ errors, drug_name: res.data.records[0].name, dea: res.data.records[0].dea, ndc: res.data.records[0].ndc });
                    } else {
                        let { errors } = this.state
                        let { errs } = this.state

                        errors.ndc = "NDC not exists"
                        errs.ndc = <a className="text-primary float-right " data-toggle="modal" href="#add-drug-modal" onClick={this.hide}>Add New NDC</a>
                        this.setState({ errors });
                        this.setState({ errs });


                    }


                })
        }

    }


    onReportAdd = e => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.purchase_date === '') {
            errors.purchase_date = "This field is required"
        } else {
            delete errors.purchase_date
        }
        if (this.state.ndc === '') {
            errors.ndc = "This field is required"
        } else {
            delete errors.ndc
        }
        if (this.state.package_size === '') {
            errors.package_size = "This field is required"
        } else if (isNaN(this.state.package_size)) {
            errors.package_size = "This field should be number"
        } else if (parseInt(this.state.package_size)>1000) {
            errors.package_size = "Should not more than 1000"
        }else {
            delete errors.package_size
        }
        if (this.state.qty_received === '') {
            errors.qty_received = "This field is required"
        } else if (parseInt(this.state.qty_received)>99) {
            errors.qty_received = "Should not more than 99"
        } else if (isNaN(this.state.qty_received)) {
            errors.qty_received = "This field should be number"
        } else {
            delete errors.qty_received
        }
        if (this.state.form_number === '') {
            errors.form_number = "This field is required"
        } else {
            delete errors.form_number
        }
        if (this.state.invoice_number === '') {
            errors.invoice_number = "This field is required"
        } else {
            delete errors.invoice_number
        }
        if (this.state.supplier === '') {
            errors.supplier = "This field is required"
        } else {
            delete errors.supplier
        }
        if (isEmpty(errors)) {
            this.setState({ errors })
            // const newWaiver = {
            //     purchase_date: this.state.purchase_date,
            //     expiration_date: this.state.expiration_date,
            //     lot: this.state.lot,
            //     ndc: this.state.ndc,
            //     drug_name: this.state.drug_name,
            //     dea: this.state.dea,
            //     package_size: this.state.package_size,
            //     qty_received: this.state.qty_received,
            //     total_qty: parseInt(this.state.qty_received) * parseInt(this.state.package_size),
            //     form_number: this.state.form_number,
            //     invoice_number: this.state.invoice_number,
            //     supplier: this.state.supplier,
            //     notes: this.state.notes,
            //     pharmacy_account: this.props.auth.user.final_user,
            //     user_created: this.props.auth.user.email
            // };
            const formData = new FormData();
            if (this.state.invoiceimage !== "")
                formData.append("invoiceimage", this.state.invoiceimage);
            if (this.state.purchase_date !== "")
                formData.append("purchase_date", this.state.purchase_date);
            if (this.state.expiration_date !== "")
                formData.append("expiration_date", this.state.expiration_date);
            if (this.state.lot !== "")
                formData.append("lot", this.state.lot);
            if (this.state.ndc !== "")
                formData.append("ndc", this.state.ndc);
            if (this.state.drug_name !== "")
                formData.append("drug_name", this.state.drug_name);
            if (this.state.dea !== "")
                formData.append("dea", this.state.dea);
            if (this.state.package_size !== "")
                formData.append("package_size", this.state.package_size);
            if (this.state.qty_received !== "")
                formData.append("qty_received", this.state.qty_received);

            if (this.state.form_number !== "")
                formData.append("form_number", this.state.form_number);
            if (this.state.invoice_number !== "")
                formData.append("invoice_number", this.state.invoice_number);
            if (this.state.supplier !== "")
                formData.append("supplier", this.state.supplier);
            formData.append("total_qty", parseInt(this.state.qty_received) * parseInt(this.state.package_size));
            formData.append("pharmacy_account", this.props.auth.user.final_user);
            formData.append("user_created", this.props.auth.user.email);
            axios
                .post("/api/purchase-add", formData,{})
                .then(res => {
                    this.props.getData();
                    $('#add-purchase-modal').modal('hide');
                    this.setState({
                        purchase_date: "",
                        ndc: "",
                        drug_name: "",
                        package_size: "",
                        qty_received: "",
                        form_number: "",
                        invoice_number: "",
                        supplier: "",
                        notes: "",
                        lot: "",
                        dea: "",
                        expiration_date: "",
                        invoiceimage: ""
                    })
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }).catch(err =>
                    console.log(err)
                )
        } else {
            this.setState({ errors })
        }
    };

    onImageChange = event => {
        this.setState({
            [event.target.id]: event.target.files[0],
        });
    };

    setReportDate = (date) => {
        if(moment().diff(date, 'days')>14){
            confirmAlert({
                title: 'Confirmation Required',
                message: 'You entered the date as '+moment(date).add(5, 'hours').format('L')+'. Are you sure?',
                overlayClassName: "entereddate",
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => this.setState({ purchase_date: date })
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        }else{
            this.setState({ purchase_date: date });
        }
    }

    setExpirationDate = (date) => {
        this.setState({ expiration_date: date });
    }

    openDatepicker = (e) => {
        this.toDatePicker.setOpen(true);
    }

    handleOnSelect = (item) => {
        // the item selected
        this.setState({
            ndc: item.ndc,
            drug_name: item.name
        })
    }
    formatResult = (item) => {
        return item
        // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
    }

    onBlur = (e) => {
        if (this.state.ndc.length < 11) {
            let { errors } = this.state
            errors.ndc = "NDC should be of 11 digit"
            this.setState({ errors});
        }else{
            let { errors } = this.state
            errors.ndc = ""
            this.setState({ errors});
        }

    }

    render() {
        const { errors } = this.state;
        const { errs } = this.state;

        return (
            <div>
                <div className="modal fade" id="add-purchase-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">

                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add New Purchase</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onReportAdd} id="add-report" encType="multipart/form-data">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="searchDrug">NDC</label>
                                        </div>
                                        <div className="col-md-9">
                                            {/* <ReactSearchAutocomplete
                                                items={this.props.ncDrugs}
                                                onSelect={this.handleOnSelect}
                                                autoFocus
                                                formatResult={this.formatResult}
                                            /> */}
                                            <input
                                                onChange={this.onChange}
                                                onBlur={this.onBlur}
                                                value={this.state.ndc}
                                                autofocus
                                                error={errors.ndc}
                                                errs={errs.ndc}
                                                id="ndc"
                                                type="text"
                                                placeholder="Enter 11 Digit NDC or Scan Barcode here"
                                                className={classnames("form-control", {
                                                    invalid: errors.ndc
                                                })}
                                            />
                                            <span className="text-danger">{errors.ndc}{errs.ndc}</span>
                                        </div>
                                    </div>
                                    {this.state.drug_name && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="rx_number">Medication</label>
                                        </div>
                                        <div className="col-md-9">
                                            {this.state.drug_name}
                                        </div>
                                    </div>}
                                    {this.state.dea && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="rx_number">DEA Class</label>
                                        </div>
                                        <div className="col-md-9">
                                            {this.state.dea}
                                        </div>
                                    </div>}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <DatePicker maxDate={moment().toDate()} selected={this.state.purchase_date} placeholderText="MM/DD/YYYY" onChange={(date) => this.setReportDate(date)} className="form-control" ref={(c) => this.toDatePicker = c}  disabled={this.state.drug_name === '' ? true : false} />

                                            <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                            <span className="text-danger">{errors.purchase_date}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="package_size">Package Size</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.package_size}
                                                error={errors.package_size}
                                                id="package_size"
                                                type="text"
                                                disabled={this.state.drug_name === '' ? true : false}

                                                className={classnames("form-control", {
                                                    invalid: errors.package_size
                                                })}
                                            />
                                            <span className="text-danger">{errors.package_size}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="qty_received">No. Pkg Received</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.qty_received}
                                                id="qty_received"
                                                type="text"
                                                disabled={this.state.drug_name === '' ? true : false}
                                                className={classnames("form-control", {
                                                    invalid: errors.qty_received
                                                })}
                                            />
                                            <span className="text-danger">{errors.qty_received}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="form_number">PO/Form Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.form_number}
                                                id="form_number"
                                                type="text"
                                                disabled={this.state.drug_name === '' ? true : false}

                                                className={classnames("form-control", {
                                                    invalid: errors.form_number
                                                })}
                                            />
                                            <span className="text-danger">{errors.form_number}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="invoice_number">Invoice Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.invoice_number}
                                                id="invoice_number"
                                                type="text"
                                                disabled={this.state.drug_name === '' ? true : false}

                                                error={errors.invoice_number}
                                                className={classnames("form-control", {
                                                    invalid: errors.invoice_number
                                                })} />
                                            <span className="text-danger">{errors.invoice_number}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="supplier">Supplier</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.supplier}
                                                id="supplier"
                                                type="text"
                                                disabled={this.state.drug_name === '' ? true : false}

                                                error={errors.rx_bin}
                                                className={classnames("form-control", {
                                                    invalid: errors.supplier
                                                })} />
                                            <span className="text-danger">{errors.supplier}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="expiration_date1">Expiration Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <DatePicker selected={this.state.expiration_date} placeholderText="MM/DD/YYYY" onChange={(date) => this.setExpirationDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} disabled={this.state.drug_name === '' ? true : false}/>
                                            <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                            <span className="text-danger">{errors.expiration_date}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="lot">Lot Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.lot}
                                                id="lot"
                                                type="text"
                                                disabled={this.state.drug_name === '' ? true : false}

                                                error={errors.lot}
                                                className={classnames("form-control", {
                                                    invalid: errors.lot
                                                })} />
                                            <span className="text-danger">{errors.lot}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="notes">Notes</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.notes}
                                                id="notes"
                                                type="text"
                                                disabled={this.state.drug_name === '' ? true : false}

                                                error={errors.rx_bin}
                                                className={classnames("form-control", {
                                                    invalid: errors.notes
                                                })} />
                                            <span className="text-danger">{errors.notes}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="invoice_number">Invoice Upload</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onImageChange}
                                                id="invoiceimage"
                                                type="file"
                                                disabled={this.state.drug_name === '' ? true : false}
                                                error={errors.invoiceimage}
                                                className={classnames("form-control", {
                                                    invalid: errors.invoiceimage
                                                })} />
                                            <span className="text-danger">{errors.invoiceimage}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-report"
                                    type="submit"
                                    disabled={this.state.drug_name === '' ? true : false}
                                    className="btn btn-primary">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps
)(PurchaseAddModal);
