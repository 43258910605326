import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import "react-datepicker/dist/react-datepicker.css";
import Control from "./dashboard/Control";
import FilterDate from "./dashboard/FilterDate";
import CustomMap from "./dashboard/CustomMap";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import dashboard from "../../utils/dashboard.png";
import { Link } from "react-router-dom";
import Pincodes from "../../utils/latlong.json";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";

const colors = [
  "#ff6384",
  "#36a2eb",
  "#ffce56",
  "#4bc0c0",
  "#9966ff",
  "#ff9f40",
  "#ff6384",
  "#36a2eb",
  "#ffce56",
  "#4bc0c0",
];

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: {
        from_date: moment().startOf("year").toDate(),
        to_date: moment().endOf("day").toDate(),
        pharmacy_account: this.props.auth.user.final_user,
      },
      recordsPhysician: [],
      recordsClaim: [],
      claimArr: [],
      cashArr: [],
      dispenseArr: [],
      dispenseArr2: [],
      drugCount: "",
      drugCount2: "",
      purchaseArr: [],
      purchaseArr2: [],
      purchaseCount: "",
      purchaseCount2: "",
      MMEArr: [],
      ahfsArr: [],
      trendArr: [],
      loading: false,
      selectedGroup: undefined,
    };
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  onSearch = (from_date, to_date, rx_group_associated, selectedGroup) => {
    this.setState(
      {
        selectedGroup,
        search: {
          from_date: from_date,
          to_date: to_date,
          pharmacy_account: rx_group_associated,
          ...(this.props.multistoreData.length > 0 &&
            (rx_group_associated === undefined ||
              rx_group_associated === "") && {
              multiStoreEmails: this.props.multistoreData.map((a) => a.email),
            }),
        },
      },
      () => this.callAll()
    );
  };

  callAll = () => {
    this.getMapData();
    this.getGraphData();
  };

  componentDidUpdate(prevProps) {
    if (this.props.multistoreData.length !== prevProps.multistoreData.length) {
      this.callAll();
    }
  }

  componentDidMount() {
    this.callAll();
    this.getStaticData();
  }

  getMapData() {
    const formData = {
      ...this.state.search,
      ...(this.props.multistoreData.length > 0 &&
        this.props.auth.user.final_user !== this.state.rx_group_associated && {
          multiStoreEmails: this.props.multistoreData
            .filter((a) => {
              if (this.state.selectedGroup) {
                if (this.state.selectedGroup === a.email) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            })
            .map((e) => e.email),
        }),
    };
    axios
      .post("/api/physician-by-pincode", formData)
      .then((res) => {
        const data = res.data.doctors.filter(function (element) {
          return Pincodes[element.practice_postal] !== undefined;
        });
        this.setState({
          recordsPhysician: data,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });

    axios
      .post("/api/patients-by-pincode", formData)
      .then((res) => {
        const data = res.data.records.filter(function (element) {
          return Pincodes[element._id] !== undefined;
        });
        this.setState({
          recordsClaim: data,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  getGraphData() {
    this.setState({
      loading: true,
    });
    const formData = {
      ...this.state.search,
      ...(this.props.multistoreData.length > 0 && {
        multiStoreEmails: this.props.multistoreData
          .filter((a) => {
            if (this.state.selectedGroup) {
              if (this.state.selectedGroup === a.email) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          })
          .map((e) => e.email),
      }),
    };
    axios
      .post(`/api/graph-age`, formData)
      .then((res) => {
        let claimDataObj = [];
        claimDataObj.push(["Age", "Count", { role: "style" }]);
        let records = res.data.records;
        records.sort((a, b) => (a._id > b._id ? 1 : b._id > a._id ? -1 : 0));
        records.map((each, i) => {
          if (each._id !== "" || each._id !== undefined) {
            claimDataObj.push([each._id, each.count, colors[i]]);
          }
        });

        var x = 0;
        for (let p = 1; p < claimDataObj.length; p++) {
          x += claimDataObj[p][1];
        }
        x = new Intl.NumberFormat().format(x);
        this.setState({
          claimArr: claimDataObj,
          claimArrCount: x,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    axios
      .post(`/api/graph-drug`, formData)
      .then((res) => {
        let DispenseArr = [];
        let DispenseArr2 = [];
        let sum = 0;
        let sum2 = 0;
        DispenseArr.push(["Drug", "Qty", "Rx Count"]);
        DispenseArr2.push(["Drug", "Qty", "Rx Count"]);

        for (let i = 0; i < res.data.dispense.length; i++) {
          let each = res.data.dispense[i];
          if (each.dea === "2" || each.dea === "2N") {
            //     console.log(each.dea)}
            DispenseArr.push([
              each.drug_name,
              each.qty_dispensed,
              each.rx_count,
            ]);

            sum = sum + parseInt(each.qty_dispensed);
          } else if (
            each.dea === "3" ||
            each.dea === "4" ||
            each.dea === "5" ||
            each.dea === "3N"
          ) {
            DispenseArr2.push([
              each.drug_name,
              each.qty_dispensed,
              each.rx_count,
            ]);
            sum2 = sum2 + each.qty_dispensed;
          }
        }
        sum = new Intl.NumberFormat().format(sum);
        sum2 = new Intl.NumberFormat().format(sum2);
        this.setState({
          dispenseArr: DispenseArr,
          dispenseArr2: DispenseArr2,
          drugCount: sum,
          drugCount2: sum2,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });

    axios
      .post(`/api/graph-drug-purchase`, formData)
      .then((res) => {
        let PurchaseArr = [];
        let PurchaseArr2 = [];
        let sum = 0;
        let sum2 = 0;
        PurchaseArr.push(["Drug", "Qty", "Rx Count"]);
        PurchaseArr2.push(["Drug", "Qty", "Rx Count"]);

        for (let i = 0; i < res.data.purchase.length; i++) {
          let each = res.data.purchase[i];
          if (each.dea === "2" || each.dea === "2N") {
            //     console.log(each.dea)}
            PurchaseArr.push([each.drug_name, each.total_qty, each.rx_count]);

            sum = sum + each.total_qty;
          } else if (
            each.dea === "3" ||
            each.dea === "4" ||
            each.dea === "5" ||
            each.dea === "3N"
          ) {
            PurchaseArr2.push([each.drug_name, each.total_qty, each.rx_count]);
            sum2 = sum2 + each.total_qty;
          }
        }
        sum = new Intl.NumberFormat().format(sum);
        sum2 = new Intl.NumberFormat().format(sum2);
        this.setState({
          purchaseArr: PurchaseArr,
          purchaseArr2: PurchaseArr2,
          purchaseCount: sum,
          purchaseCount2: sum2,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    axios
      .post(`/api/graph-cash`, formData)
      .then((res) => {
        let CashArr = [];
        CashArr.push(["Type", "Percent"]);
        CashArr.push(["Yes", res.data.cash]);
        CashArr.push(["No", res.data.tp]);
        let rxCount = res.data.cash + res.data.tp;
        rxCount = new Intl.NumberFormat().format(rxCount);
        this.setState({
          rxCount,
          cashArr: CashArr,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    axios
      .post(`/api/graph-ahfs`, formData)
      .then((res) => {
        let drugsArr = {};

        for (let i = 0; i < res.data.drugs.length; i++) {
          let each = res.data.drugs[i];
          drugsArr[each.ndc] = each.ahfs_desc;
        }
        let reportByAhfs = [];
        for (let i = 0; i < res.data.dispense.length; i++) {
          let each = res.data.dispense[i];
          if (reportByAhfs[drugsArr[each._id]] !== undefined) {
            reportByAhfs[drugsArr[each._id]] =
              reportByAhfs[drugsArr[each._id]] + each.qty_dispensed;
          } else {
            reportByAhfs[drugsArr[each._id]] = each.qty_dispensed;
          }
        }
        let reportByAhfsArr = [];
        reportByAhfsArr.push(["Therapeutic Class", "Count", { role: "style" }]);
        Object.keys(reportByAhfs).map((each, i) => {
          if (each !== "undefined") {
            reportByAhfsArr.push([each, reportByAhfs[each], colors[i]]);
          }
        });
        this.setState({
          ahfsArr: reportByAhfsArr,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    axios
      .post(`/api/graph-trend`, formData)
      .then((res) => {
        let TrendArr = [];
        TrendArr.push(["Day", "Qty"]);
        for (let i = 0; i < res.data.dispense.length; i++) {
          let each = res.data.dispense[i];
          TrendArr.push([each.date, each.count]);
        }
        this.setState({
          trendArr: TrendArr,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    let searchD = {
      page_number: 1,
      page_size: 20000,
      sort_order: { column: "mme", order: "desc" },
      search: formData,
    };
    axios
      .post(`/api/mme-report`, searchD)
      .then((res) => {
        let MMEArr = [];
        let drugsArr = {};
        for (let i = 0; i < res.data.drugs.length; i++) {
          let each = res.data.drugs[i];
          drugsArr[each.ndc] = each;
        }
        let red = 0;
        let orange = 0;
        let green = 0;
        for (let i = 0; i < res.data.dispense.length; i++) {
          let each = res.data.dispense[i];
          if (each.ndc !== undefined) {
            if (
              each.day_supply > 0 &&
              each.qty_dispensed > 0 &&
              parseInt(drugsArr[each.ndc]["unit_strength_qty"]) > 0 &&
              drugsArr[each.ndc]["mme"] > 0
            ) {
              let mme = Math.round(
                (each.qty_dispensed / each.day_supply) *
                  parseInt(drugsArr[each.ndc]["unit_strength_qty"]) *
                  drugsArr[each.ndc]["mme"]
              );
              if (mme > 90) {
                red++;
              }
              if (mme <= 90 && mme > 60) {
                orange++;
              }
              if (mme <= 60) {
                green++;
              }
            }
          }
        }
        MMEArr.push(["Type", "Count"]);
        MMEArr.push(["Red", red]);
        MMEArr.push(["Orange", orange]);
        MMEArr.push(["Green", green]);
        this.setState({
          MMEArr: MMEArr,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  getStaticData() {
    const formData = {
      ...this.state.search,
      ...(this.props.multistoreData.length > 0 && {
        multiStoreEmails: this.props.multistoreData
          .filter((a) => {
            if (this.state.selectedGroup) {
              if (this.state.selectedGroup === a.email) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          })
          .map((e) => e.email),
      }),
    };
    axios
      .post(`/api/coupon-count`, formData)
      .then((res) => {
        this.setState({
          couponCount: res.data.count
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    axios
      .post(`/api/drug-count`)
      .then((res) => {
        this.setState({
          drugTotalCount: res.data.count
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    axios
      .post(`/api/npi-count`, formData)
      .then((res) => {
        this.setState({
          drugNpiCount: res.data.count
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    axios
      .post(`/api/pending-purchase`, formData)
      .then((res) => {
        this.setState({
          pendingPurchase: res.data.count,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    axios
      .post(`/api/pending-dispense`, formData)
      .then((res) => {
        this.setState({
          pendingDispense: res.data.count,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    axios
      .post(`/api/refrigerator-last`, formData)
      .then((res) => {
        this.setState({
          refridgeratorLast: res.data.lastDate,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    axios
      .post(`/api/rxarea-last`, formData)
      .then((res) => {
        this.setState({
          rxAreaLast: res.data.lastDate,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  render() {
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    return (
      <div className="d-flex dashboard" id="wrapper">
        <div id="page-content-wrapper">
          {this.props.auth.user.tabulaRx ||
          this.props.auth.user.superadmin ||
          this.props.auth.user.expiration_tabula === "" ||
          this.props.auth.user.expiration_tabula >= moment().format() ? (
            <div className="container-fluid">
              {this.state.loading && (
                <div className="loading-wrapper">
                  <PulseLoader color="#007bff" loading={true} size={20} />
                </div>
              )}
              <h1 className="mt-2 text-primary">Dashboard</h1>
              <div className="container">
                <a href="/npis">
                  <img
                    src="https://rxreachanalytis.s3.amazonaws.com/logo/Banner.gif"
                    width={"100%"}
                    style={{ marginBottom: "10px" }}
                  />
                </a>
                <FilterDate onSearch={this.onSearch} />
              </div>
              <Control
                couponCount={this.state.couponCount}
                drugTotalCount={this.state.drugTotalCount}
                drugNpiCount={this.state.drugNpiCount}
                claimArr={this.state.claimArr}
                claimArrCount={this.state.claimArrCount}
                cashArr={this.state.cashArr}
                rxCount={this.state.rxCount}
                dispenseArr={this.state.dispenseArr}
                dispenseArr2={this.state.dispenseArr2}
                drugCount={this.state.drugCount}
                drugCount2={this.state.drugCount2}
                purchaseArr={this.state.purchaseArr}
                purchaseArr2={this.state.purchaseArr2}
                purchaseCount={this.state.purchaseCount}
                purchaseCount2={this.state.purchaseCount2}
                ahfsArr={this.state.ahfsArr}
                trendArr={this.state.trendArr}
                pendingPurchase={this.state.pendingPurchase}
                pendingDispense={this.state.pendingDispense}
                refridgeratorLast={this.state.refridgeratorLast}
                rxAreaLast={this.state.rxAreaLast}
                MMEArr={this.state.MMEArr}
              />
              <CustomMap
                recordsPhysician={this.state.recordsPhysician}
                recordsClaim={this.state.recordsClaim}
              />
            </div>
          ) : (
            <div className="container-fluid static-card">
              <div className="card">
                <div className="card-body">
                  {this.props.auth.user.expiration_tabula <
                  moment().format() ? (
                    <h6 class="font-weight-bold text-danger">
                      Your Subscription is ended<br></br>
                      <Link to="/subscribe">
                        <button
                          className="btn btn-success"
                          onClick={this.Subscribe}
                        >
                          Subscribe Now
                        </button>
                      </Link>
                    </h6>
                  ) : !this.props.auth.user.tabulaRx ? (
                    <h6 class="font-weight-bold text-danger">
                      You accounts is inactive!! To activate you account please{" "}
                      <Link to="/support" className="">
                        Contact
                      </Link>{" "}
                      Us.
                    </h6>
                  ) : (
                    <h6 class="font-weight-bold text-danger">
                      You accounts is inactive!! To activate you account please{" "}
                      <Link to="/support" className="">
                        Contact
                      </Link>{" "}
                      Us.
                    </h6>
                  )}
                </div>
              </div>
              <img className="static-image" src={dashboard} alt="" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  multistoreData: state.multistoreData,
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
