import React, { Component, Fragment } from "react";
import { Row, Container, Col } from "react-bootstrap";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { faPlus, faBackward } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import GroupFilter from "./Filter/GroupFilter.js";
import Chart from "react-google-charts";
import DatePicker from "react-datepicker";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Link } from "react-router-dom";
import AddFridge from "../partials/AddFridge";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import dashboard from "../../utils/dashboard.png";
import { logoutUser } from "../../actions/authActions";
import "react-confirm-alert/src/react-confirm-alert.css";
class FridgeLog extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "date",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
      },
      {
        key: "type",
        text: "Fridge Type",
        className: "type",
        align: "left",
      },
      {
        key: "temperture",
        text: "Temperture  (°F)",
        className: "temperture",
        align: "left",
      },
    ];
    if (!this.props.auth.user.superadmin && !this.props.auth.user.multistore) {
      this.columns.push({
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              {/* <button
                                data-toggle="modal"
                                data-target="#update-purchase-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button> */}
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      });
    } else {
      this.columns.push({
        key: "pharmacy_account",
        text: "User Email",
        className: "pharmacy_account",
        align: "left",
        sortable: true,
      });
    }

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "Log List",
      no_data_text: "No Log found!",
      button: {
        excel: false,
        print: false,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
      sort: {
        column: "date",
        order: "desc",
      },
    };

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "date", order: "desc" },
      },
      currentRecord: {},
      date: undefined,
      rx_group_associated: undefined,
      from_date: moment().subtract(30, "days").startOf("day").toDate(),
      to_date: moment().endOf("day").toDate(),
      // temperture: undefined,
      recordsGraph: [],
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
    // this.getDrugData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.multistoreData.length !== prevProps.multistoreData.length) {
      this.getData();
    }
  }

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.to_date !== "" && { to_date: this.state.to_date }),
      ...(this.state.from_date !== "" && { from_date: this.state.from_date }),
      ...(this.state.temperture !== "" && {
        temperture: this.state.temperture,
      }),
      ...(this.props.multistoreData.length > 0 &&
        (this.state.rx_group_associated === undefined ||
          this.state.rx_group_associated === "") && {
          multiStoreEmails: this.props.multistoreData.map((a) => a.email),
        }),
      pharmacy_account:
        this.state.rx_group_associated !== undefined &&
        this.state.rx_group_associated !== ""
          ? this.state.rx_group_associated
          : this.props.auth.user.final_user,
    };
    axios
      .post("/api/fridge-log", filterData)
      .then((res) => {
        var result = [];
        let recordsGraph = [];
        recordsGraph.push(["Date", "Fridge1", "Fridge2", "Fridge3"]);
        // add data rows in sheet array
        for (let i in res.data.records) {
          let each = res.data.records[i];
          if (each.date !== "") {
            each.date = moment(each.date).format("LLL");
            each.dateOnly = moment(each.date).format("LL");
          }
          //   recordsGraph.push([
          //     (each.date = moment(each.date).format("LL")),
          //     (each.temperture = each.temperture),
          //   ]);
          result.push(each);
        }
        Object.entries(Object.groupBy(result, ({ dateOnly }) => dateOnly)).map(
          (val) => {
            let fridge1Val = val[1].find((each) => each.type === "Fridge1");
            let fridge2Val = val[1].find((each) => each.type === "Fridge2");
            let fridge3Val = val[1].find((each) => each.type === "Fridge3");
            let currValue = [
              moment(val[0]).format("LL"),
              fridge1Val ? fridge1Val.temperture : 0,
              fridge2Val ? fridge2Val.temperture : 0,
              fridge3Val ? fridge3Val.temperture : 0,
            ];
            recordsGraph.push(currValue);
          }
        );
        recordsGraph.sort((a, b) => {
          return new Date(a[0]) - new Date(b[0]);
        });
        this.setState({
          records: result,
          recordsGraph: recordsGraph,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  // getDrugData() {
  //     this.setState({ loading: true })
  //     axios
  //         .post("/api/nc-drugs")
  //         .then(res => {
  //             this.setState({
  //                 ncDrugs: res.data.records,
  //                 loading: false,
  //             })
  //         })

  //         .catch(function (error) {
  //             if (error.response) {
  //                 if (error.response.status === 403) {
  //                     window.location.href = "./login"
  //                 }
  //             }
  //         })
  // }

  // editRecord(record) {
  //     this.setState({ currentRecord: record });
  // }
  setFromDate = (date) => {
    this.setState({ from_date: date });
  };
  setToDate = (date) => {
    this.setState({ to_date: date });
  };
  deleteRecord(record) {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.finalDelete(record),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  finalDelete = (record) => {
    axios
      .post("/api/fridge-delete", {
        _id: record._id,
        delete: this.props.auth.user.user_type !== "Technician" ? true : false,
        email: this.props.auth.user.email,
      })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getData();
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  // deleteRecord(record) {
  //     axios
  //         .post("/api/fridge-delete", { _id: record._id })
  //         .then(res => {
  //             if (res.status === 200) {
  //                 toast(res.data.message, {
  //                     position: toast.POSITION.TOP_CENTER,
  //                 })
  //             }
  //         })
  //         .catch(function (error) {
  //             if (error.response) {
  //                 if (error.response.status === 403) {
  //                     window.location.href = "./login"
  //                 }
  //             }
  //         });
  //     this.getData();
  // }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };
  resetSearch = () => {
    this.setState(
      {
        date: undefined,
        // time: undefined,
        from_date: undefined,
        to_date: undefined,
        temperture: undefined,
        rx_group_associated: undefined,
        filterData: {
          sort_order: { column: "_id", order: "desc" },
        },
      },
      () => this.getData()
    );
  };

  exportToCSV = () => {
    toast.success("Dowloading...Please Wait!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    var headers = {
      date: "Date &Time",
      temperture: "Temperture  (°F)",
      // "pharmacy_account":"Pharmacy Name"
    };
    var records = [];
    // add data rows in sheet array
    this.setState({ loading: false });
    let filterData = {
      page_number: 1,
      page_size: 20000,
      sort_order: { column: "date", order: "desc" },
    };
    filterData.search = {
      ...(this.state.from_date !== "" && { from_date: this.state.from_date }),
      ...(this.state.to_date !== "" && { to_date: this.state.to_date }),
      ...(this.props.multistoreData.length > 0 &&
        (this.state.rx_group_associated === undefined ||
          this.state.rx_group_associated === "") && {
          multiStoreEmails: this.props.multistoreData.map((a) => a.email),
        }),
      pharmacy_account:
        this.state.rx_group_associated !== undefined &&
        this.state.rx_group_associated !== ""
          ? this.state.rx_group_associated
          : this.props.auth.user.final_user,
    };
    axios
      .post("/api/fridge-log", filterData)
      .then((res) => {
        // add data rows in sheet array
        for (let i in res.data.records) {
          let record = res.data.records[i];

          delete record._id;
          delete record.id;
          delete record.__v;
          delete record.time;
          delete record.pharmacy_account;
          if (record.date !== "") {
            record.date = moment(record.date).format("LLL");
          }
          record.date = '"' + record.date + '"';

          // record.purchase_date = record.purchase_date ? moment(record.purchase_date).add(5, 'hours').format('L') : ""
          records.push(record);
        }
        if (headers) {
          records.unshift(headers);
        }
        // Convert Object to JSON
        // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
        // var universalBOM = "\uFEFF";
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
        var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
          type: "application/csv;charset=UTF-8",
        });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  convertToCSV(objArray) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  openDatepicker = (e) => {
    if (e.target.id === "toD") {
      this.toDatePicker.setOpen(true);
    } else {
      this.fromDatePicker.setOpen(true);
    }
  };

  expand = (type) => {
    this.setState({ expand: type });
  };

  exportPdf = () => {
    this.setState(
      {
        exporting: true,
      },
      () => this._exportPdf()
    );
  };

  _exportPdf = () => {
    html2canvas(document.querySelector("#graphPDF")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
      this.setState({
        exporting: false,
      });
    });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  render() {
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    return (
      <>
        <div className="d-flex" id="wrapper">
          {this.state.loading && (
            <div className="loading-wrapper">
              <PulseLoader color="#007bff" loading={true} size={20} />
            </div>
          )}
          {/* <PurchaseAddModal getData={this.getData} ncDrugs={this.state.ncDrugs} />
                    <PurchaseUpdateModal record={this.state.currentRecord} getData={this.getData} />
                    
                    <AddNDC getData={this.getData}  /> */}
          <AddFridge getData={this.getData} />
          <div id="page-content-wrapper">
            {this.props.auth.user.tabulaRx ||
            this.props.auth.user.superadmin ||
            this.props.auth.user.expiration_tabula === "" ||
            this.props.auth.user.expiration_tabula >= moment().format() ? (
              <div className="container-fluid">
                {this.props.auth.user.rx_group !== "superadmin" &&
                  !this.props.auth.user.multistore && (
                    <button
                      className="btn btn-primary float-right mt-3 mr-2"
                      data-toggle="modal"
                      data-target="#add-fridge-modal"
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add new Records
                    </button>
                  )}
                <h1 className="mt-2 text-primary">Fridge Log</h1>
                <button className="btn btn-warning">
                  <FontAwesomeIcon icon={faBackward} />
                  <Link to="/dashboard"> Go to Dashboard</Link>
                </button>
                <br></br>
                <br></br>
                <div className="form-row">
                  <GroupFilter
                    onChangeSelect={this.onChangeSelect}
                    rx_group_associated={this.state.rx_group_associated}
                  />
                  <div className="form-group col-md-3">
                    <label htmlFor="npi">From Date</label>
                    <DatePicker
                      selected={this.state.from_date}
                      onChange={(date) => this.setFromDate(date)}
                      className="form-control"
                      ref={(c) => (this.fromDatePicker = c)}
                    />
                    <i
                      aria-hidden="true"
                      className="fa fa-calendar"
                      id="fromD"
                      onClick={this.openDatepicker}
                    ></i>
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="first_name">To Date</label>
                    <DatePicker
                      selected={this.state.to_date}
                      maxDate={moment().toDate()}
                      onChange={(date) => this.setToDate(date)}
                      className="form-control"
                      ref={(c) => (this.toDatePicker = c)}
                    />
                    <i
                      aria-hidden="true"
                      className="fa fa-calendar"
                      id="toD"
                      onClick={this.openDatepicker}
                    ></i>
                  </div>
                </div>
                <div className="form-group col-md-3">
                  <button
                    onClick={this.onSearch}
                    type="button"
                    className="btn btn-primary"
                  >
                    Search
                  </button>
                  <button
                    type="reset"
                    onClick={this.resetSearch}
                    className="btn btn-secordry"
                  >
                    Reset
                  </button>
                </div>
                <br></br>
                <br></br>
                <Container>
                  <Row>
                    {this.state.recordsGraph.length > 1 && (
                      <Col md={12}>
                        <div className="card shadow-lg">
                          <div className="card-body">
                            <h4>
                              Fridge Log Graph
                              {/* <a href="#" style={{ "float": "right", "fontSize": "14px" }} data-toggle="modal" data-target="#show-graph" onClick={(e) => this.expand("daily_report")}>Expand</a> */}
                            </h4>
                            <Chart
                              width={"100%"}
                              height={"240px"}
                              chartType="ColumnChart"
                              loader={<div>Loading Chart</div>}
                              data={this.state.recordsGraph}
                              options={{
                                // seriesType: 'bars',
                                // series: { 2: { type: 'line' } },
                                legend: { position: "none" },
                              }}
                              rootProps={{ "data-testid": "1" }}
                              formatters={[
                                {
                                  type: "NumberFormat",
                                  column: 1,
                                  options: {
                                    suffix: "°f",
                                    negativeColor: "red",
                                    negativeParens: true,
                                  },
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Container>
                <br></br>
                <br></br>

                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  onChange={this.filter}
                  dynamic={true}
                  total_record={this.state.totalCount}
                  loading={true}
                  exportToCSV={this.exportToCSV}
                />
              </div>
            ) : (
              <div className="container-fluid static-card">
                <div className="card">
                  <div className="card-body">
                    {this.props.auth.user.expiration_tabula <
                    moment().format() ? (
                      <h6 class="font-weight-bold text-danger">
                        Your Subscription is ended<br></br>
                        <Link to="/subscribe">
                          <button
                            className="btn btn-success"
                            onClick={this.Subscribe}
                          >
                            Subscribe Now
                          </button>
                        </Link>
                      </h6>
                    ) : !this.props.auth.user.tabulaRx ? (
                      <h6 class="font-weight-bold text-danger">
                        You accounts is inactive!! To activate you account
                        please{" "}
                        <Link to="/support" className="">
                          Contact
                        </Link>{" "}
                        Us.
                      </h6>
                    ) : (
                      <h6 class="font-weight-bold text-danger">
                        You accounts is inactive!! To activate you account
                        please{" "}
                        <Link to="/support" className="">
                          Contact
                        </Link>{" "}
                        Us.
                      </h6>
                    )}
                  </div>
                </div>
                <img className="static-image" src={dashboard} />
              </div>
            )}
          </div>
          <ToastContainer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  multistoreData: state.multistoreData,
});

export default connect(mapStateToProps, { logoutUser })(FridgeLog);
