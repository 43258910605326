import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../utils/AlereRx.png";
import video from "../../utils/Neigborhood2.mp4";

class Alererx extends React.Component {
  render() {
    return (
      <div className="section">
        <div className="container alererx">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title m-3">
                <img src={logo} height={60} style={{ marginLeft: "12px" }} />

                <div className="card">
                  <div className="card-body">
                    <h1
                      className="card-title text-primary"
                      style={{ fontWeight: "bold", fontSize: "47px" }}
                    >
                      Complete Telehealth Program
                    </h1>
                    <div style={{}}>
                      <p className="card-text" style={{ fontSize: "24px" }}>
                        <b>First time user? You must register</b>
                      </p>
                      <button
                        type="button"
                        style={{ marginRight: "63px" }}
                        className="btn btn-success btn-lg"
                      >
                        <a
                          href="https://eform.pandadoc.com/?eform=cb698532-9075-481e-bafb-b78d44286dce"
                          target="_blank"
                          style={{ color: "#fff", textDecoration: "none" }}
                        >
                          CLICK HERE TO REGISTER
                        </a>
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <video
                          style={{ width: "100%" }}
                          controls
                          loop
                          muted
                          autoPlay
                        >
                          <source src={video} type="video/mp4" />
                        </video>
                      </div>

                      <div className="col-md-12">
                        <br></br>
                        <p className="card-text" style={{ fontSize: "24px" }}>
                          It is becoming apparent to small and midsize employers
                          alike that the way to lower healthcare costs, retain a
                          diverse workforce, and keep them happy and healthy, is
                          to provide telehealth benefits to all employees,
                          regardless of their enrollment status. AlereRx
                          provides unlimited no cost telemedicine, Aetna
                          Discount Dental, Vision, Lab and Imaging for a family
                          of five for only $24.99 per month with no contract.
                        </p>
                        <p className="card-text" style={{ fontSize: "24px" }}>
                          Another way you can help your community and small
                          businesses in your area is to market AlereRx. Each
                          time someone enrolls in your plan including your
                          employee’s enrollment, you will earn $10 per member
                          per month or $120 per member per year if they sign up
                          for the annual plan.
                        </p>
                        <p className="card-text" style={{ fontSize: "24px" }}>
                          AlereRx provides you with all the custom marketing
                          materials such as cards, posters and flyers as well as
                          custom link for your website to create awareness at
                          your store. This program is provided to your pharmacy
                          at no cost and can truly generate hundreds of dollars
                          in on-going revenue for your pharmacy.
                        </p>
                      </div>
                      {/* <div className="col-md-7">
                        <p className="card-text" style={{ fontSize: "24px" }}>  medication savings card with a kick. Each time your card is used at any pharmacy nationwide you will receive $1.50 in admin fees plus all the dispensing data which can help you market your pharmacy to local physicians and organizations.</p>
                      </div>
                      <div className="col-md-5" style={{ "textAlign": "center" }}><img style={{ width: "90%" }} src={rxr} /></div> */}
                    </div>

                    {/* <div className="row">
                      <div className="col-md-12" style={{ textAlign: "center", marginTop: "80px" }}>
                        <a aria-label="Get the mobile app for iOS on the App Store" href="https://apps.apple.com/us/app/my-benefits-work/id1364040226" target="_blank" rel="noopener"><img alt="Download on the App Store" width="200" class="optanon-category-C0002 app-badging lazyloaded" src={ios} /></a>
                        <a aria-label="Get the mobile app for Android on the App Store" href="https://play.google.com/store/apps/details?id=com.newbenefits.mybenefitswork" target="_blank" rel="noopener"><img alt="Download on the App Store" width="200" class="optanon-category-C0002 app-badging lazyloaded" src={android} /></a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Alererx;
