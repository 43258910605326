import React from "react";
import { Map, Circle, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { Row, Container, Col } from 'react-bootstrap';
import Pincodes from '../../../utils/latlong.json';
import axios from "axios";
import moment from "moment"
import _ from 'lodash';

export class CustomMap extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            recordsClaim: [],
            recordsPhysician: [],
            from_date: moment().subtract(30, "days").toDate(),
            to_date: moment().toDate(),
            rx_group_associated: undefined,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            selectedPlaceSecond: {},
            activeMarkerSecond: {},
            showingInfoWindowSecond: false,
            multiStoreEmails:[]
        };
    }



    componentDidMount() {
        // this.getData()
    };

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     if(!_.isEqual(nextProps.formData, prevState.formData)){
    //         this.getData()
    //         return {}
    //     }
    //     // if (nextProps.formData.pharmacy_account !== prevState.pharmacy_account && nextProps.formData.pharmacy_account!==undefined) {
    //     //     return { pharmacy_account: nextProps.formData.pharmacy_account};
    //     // }else{
    //     //     return {}
    //     // }
    // }

    // componentWillReceiveProps(nextProps){
    //     if(!_.isEqual(nextProps.formData, this.props.formData)){
    //         this.setState({...nextProps.formData}, () => this.getData())
    //     }
    // }

    

    getData = () => {
        const formData = {
            from_date: this.state.from_date,
            to_date: this.state.to_date,
            pharmacy_account: this.state.pharmacy_account,
            ...(this.state.multiStoreEmails.length > 0 && { multiStoreEmails: this.state.multiStoreEmails }),
        }
        axios
            .post("/api/physician-by-pincode", formData)
            .then(res => {
                const data = res.data.doctors.filter(function (element) {
                    return Pincodes[element.practice_postal] !== undefined;
                });
                this.setState({
                    recordsPhysician: data,
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })

        axios
            .post("/api/patients-by-pincode", formData)
            .then(res => {
                const data = res.data.records.filter(function (element) {
                    return Pincodes[element._id] !== undefined;
                });
                this.setState({
                    recordsClaim: data,
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })
    }

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onMarkerClickSecond = (props, marker, e) =>
        this.setState({
            selectedPlaceSecond: props,
            activeMarkerSecond: marker,
            showingInfoWindowSecond: true
        });

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                showingInfoWindowSecond: false,
                activeMarker: null,
                activeMarkerSecond: null
            })
        }
    };

    render() {
        return (
            <div className="row px-2" style={{height:"583px"}}>
                <div className="card shadow-lg" style={{ "width": "100%" }}>
                    <div className="card-body">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">MapView of Patients by ZIP Code</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">MapView of Physician by ZIP Code</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <Col md={12}>
                                    <div>
                                        <Map
                                            google={this.props.google}
                                            zoom={4.5}
                                            initialCenter={{ lat: 37.878, lng: -92.629 }}
                                            style={{ height: 500, position: 'relative' }}
                                            className={'map'}
                                            onClick={this.onMapClicked}
                                        >
                                            {
                                                this.props.recordsClaim.map((location, index) => {
                                                    return (
                                                        <Marker
                                                            key={index}
                                                            id={index}
                                                            position={Pincodes[location._id]}
                                                            onClick={this.onMarkerClick}
                                                            name={"ZipCode:" + location._id}
                                                            count={"Count:" + location.count}
                                                        />
                                                    );
                                                })
                                            }
                                            <InfoWindow
                                                marker={this.state.activeMarker}
                                                visible={this.state.showingInfoWindow}>
                                                <div>
                                                    <div className="card text-dark bg-light mb-3" style={{ "maxWidth": "25rem" }}>
                                                        <div className="card-header">{this.state.selectedPlace.name}</div>
                                                        <div className="card-body">
                                                            <p className="card-text">{this.state.selectedPlace.count}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </InfoWindow>
                                        </Map >
                                    </div>
                                </Col>
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <Col md={12}>
                                    <div>
                                        <Map
                                            google={this.props.google}
                                            zoom={4.5}
                                            initialCenter={{ lat: 37.878, lng: -92.629 }}
                                            style={{ height: 500, position: 'relative' }}
                                            className={'maps'}
                                            onClick={this.onMapClicked}
                                        >
                                            {
                                                this.props.recordsPhysician.map((location, index) => {
                                                    return (
                                                        <Marker
                                                            key={index}
                                                            id={index}
                                                            position={Pincodes[location.practice_postal]}
                                                            onClick={this.onMarkerClickSecond}
                                                            name={"Pincode:" + location.practice_postal}
                                                            prescription_npi={"Physicians:" + location.first_name+" " + location.last_name}
                                                        />
                                                    );
                                                })
                                            }
                                            <InfoWindow
                                                marker={this.state.activeMarkerSecond}
                                                visible={this.state.showingInfoWindowSecond}>
                                                <div>
                                                    <div className="card text-dark bg-light mb-3" style={{ "maxWidth": "25rem" }}>
                                                        <div className="card-header">{this.state.selectedPlaceSecond.name}</div>
                                                        <div className="card-body">
                                                            <p className="card-text">{this.state.selectedPlaceSecond.prescription_npi}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </InfoWindow>
                                        </Map >
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


        );
    }
}
export default GoogleApiWrapper({
    apiKey: ('AIzaSyDhjIMAgPWbXMQjetYIge4rdAdbA9o4F1I')
})(CustomMap)