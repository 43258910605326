import React, { Component } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PulseLoader from "react-spinners/PulseLoader";
import GroupFilter from "./Filter/GroupFilter.js";
import { toast, ToastContainer } from "react-toastify";
import dashboard from "../../utils/dashboard.png"
import { Link } from "react-router-dom";
import { logoutUser } from "../../actions/authActions";
import moment from "moment"

class MmeReport extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "dispense_date",
                text: "Date",
                className: "dispense_date",
                align: "left",
                sortable: true,
            },
            {
                key: "rx_number",
                text: "Rx Number",
                className: "rx_number",
                align: "left",
                sortable: true,
            },
            {
                key: "patient_name",
                text: "Patient Initials",
                className: "patient_name",
                align: "left",
                sortable: true,
            },
            {
                key: "ndc",
                text: "NDC",
                className: "ndc",
                align: "left",
                sortable: true,
            },
            {
                key: "drug_name",
                text: "Drug Name",
                className: "drug_name",
                align: "left",
                sortable: true,
            },
            {
                key: "day_supply",
                text: "Day Supply",
                className: "day_supply",
                align: "left",
                sortable: true,
            },
            {
                key: "qty_dispensed",
                text: "Total Dispense",
                className: "qty_dispensed",
                align: "left",
                sortable: true,
            },
            {
                key: "mme",
                mme: true,
                text: "MME",
                className: "mme",
                align: "left",
                sortable: true,
            },
            {
                key: "mmeIcon",
                text: "More",
                className: "mmeIcon",
                align: "left",
                mmeIcon: true
            },
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Stats",
            no_data_text: 'No result found!',
            button: {
                excel: false,
                print: true,
                csv: true,
                range: false
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
            sort: {
                column: "mme",
                order: "desc"
            }
        };

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "mme", order: "desc" }
            },
            currentRecord: {
            },
            name: undefined,
            from_date: moment().subtract(90, "days").startOf('day').toDate(),
            to_date: moment().endOf('day').toDate(),
            rx_group_associated: undefined,
            drug_name: undefined,
            ndc: undefined,
            loading: false,
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
    };

    componentDidUpdate(prevProps) {
        if (this.props.multistoreData.length !== prevProps.multistoreData.length) {
            this.getData()
        }
    }

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.from_date !== '' && { from_date: this.state.from_date }),
            ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
            ...(this.state.ndc !== '' && { ndc: this.state.ndc }),
            ...(this.state.drug_name !== '' && { drug_name: this.state.drug_name }),
            ...(this.props.multistoreData.length > 0 && (this.state.rx_group_associated === undefined || this.state.rx_group_associated === "") && { multiStoreEmails: this.props.multistoreData.map(a => a.email) }),
            pharmacy_account: this.state.rx_group_associated !== undefined && this.state.rx_group_associated !== "" ? this.state.rx_group_associated : this.props.auth.user.final_user,
        }
        axios
            .post("/api/mme-report", filterData)
            .then(res => {
                let result = []
                let drugsArr = {}
                for (let i = 0; i < res.data.drugs.length; i++) {
                    let each = res.data.drugs[i]
                    drugsArr[each.ndc] = each
                }
                for (let i = 0; i < res.data.dispense.length; i++) {
                    let each = res.data.dispense[i]
                    if (each.ndc !== undefined && drugsArr[each.ndc] !== undefined) {
                        if (each.day_supply > 0 && each.qty_dispensed > 0 && parseInt(drugsArr[each.ndc]["unit_strength_qty"]) > 0 && drugsArr[each.ndc]["mme"] > 0) {
                            each.mme = Math.round((each.qty_dispensed / each.day_supply) * parseInt(drugsArr[each.ndc]["unit_strength_qty"]) * drugsArr[each.ndc]["mme"])
                            each.dispense_date = each.dispense_date ? moment(each.dispense_date).add(5, 'hours').format('L') : ""
                            result.push(each)
                        }
                    }

                }
                result.sort((a, b) => Number(b.mme) - Number(a.mme));
                this.setState({
                    records: result,
                    totalCount: res.data.count,
                    loading: false
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })
    }

    onSearch = e => {
        this.setState({
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "retail", order: "desc" }
            }
        }, () => this.getData());
    }

    reset = () => {
        this.setState({
            drug_name: undefined,
            ndc: undefined,
            from_date: undefined,
            to_date: undefined,
            rx_group_associated: "",
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "mme", order: "desc" }
            }
        }, () => this.getData());
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    setFromDate = (date) => {
        this.setState({ from_date: date });
    }
    setToDate = (date) => {
        this.setState({ to_date: date });
    }

    shouldItemRender = (item, value) => {
        return item.toLowerCase().indexOf(value.toLowerCase()) > -1
    }
    exportToCSV = (from, to) => {
        let filterData = {}
        this.setState({ loading: true })
        filterData.search = {
            ...(from !== '' && { from_date: from }),
            ...(to !== '' && { to_date: to }),
            pharmacy_account: this.state.rx_group_associated !== undefined && this.state.rx_group_associated !== "" ? this.state.rx_group_associated : this.props.auth.user.final_user,
        }
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            dispense_date: "Date",
            ndc: "NDC",
            drug_name: "Drug Name",
            rx_number: "RX Number",
            qty_dispensed: "Total Dispense",
            day_supply: "Days Supply",
            patient_name: "Patient Initials",
            mme: "MME"
        };
        // // add columns in sheet array
        // for (let column of this.props.columns) {
        //     headers[column.key] = '"' + column.text + '"';
        // }
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false })
        for (let i in this.state.records) {
            let record = this.state.records[i]
            delete record._id; delete record.id; delete record.payment_type; delete record.user_initial; delete record.__v; delete record.notes; delete record.doctor_npi; delete record.patient_zip; delete record.pharmacy_account; delete record.user_created;
            records.push(record);
        }
        if (headers) {
            records.unshift(headers);
        }
        // Convert Object to JSON
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }


    }

    convertToCSV(objArray, headers) {
        const keys = Object.keys(headers);

        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in keys) {
                line += array[i][keys[index]] === undefined ? "," : array[i][keys[index]] + ",";
                line.trim(",")


            }
            str += line + '\r\n';
        }
        return str;
    }

    exportToPDF = (from, to) => {
        this.setState({ loading: false })
        let sTable = "";
        sTable += "<table>";
        sTable += "<thead>";
        sTable += "<tr>";
        for (let column of this.columns) {
            sTable += "<th>" + column.text + "</th>";
        }
        sTable += "</tr>";
        sTable += "</thead>";
        sTable += "<tbody>";
        for (let i in this.state.records) {
            let record = this.state.records[i];
            sTable += "<tr>";
            for (let column of this.columns) {
                if (column.cell && typeof column.cell === "function") {
                    sTable += "<td></td>";
                } else if (record[column.key]) {
                    sTable += "<td>" + record[column.key] + "</td>";
                } else {
                    sTable += "<td></td>";
                }
            }
            sTable += "</tr>";
        }
        sTable += "</tbody>";
        sTable += "</table>";

        var style = "<style>";
        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;textAlign:left;}";
        style = style + "</style>";

        var win = window.open('', '_blank');
        win.document.write('<html><head>');
        win.document.write('<title>' + this.config.filename + '</title>');
        win.document.write(style);
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write('<h1>' + this.config.filename + '</h1>');
        win.document.write(sTable);
        win.document.write('</body></html>');
        win.print();
        win.close();
    }

    openDatepicker = (e) => {
        if (e.target.id === "toD") {
            this.toDatePicker.setOpen(true);
        } else {
            this.fromDatePicker.setOpen(true);
        }
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        if ((this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula === undefined || this.props.auth.user.expiration_tabula < moment().format()) && this.props.auth.user.superadmin !== true) {
            window.location.href = ("/choose-plan?solution=TabulaRx");
        }
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    {this.props.auth.user.tabulaRx || this.props.auth.user.superadmin || this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula >= moment().format() ? (

                        <div className="container-fluid">
                            <div className="float-right info" style={{ fontSize: "16px", marginTop: "20px" }}>
                                <div className="color-red"><FontAwesomeIcon icon={faCheckCircle} /> {"Red – MME >90"}</div>
                                <div className="color-orange"><FontAwesomeIcon icon={faCheckCircle} /> {"Orange – MME 60-90"}</div>
                                <div className="color-green"><FontAwesomeIcon icon={faCheckCircle} /> {"Green - <60"}</div>
                            </div>
                            <h1 className="mt-2 text-primary">Morphine Milligram Equivalent (MME) Report</h1>
                            <button className="btn btn-warning"><FontAwesomeIcon icon={faBackward} /><Link to="/dashboard"> Go to Dashboard</Link></button>
                            <br></br>
                            <br></br>
                            <div>
                                <form noValidate >
                                    <div className="form-row">
                                        <GroupFilter onChangeSelect={this.onChangeSelect} rx_group_associated={this.state.rx_group_associated} />
                                        <div className="form-group col-md-3">
                                            <label htmlFor="npi">From Date</label>
                                            <DatePicker selected={this.state.from_date} onChange={(date) => this.setFromDate(date)} className="form-control" ref={(c) => this.fromDatePicker = c} />
                                            <i aria-hidden="true" className="fa fa-calendar" id="fromD" onClick={this.openDatepicker}></i>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="first_name">To Date</label>
                                            <DatePicker selected={this.state.to_date} onChange={(date) => this.setToDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                            <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="ndc">NDC</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ndc}
                                                id="ndc"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="drug_name">Drug Name</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.drug_name}
                                                id="drug_name"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <button
                                        onClick={this.onSearch}
                                        type="button"
                                        className="btn btn-primary">
                                        Search
                                    </button>
                                    <button type="reset" className="btn btn-secondary" onClick={this.reset}>Clear</button>
                                </form>
                            </div>
                            <br></br>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={false}
                                total_record={this.state.totalCount}
                                loading={true}
                                exportToCSV={this.exportToCSV}
                                exportToPDF={this.exportToPDF}
                            />
                        </div>
                    ) : (
                        <div className="container-fluid static-card">
                            <div className="card">
                                <div className="card-body">
                                    {this.props.auth.user.expiration_tabula < moment().format() ? (
                                        <h6 class="font-weight-bold text-danger">Your Subscription is ended<br></br>
                                            <Link to="/subscribe"><button className="btn btn-success" onClick={this.Subscribe}>Subscribe Now</button></Link>
                                        </h6>
                                    ) : !this.props.auth.user.tabulaRx ? (
                                        <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="">Contact</Link> Us.</h6>
                                    ) : (
                                        <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="">Contact</Link> Us.</h6>
                                    )}
                                </div>
                            </div>
                            <img className="static-image" src={dashboard} />
                        </div>
                    )
                    }
                </div>

                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    multistoreData: state.multistoreData,
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(MmeReport);
