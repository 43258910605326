import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import isEmpty from "is-empty";
import axios from "axios";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "@emotion/react";
import CreditCardInput from "react-credit-card-input";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";
import { logoutUser } from "../../actions/authActions";
import { confirmAlert } from "react-confirm-alert";
import usState from "../../utils/state.json";
import "react-confirm-alert/src/react-confirm-alert.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Subscribe extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      cardNumber: "",
      expiry: "",
      _id: "",
      current_points: 0,
      errors: {},
      records: [],
      cardError: true,
      loading: false,
      amount: 49.99,
      name: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      termscondition: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSave = (e) => {
    e.preventDefault();

    let { errors } = this.state;
    if (this.state.name === "") {
      errors.name = "This field is required";
    } else {
      delete errors.name;
    }
    if (this.state.street === "") {
      errors.street = "This field is required";
    } else {
      delete errors.street;
    }
    if (this.state.city === "") {
      errors.city = "This field is required";
    } else {
      delete errors.city;
    }
    if (this.state.state === "") {
      errors.state = "This field is required";
    } else {
      delete errors.state;
    }
    if (this.state.zip === "") {
      errors.zip = "This field is required";
    } else {
      delete errors.zip;
    }
    if (this.state.termscondition === false) {
      errors.termscondition = "This field is required";
    } else {
      delete errors.termscondition;
    }
    if (this.state.cardError === false && isEmpty(errors)) {
      this.setState({
        loading: true,
      });
      const newCard = {
        email: this.props.auth.user.final_user,
        cardNumber: this.state.cardNumber,
        expiry: this.state.expiry,
        amount: this.state.amount,
        name: this.state.name,
        street: this.state.street,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
      };
      axios
        .post("/api/subscribe", newCard)
        .then((res) => {
          this.setState({
            loading: false,
            errors: {},
            cardNumber: "",
            expiry: "",
            name: "",
            street: "",
            city: "",
            state: "",
            zip: "",
          });
          confirmAlert({
            title: "Subscription Successfull",
            message: "Please logout & login to continue.",
            buttons: [
              {
                label: "Continue",
                onClick: () => this.props.logoutUser(),
              },
            ],
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      if (this.state.cardError) {
        alert("Invalid card detail");
      }
      this.setState({ errors });
    }
  };

  handleCardNumberChange = (e) => {
    this.setState({
      cardNumber: e.target.value,
      cardError: false,
    });
  };

  toggleChange = (e) => {
    this.setState({ [e.target.id]: !this.state[e.target.id] });
  };

  handleCardExpiryChange = (e) => {
    this.setState({
      expiry: e.target.value,
      cardError: false,
    });
  };

  setError = () => {
    this.setState({
      cardError: true,
    });
  };

  render() {
    const { errors } = this.state;
    const iFrame =
      '<div class="AuthorizeNetSeal"> <script type="text/javascript" language="javascript">var ANS_customer_id="308dab4c-21ef-414b-a2ca-db0d7684fa3d";</script> <script type="text/javascript" language="javascript" src="https://verify.authorize.net:443/anetseal/seal.js" ></script> </div>';
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">Subscribe to TabulaRx </h1>
            <form
              noValidate
              onSubmit={this.onUpload}
              id="add-points"
              style={{ clear: "both" }}
            >
              <div class="card">
                {this.props.auth.user.subscriptionId &&
                this.props.auth.user.expiration_tabula > moment().format() ? (
                  <div class="card-header">
                    <h5>
                      You have already subscribed till{" "}
                      {moment(this.props.auth.user.expiration_tabula)
                        .add(5, "hours")
                        .format("L")}
                      . To Unsubscribe please <Link to="/support">Contact</Link>{" "}
                      us.
                    </h5>
                  </div>
                ) : (
                  <>
                    <div class="card-header">
                      <h5
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "26px",
                        }}
                      >
                        Your subscription to TabulaRx includes unlimited access
                        to all features and download.
                      </h5>
                      <h5 style={{ fontWeight: "bold" }}>
                        $49.99 per month - Auto-renewal - Cancel anytime
                      </h5>
                      <h5>
                        Questions? Call us{" "}
                        <a href="tel:888-442-8348">888-442-8348 Ext-1</a>
                      </h5>
                    </div>
                    <div class="card-body">
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <h5 htmlFor="name">Card Details</h5>
                        </div>
                        <div className="col-md-9">
                          <CreditCardInput
                            onError={() => this.setError()}
                            cardNumberInputProps={{
                              value: this.state.cardNumber,
                              onChange: this.handleCardNumberChange,
                            }}
                            cardExpiryInputProps={{
                              value: this.state.expiry,
                              onChange: this.handleCardExpiryChange,
                            }}
                            fieldClassName="input"
                          />
                        </div>
                        <div className="col-md-3">
                          <iframe
                            style={{
                              height: "95px",
                              marginTop: "-20px",
                              borderWidth: 0,
                            }}
                            title={"badge"}
                            srcDoc={iFrame}
                          />
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div className="col-md-12">
                          <h5 htmlFor="name">Billing Address</h5>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="name">Name</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.name}
                            id="name"
                            type="text"
                            error={errors.name}
                            className={classnames("form-control", {
                              invalid: errors.name,
                            })}
                          />
                          <span className="text-danger">{errors.name}</span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="street">Address Line 1</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.street}
                            id="street"
                            type="text"
                            error={errors.street}
                            className={classnames("form-control", {
                              invalid: errors.street,
                            })}
                          />
                          <span className="text-danger">{errors.street}</span>
                        </div>

                        <div className="col-md-6 col-12">
                          <label htmlFor="city">City</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.city}
                            id="city"
                            type="text"
                            error={errors.city}
                            className={classnames("form-control", {
                              invalid: errors.city,
                            })}
                          />
                          <span className="text-danger">{errors.city}</span>
                        </div>

                        <div className="col-md-6 col-12">
                          <label htmlFor="state">State</label>
                          <select
                            name="state"
                            onChange={this.onChange}
                            className="form-control"
                            id="state"
                          >
                            <option value="" selected="">
                              Please Select
                            </option>
                            {Object.keys(usState).map((each, i) => (
                              <option
                                key={i}
                                value={each}
                                selected={this.state.state === each}
                              >
                                {usState[each]}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-md-6 col-12">
                          <label htmsuperadminlFor="zip">Zip</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.zip}
                            id="zip"
                            type="text"
                            error={errors.zip}
                            className={classnames("form-control", {
                              invalid: errors.zip,
                            })}
                          />
                          <span className="text-danger">{errors.zip}</span>
                        </div>
                        <div className="col-md-12 col-12">
                          <br></br>
                          <input
                            type="checkbox"
                            class="form-check float-left"
                            style={{
                              height: "20px",
                              width: "20px",
                              marginTop: "5px",
                              marginRight: "5px",
                            }}
                            Checked={this.state.termscondition}
                            onClick={this.toggleChange}
                            id="termscondition"
                          />
                          <b>
                            <p style={{ fontSize: "20px" }}>
                              By clicking, I confirm that I have read,
                              understood and agree with the{" "}
                              <a
                                href="https://www.psgarx.com/terms-and-conditions/"
                                target="_blank"
                              >
                                terms and conditions{" "}
                              </a>{" "}
                            </p>
                          </b>
                          <span className="text-danger">{errors.zip}</span>
                        </div>
                      </div>

                      <button
                        form="add-points"
                        type="submit"
                        onClick={this.onSave}
                        className="btn btn-primary"
                      >
                        Pay Now
                      </button>
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { addUser, logoutUser })(
  withRouter(Subscribe)
);
