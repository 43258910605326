import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { connect } from "react-redux";
import $ from 'jquery';
import isEmpty from 'is-empty';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import zips from '../../utils/latlong.json';
import moment from "moment"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class DispenseUpdateModal extends React.Component {

    constructor() {
        super();
        this.state = {
            dispense_date: "",
            ndc: "",
            drug_name: "",
            rx_number: "",
            qty_dispensed: "",
            day_supply: "",
            patient_name: "",
            patient_zip: "",
            patient_dob: "",
            payment_type: "",
            doctor_npi: "",
            notes: "",
            pharmacy_initial:"",
            on_hand: true,
            on_hand_qty: "",
            on_hand_initials: "",
            errors: {},
            distance: ""
        };
    }

    onChange = e => {
        const currentId = e.target.id
        let value = e.target.value
        this.setState({ [e.target.id]: value },
            () => this.checkNdcNpi(currentId));
    };

    checkNdcNpi = (currentId) => {
        if (currentId === "patient_zip") {
            if (this.state.patient_zip.length===5 && zips[this.state.patient_zip] !== undefined && zips[this.props.auth.user.zip] !== undefined) {
                let patientLats = zips[this.state.patient_zip]
                let accountLats = zips[this.props.auth.user.zip]
                var p = 0.017453292519943295;    // Math.PI / 180
                var c = Math.cos;
                var a = 0.5 - c((accountLats['lat'] - patientLats['lat']) * p) / 2 +
                    c(patientLats['lat'] * p) * c(accountLats['lat'] * p) *
                    (1 - c((accountLats['lng'] - patientLats['lng']) * p)) / 2;

                let distance = 12742 * Math.asin(Math.sqrt(a)) / 1.609; // 2 * R; R = 6371 km
                this.setState({
                    distance
                })
            }else{
                this.setState({
                    distance: ""
                })
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                ...nextProps.record,
                dispense_date: moment(nextProps.record.dispense_date).toDate(),
                patient_dob: moment(nextProps.record.patient_dob).isValid()?moment(nextProps.record.patient_dob).toDate():""
            });
        }
    }

    onReportAdd = e => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.dispense_date === '') {
            errors.dispense_date = "This field is required"
        } else {
            delete errors.dispense_date
        }
        // if (this.state.ndc === '') {
        //     errors.ndc = "This field is required"
        // } else {
        //     delete errors.ndc
        // }
        if (this.state.qty_dispensed === '') {
            errors.qty_dispensed = "This field is required"
        } else if (isNaN(this.state.qty_dispensed)) {
            errors.qty_dispensed = "This field should be number"
        } else {
            delete errors.qty_dispensed
        }
        if (this.state.rx_number === '') {
            errors.rx_number = "This field is required"
        } else {
            delete errors.rx_number
        }
        if (this.state.day_supply === '') {
            errors.day_supply = "This field is required"
        } else {
            delete errors.day_supply
        }
        if (this.state.doctor_npi === '') {
            errors.doctor_npi = "This field is required"
        } else {
            delete errors.doctor_npi
        }
        if (this.state.patient_name === '') {
            errors.patient_name = "This field is required"
        } else {
            delete errors.patient_name
        }
        if (this.state.patient_zip === '') {
            errors.patient_zip = "This field is required"
        } else {
            delete errors.patient_zip
        }
        if (this.state.payment_type === '') {
            errors.payment_type = "This field is required"
        } else {
            delete errors.payment_type
        }
        if (isEmpty(errors)) {
            this.setState({ errors })
            const newWaiver = {
                _id: this.state._id,
                dispense_date: this.state.dispense_date,
                ndc: this.state.ndc,
                drug_name: this.state.drug_name,
                rx_number: this.state.rx_number,
                qty_dispensed: Math.round(this.state.qty_dispensed),
                day_supply: this.state.day_supply,
                patient_name: this.state.patient_name,
                patient_dob: this.state.patient_dob,
                patient_zip: this.state.patient_zip,
                payment_type: this.state.payment_type,
                doctor_npi: this.state.doctor_npi,
                notes: this.state.notes,
                pharmacy_initial: this.state.pharmacy_initial,
                on_hand_qty: this.state.on_hand_qty,
                on_hand_initials: this.state.on_hand_initials,
                user: this.props.auth.user.id,
            };
            axios
                .post("/api/dispense-update", newWaiver)
                .then(res => {
                    this.props.getData();
                    $('#update-dispense-modal').modal('hide');
                    this.setState({
                        dispense_date: "",
                        ndc: "",
                        drug_name: "",
                        rx_number: "",
                        qty_dispensed: "",
                        day_supply: "",
                        patient_name: "",
                        patient_zip: "",
                        payment_type: "",
                        doctor_npi: "",
                        notes: "",
                        pharmacy_initial: "",
                        on_hand_qty: "",
                        on_hand_initials: "",
                        distance: ""
                    })
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }).catch(err =>
                    console.log(err)
                )
        } else {
            this.setState({ errors })
        }
    };

    setReportDate = (date) => {
        if(moment().diff(date, 'days')>14){
            confirmAlert({
                title: 'Confirmation Required',
                message: 'You entered the date as '+moment(date).add(5, 'hours').format('L')+'. Are you sure?',
                overlayClassName: "entereddate",
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => this.setState({ dispense_date: date })
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        }else{
            this.setState({ dispense_date: date });
        }
    }

    setDobDate = (date) => {
        this.setState({ patient_dob: date });
    }

    openDatepicker = (e) => {
        this.toDatePicker.setOpen(true);
    }

    onChangeCheck = (e) => {
        this.setState({
            [e.target.id]: e.target.checked
        });
    }

    handleOnSelect = (item) => {
        // the item selected
        this.setState({
            ndc: item.ndc,
            drug_name: item.name
        })
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-dispense-modal" data-reset="false">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Dispense Record</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onReportAdd} id="update-report">
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="searchDrug">Search by Name or NDC or UPC code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <ReactSearchAutocomplete
                                                items={this.props.ncDrugs}
                                                onSelect={this.handleOnSelect}
                                                autoFocus
                                                formatResult={this.formatResult}
                                            />
                                            <span className="text-danger">{errors.ndc}</span>
                                        </div>
                                    </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <DatePicker maxDate={moment().toDate()} selected={this.state.dispense_date} placeholderText="MM/DD/YYYY" onChange={(date) => this.setReportDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                            <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                            <span className="text-danger">{errors.dispense_date}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="rx_number">RX Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.rx_number}
                                                error={errors.rx_number}
                                                id="rx_number"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.rx_number
                                                })}
                                            />
                                            <span className="text-danger">{errors.rx_number}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="qty_dispensed">Qty Dispensed</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.qty_dispensed}
                                                id="qty_dispensed"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.qty_dispensed
                                                })}
                                            />
                                            <span className="text-danger">{errors.qty_dispensed}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="day_supply">Day Supply</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.day_supply}
                                                id="day_supply"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.day_supply
                                                })}
                                            />
                                            <span className="text-danger">{errors.day_supply}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="patient_name">Patient Initials or System ID(Max 6 character)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.patient_name}
                                                id="patient_name"
                                                type="text"
                                                error={errors.patient_name}
                                                maxLength="6"
                                                className={classnames("form-control", {
                                                    invalid: errors.patient_name
                                                })} />
                                            <span className="text-danger">{errors.patient_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="patient_dob">Patient DOB</label>
                                        </div>
                                        <div className="col-md-9">
                                            <DatePicker selected={this.state.patient_dob} placeholderText="MM/DD/YYYY" onChange={(date) => this.setDobDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                            <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                            <span className="text-danger">{errors.patient_dob}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="patient_zip">Patient Zip Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.patient_zip}
                                                id="patient_zip"
                                                type="text"
                                                error={errors.patient_zip}
                                                className={classnames("form-control", {
                                                    invalid: errors.patient_zip
                                                })} />
                                            <span className="text-danger">{errors.patient_zip}</span>
                                            {
                                                (this.state.distance!=="" && this.state.distance<15)?(
                                                    <span className="text-success" style={{fontSize:"16px"}}>{Math.round(this.state.distance) } Miles Away</span>
                                                ):(this.state.distance!=="" && this.state.distance>15)?(
                                                    <span className="text-danger"  style={{fontSize:"16px"}}>{Math.round(this.state.distance) } Miles Away</span>
                                                ):""
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="payment_type">Is Cash Sale</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select
                                                onChange={this.onChangeSelect}
                                                value={this.state.payment_type}
                                                error={errors.payment_type}
                                                id="payment_type"
                                                className={classnames("form-control", {
                                                    invalid: errors.payment_type
                                                })}
                                            >
                                                <option value="">Please Select</option>
                                                <option value="Y">Yes</option>
                                                <option value="N">No</option>
                                            </select>
                                            <span className="text-danger">{errors.payment_type}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="searchDrug">NPI</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.doctor_npi}
                                                id="doctor_npi"
                                                type="text"
                                                error={errors.doctor_npi}
                                                className={classnames("form-control", {
                                                    invalid: errors.doctor_npi
                                                })} />
                                            <span className="text-danger">{errors.doctor_npi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="dea">On-Hand
                                                <input
                                                    onChange={this.onChangeCheck}
                                                    checked={this.state.on_hand}
                                                    id="on_hand"
                                                    disabled={this.state.drug_name === '' ? true : false}
                                                    type="checkbox"
                                                    className="dispense-checkbox"
                                                />
                                            </label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.on_hand_qty}
                                                id="on_hand_qty"
                                                type="text"
                                                placeholder="Qty"
                                                style={{ width: "48%", float: "left" }}
                                                error={errors.on_hand_qty}
                                                disabled={this.state.drug_name === '' ? true : this.state.on_hand===true? false:true}
                                                className={classnames("form-control", {
                                                    invalid: errors.on_hand_qty
                                                })} />
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.on_hand_initials}
                                                id="on_hand_initials"
                                                type="text"
                                                style={{ width: "48%", float: "right" }}
                                                placeholder="Initials"
                                                error={errors.on_hand_initials}
                                                disabled={this.state.drug_name === '' ? true : this.state.on_hand===true? false:true}
                                                className={classnames("form-control", {
                                                    invalid: errors.on_hand_initials
                                                })} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="pharmacy_initial">Pharmacist Initials</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.pharmacy_initial}
                                                id="pharmacy_initial"
                                                type="text"
                                                error={errors.pharmacy_initial}
                                                className={classnames("form-control", {
                                                    invalid: errors.pharmacy_initial
                                                })} />
                                            <span className="text-danger">{errors.pharmacy_initial}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="notes">Notes</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.notes}
                                                id="notes"
                                                type="text"
                                                error={errors.notes}
                                                className={classnames("form-control", {
                                                    invalid: errors.notes
                                                })} />
                                            <span className="text-danger">{errors.notes}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-report"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps
)(DispenseUpdateModal);
