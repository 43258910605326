import React from "react";
import Select from "react-select";
// const rows =
//   [
//     {
//       name: "GLP-1",
//       description: "Includes Online Consult and Medication",
//       condition: "Weight Loss",
//       image: "GLP-1",
//     },
//     {
//       name: "Semaglutide injectable",
//       description: "Includes Online Consult and Medication",
//       condition: "Weight Loss",
//       image: "GLP-1",
//     },
//     {
//       name: "Semaglutide 2.5mg/ml",
//       description: "Includes Online Consult and Medication",
//       condition: "Weight Loss",
//       image: "GLP-1",
//     },
//     {
//       name: "Semaglutide oral",
//       description: "Includes Online Consult and Medication",
//       condition: "Weight Loss",
//       image: "GLP-1",
//     },
//     {
//       name: "Tirzepatide Injectable",
//       description: "Includes Online Consult and Medication",
//       condition: "Weight Loss",
//       image: "GLP-1",
//     },
//     {
//       name: "Semaglutide ODT",
//       description: "Includes Online Consult and Medication",
//       condition: "Weight Loss",
//       image: "GLP-1",
//     },
//     {
//       name: "Tirzepatide ODT",
//       description: "Includes Online Consult and Medication",
//       condition: "Weight Loss",
//       image: "GLP-1",
//     },
//     {
//       name: "Tirzepatide/B12 Injections",
//       description: "Includes Online Consult and Medication",
//       condition: "Weight Loss",
//       image: "GLP-1",
//     },
//     {
//       name: "Sildenafil Tabs ED",
//       description: "Includes Online Consult and Medication",
//       condition: "Erectile Dysfun",
//       image: "EDI",
//     },
//     {
//       name: "Tadalafil Tabs ED",
//       description: "Includes Online Consult and Medication",
//       condition: "Erectile Dysfun",
//       image: "EDI",
//     },
//     {
//       name: "Sildenafil/Yohimbine Troche",
//       description: "Includes Online Consult and Medication",
//       condition: "Erectile Dysfun",
//       image: "ED2",
//     },
//     {
//       name: "Sildenafil Troche",
//       description: "Includes Online Consult and Medication",
//       condition: "Erectile Dysfun",
//       image: "ED2",
//     },
//     {
//       name: "Tadalafil Troche",
//       description: "Includes Online Consult and Medication",
//       condition: "Erectile Dysfun",
//       image: "ED2",
//     },
//     {
//       name: "Travel Med Abx",
//       description: "Includes Online Consult and Medication",
//       condition: "Travel Meds",
//       image: "Travel",
//     },
//     {
//       name: "Naltrexone LDN",
//       description: "Includes Online Consult and Medication",
//       condition: "Fibromyalfia",
//       image: "LDN",
//     },
//     {
//       name: "Scream Female Sexual Cream",
//       description: "Includes Online Consult and Medication",
//       condition: "Female Sexual",
//       image: "Scream",
//     },
//     {
//       name: "Finast/Minoxidil Scalp soln",
//       description: "Includes Online Consult and Medication",
//       condition: "Hair Loss",
//       image: "Hairloss",
//     },
//     {
//       name: "Finasteride 1mg Tabs",
//       description: "Includes Online Consult and Medication",
//       condition: "Hair Loss",
//       image: "Hairloss2",
//     },
//     {
//       name: "Nurtec ODT",
//       description: "Includes Online Consult and Medication",
//       condition: "Migraines",
//       image: "Migraine",
//     },
//     {
//       name: "Sumatriptan",
//       description: "Includes Online Consult and Medication",
//       condition: "Migraines",
//       image: "MIgraine",
//     },
//     {
//       name: "Lidocaine 10% Ointment",
//       description: "Includes Online Consult and Medication",
//       condition: "Anesthetics",
//       image: "Lidocaine",
//     },
//     {
//       name: "Latisse Eyelash Growth",
//       description: "Includes Online Consult and Medication",
//       condition: "Eyelash Growth",
//       image: "EyeLash",
//     },
//     {
//       name: "Tri-Luma Cream",
//       description: "Includes Online Consult and Medication",
//       condition: "Dark Spots",
//       image: "Dermatology2",
//     },
//     {
//       name: "Hydroquinone 4% Cream",
//       description: "Includes Online Consult and Medication",
//       condition: "Skin Bleaching",
//       image: "Dermatology2",
//     },
//     {
//       name: "Hydroquinone Cream",
//       description: "Includes Online Consult and Medication",
//       condition: "Skin Bleaching",
//       image: "Dermatology2",
//     },
//     {
//       name: "Hydroq/Tretin/TCA Cream",
//       description: "Includes Online Consult and Medication",
//       condition: "Dark Spots",
//       image: "Dermatology",
//     },
//     {
//       name: "Hydroq/Tretin/TCA Cr Plus",
//       description: "Includes Online Consult and Medication",
//       condition: "Dark Spots",
//       image: "Dermatology",
//     },
//     {
//       name: "Clinda/Benzoylp Acne Cream",
//       description: "Includes Online Consult and Medication",
//       condition: "Acne",
//       image: "Acne",
//     },
//     {
//       name: "Retin-A Micro Gel",
//       description: "Includes Online Consult and Medication",
//       condition: "Dermatology",
//       image: "RetinA",
//     },
//     {
//       name: "Retin-A Micro Cream",
//       description: "Includes Online Consult and Medication",
//       condition: "Dermatology",
//       image: "RetinA",
//     },

// ];

const options = [
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/rxl.png",
    label: "Default",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/ACNE.png",
    label: "Acne",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/Antibiotic.png",
    label: "Antibiotic",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/Antibiotic2.png",
    label: "Antibiotic2",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/Cream.png",
    label: "Cream",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/Dermatology.png",
    label: "Dermatology",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/Dermatology2.png",
    label: "Dermatology2",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/diebeties.png",
    label: "Diebeties",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/ED.png",
    label: "ED",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/ED1.png",
    label: "ED1",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/ED2.png",
    label: "ED2",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/Eye.png",
    label: "Eye",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/eyelash.png",
    label: "EyeLash",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/Facial.png",
    label: "Facial",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/GeneralHealth.png",
    label: "GeneralHealth",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/GI.png",
    label: "GI",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/GLP1.png",
    label: "GLP1",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/Hairloss.png",
    label: "Hairloss",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/Hairloss2.png",
    label: "Hairloss2",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/IBD.png",
    label: "IBD",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/LDN.png",
    label: "LDN",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/lidocaine.png",
    label: "Lidocaine",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/migaine.png",
    label: "Migraine",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/Retin.png",
    label: "Retin",
  },
  {
    value: "https://rxreachanalytis.s3.amazonaws.com/telmed/Retin+A.png",
    label: "RetinA",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/Scream.png",
    label: "Scream",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/ToeFungus.png",
    label: "ToeFungus",
  },
  {
    value: "https://d3l9g5ay50k79j.cloudfront.net/telmed/TravelMeds.png",
    label: "TravelMeds",
  },
];

const XpedicareLinks = (props) => {
  const { errors } = props;
  const handleChange = (selectedOption, idx) => {
    props.handleChangeImage(selectedOption, idx);
  };
  return (
    <>
      <div className="card">
        <div className="card-header" id="headingThree">
          <h5 className="mb-0">
            <button
              type="button"
              className="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#xpedicarerxcustompage"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Links
            </button>
          </h5>
        </div>
        <div
          id="xpedicarerxcustompage"
          className="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="row mt-2">
              <div className="row clearfix">
                <div className="col-md-12 column">
                  <table
                    className="table table-bordered table-hover"
                    id="tab_logic"
                  >
                    <thead>
                      <tr>
                        <th className="text-left">Name</th>
                        <th className="text-left">Description</th>
                        <th className="text-left">Link</th>
                        <th className="text-left">Condition</th>
                        <th className="text-left">Price</th>
                        <th className="text-left">Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.rows.map((item, idx) => (
                        <tr id="addr0" key={idx}>
                          <td>
                            <input
                              type="text"
                              name="name"
                              value={props.rows[idx].name}
                              maxLength={26}
                              onChange={props.handleChange(idx)}
                              className="form-control"
                            />
                          </td>

                          <td>
                            <input
                              type="text"
                              name="description"
                              value={props.rows[idx].description}
                              maxLength={100}
                              onChange={props.handleChange(idx)}
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="link"
                              value={props.rows[idx].link}
                              onChange={props.handleChange(idx)}
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="condition"
                              value={props.rows[idx].condition}
                              onChange={props.handleChange(idx)}
                              className="form-control"
                              maxLength={15}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="price"
                              value={props.rows[idx].price}
                              onChange={props.handleChange(idx)}
                              className="form-control"
                              maxLength={7}
                            />
                          </td>
                          <td>
                            <Select
                              className={"image-select"}
                              value={props.rows[idx].image}
                              onChange={(val) => handleChange(val, idx)}
                              options={options}
                              formatOptionLabel={(each) => (
                                <div className="country-option">
                                  <img
                                    width={40}
                                    height={40}
                                    src={each.value}
                                    alt={each.label}
                                  />
                                  <span>{each.label}</span>
                                </div>
                              )}
                            />
                          </td>
                          <td
                            onClick={(e) => props.handleRemoveRow(idx, e)}
                            className="pull-right btn btn-secondary"
                            style={{ marginRight: "20px" }}
                          >
                            Delete Row
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <span
                    onClick={props.handleAddRow}
                    className="btn btn-success pull-left"
                    style={{ marginLeft: "20px" }}
                  >
                    Add Row
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default XpedicareLinks;
