import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../utils/BonaRx.png";
import rxr from "../../utils/brx.jpg";

class Rxreach extends React.Component {
  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title m-3">
                <img src={logo} height={60} />
                <div className="card">
                  <div className="card-body">
                    <h1
                      className="card-title text-primary"
                      style={{ fontWeight: "bold", fontSize: "47px" }}
                    >
                      Custom Medication Savings Card
                    </h1>
                    <div className="row">
                      <div className="col-md-12">
                        <p className="card-text" style={{ fontSize: "24px" }}>
                          In today’s pharmacy business environment, it is
                          necessary to have all the tools to help you grow your
                          business. BonaRx provides custom medication savings
                          card with a kick. Each time your card is used at any
                          pharmacy nationwide you will receive $1.50 in admin
                          fees plus all the dispensing data which can help you
                          market your pharmacy to local physicians and
                          organizations.
                        </p>
                        <p className="card-text" style={{ fontSize: "24px" }}>
                          Get your custom savings card and URL for your website
                          and start growing your business.
                          <br></br>
                          <br></br>
                          <b>First time user? You must register</b>
                          <br></br>
                          <button
                            type="button"
                            style={{ marginTop: "10px" }}
                            className="btn btn-success btn-lg"
                          >
                            <a
                              href="https://therx.net/EnterRegCode?regCode=HW-BONARXNP-2024&rxGroup=HWBBVV"
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              CLICK TO REGISTER
                            </a>
                          </button>
                        </p>
                      </div>
                      <div className="col-md-12" style={{ marginTop: "20px" }}>
                        <img style={{ width: "100%" }} src={rxr} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rxreach;
