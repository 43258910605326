import React from "react";
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import PulseLoader from "react-spinners/PulseLoader";
import classnames from "classnames";
import { connect } from "react-redux";
import axios from "axios";
import { updateUser } from "../../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import { Table } from "react-bootstrap";

import "react-toastify/dist/ReactToastify.css";

class Npis extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      record: {},
      taxonomys: [],
      errors: {},
    };
  }

  componentDidMount() {
    const filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "asc" },
      search: { _id: this.props.match.params._id },
    };
    this.setState({
      loading: true,
    });
    axios
      .post(`/api/doctors`, filterData)
      .then((res) => {
        this.setState({
          record: res.data.records[0],
          loading: false,
        });
        let txList = [];
        if (res.data.records[0].taxonomy_1) {
          txList.push(res.data.records[0].taxonomy_1);
        }
        if (res.data.records[0].taxonomy_2) {
          txList.push(res.data.records[0].taxonomy_2);
        }
        if (res.data.records[0].taxonomy_3) {
          txList.push(res.data.records[0].taxonomy_3);
        }
        if (res.data.records[0].taxonomy_4) {
          txList.push(res.data.records[0].taxonomy_4);
        }
        if (res.data.records[0].taxonomy_5) {
          txList.push(res.data.records[0].taxonomy_5);
        }
        this.getTaxonomy(txList);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  getTaxonomy = (taxonomy_1) => {
    axios
      .post("/api/taxonomy", { code: taxonomy_1 })
      .then((res) => {
        this.setState({
          taxonomys: res.data.taxonomy,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  goBack() {
    this.props.history.goBack();
  }

  findTaxonamy = (code) => {
    const finddata = this.state.taxonomys.find((each) => each.code === code);
    if (finddata) {
      return finddata.classification || "";
    }
  };
  render() {
    const { errors } = this.state;
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Values</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>NPI</td>
              <td>{this.state.record.npi}</td>
            </tr>
            <tr>
              <td>NPI Type</td>
              <td>
                {this.state.record.entity_type_code === 1
                  ? "Doctors"
                  : "Pharmacy"}
              </td>
            </tr>
            <tr>
              <td>Name</td>
              <td>
                {this.state.record.name_prefix +
                  " " +
                  this.state.record.first_name +
                  " " +
                  this.state.record.middle_name +
                  " " +
                  this.state.record.last_name +
                  " " +
                  this.state.record.name_suffix}
              </td>
            </tr>
            <tr>
              <td>Mailing Address</td>
              <td>
                {this.state.record.address_first_line +
                  " " +
                  this.state.record.address_second_line +
                  " " +
                  this.state.record.address_city +
                  " " +
                  this.state.record.address_state +
                  " " +
                  this.state.record.address_postal}
                <br />
                {"United State"}
                <br />
                {"Phone: " +
                  this.state.record.address_telephone +
                  " " +
                  " | " +
                  "Fax: " +
                  this.state.record.address_fax}
              </td>
            </tr>
            <tr>
              <td>Primary Practice Phone</td>
              <td>{this.state.record.practice_telephone}</td>
            </tr>
            <tr>
              <td>Primary Practice Fax</td>
              <td>{this.state.record.practice_fax}</td>
            </tr>
            <tr>
              <td>Taxonomy</td>
              <td>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Primary Taxonomy</th>
                      <th>State</th>
                      <th>License Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.findTaxonamy(this.state.record.taxonomy_1) ? (
                      <tr>
                        <td>
                          {this.findTaxonamy(this.state.record.taxonomy_1)}
                        </td>
                        <td>{this.state.record.license_number_state_1}</td>
                        <td>{this.state.record.license_number_1}</td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {this.findTaxonamy(this.state.record.taxonomy_2) ? (
                      <tr>
                        <td>
                          {this.findTaxonamy(this.state.record.taxonomy_2)}
                        </td>
                        <td>{this.state.record.license_number_state_2}</td>
                        <td>{this.state.record.license_number_2}</td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {this.findTaxonamy(this.state.record.taxonomy_3) ? (
                      <tr>
                        <td>
                          {this.findTaxonamy(this.state.record.taxonomy_3)}
                        </td>
                        <td>{this.state.record.license_number_state_3}</td>
                        <td>{this.state.record.license_number_3}</td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {this.findTaxonamy(this.state.record.taxonomy_4) ? (
                      <tr>
                        <td>
                          {this.findTaxonamy(this.state.record.taxonomy_4)}
                        </td>
                        <td>{this.state.record.license_number_state_4}</td>
                        <td>{this.state.record.license_number_4}</td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {this.findTaxonamy(this.state.record.taxonomy_5) ? (
                      <tr>
                        <td>
                          {this.findTaxonamy(this.state.record.taxonomy_5)}
                        </td>
                        <td>{this.state.record.license_number_state_5}</td>
                        <td>{this.state.record.license_number_5}</td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
            <button
              type="button"
              class="btn btn-dark btn-lg"
              onClick={this.goBack}
            >
              Go Back
            </button>
          </tbody>
        </table>
      </div>
    );
  }
}
export default withRouter(Npis);
