import { combineReducers } from "redux";
import authReducer from "./authReducers";
import errorReducer from "./errorReducers";
import pharmacyReducer from "./pharmacyReducers";
import groupReducers from "./groupReducers";
import multistoreDataReducers from "./multistoreDataReducers";
import filterReducers from "./filterReducers";
export default combineReducers({
    auth: authReducer,
    pharmacy: pharmacyReducer,
    errors: errorReducer,
    groups: groupReducers,
    multistoreData: multistoreDataReducers,
    filterData: filterReducers
});