import React, { Component, Fragment } from "react";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { faPlus, faBackward } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import PurchaseUpdateModal from "../partials/PurchaseUpdateModal";
import GroupFilter from "./Filter/GroupFilter.js";
import PurchaseAddModal from "../partials/PurchaseAddModal";
import DatePicker from "react-datepicker";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import AddNDC from "../partials/AddNDC";
import UploadInitialInventory from "../partials/UploadInitialInventory";
import dashboard from "../../utils/dashboard.png";
import Multiselect from "multiselect-react-dropdown";
import { logoutUser } from "../../actions/authActions";
import $ from "jquery";
class Purchases extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "purchase_date",
        text: "Purchase Date",
        className: "purchase_date",
        align: "left",
        sortable: true,
      },
      {
        key: "drug_name",
        text: "Drug Name",
        className: "drug_name",
        align: "left",
        sortable: true,
      },
      {
        key: "ndc",
        text: "NDC",
        className: "ndc",
        align: "left",
        sortable: true,
      },
      {
        key: "dea",
        text: "DEA",
        className: "dea",
        align: "left",
        sortable: true,
      },
      {
        key: "package_size",
        text: "Package Size",
        className: "package_size",
        align: "left",
        sortable: true,
      },
      {
        key: "qty_received",
        text: "No. pkg Received",
        className: "qty_received",
        align: "left",
        sortable: true,
        number: true,
      },
      {
        key: "total_qty",
        text: "Total Qty",
        className: "total_qty",
        align: "left",
        sortable: true,
        number: true,
      },
      {
        key: "form_number",
        text: "Form Number",
        className: "form_number",
        align: "left",
        sortable: true,
      },
      {
        key: "invoice_number",
        text: "Invoice Number",
        className: "invoice_number",
        align: "left",
        sortable: true,
      },
      {
        key: "supplier",
        text: "Supplier",
        className: "supplier",
        align: "left",
        sortable: true,
      },
      {
        key: "expiration_date",
        text: "Expires on",
        className: "expiration_date",
        align: "left",
        sortable: true,
      },
      {
        key: "lot",
        text: "Lot No.",
        className: "lot",
        align: "left",
        sortable: true,
      },
      {
        key: "notes",
        text: "Notes",
        className: "notes",
        align: "left",
        sortable: true,
      },
      {
        key: "invoiceimage",
        text: "Invoice",
        className: "invoiceimage",
        align: "left",
        sortable: false,
        downloadable: true,
      },
      {
        key: "adjusted",
        text: "Quick Adj",
        className: "adjusted",
        align: "left",
        sortable: true,
        rev_des: true,
      },
    ];
    if (this.props.auth.user.user_type !== "Technician") {
      this.columns.push({
        key: "user_created",
        text: "Created By",
        className: "user_created",
        align: "left",
        sortable: true,
      });
    }
    if (!this.props.auth.user.superadmin && !this.props.auth.user.multistore) {
      this.columns.push({
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              {this.props.auth.user.user_type !== "Technician" && (
                <button
                  data-toggle="modal"
                  data-target="#update-purchase-modal"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.editRecord(record)}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-edit"></i>
                </button>
              )}
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      });
    } else {
      this.columns.push({
        key: "pharmacy_account",
        text: "Pharmacy Email",
        className: "pharmacy_account",
        align: "left",
        sortable: true,
      });
    }

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100, 500],
      filename: "Purchase List",
      no_data_text: "No Purchase found!",
      button: {
        excel: false,
        print: false,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
      sort: {
        column: "purchase_date",
        order: "desc",
      },
      name: "purchase",
    };

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "purchase_date", order: "desc" },
      },
      currentRecord: {},
      ncDrugs: undefined,
      drug_name: undefined,
      ndc: undefined,
      rx_group_associated: "",
      plainArray: ["2", "3", "4", "5", "2N", "3N", "NC"],
      deaSelected: [],
      initialUploaded: false,
      from_date: moment().subtract(30, "days").startOf("day").toDate(),
      to_date: moment().endOf("day").toDate(),
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getDrugData();
    this.getInitialUpload();
  }

  componentDidUpdate(prevProps) {
    if (this.props.multistoreData.length !== prevProps.multistoreData.length) {
      this.getData();
    }
  }

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.from_date !== "" && { from_date: this.state.from_date }),
      ...(this.state.to_date !== "" && { to_date: this.state.to_date }),
      ...(this.state.ndc !== "" && { ndc: this.state.ndc }),
      ...(this.state.drug_name !== "" && { drug_name: this.state.drug_name }),
      ...(this.state.deaSelected.length > 0 && { dea: this.state.deaSelected }),
      pharmacy_account:
        this.state.rx_group_associated !== undefined &&
        this.state.rx_group_associated !== ""
          ? this.state.rx_group_associated
          : this.props.auth.user.final_user,
      ...(this.props.multistoreData.length > 0 &&
        (this.state.rx_group_associated === undefined ||
          this.state.rx_group_associated === "") && {
          multiStoreEmails: this.props.multistoreData.map((a) => a.email),
        }),
      exact: true,
    };
    axios
      .post("/api/purchase", filterData)
      .then((res) => {
        var result = [];
        // add data rows in sheet array
        for (let i in res.data.records) {
          let each = res.data.records[i];
          each.orig_purchase_date = each.purchase_date;
          each.purchase_date = each.purchase_date
            ? moment(each.purchase_date).add(5, "hours").format("L")
            : "";
          each.expiration_date = each.expiration_date
            ? moment(each.expiration_date).add(5, "hours").format("L")
            : "";
          if (each.adjusted !== undefined) {
            each.adjusted = "Yes";
          }
          result.push(each);
        }
        this.setState({
          records: result,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  getDrugData() {
    this.setState({ loading: true });
    axios
      .post("/api/nc-drugs")
      .then((res) => {
        this.setState({
          ncDrugs: res.data.records,
          loading: false,
        });
      })

      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  getInitialUpload() {
    this.setState({ loading: true });
    let filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "desc" },
    };

    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        this.setState({
          initialUploaded:
            res.data.records[0].initial_upload === true ? true : false,
          loading: false,
        });
      })

      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  editRecord(record) {
    this.setState({ currentRecord: record });
  }
  setFromDate = (date) => {
    this.setState({ from_date: date });
  };
  setToDate = (date) => {
    this.setState({ to_date: date });
  };
  deleteRecord(record) {
    if (this.props.auth.user.user_type !== "Technician") {
      confirmAlert({
        title: "Records will be deleted from purchase record",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.finalDelete(record),
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } else {
      confirmAlert({
        title: "Record will delete only after approval from admin.",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.finalDelete(record),
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    }
  }

  finalDelete = (record) => {
    axios
      .post("/api/purchase-delete", {
        _id: record._id,
        delete: this.props.auth.user.user_type !== "Technician" ? true : false,
        email: this.props.auth.user.email,
      })
      .then((res) => {
        if (res.status === 200) {
          this.getData();
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    this.getData();
  };

  onChange = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    this.setState({ [e.target.id]: e.target.value }, () =>
      this.checkUPC(id, value.trim())
    );
  };

  checkUPC = (id, value) => {
    if (id === "ndc" && value.length > 11) {
      this.getData();
    }
  };
  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  resetSearch = () => {
    this.setState(
      {
        rx_group_associated: "",
        ndc: undefined,
        drug_name: undefined,
        deaSelected: [],
        from_date: moment().subtract(7, "days").startOf("day").toDate(),
        to_date: moment().endOf("day").toDate(),
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "_id", order: "desc" },
        },
      },
      () => this.getData()
    );
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  exportToCSV = () => {
    toast.success("Dowloading...Please Wait!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    var headers = {
      purchase_date: "Purchase Date",
      ndc: "NDC",
      dea: "DEA",
      drug_name: "Drug Name",
      package_size: "Package Size",
      qty_received: "No. pkg Received",
      total_qty: "Total Qty",
      form_number: "Form Number",
      invoice_number: "Invoice Number",
      supplier: "Supplier",
      notes: "Notes",
      pharmacy_account: "Pharmacy Account",
      user_created: "User Created",
      adjusted: "Quick Adjust",
    };
    var records = [];
    // add data rows in sheet array
    this.setState({ loading: false });
    let filterData = {
      page_number: 1,
      page_size: 20000,
      sort_order: { column: "purchase_date", order: "desc" },
    };
    filterData.search = {
      ...(this.state.from_date !== "" && { from_date: this.state.from_date }),
      // ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
      ...(this.state.ndc !== "" && { ndc: this.state.ndc }),
      ...(this.state.drug_name !== "" && { drug_name: this.state.drug_name }),
      ...(this.state.deaSelected.length > 0 && { dea: this.state.deaSelected }),
      pharmacy_account:
        this.state.rx_group_associated !== undefined &&
        this.state.rx_group_associated !== ""
          ? this.state.rx_group_associated
          : this.props.auth.user.final_user,
      exact: true,
    };
    axios
      .post("/api/purchase", filterData)
      .then((res) => {
        // add data rows in sheet array
        for (let i in res.data.records) {
          let record = res.data.records[i];
          delete record._id;
          delete record.id;
          delete record.__v;
          delete record.user;
          record.purchase_date = record.purchase_date
            ? moment(record.purchase_date).add(5, "hours").format("L")
            : "";
          if (record.adjusted !== undefined) {
            record.adjusted = "Yes";
          }
          records.push(record);
        }
        if (headers) {
          records.unshift(headers);
        }
        console.log(records);
        // Convert Object to JSON
        // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
        // var universalBOM = "\uFEFF";
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
        var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
          type: "application/csv;charset=UTF-8",
        });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);

    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : `"${array[i][keys[index]]}",`;
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }

  openDatepicker = (e) => {
    if (e.target.id === "toD") {
      this.toDatePicker.setOpen(true);
    } else {
      this.fromDatePicker.setOpen(true);
    }
  };

  expand = (type) => {
    this.setState({ expand: type });
  };

  exportPdf = () => {
    this.setState(
      {
        exporting: true,
      },
      () => this._exportPdf()
    );
  };

  _exportPdf = () => {
    html2canvas(document.querySelector("#graphPDF")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
      this.setState({
        exporting: false,
      });
    });
  };

  onSelectMultiple = (selectedList, selectedItem) => {
    this.setState({ deaSelected: selectedList });
  };

  alertInitialUpload = () => {
    confirmAlert({
      title: "You have already uploaded the initial inventory",
      message: "Are you sure you want to proceed?",
      buttons: [
        {
          label: "Yes",
          onClick: () => $("#initial_upload").trigger("click"),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      overlayClassName: "initialInventory",
    });
  };

  render() {
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    return (
      <>
        <div className="d-flex" id="wrapper">
          {this.state.loading && (
            <div className="loading-wrapper">
              <PulseLoader color="#007bff" loading={true} size={20} />
            </div>
          )}
          <PurchaseAddModal
            getData={this.getData}
            ncDrugs={this.state.ncDrugs}
          />
          <PurchaseUpdateModal
            record={this.state.currentRecord}
            getData={this.getData}
          />
          <UploadInitialInventory />
          <AddNDC getData={this.getData} />

          <div id="page-content-wrapper">
            {this.props.auth.user.tabulaRx ||
            this.props.auth.user.superadmin ||
            this.props.auth.user.expiration_tabula === "" ||
            this.props.auth.user.expiration_tabula >= moment().format() ? (
              <div className="container-fluid">
                {!this.props.auth.user.superadmin &&
                  !this.props.auth.user.multistore && (
                    <>
                      <button
                        className="btn btn-primary float-right mt-3 mr-2"
                        data-toggle="modal"
                        data-target="#add-purchase-modal"
                      >
                        <FontAwesomeIcon icon={faPlus} /> Add new Purchase
                        Records
                      </button>
                      {this.state.initialUploaded ? (
                        <>
                          <button
                            className="btn btn-primary float-right mt-3 mr-2"
                            onClick={this.alertInitialUpload}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Upload Initial
                            Inventory
                          </button>
                          <button
                            className="btn btn-primary float-right mt-3 mr-2"
                            id="initial_upload"
                            style={{ display: "none" }}
                            data-toggle="modal"
                            data-target="#upload-initial-inventory-modal"
                          >
                            <FontAwesomeIcon icon={faPlus} /> Upload Initial
                            Inventory
                          </button>
                        </>
                      ) : (
                        <button
                          className="btn btn-primary float-right mt-3 mr-2"
                          data-toggle="modal"
                          data-target="#upload-initial-inventory-modal"
                        >
                          <FontAwesomeIcon icon={faPlus} /> Upload Initial
                          Inventory
                        </button>
                      )}
                    </>
                  )}
                <h1 className="mt-2 text-primary">Purchase</h1>
                <button className="btn btn-warning">
                  <FontAwesomeIcon icon={faBackward} />
                  <Link to="/dashboard"> Go to Dashboard</Link>
                </button>
                <br></br>
                <br></br>
                <form>
                  <div className="form-row">
                    <GroupFilter
                      onChangeSelect={this.onChangeSelect}
                      rx_group_associated={this.state.rx_group_associated}
                      type="purchases"
                    />
                    <div className="form-group col-md-3">
                      <label htmlFor="npi">From Date</label>
                      <DatePicker
                        selected={this.state.from_date}
                        onChange={(date) => this.setFromDate(date)}
                        className="form-control"
                        ref={(c) => (this.fromDatePicker = c)}
                      />
                      <i
                        aria-hidden="true"
                        className="fa fa-calendar"
                        id="fromD"
                        onClick={this.openDatepicker}
                      ></i>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="first_name">To Date</label>
                      <DatePicker
                        selected={this.state.to_date}
                        maxDate={moment().toDate()}
                        onChange={(date) => this.setToDate(date)}
                        className="form-control"
                        ref={(c) => (this.toDatePicker = c)}
                      />
                      <i
                        aria-hidden="true"
                        className="fa fa-calendar"
                        id="toD"
                        onClick={this.openDatepicker}
                      ></i>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="ndc">
                        Search Purchase by NDC or Scan Barcode
                      </label>
                      <input
                        onChange={this.onChange}
                        value={this.state.ndc}
                        id="ndc"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="drug_name">Drug Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.drug_name}
                        id="drug_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="drug_name">Class</label>
                      <Multiselect
                        selectedValues={this.state.deaSelected}
                        options={this.state.plainArray}
                        isObject={false}
                        onSelect={this.onSelectMultiple} // Function will trigger on select event
                        onRemove={this.onSelectMultiple} // Function will trigger on remove event
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-3">
                    <button
                      onClick={this.onSearch}
                      type="button"
                      className="btn btn-primary"
                    >
                      Search
                    </button>
                    <button
                      type="reset"
                      onClick={this.resetSearch}
                      className="btn btn-secordry"
                    >
                      Reset
                    </button>
                  </div>
                </form>
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.totalCount}
                  loading={true}
                  exportToCSV={this.exportToCSV}
                  onChange={this.filter}
                />
              </div>
            ) : (
              <div className="container-fluid static-card">
                <div className="card">
                  <div className="card-body">
                    {this.props.auth.user.expiration_tabula <
                    moment().format() ? (
                      <h6 class="font-weight-bold text-danger">
                        Your Subscription is ended<br></br>
                        <Link to="/subscribe">
                          <button
                            className="btn btn-success"
                            onClick={this.Subscribe}
                          >
                            Subscribe Now
                          </button>
                        </Link>
                      </h6>
                    ) : !this.props.auth.user.tabulaRx ? (
                      <h6 class="font-weight-bold text-danger">
                        You accounts is inactive!! To activate you account
                        please{" "}
                        <Link to="/support" className="">
                          Contact
                        </Link>{" "}
                        Us.
                      </h6>
                    ) : (
                      <h6 class="font-weight-bold text-danger">
                        You accounts is inactive!! To activate you account
                        please{" "}
                        <Link to="/support" className="">
                          Contact
                        </Link>{" "}
                        Us.
                      </h6>
                    )}
                  </div>
                </div>
                <img className="static-image" alt="Static" src={dashboard} />
              </div>
            )}
          </div>
          <ToastContainer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
  multistoreData: state.multistoreData,
});

export default connect(mapStateToProps, { logoutUser })(Purchases);
