import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { toast } from 'react-toastify';
import $ from 'jquery';
import isEmpty from 'is-empty';
import { connect } from "react-redux";
import Multiselect from 'multiselect-react-dropdown';



import 'react-toastify/dist/ReactToastify.css';

const options = [
    { name: 'RxPodcast', id: "rxpodcast" },
    { name: 'Rxminder', id: "rxminder" },
    { name: 'TabulaRx', id: "tabularx" },
    { name: 'BonaRx', id: "bonarx" },
    { name: 'DoorstepRx', id: "doorsteprx" },
    { name: 'RxReach', id: "rxreach" },
    { name: 'Xpedicare', id: "xpedicare" },
    { name: 'AlereRx', id: "alererx" }
];

class GroupEmailAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            firstname: "",
            lastname: "",
            name: "",
            solutions: [],
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors

            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-groupEmail-modal').modal('hide');

            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSelectMultiple = (selectedList, selectedItem) => {
        this.setState({ solutions: selectedList })
    }


    onAdd = e => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.email === '') {
            errors.email = "This field is required"
        } else {
            delete errors.email
        }
        if (this.state.firstname === '') {
            errors.firstname = "This field is required"
        } else {
            delete errors.firstname
        }
        if (this.state.lastname === '') {
            errors.lastname = "This field is required"
        } else {
            delete errors.lastname
        }
        if (this.state.solutions.length===0) {
            errors.solutions = "This field is required"
        } else {
            delete errors.solutions
        }


        if (isEmpty(errors)) {
            const newForm = {
                email: this.state.email,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                name: this.state.name,
                solutions: this.state.solutions.map(each => each.id)
            };
            axios.post('/groupEmail/add-email', newForm)
                .then((response) => {
                    this.props.getData();
                    $('#add-groupEmail-modal').modal('hide');
                    this.setState({
                        email: "",
                        firstname: "",
                        lastname: "",
                        name: "",
                        solutions: "",
                    })
                }, (error) => {
                    console.log(error);
                });

        } else {
            this.setState({ errors })
        }
    };


    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-groupEmail-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Email</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <form noValidate onSubmit={this.onAdd} id="add-groupEmail">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Email</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.email}
                                                id="email"
                                                type="text"
                                                error={errors.email}
                                                className={classnames("form-control", {
                                                    invalid: errors.email
                                                })} />
                                            <span className="text-danger">{errors.email}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="firstname">First Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.firstname}
                                                id="firstname"
                                                type="text"
                                                error={errors.firstname}
                                                className={classnames("form-control", {
                                                    invalid: errors.firstname
                                                })} />
                                            <span className="text-danger">{errors.firstname}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="lastname">Last Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.lastname}
                                                id="lastname"
                                                type="text"
                                                error={errors.lastname}
                                                className={classnames("form-control", {
                                                    invalid: errors.lastname
                                                })} />
                                            <span className="text-danger">{errors.lastname}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Pharmacy Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })} />
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="soltions">Solutions</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Multiselect
                                                selectedValues={this.state.solutions}
                                                options={options}
                                                displayValue="name" 
                                                onSelect={this.onSelectMultiple} // Function will trigger on select event
                                                onRemove={this.onSelectMultiple} // Function will trigger on remove event
                                            />
                                            <span className="text-danger">{errors.email}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-groupEmail"
                                    type="submit"
                                    // data-toggle="modal" data-target="#add-drug-modal"
                                    className="btn btn-primary">
                                    Add Email
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(
    mapStateToProps
)(GroupEmailAdd);
