import React, { Fragment, useEffect, useState } from "react";
const FaxTemplatePlan = () => {
  const [faxType, SetFaxType] = useState("");
  const onChange = (event) => {
    console.log(event.target.value);
    SetFaxType(event.target.value);
  };
  const sendPlan = () => {
    window.location.href = `/card-info?solution=${faxType}`;
  };

  const faxOptions = {
    "500": "$36.99",
    "1000": "$67.99",
    "1500": "$99.99",
    "2000": "$129.99",
    "5000": "$299.99",
    "10000": "$599.99",
  };
  return (
    <div>
      <div className="modal fade" id="faxtemplateplan-modal" data-reset="false">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Please Choose an option </h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form noValidate onSubmit={sendPlan} id="faxtemplateplan-modal">
                <div className="modal-body">
                  <>
                    {Object.keys(faxOptions).map((option, index) => (
                      <div className="form-check" key={index}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="faxType"
                          onChange={onChange}
                          value={option}
                          id="flexRadioDefault"
                        />
                        <label className="form-check-label">
                          {option} Credits - {faxOptions[option]}
                        </label>
                      </div>
                    ))}
                  </>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    form="faxtemplateplan-modal"
                    type="submit"
                    className="btn btn-primary"
                    onClick={sendPlan}
                  >
                    Pay
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FaxTemplatePlan;
