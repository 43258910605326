import React from "react";
import classnames from "classnames";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import isEmpty from "is-empty";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";

import "react-toastify/dist/ReactToastify.css";

class AddFridge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      time: "",
      type: "Fridge1",
      temperture: "",
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.record) {
    //     this.setState({
    //         ...nextProps.record
    //     })
    // }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.user !== undefined &&
      nextProps.auth.user.data !== undefined &&
      nextProps.auth.user.data.message !== undefined
    ) {
      $("#update-drug-modal").modal("hide");

      toast(nextProps.auth.user.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
  // componentDidMount() {
  //     $('#add-purchase-modal').modal('hide');
  // };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onDrugUpdate = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.date === "") {
      errors.date = "This field is required";
    } else {
      delete errors.date;
    }
    // if (this.state.time === '') {
    //     errors.time = "This field is required"
    // } else {
    //     delete errors.time
    // }
    if (this.state.temperture === "") {
      errors.temperture = "This field is required";
    } else {
      delete errors.temperture;
    }

    if (isEmpty(errors)) {
      const newDrug = {
        date: this.state.date,
        time: this.state.time,
        temperture: this.state.temperture,
        type: this.state.type,
        pharmacy_account: this.props.auth.user.final_user,
      };
      axios.post("/api/fridge-add", newDrug).then(
        (response) => {
          this.props.getData();
          $("#add-fridge-modal").modal("hide");
          this.setState({
            date: "",
            temperture: "",
            type: "Fridge1",
          });
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.setState({ errors });
    }

    //this.props.addUser(newUser, this.props.history);
  };
  handleDateChange = (date_to) => {
    this.setState({ date: date_to });
  };
  openDatepicker = (e) => {
    if (e.target.id === "toD") {
      this.toDatePicker.setOpen(true);
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="add-fridge-modal" data-reset="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Fridge Log</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <form noValidate onSubmit={this.onDrugUpdate} id="add-fridge">
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="date">DATE</label>
                    </div>
                    <div className="col-md-9">
                      <DatePicker
                        selected={this.state.date}
                        onChange={this.handleDateChange}
                        showTimeSelect
                        dateFormat="Pp"
                        ref={(c) => (this.toDatePicker = c)}
                        className="form-control"
                      />
                      <i
                        aria-hidden="true"
                        className="fa fa-calendar"
                        id="toD"
                        onClick={this.openDatepicker}
                      ></i>
                      <span className="text-danger">{errors.date}</span>
                    </div>
                  </div>

                  {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="time">Time</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.time}
                                                id="time"
                                                type="text"
                                                error={errors.time}
                                                className={classnames("form-control", {
                                                    invalid: errors.time
                                                })} />
                                            <span className="text-danger">{errors.time}</span>
                                        </div>
                                    </div> */}
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="temperture">TEMPERTURE (°F)</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.temperture}
                        id="temperture"
                        type="text"
                        error={errors.temperture}
                        className={classnames("form-control", {
                          invalid: errors.temperture,
                        })}
                      />
                      <span className="text-danger">{errors.temperture}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="temperture">Fridge Type</label>
                    </div>
                    <div className="col-md-9">
                      <select
                        onChange={this.onChange}
                        value={this.state.type}
                        id="type"
                        className={classnames("form-control", {})}
                      >
                        <option selected={this.state.type === "Fridge1"}>
                          Fridge1
                        </option>
                        <option selected={this.state.type === "Fridge2"}>
                          Fridge2
                        </option>
                        <option selected={this.state.type === "Fridge3"}>
                          Fridge3
                        </option>
                      </select>
                      <span className="text-danger">{errors.temperture}</span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="add-fridge"
                  type="submit"
                  // data-toggle="modal" data-target="#add-drug-modal"
                  className="btn btn-primary"
                >
                  Add Fridge log
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(AddFridge);
