import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faPlus } from "@fortawesome/free-solid-svg-icons";
import AddFaxTemplate from "../partials/AddFaxTemplate";
import UpdateFaxTemplate from "../partials/UpdateFaxTemplate";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import ReactDatatable from "../../utils/ReactDatatable";
// import SendFax from "../partials/SendFax";
import PulseLoader from "react-spinners/PulseLoader";
const FaxTemplate = (props) => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentRecord, setCurrentRecord] = useState({});

  useEffect(() => {
    getData();
  }, [props]);
  const getData = () => {
    axios
      .post("/api/fax-template", { pharmacy_account: props.auth.user.email })
      .then((res) => {
        var result = [];
        for (let i in res.data.records) {
          let each = res.data.records[i];
          result.push(each);
        }
        setRecords(result);
        setLoading(false);
      });
  };
  const editRecord = (records) => {
    setCurrentRecord({ currentRecord: records });
  };
  const deleteRecord = (records) => {
    confirmAlert({
      title: "Warning",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => finalDelete(records),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  const finalDelete = (records) => {
    axios.post("/api/faxtemplate-delete", { _id: records._id }).then((res) => {
      getData();
    });
  };

  const columns = [
    {
      key: "template_name",
      text: "Fax Template Name",
      className: "template_name",
      align: "left",
      sortable: false,
    },
    {
      key: "template_file",
      text: "Fax Template File",
      className: "template_file",
      align: "left",
      downloadable: true,
      sortable: false,
    },
    {
      key: "page_count",
      text: "Page Count",
      className: "page_count",
      align: "left",
      sortable: false,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (records) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-faxtemplate-modal"
              className="btn btn-primary btn-sm"
              onClick={() => editRecord(records)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit"></i>
            </button>

            <button
              className="btn btn-danger btn-sm"
              onClick={() => deleteRecord(records)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];
  const config = {
    page_size: 20,
    length_menu: [20, 50, 100],
    filename: "Physician List",
    no_data_text: "No record found!",
    button: {
      excel: false,
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };
  return (
    <div className="d-flex" id="wrapper">
      {loading && (
        <div className="loading-wrapper">
          <PulseLoader color="#007bff" loading={true} size={20} />
        </div>
      )}
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <h1 className="mt-2 text-primary">Fax Template</h1>
          <AddFaxTemplate getData={getData} />
          <UpdateFaxTemplate records={currentRecord} getData={getData} />
          {/* <SendFax record={records} /> */}
          <button
            className="btn btn-success mt-3 mr-2"
            onClick={() => window.history.go(-1)}
          >
            {"<"} Go to Prescriber List
          </button>
          <button
            className="btn btn-primary float-right mt-3 mr-2"
            data-toggle="modal"
            data-target="#add-faxtemplate-modal"
          >
            <FontAwesomeIcon icon={faPlus} /> Add Fax Template
          </button>
          <button className="btn btn-success float-right mt-3 mr-2">
            <a
              href="https://psgaonline.com/collections/fax-flyer"
              target="_blank"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              <FontAwesomeIcon icon={faCartPlus} /> Need Fax templates?
            </a>
          </button>
          <ReactDatatable columns={columns} config={config} records={records} />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(FaxTemplate);
