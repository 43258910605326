import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import axios from "axios";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEmpty from "is-empty";

const AddFaxTemplate = (props) => {
  const formRef = useRef();
  const [template_file, setTemplate_File] = useState(null);
  const [template_name, setTemplate_Name] = useState("");
  const [pageCount, setPageCount] = useState(0);
  let [errors, setErrors] = useState({});

  const onVendorAdd = (e) => {
    e.preventDefault();
    let abc = {};

    if (template_name === "") {
      abc.template_name = "This field is required";
    }
    if (template_file === null || template_file === undefined) {
      abc.template_file = "This field is required";
    }
    setErrors(abc);
    if (isEmpty(abc)) {
      setErrors({ abc });

      const formData = new FormData();
      if (template_file !== "") formData.append("template_file", template_file);
      if (template_name !== "") formData.append("template_name", template_name);
      if (pageCount !== "") formData.append("page_count", pageCount);
      formData.append("pharmacy_account", props.auth.user.final_user);

      axios.post("/api/faxtemplate-add", formData).then((res) => {
        $("#add-faxtemplate-modal").modal("hide");
        props.getData();
        setTemplate_Name("");
        setPageCount(0);
        formRef.current.value = "";
        toast(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    }
  };
  const onFileUpload = (e) => {
    console.log(e.target.files);
    if (e.target.files[0].type === "application/pdf") {
      var reader = new FileReader();
      reader.readAsBinaryString(e.target.files[0]);
      reader.onloadend = function () {
        var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
        console.log("count", count);
        setPageCount(count);
      };
      setTemplate_File(e.target.files[0]);
    } else {
      formRef.current.value = "";
      alert("Please upload PDF file");
    }
  };
  return (
    <div>
      <div className="modal fade" id="add-faxtemplate-modal" data-reset="false">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Fax Template</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form
                noValidate
                onSubmit={onVendorAdd}
                id="add-faxtemplate"
                encType="multipart/form-data"
              >
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="name">Fax Template Name</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      onChange={(e) => {
                        setTemplate_Name(e.target.value);
                      }}
                      value={template_name}
                      id="name"
                      type="text"
                      error={errors.template_name}
                      className={classnames("form-control", {
                        invalid: errors.template_name,
                      })}
                    />
                    <span className="text-danger">{errors.template_name}</span>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="name">
                      Fax Template File <br></br>(PDF only)
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      onChange={(e) => {
                        onFileUpload(e);
                      }}
                      id="template_file"
                      type="file"
                      accept=".pdf"
                      ref={formRef}
                      error={errors.template_file}
                      className={classnames("form-control", {
                        invalid: errors.template_file,
                      })}
                    />
                    <span className="text-danger">{errors.template_file}</span>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                form="add-faxtemplate"
                type="submit"
                className="btn btn-primary"
              >
                Add Fax Template
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(AddFaxTemplate));
