import React from "react";
import DatePicker from "react-datepicker";

const XPedicareRx = (props) => {
  const { errors } = props;
  return (
    <>
      <div className="card">
        <div className="card-header" id="headingThree">
          <h5 className="mb-0">
            <button
              type="button"
              className="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#XPedicareRx"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              XPedicareRx
            </button>
          </h5>
        </div>
        <div
          id="XPedicareRx"
          className="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="bonarx">Exp Date</label>
              </div>
              <div className="col-md-9">
                <DatePicker
                  selected={props.expiration_xpedicare}
                  onChange={(date) =>
                    props.setDate(date, "expiration_xpedicare")
                  }
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="xpedicare_subscriptionId">
                  Subscription Id
                </label>
              </div>
              <div className="col-md-9">
                <input
                  onChange={props.onChange}
                  value={props.xpedicare_subscriptionId}
                  id="xpedicare_subscriptionId"
                  type="text"
                  error={errors.xpedicare_subscriptionId}
                  className={"form-control"}
                />
                <span className="text-danger">
                  {errors.xpedicare_subscriptionId}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header" id="headingThree">
          <h5 className="mb-0">
            <button
              type="button"
              className="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#XPedicareRx24"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              XPedicareRx24
            </button>
          </h5>
        </div>
        <div
          id="XPedicareRx24"
          className="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="bonarx">Exp Date</label>
              </div>
              <div className="col-md-9">
                <DatePicker
                  {...(props.expiration_xpedicare24 !== undefined && {
                    selected: props.expiration_xpedicare24,
                  })}
                  // selected={props.expiration_xpedicare24}
                  onChange={(date) =>
                    props.setDate(date, "expiration_xpedicare24")
                  }
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="xpedicare24_subscriptionId">
                  Subscription Id
                </label>
              </div>
              <div className="col-md-9">
                <input
                  onChange={props.onChange}
                  value={props.xpedicare24_subscriptionId}
                  id="xpedicare24_subscriptionId"
                  type="text"
                  error={errors.xpedicare24_subscriptionId}
                  className={"form-control"}
                />
                <span className="text-danger">
                  {errors.xpedicare24_subscriptionId}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default XPedicareRx;
