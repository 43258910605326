import { BrowserRouter as Router } from "react-router-dom";
import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "./store";
import RouteData from "./RouteData";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import queryString from "query-string";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "../node_modules/font-awesome/css/font-awesome.css";
import "../node_modules/jquery/dist/jquery.min";
import "../node_modules/popper.js/dist/popper";
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style
import "./App.css";
let decoded = {};
if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);

  try {
    decoded = jwt_decode(token);
  } catch (e) {
    // Also tried JwtException
    localStorage.setItem("jwtToken", "");
  }

  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "./login";
  }

  if (queryString.parse(window.location.search).forceLogout) {
    store.dispatch(logoutUser("?solution=xpedicare24"));
  }
  if (queryString.parse(window.location.search).logout) {
    store.dispatch(logoutUser());
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <RouteData />
        </Router>
      </Provider>
    );
  }
}

export default App;
