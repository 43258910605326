import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import "bootstrap/dist/css/bootstrap.min.css";
import landing from "../../utils/landing.jpg";
import PulseLoader from "react-spinners/PulseLoader";

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (this.props.auth.user === "david.bigoness@animalmedexpress.com") {
      window.location.href =
        "https://animalmedrx.com?goto=dashboard&reference=" +
        localStorage.getItem("jwtToken");
    }
  }

  openLink = () => {
    window
      .open("https://calendly.com/rxconnexion/30min?month=2023-11", "_blank")
      .focus();
  };

  render() {
    return (
      <div className="d-flex" id="wrapper">
        <div id="page-content-wrapper">
          <div className="container-fluid">
            {this.state.loading && (
              <div className="loading-wrapper">
                <PulseLoader color="#007bff" loading={true} size={20} />
              </div>
            )}

            <br></br>
            <button type="button" class="btn btn-warning text-dark btn-lg">
              <b>Main Menu</b>
            </button>
            <button
              type="button"
              class="btn btn-success btn-lg float-right mobile-float-left"
              onClick={this.openLink}
            >
              <b>Schedule Consultation</b>
            </button>
            <h1
              className="mt-2 text-primary"
              style={{ fontWeight: "bold", fontSize: "48px" }}
            >
              Welcome to RxConnexion
            </h1>
            <br></br>
            <h2 style={{ fontSize: "33px" }}>
              We are dedicated in providing new and innovative programs to help
              you increase revenue, reduce overhead and improve cash flow.
              <br></br>
              <br></br>
              Please select the solution on left to start
            </h2>
            <br></br>
            <br></br>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  "https://rxreachanalytis.s3.amazonaws.com/Lady+RPh+at+counter.jpg"
                }
                alt="Landing"
                style={{ width: "100%" }}
              />
            </div>
            <br></br>
            <br></br>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Landing);
