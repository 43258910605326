import React, { Component } from "react";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";
import { logoutUser } from "../../actions/authActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Collapse } from "react-collapse";
import usState from "../../utils/state.json";
class Data2000 extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "first_name",
        text: "First Name",
        className: "first_name",
        align: "left",
        sortable: true,
      },
      {
        key: "middle_name",
        text: "Middle Name",
        className: "middle_name",
        align: "left",
        sortable: true,
      },
      {
        key: "last_name",
        text: "Last Name",
        className: "last_name",
        align: "left",
        sortable: true,
      },
      {
        key: "suffix",
        text: "Suffix",
        className: "suffix",
        align: "left",
        sortable: true,
      },
      {
        key: "addressline1",
        text: "Address Line 1",
        className: "addressline1",
        align: "left",
        sortable: true,
      },
      {
        key: "addressline2",
        text: "Address Line 2",
        className: "addressline2",
        align: "left",
        sortable: true,
      },
      {
        key: "city",
        text: "City",
        className: "city",
        align: "left",
        sortable: true,
      },
      {
        key: "state",
        text: "State",
        className: "state",
        align: "left",
        sortable: true,
      },
      {
        key: "zip",
        text: "ZIP",
        className: "zip",
        align: "left",
        sortable: true,
      },
      {
        key: "county",
        text: "County",
        className: "county",
        align: "left",
        sortable: true,
      },
      // {
      //     key: "lat",
      //     text: "Latitide",
      //     className: "lat",
      //     align: "left",
      //     sortable: true,
      // },
      // {
      //     key: "long",
      //     text: "Longitude",
      //     className: "long",
      //     align: "left",
      //     sortable: true,
      // },
      {
        key: "phone",
        text: "Phone",
        className: "phone",
        align: "left",
        sortable: true,
      },
      {
        key: "fax",
        text: "Fax",
        className: "fax",
        align: "left",
        sortable: true,
      },
    ];
    if (this.props.auth.user.superadmin) {
      this.config = {
        page_size: 20,
        length_menu: [20, 50, 100],
        filename: "Data 2000 Physician List",
        no_data_text: "No record found!",
        button: {
          excel: false,
          print: true,

          csv: true,
        },
        language: {
          length_menu: "Show _MENU_ result per page",
          filter: "Filter in records...",
          info: "Showing _START_ to _END_ of _TOTAL_ records",
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: true,
        show_filter: false,
        show_pagination: true,
        show_info: true,
      };
    } else {
      this.config = {
        page_size: 20,
        length_menu: [20, 50, 100],
        filename: "Data 2000 Physician List",
        no_data_text: "No record found!",
        button: {
          excel: false,
          print: true,
          csv: true,
        },
        language: {
          length_menu: "Show _MENU_ result per page",
          filter: "Filter in records...",
          info: "Showing _START_ to _END_ of _TOTAL_ records",
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: true,
        show_filter: false,
        show_pagination: true,
        show_info: true,
      };
    }

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "first_name", order: "asc" },
      },
      currentRecord: {},
      first_name: undefined,
      last_name: undefined,
      addressline1: undefined,
      addressline2: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
      county: undefined,
      loading: false,
      isFilterOpen: true,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.zip !== "" && { zip: this.state.zip }),
      ...(this.state.first_name !== "" && {
        first_name: this.state.first_name,
      }),
      ...(this.state.last_name !== "" && { last_name: this.state.last_name }),
      ...(this.state.addressline1 !== "" && {
        addressline1: this.state.addressline1,
      }),
      ...(this.state.addressline2 !== "" && {
        addressline2: this.state.addressline2,
      }),
      ...(this.state.city !== "" && { city: this.state.city }),
      ...(this.state.state !== "" && { state: this.state.state }),
      ...(this.state.county !== "" && { county: this.state.county }),
    };
    axios
      .post("/api/data2000", filterData)
      .then((res) => {
        this.setState({
          records: res.data.records,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  resetSearch = () => {
    this.setState(
      {
        first_name: undefined,
        last_name: undefined,
        addressline1: undefined,
        addressline2: undefined,
        city: undefined,
        state: undefined,
        zip: undefined,
        county: undefined,
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "first_name", order: "asc" },
        },
      },
      () => this.getData()
    );
  };

  toggleChange = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  exportToCSV = () => {
    if (this.state.zip === undefined && !this.props.auth.user.superadmin) {
      toast.error(" Please Enter ZIP Code", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.success("Dowloading...Please Wait!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      var headers = {
        first_name: "First Name",
        middle_name: "Middle Name",
        last_name: "Last Name",
        suffix: "Suffix",
        addressline1: "Address Line 1",
        addressline2: "Address Line 2",
        city: "City",
        state: "State",
        zip: "Zip",
        county: "County",
        lat: "Latitude",
        long: "Longitude",
        phone: "Phone",
        fax: "Fax",
      };
      let filterData = {
        page_number: 1,
        page_size: 50000,
        sort_order: { column: "first_name", order: "asc" },
      };
      filterData.search = {
        ...(this.state.zip !== "" && { zip: this.state.zip }),
        ...(this.state.first_name !== "" && {
          first_name: this.state.first_name,
        }),
        ...(this.state.last_name !== "" && { last_name: this.state.last_name }),
        ...(this.state.addressline1 !== "" && {
          addressline1: this.state.addressline1,
        }),
        ...(this.state.addressline2 !== "" && {
          addressline2: this.state.addressline2,
        }),
        ...(this.state.city !== "" && { city: this.state.city }),
        ...(this.state.state !== "" && { state: this.state.state }),
        ...(this.state.county !== "" && { county: this.state.county }),
      };
      axios
        .post("/api/data2000", filterData)
        .then((res) => {
          var records = [];
          // add data rows in sheet array
          this.setState({ loading: false });
          for (let i in res.data.records) {
            let record = res.data.records[i];
            delete record._id;
            delete record.id;
            delete record.__v;
            records.push(record);
          }
          if (headers) {
            records.unshift(headers);
          }
          let jsonObject = JSON.stringify(records);
          let csv = this.convertToCSV(jsonObject);
          let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
          var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
            type: "application/csv;charset=UTF-8",
          });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
          } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
              // feature detection
              let url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", exportedFilenmae);
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    }
  };

  convertToCSV(objArray) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToPDF = (from, to) => {
    if (this.state.zip === undefined && !this.props.auth.user.superadmin) {
      toast.error(" Please Enter ZIP Code", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.success("Dowloading...Please Wait!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      let filterData = {
        page_number: 1,
        page_size: 50000,
        sort_order: { column: "first_name", order: "asc" },
      };
      filterData.search = {
        ...(this.state.zip !== "" && { zip: this.state.zip }),
        ...(this.state.first_name !== "" && {
          first_name: this.state.first_name,
        }),
        ...(this.state.last_name !== "" && { last_name: this.state.last_name }),
        ...(this.state.addressline1 !== "" && {
          addressline1: this.state.addressline1,
        }),
        ...(this.state.addressline2 !== "" && {
          addressline2: this.state.addressline2,
        }),
        ...(this.state.city !== "" && { city: this.state.city }),
        ...(this.state.state !== "" && { state: this.state.state }),
        ...(this.state.county !== "" && { county: this.state.county }),
      };
      axios.post("/api/data2000", filterData).then((res) => {
        this.setState({ loading: false });
        let sTable = "";
        sTable += "<table>";
        sTable += "<thead>";
        sTable += "<tr>";
        for (let column of this.columns) {
          sTable += "<th>" + column.text + "</th>";
        }
        sTable += "</tr>";
        sTable += "</thead>";
        sTable += "<tbody>";
        for (let i in res.data.records) {
          let record = res.data.records[i];
          sTable += "<tr>";
          for (let column of this.columns) {
            if (column.cell && typeof column.cell === "function") {
              sTable += "<td></td>";
            } else if (record[column.key]) {
              sTable += "<td>" + record[column.key] + "</td>";
            } else {
              sTable += "<td></td>";
            }
          }
          sTable += "</tr>";
        }
        sTable += "</tbody>";
        sTable += "</table>";

        var style = "<style>";
        style = style + "table {width: 100%;font: 17px Calibri;}";
        style =
          style +
          "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;textAlign:left;}";
        style = style + "</style>";

        var win = window.open("", "_blank");
        win.document.write("<html><head>");
        win.document.write("<title>" + this.config.filename + "</title>");
        win.document.write(style);
        win.document.write("</head>");
        win.document.write("<body>");
        win.document.write("<h1>" + this.config.filename + "</h1>");
        win.document.write(sTable);
        win.document.write("</body></html>");
        win.print();
        win.close();
      });
    }
  };

  render() {
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">
              Opioid Use Disorder Physician Listing (DATA-Waiver for
              Buprenorphine Eliminated as of Jan 1, 2023)
            </h1>
            <p className="help-text font-weight-bold h6">
              We can help you view or download physicians in your area that
              specialize in Opioid Use Disorder (OUD) Treatment.
            </p>
            <button className="btn btn-warning">
              <FontAwesomeIcon icon={faBackward} />
              <Link to="/dashboard"> Go to Dashboard</Link>
            </button>
            <br></br>
            <br></br>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={this.state.isFilterOpen}
                  onClick={this.toggleChange}
                  id="isFilterOpen"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Show Filter
                </label>
              </div>
              <Collapse isOpened={this.state.isFilterOpen}>
                <form noValidate onSubmit={this.onSearch}>
                  <div className="form-row">
                    {this.props.auth.user.superadmin ? (
                      <>
                        <div className="form-group col-md-3">
                          <label htmlFor="zip">First Name</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.first_name}
                            id="first_name"
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="last_name">Last Name</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.last_name}
                            id="last_name"
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="zip">Address Line 1</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.addressline1}
                            id="addressline1"
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="addressline2">Address Line 2</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.addressline2}
                            id="addressline2"
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="city">City</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.city}
                            id="city"
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="state">State</label>
                          <select
                            name="state"
                            onClick={this.onChangeSelect}
                            className="form-control"
                            id="state"
                          >
                            <option value="" selected="">
                              Any
                            </option>
                            {Object.keys(usState).map((each, i) => (
                              <option
                                key={i}
                                value={each}
                                selected={this.state.state === each}
                              >
                                {usState[each]}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="zip">Zip Code</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.zip}
                            id="zip"
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="county">County</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.county}
                            id="county"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </>
                    ) : (
                      <div className="form-group col-md-3">
                        <label htmlFor="zip">Zip Code</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.zip}
                          id="zip"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    )}
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Filter
                  </button>
                  <button
                    type="reset"
                    onClick={this.resetSearch}
                    className="btn btn-secordry"
                  >
                    Reset
                  </button>
                </form>
              </Collapse>
            </div>
            <br></br>
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
              exportToCSV={this.exportToCSV}
              exportToPDF={this.exportToPDF}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps, { logoutUser })(Data2000);
