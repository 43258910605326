import React from 'react'
import classnames from "classnames";
import axios from "axios";
import isEmpty from 'is-empty';
import { ToastContainer, toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { connect } from "react-redux";
import moment from "moment"

class ConfigSetting extends React.Component {

    constructor() {
        super();
        this.state = {
            rxpodcast_frequency: undefined,
            rxminder_frequency: undefined,
            tabularx_frequency: undefined,
            bonarx_frequency: undefined,
            doorsteprx_frequency: undefined,
            rxreach_frequency: undefined,
            xpedicare_frequency: undefined,
            alererx_frequency: undefined,
            rxpodcast_last_date: undefined,
            rxminder_last_date: undefined,
            tabularx_last_date: undefined,
            bonarx_last_date: undefined,
            doorsteprx_last_date: undefined,
            rxreach_last_date: undefined,
            xpedicare_last_date: undefined,
            alererx_last_date: undefined
        };
    }

    componentDidMount() {
        this.getData()
    };

    getData() {
        this.setState({ loading: true })
        axios
            .post("/config/get-data", {})
            .then(res => {
                this.setState({
                    ...res.data.data[0],
                    loading: false
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSave = e => {
        e.preventDefault();
        let { errors } = this.state
       
        if (isEmpty(errors)) {
            this.setState({ errors })
            const newWaiver = {
                ...this.state
            };
            axios
                .post("/config/save-data", newWaiver)
                .then(res => {
                    this.getData();
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }).catch(err =>
                    console.log(err)
                )
        } else {
            this.setState({ errors })
        }
    };

    render() {
        const errors = {};
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">Frequency Settings (Enter 0 to disable)</h1>
                        <form noValidate onSubmit={this.onSave} id="add-drug">
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="v">RxMinder Frequency (Last Run - {this.state.rxminder_last_date && moment(this.state.rxminder_last_date).format('DD/MM/YYYY')})</label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.rxminder_frequency}
                                        id="rxminder_frequency"
                                        type="text"
                                        error={errors.rxminder_frequency}
                                        className={classnames("form-control", {
                                            invalid: errors.rxminder_frequency
                                        })} />
                                    <span className="text-danger">{errors.rxminder_frequency}</span>
                                </div>
                            </div>


                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="rxpodcast_frequency">RxPodcast Frequency (Last Run - {this.state.rxpodcast_last_date && moment(this.state.rxpodcast_last_date).format('DD/MM/YYYY')})</label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.rxpodcast_frequency}
                                        id="rxpodcast_frequency"
                                        type="text"
                                        error={errors.rxpodcast_frequency}
                                        className={classnames("form-control", {
                                            invalid: errors.rxpodcast_frequency
                                        })} />
                                    <span className="text-danger">{errors.rxpodcast_frequency}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="tabularx_frequency">TabulaRx Frequency (Last Run - {this.state.tabularx_last_date && moment(this.state.tabularx_last_date).format('DD/MM/YYYY')})</label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.tabularx_frequency}
                                        id="tabularx_frequency"
                                        type="text"
                                        error={errors.tabularx_frequency}
                                        className={classnames("form-control", {
                                            invalid: errors.tabularx_frequency
                                        })} />
                                    <span className="text-danger">{errors.tabularx_frequency}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="bonarx_frequency">BonaRx Frequency (Last Run - {this.state.bonarx_last_date && moment(this.state.bonarx_last_date).format('DD/MM/YYYY')})</label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.bonarx_frequency}
                                        id="bonarx_frequency"
                                        type="text"
                                        error={errors.bonarx_frequency}
                                        className={classnames("form-control", {
                                            invalid: errors.bonarx_frequency
                                        })} />
                                    <span className="text-danger">{errors.bonarx_frequency}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="doorsteprx_frequency">DoorstepRx Frequency (Last Run - {this.state.doorsteprx_last_date && moment(this.state.doorsteprx_last_date).format('DD/MM/YYYY')})</label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.doorsteprx_frequency}
                                        id="doorsteprx_frequency"
                                        type="text"
                                        error={errors.doorsteprx_frequency}
                                        className={classnames("form-control", {
                                            invalid: errors.doorsteprx_frequency
                                        })} />
                                    <span className="text-danger">{errors.doorsteprx_frequency}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="rxreach_frequency">RxReach Frequency (Last Run - {this.state.rxreach_last_date && moment(this.state.rxreach_last_date).format('DD/MM/YYYY')})</label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.rxreach_frequency}
                                        id="rxreach_frequency"
                                        type="text"
                                        error={errors.rxreach_frequency}
                                        className={classnames("form-control", {
                                            invalid: errors.rxreach_frequency
                                        })} />
                                    <span className="text-danger">{errors.rxreach_frequency}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="xpedicare_frequency">Xpedicare Frequency (Last Run - {this.state.xpedicare_last_date && moment(this.state.xpedicare_last_date).format('DD/MM/YYYY')})</label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.xpedicare_frequency}
                                        id="xpedicare_frequency"
                                        type="text"
                                        error={errors.xpedicare_frequency}
                                        className={classnames("form-control", {
                                            invalid: errors.xpedicare_frequency
                                        })} />
                                    <span className="text-danger">{errors.xpedicare_frequency}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="alererx_frequency">Alererx Frequency (Last Run - {this.state.alererx_last_date && moment(this.state.alererx_last_date).format('DD/MM/YYYY')})</label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.alererx_frequency}
                                        id="alererx_frequency"
                                        type="text"
                                        error={errors.alererx_frequency}
                                        className={classnames("form-control", {
                                            invalid: errors.alererx_frequency
                                        })} />
                                    <span className="text-danger">{errors.alererx_frequency}</span>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">Save</button>

                        </form>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    pharmacy: state.pharmacy,
    groups: state.groups,
});

export default connect(
    mapStateToProps, null
)(ConfigSetting);
