import React from "react";
import ReactDatatable from '../../../utils/ReactDatatable';
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import '../../../App.css';
import PulseLoader from "react-spinners/PulseLoader";

class PrescriberRxDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "ndc", order: "asc" }
            },
            prescriberRx: undefined
        };

        this.columns = [
            {
                key: "drug",
                text: "Drug Name",
                className: "drug",
                align: "left",
                sortable: false,
            },
            {
                key: "generic",
                text: "Generic Name",
                className: "generic",
                align: "left",
                sortable: false,
            },
            {
                key: "no_of_prescription",
                text: "Number of Prescriptions",
                className: "no_of_prescription",
                align: "left",
                sortable: false,
            },
            {
                key: "beneficiaries",
                text: "Beneficiaries",
                className: "beneficiaries",
                align: "left",
                sortable: false,
            },
            {
                key: "days",
                text: "Number of Days Supplies",
                className: "days",
                align: "left",
                sortable: false,
            },
            {
                key: "drug_cost",
                text: "Drug Cost",
                className: "drug_cost",
                align: "left",
                sortable: false,
            }
        ];


        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Stats",
            no_data_text: 'No result found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.prescriberRx !== nextProps.prescriberRx) {
            this.setState({
                prescriberRx: nextProps.prescriberRx
            }, () => this.getData())
        }
    }

    getData = () => {
        this.setState({ loading: true })
        let query = ""
        if (this.state.prescriberRx !== "" && this.state.prescriberRx !== undefined) {
            query += "filter[npi][condition][path]=Prscrbr_NPI&filter[npi][condition][operator]=%3D&filter[npi][condition][value]=" + this.state.prescriberRx + "&"
        }
        let offset = (parseInt(this.state.filterData.page_number) - 1) * 20
        let limit = this.state.filterData.page_size
        let url = "https://data.cms.gov/data-api/v1/dataset/c1a312b1-acc6-40d0-a630-14e5ddb74fd3/data-viewer?" + query + "size=" + limit + "&offset=" + offset + "&sort[sort-author][path]=Tot_Clms&sort[sort-author][direction]=DESC"
        axios
            .get(url)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.data.length; i++) {
                    let each = res.data.data[i]
                    each.npi = each[0]
                    each.name = each[1] + ' ' + each[2]
                    each.drug = each[8]
                    each.generic = each[9]
                    each.no_of_prescription = each[10]
                    each.days = each[19]
                    each.drug_cost = each[18] ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each[18]) : ""
                    each.beneficiaries = each[14]
                    result.push(each)
                }
                this.setState({
                    records: result,
                    loading: false
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })

        axios
            .get("https://data.cms.gov/data-api/v1/dataset/c1a312b1-acc6-40d0-a630-14e5ddb74fd3/data-viewer/stats?" + query)
            .then(res => {
                this.setState({
                    totalCount: res.data.data.found_rows,
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })

    }

    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }

    exportToCSV = () => {
        var headers = {
            "npi": "NPI",
            "claims": "First Name",
            "retail": "Last Name",
            "City": "City",
            "State": "State",
            "FIPS": "FIPS",
            "Type": "Type",
            "SRC": "SRC",
            "Brand": "Brand Name",
            "Generic": "Generic Name",
            "Claims": "Claims",
            "30Days": "30Days Fills",
            "Days": "Days Supplies",
            "Drug": "Drug Cost",
            "Beneficiaries": "Beneficiaries",
            "GE65_Sprsn_Flag": "GE65_Separation Flag",
            "GE65_Tot_Clms": "GE65 Claims",
            "GE65_Tot_30day_Fills": "GE65 30Days Fills",
            "GE65_Tot_Drug_Cst": "GE65 Drug Cost",
            "GE65_Tot_Day_Suply": "GE65 Day Supply",
            "GE65_Bene_Sprsn_Flag": "GE65 Beneficiary Separation Flag",
            "GE65_Tot_Benes": "GE65 Beneficiaries",
        };
        // // add columns in sheet array

        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false })
        let query = ""
        if (this.state.prescriberRx !== "" && this.state.prescriberRx !== undefined) {
            query += "filter[npi][condition][path]=Prscrbr_NPI&filter[npi][condition][operator]=%3D&filter[npi][condition][value]=" + this.state.prescriberRx + "&"
        }
        let url = "https://data.cms.gov/data-api/v1/dataset/c1a312b1-acc6-40d0-a630-14e5ddb74fd3/data-viewer?" + query + "size=9999&offset=0"
        axios
            .get(url)
            .then(res => {
                for (let i = 0; i < res.data.data.length; i++) {
                    let each = res.data.data[i]
                    each.npi = each[0]
                    each.name = each[1] + ' ' + each[2]
                    each.drug = each[8]
                    each.generic = each[9]
                    each.no_of_prescription = each[10]
                    each.days = each[19]
                    each.drug_cost = each[18] ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each[18]) : ""
                    each.beneficiaries = each[14]
                    delete each.GE65_Sprsn_Flag
                    delete each.GE65_Tot_Clms
                    delete each.GE65_Tot_30day_Fills
                    delete each.GE65_Tot_Day_Suply
                    delete each.GE65_Bene_Sprsn_Flag
                    delete each.GE65_Tot_Benes
                    records.push(each)
                }
                if (headers) {
                    records.unshift(headers);
                }
                // Convert Object to JSON
                let jsonObject = JSON.stringify(records);
                let csv = this.convertToCSV(jsonObject);
                let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
                let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })
        
    }

    convertToCSV(objArray) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    render() {
        return (
            <div>
                <div className="modal" id="show-physician-rx" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        {this.state.loading &&
                            <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                        {this.state.records.length > 0 &&
                            <div className="modal-content">
                                <div className="modal-header">

                                    <h5 className="modal-title">NPI: {this.state.records[0].npi}</h5><br />
                                    <h5 className="float-right-padding">Name: {this.state.records[0].name}</h5>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.records}
                                        columns={this.columns}
                                        dynamic={true}
                                        onChange={this.filter}
                                        total_record={this.state.totalCount}
                                        loading={true}
                                        exportToCSV={this.exportToCSV}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

}
export default (PrescriberRxDetail)
