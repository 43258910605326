import React from "react";
import DatePicker from "react-datepicker";

const AlereRx = (props) => {
  const { errors} = props;
  return (
    <><div className="card">
    <div className="card-header" id="headingThree">
      <h5 className="mb-0">
        <button
          type="button"
          className="btn btn-link collapsed"
          data-toggle="collapse"
          data-target="#AlereRx"
          aria-expanded="false"
          aria-controls="collapseThree"
        >
          AlereRx
        </button>
      </h5>
    </div>
    <div
      id="AlereRx"
      className="collapse"
      aria-labelledby="headingThree"
      data-parent="#accordion"
    >
      <div className="card-body">
        
      <div className="row mt-2">
        <div className="col-md-3">
          <label htmlFor="bonarx">
          Exp Date
          </label>
        </div>
        <div className="col-md-9">
          <DatePicker
            selected={props.expiration_alererx}
            onChange={(date) => props.setDate(date, "expiration_alererx")}
            className="form-control"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-3">
          <label htmlFor="alererx_subscriptionId">Subscription Id</label>
        </div>
        <div className="col-md-9">
          <input
            onChange={props.onChange}
            value={props.alererx_subscriptionId}
            id="alererx_subscriptionId"
            type="text"
            error={errors.alererx_subscriptionId}
            className={"form-control"}
          />
          <span className="text-danger">{errors.alererx_subscriptionId}</span>
        </div>
      </div>
      </div>
    </div>
  </div>
    </>
  );
};

export default AlereRx;
