import React from "react";
import Chart from "react-google-charts";
import axios from "axios";
import moment from "moment"

export class ExpandGraph extends React.Component {

    render() {
        return (
            <div className="modal fade" id="show-graph" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content" >
                            <div className="modal-header">
                                {this.props.expand === "daily_report" ? (<h5>Daily Report</h5>) : null}
                                {this.props.expand === "daily_rx" ? (<h5>Total RX: {this.props.totalRx}</h5>) : null}
                                {this.props.expand === "total_rx" ? (<h5>Total RX: {this.props.totalRx}</h5>) : null}
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                {this.props.recordsGraph.length > 0 && (
                                    <div className="card shadow-lg" style={{display:this.props.expand === "daily_report"?"block":"none"}}>
                                        <div className="card-body">
                                            <Chart
                                                chartType="ComboChart"
                                                loader={<div>Loading Chart</div>}
                                                data={this.props.recordsGraph}
                                                options={{
                                                    // hAxis: { title: 'Date' },
                                                    // vAxis: { title: 'Dollars', format: "currency" },
                                                    seriesType: 'bars',
                                                    series: { 2: { type: 'line' } },
                                                    legend: { position: 'top' },
                                                }}
                                                rootProps={{ 'data-testid': '74' }}
                                                formatters={[
                                                    {
                                                        type: 'NumberFormat',
                                                        column: 2,
                                                        options: {
                                                            prefix: '$',
                                                            negativeColor: 'red',
                                                            negativeParens: true,
                                                        },
                                                    },
                                                    {
                                                        type: 'NumberFormat',
                                                        column: 1,
                                                        options: {
                                                            prefix: '$',
                                                            negativeColor: 'red',
                                                            negativeParens: true,
                                                        },
                                                    },
                                                ]}
                                            />

                                        </div>
                                    </div>
                                )}

                                {this.props.rxGraph.length > 0 && (
                                    <div className="card shadow-lg" style={{display:this.props.expand === "daily_rx"?"block":"none"}}>
                                        <div className="card-body">
                                            <Chart
                                                width={'100%'}
                                                height={'400px'}
                                                chartType="AreaChart"
                                                loader={<div>Loading Chart</div>}
                                                data={this.props.rxGraph}
                                                options={{
                                                    legend: { position: 'top' },
                                                }}
                                                rootProps={{ 'data-testid': '63' }}
                                            />

                                        </div>
                                    </div>
                                )}
                                {this.props.totalRxGraph.length > 0 && (
                                    <div className="card shadow-lg" style={{display:this.props.expand === "total_rx"?"block":"none"}}>
                                        <div className="card-body">
                                            <Chart
                                                // width={800}
                                                // height={'400px'}
                                                chartType="LineChart"
                                                loader={<div>Loading Chart</div>}
                                                data={this.props.totalRxGraph}
                                                options={{
                                                    legend: { position: 'top' },
                                                    // chartArea: { width: '90%', height: '70%' },
                                                }}
                                                rootProps={{ 'data-testid': '62' }}
                                            />

                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}
export default ExpandGraph