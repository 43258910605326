import React from 'react'
import classnames from "classnames";
import axios from "axios";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';
import $ from 'jquery';
import isEmpty from 'is-empty';
const softwares = [
    "AbacusRx",
    "AdvanceNet",
    "BestRx",
    "CarePoint",
    "Cerner",
    "ComputerRx",
    "DigitalRx",
    "DRx",
    "ePostRx",
    "Framework LTC",
    "FSI Foundation",
    "Integra",
    "Intelligent Pharmacy Software(IPS)",
    "Liberty",
    "Micromerchant(PrimeRx)",
    "PioneerRx",
    "QS1",
    "Rx30",
    "RxBlu",
    "RxDispense",
    "RxSystem",
    "ScriptPro",
    "SRS System",
    "SuiteRx-IPS",
    "Visual Superscript",
    "Z Others- Type Response"
]
class UploadDispenseBefore extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dispense_sample_file: "",
            software: this.props.auth.user.software,
            loading: false,
            errors: {},
        };
    }

    componentDidMount() {
    };

    onSampleAdd = (e) => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.dispense_sample_file === '') {
            errors.dispense_sample_file = "This field is required"
        } else {
            delete errors.dispense_sample_file
        }
        if (isEmpty(errors)) {
            this.setState({ errors })
            const formData = new FormData();
            if (this.state.dispense_sample_file !== "")
                formData.append("dispense_sample_file", this.state.dispense_sample_file);
                formData.append("email", this.props.auth.user.final_user);
            if (this.state.software !== "")
                formData.append("software", this.state.software);
            axios
                .post("/api/dispense-sample-add", formData,{})
                .then(res => {
                    $('#upload-dispense-before-modal').modal('hide')
                    this.setState({
                        dispense_sample_file: ""
                    })
                    confirmAlert({
                        title: 'Success',
                        message: 'Your upload was successful! Please allow 48-72 hours and we\'ll notify your as soon as your system is ready to accept files',
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => window.location.reload()
                            },
                        ]
                    });
                }).catch(err =>
                    console.log(err)
                )
        } else {
            this.setState({ errors })
        }
    };

    reset = () => {
        this.setState({
            dispense_sample_file: ""
        })
    }

    onCsvChange = event => {
        this.setState({
            [event.target.id]: event.target.files[0],
        });
    };

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="upload-dispense-before-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Dispensing Data Upload</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <p>To get started uploading your dispensing file, please choose your software from the list and upload a COMPLETE dispensing file from your PMS.We will notify you within 72 hours when your system is mapped and is ready to accept files. Please send us a support ticket if you may have any questions.</p>
                                <form noValidate onSubmit={this.onSampleAdd} id="add-sample-file" encType="multipart/form-data">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="software">Software</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select name="software" id="software" onChange={this.onChange} className="form-control">
                                                <option>Please Select</option>
                                                {softwares.map(each => {
                                                    return (
                                                        <option key={each} value={each} selected={this.props.auth.user.software===each}>{each}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="dispense_sample_file">Select CSV File</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onCsvChange}
                                                id="dispense_sample_file"
                                                type="file"
                                                error={errors.dispense_sample_file}
                                                className={classnames("form-control", {
                                                    invalid: errors.dispense_sample_file
                                                })} />
                                            <span className="text-danger">{errors.dispense_sample_file}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-sample-file"
                                    type="submit"
                                    className="btn btn-primary">
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(
    mapStateToProps
)(UploadDispenseBefore);
