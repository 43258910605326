import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import { connect } from "react-redux";
import dashboard from "../../utils/dashboard.png";
import { Link } from "react-router-dom";
import { logoutUser } from "../../actions/authActions";
import $ from "jquery";
const date = moment(new Date()).format("MM/DD/YYYY");
const dateTime = moment(new Date()).format("MM/DD/YYYY HH:mm:ss a");

class BiennialForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "retail", order: "desc" },
      },
      currentRecord: {},
      name: undefined,
      drug_name: undefined,
      ndc: undefined,
      loading: false,
      dea: "",
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      // ...(this.state.from_date !== '' && { from_date: this.state.from_date }),
      // ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
      ...(this.state.ndc !== "" && { ndc: this.state.ndc }),
      ...(this.state.drug_name !== "" && { drug_name: this.state.drug_name }),
      pharmacy_account: this.props.auth.user.final_user,
    };
    if (this.state.dea !== "" && this.state.dea === "2") {
      filterData.search.dea = ["2", "2N"];
    } else if (this.state.dea !== "" && this.state.dea === "NC") {
      filterData.search.dea = ["NC"];
    } else if (this.state.dea !== "" && this.state.dea === "3") {
      filterData.search.dea = ["3", "3N", "4", "5"];
    }
    axios
      .post("/api/inventory-reconciliation", filterData)
      .then((res) => {
        let result = [];
        let despenseArr = {};
        for (let i = 0; i < res.data.dispense.length; i++) {
          let each = res.data.dispense[i];
          despenseArr[each._id] = each.qty_dispensed;
        }
        for (let i = 0; i < res.data.purchase.length; i++) {
          let each = res.data.purchase[i];
          if (each.total > 0) {
            if (despenseArr[each._id]) {
              each.qty_dispensed = despenseArr[each._id];
              each.balance = each.total - despenseArr[each._id];
            } else {
              each.qty_dispensed = "0";
              each.balance = each.total;
            }

            result.push(each);
          }
        }
        result.sort((a, b) => a.drug_name.localeCompare(b.drug_name));

        this.setState({
          records: result,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  onChangeSelect = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => this.getData()
    );
  };

  printPdf = () => {
    var style = "<style>";
    style = style + "table {width: 100%;font: 17px Calibri;}";
    style =
      style +
      "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;textAlign:left;}";
    style = style + "</style>";
    var win = window.open("", "_blank");
    win.document.write("<html><head>");
    win.document.write("<title>Biennial Form</title>");
    win.document.write(style);
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write($("#BiennialForm").html());
    win.document.write("</body></html>");
    win.print();
    win.close();
  };

  render() {
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    return (
      <div className="section">
        <div className="row">
          {this.props.auth.user.tabulaRx ||
          this.props.auth.user.superadmin ||
          this.props.auth.user.expiration_tabula === "" ||
          this.props.auth.user.expiration_tabula >= moment().format() ? (
            <div className="col-md-9 BiennialForm">
              <br></br>
              <div className="row mt-2">
                <div className="col-md-2">
                  <label htmlFor="dea">Filter by DEA</label>
                </div>
                <div className="col-md-3">
                  <select
                    onChange={this.onChangeSelect}
                    value={this.state.dea}
                    id="dea"
                    className="form-control"
                  >
                    <option value="">All</option>
                    <option value="2">2 & 2N</option>
                    <option value="3">3,3N,4&5</option>
                    <option value="NC">Non-controlled</option>
                  </select>
                </div>
              </div>
              <button
                className="btn btn-primary float-right mt-3 mr-2 a"
                onClick={this.printPdf}
              >
                Print
              </button>
              <div className="section-title p-3" id="BiennialForm">
                <h2 className="title center">
                  DEA Biennial Controlled Substance Inventory Form
                </h2>
                <br></br>
                <p>
                  Date: <b>{date}</b>
                </p>
                <p>
                  The Biennial Inventory is a requirement of the Federal Drug
                  Enforcement Administration (21 CFR 1304.11).{" "}
                </p>
                <p>
                  <b>DEA Registrant: {this.props.auth.user.name}</b>
                </p>
                <p>
                  <b>DEA Registrant Address (as appears on DEA Form 223): </b>
                  <div></div>
                </p>
                <p>
                  <b>DEA Registration #:</b>
                  ____________________________________________________________
                </p>
                <p>
                  Controlled Substances storage
                  location:_____________________________________________
                </p>
                <p>
                  <b>Inventory Performed by:</b>
                  __________________________Signature:______________________
                </p>
                <p>
                  <b>Inventory Witness:</b>
                  _______________________________Signature:______________________
                </p>
                <p>
                  <b>Time inventory was performed: {dateTime}</b>
                </p>
                <br></br>

                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">NDC Number *</th>
                      <th scope="col">Controlled Substance</th>
                      <th scope="col">OnHand Qty</th>
                      <th scope="col">Number of Unopened Containers</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.records.map((each) => {
                      if (each.balance > 0) {
                        return (
                          <tr>
                            <th scope="row">{each._id}</th>
                            <td>{each.drug_name}</td>
                            <td>{each.balance}</td>
                            <td>{each.unit_size_code}</td>
                          </tr>
                        );
                      } else {
                        return;
                      }
                    })}
                    {/* <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr> */}
                  </tbody>
                </table>
                <br></br>
                <p>
                  <b>By signing below,</b> I agree the information listed here
                  represents the actual amount of controlled substances existing
                  in inventory as of the open/close (circle one) of business on
                  (Date) __________
                </p>
                <p>
                  <b>DEA Registrant signature:</b>
                  __________________________&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <b> Date:</b>
                  <b>{date}</b>
                </p>
                <p style={{ background: "yellow" }}>
                  Keep the biennial record at the license- registered location.
                  Do not submit a copy of the biennial inventory to the DEA
                  unless requested.
                </p>
              </div>
            </div>
          ) : (
            <div className="container-fluid static-card">
              <div className="card">
                <div className="card-body">
                  {this.props.auth.user.expiration_tabula <
                  moment().format() ? (
                    <h6 class="font-weight-bold text-danger">
                      Your Subscription is ended<br></br>
                      <Link to="/subscribe">
                        <button
                          className="btn btn-success"
                          onClick={this.Subscribe}
                        >
                          Subscribe Now
                        </button>
                      </Link>
                    </h6>
                  ) : !this.props.auth.user.tabulaRx ? (
                    <h6 class="font-weight-bold text-danger">
                      You accounts is inactive!! To activate you account please{" "}
                      <Link to="/support" className="">
                        Contact
                      </Link>{" "}
                      Us.
                    </h6>
                  ) : (
                    <h6 class="font-weight-bold text-danger">
                      You accounts is inactive!! To activate you account please{" "}
                      <Link to="/support" className="">
                        Contact
                      </Link>{" "}
                      Us.
                    </h6>
                  )}
                </div>
              </div>
              <img className="static-image" src={dashboard} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(BiennialForm);
