import React, { Component } from "react";
import { Row, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import Doorstep from "./../welcome/Doorstep";
import RxMinder from "./../welcome/RxMinder";
import Tabula from "./../welcome/Tabula";

class LandingNew extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  goToSolution = (solution) => {
    switch (solution) {
      case "tabulaRx":
        if (
          this.props.auth.user.expiration_tabula !== undefined ||
          this.props.auth.user.superadmin
        ) {
          window.location =
            "https://tabula.psgarx.com?goto=dashboard&reference=" +
            localStorage.getItem("jwtToken");
        } else {
          $(".tabulaRx").trigger("click");
        }
        break;
      case "bonaRx":
        if (
          this.props.auth.user.rx_group !== undefined ||
          this.props.auth.user.superadmin
        ) {
          window.location =
            "https://analytics.psgarx.com?goto=dashboard&reference=" +
            localStorage.getItem("jwtToken");
        } else {
        }
        break;
      case "rxMinder":
        if (
          this.props.auth.user.expiration_rxminder !== undefined ||
          this.props.auth.user.superadmin
        ) {
          window.location =
            "https://rxminder.psgarx.com?goto=dashboard&reference=" +
            localStorage.getItem("jwtToken");
        } else {
          $(".rxMinder").trigger("click");
        }
        break;
      default:
      // code block
    }
  };

  render() {
    return (
      <>
        <header data-bs-theme="dark" style={{ paddingLeft: "0" }}>
          <div className="navbar navbar-dark bg-dark shadow-sm">
            <div className="container">
              <a
                href="/landing"
                className="navbar-brand d-flex align-items-center"
              >
                <img
                  src={
                    "https://tabula.psgarx.com/static/media/RxConnexion.80ee8ffc.png"
                  }
                  alt="Logo"
                  width={300}
                />
              </a>
              <button type="button" class="btn btn-dark me-2 right">
                Contact Us
              </button>
            </div>
          </div>
        </header>
        <main style={{ paddingLeft: "0" }} className="landing-new">
          <Doorstep />
          <Tabula />
          <RxMinder />
          <div className="d-none">
            <div
              data-toggle="modal"
              data-target="#tabula-modal"
              className="tabulaRx"
            >
              TabulaRx
            </div>
            <div
              data-toggle="modal"
              data-target="#rxminder-modal"
              className="rxMinder"
            >
              rxMinder
            </div>
          </div>
          <section className="text-center container">
            <div className="row py-lg-3">
              <div className="col-lg-6 col-md-8 mx-auto">
                <h1 className="fw-light">Choose a Solution</h1>
              </div>
            </div>
          </section>

          <div className="card shadow-lg container">
            <div className="card-body dashboard">
              <Row>
                <div className="col-sm-4 p-sm-2">
                  <div
                    className="card shadow-lg"
                    // style={{ backgroundColor: "rgb(119 0 169)" }}
                  >
                    <div className="card-header bg-dark text-white">
                      Insight & Analytics
                    </div>
                    <ul className="list-group list-group-flush">
                      <li
                        className="list-group-item"
                        onClick={() => this.goToSolution("tabulaRx")}
                      >
                        TabulaRx
                      </li>
                      <li
                        className="list-group-item"
                        onClick={() => this.goToSolution("bonaRx")}
                      >
                        BonaRx
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4 p-sm-2">
                  <div
                    className="card shadow-lg"
                    // style={{ backgroundColor: "rgb(119 0 169)" }}
                  >
                    <div className="card-header bg-dark text-white">
                      Supply Chain Management
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">DoorstepRx</li>
                      <li className="list-group-item">CompareRx</li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4 p-sm-2">
                  <div
                    className="card shadow-lg"
                    // style={{ backgroundColor: "rgb(119 0 169)" }}
                  >
                    <div className="card-header bg-dark text-white">
                      Telehealth
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">AlereRx</li>
                      <li className="list-group-item">XPediCareRx</li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4 p-sm-2">
                  <div
                    className="card shadow-lg"
                    // style={{ backgroundColor: "rgb(119 0 169)" }}
                  >
                    <div className="card-header bg-dark text-white">
                      Engagement Tool
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item" onClick={() => this.goToSolution("rxMinder")}>RxMinder</li>
                      <li className="list-group-item">RxReach</li>
                      <li className="list-group-item">RxPodcast</li>
                      <li className="list-group-item">RxCreator</li>
                    </ul>
                  </div>
                </div>

                <div className="col-sm-4 p-sm-2">
                  <div
                    className="card shadow-lg"
                    // style={{ backgroundColor: "rgb(119 0 169)" }}
                  >
                    <div className="card-header bg-dark text-white">
                      Marketing
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">RxCreator</li>
                      <li className="list-group-item">RxPodcast</li>
                      <li className="list-group-item">Marketing</li>
                      <li className="list-group-item">Marketing++</li>
                      
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4 p-sm-2">
                  <div
                    className="card shadow-lg"
                    // style={{ backgroundColor: "rgb(119 0 169)" }}
                  >
                    <div className="card-header bg-dark text-white">
                      Compliance
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">Audit</li>
                      <li className="list-group-item">CS Compliance</li>
                      <li className="list-group-item">Coaching</li>
                    </ul>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </main>
        <div className="container">
          <footer className="py-3 my-4" style={{ paddingLeft: "0" }}>
            <ul className="nav justify-content-center border-bottom pb-3 mb-3">
              <li className="nav-item">
                <a href="#" className="nav-link px-2 text-body-secondary">
                  Privacy Policy
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link px-2 text-body-secondary">
                  Pricing
                </a>
              </li>
            </ul>
            <p className="text-center text-body-secondary">
              © 2023 RxConnexion, Inc
            </p>
          </footer>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(LandingNew);
