import axios from "axios";
axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 403) {
      localStorage.removeItem("jwtToken");
      window.location.href = "./login?multiple=true";
    } else {
      console.log(err.response);
      return Promise.reject(err);
    }
  }
);
const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};
export default setAuthToken;
