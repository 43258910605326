import React, { Component, Fragment } from "react";
import { Row, Container, Col } from 'react-bootstrap';
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { faPlus, faBackward } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
// import moment from "moment"
import ReverseAddModal from "../partials/ReverseAddModal";
import DatePicker from "react-datepicker";
import moment from "moment"
import GroupFilter from "./Filter/GroupFilter.js";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReverseUpdateModal from "../partials/ReverseUpdateModal";
import { Link } from "react-router-dom";
import dashboard from "../../utils/dashboard.png"
import Multiselect from 'multiselect-react-dropdown';
import { logoutUser } from "../../actions/authActions";
class ReverseDistribution extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "dispense_date",
                text: "Date",
                className: "dispense_date",
                align: "left",
                sortable: true,
            },
            {
                key: "drug_name",
                text: "Drug Name",
                className: "drug_name",
                align: "left",
                sortable: true,
            },
            {
                key: "ndc",
                text: "NDC",
                className: "ndc",
                align: "left",
                sortable: true,
            },
            {
                key: "dea",
                text: "DEA",
                className: "dea",
                align: "left",
                sortable: true,
            },
            {
                key: "qty_dispensed",
                text: "Qty Returned",
                className: "qty_dispensed",
                align: "left",
                sortable: true,
            },
            {
                key: "return_number",
                text: "Return Number",
                className: "return_number",
                align: "left",
                sortable: true,
            },
            {
                key: "reason",
                text: "Reason",
                className: "reason",
                align: "left",
                sortable: true,
            },
            {
                key: "invoiceimage",
                text: "Invoice",
                className: "invoiceimage",
                align: "left",
                sortable: false,
                downloadable: true
            },
            {
                key: "reverse_distribution",
                text: "Reverse Distribution",
                className: "reverse_distribution",
                align: "left",
                sortable: true,
            },

        ];
        if (!this.props.auth.user.superadmin && !this.props.auth.user.multistore) {
            this.columns.push({
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-reverse-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            })
        }

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Dispense List",
            no_data_text: 'No Dispense found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
            sort: {
                column: "dispense_date",
                order: "desc"
            }
        };

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "dispense_date", order: "desc" }
            },
            currentRecord: {
            },
            ncDrugs: undefined,
            drug_name: undefined,
            ndc: undefined,
            rx_group_associated: "",
            plainArray: ["2", "3", "4", "5", "2N", "3N", "NC"],
            deaSelected: [],
            from_date: moment().subtract(7, "days").startOf('day').toDate(),
            to_date: moment().endOf('day').toDate()
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()

    };

    componentDidUpdate(prevProps) {
        if(this.props.multistoreData.length!==prevProps.multistoreData.length){
            this.getData()
        }
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.from_date !== '' && { from_date: this.state.from_date }),
            ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
            ...(this.state.ndc !== '' && { ndc: this.state.ndc }),
            ...(this.state.drug_name !== '' && { drug_name: this.state.drug_name }),
            ...(this.state.deaSelected.length > 0 && { dea: this.state.deaSelected }),
            pharmacy_account: this.state.rx_group_associated !== undefined && this.state.rx_group_associated !== "" ? this.state.rx_group_associated : this.props.auth.user.final_user,
            ...(this.props.multistoreData.length > 0 && (this.state.rx_group_associated === undefined || this.state.rx_group_associated === "") && { multiStoreEmails: this.props.multistoreData.map(a => a.email) }),
            exact: true
        }
        axios
            .post("/api/reverse-distribution", filterData)
            .then(res => {
                var result = [];
                // add data rows in sheet array
                for (let i in res.data.records) {
                    let each = res.data.records[i]
                    if (each.is_reverse_distribution === true) {

                        each.orig_dispense_date = each.dispense_date
                        each.dispense_date = each.dispense_date ? moment(each.dispense_date).add(5, 'hours').format('L') : ""
                        result.push(each);
                    }
                }
                this.setState({
                    records: result,
                    totalCount: res.data.count,
                    loading: false,
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })

    }

    editRecord(record) {
        this.setState({ currentRecord: record });
    }
    setFromDate = (date) => {
        this.setState({ from_date: date });
    }
    setToDate = (date) => {
        this.setState({ to_date: date });
    }
    deleteRecord(record) {
        if(this.props.auth.user.user_type !== "Technician"){
            confirmAlert({
                title: 'Records will be deleted from record',
                message: 'Are you sure to do this.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => this.finalDelete(record)
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        }else{
            confirmAlert({
                title: 'Record will delete only after approval from admin.',
                message: 'Are you sure to do this.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => this.finalDelete(record)
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        }
    }

    finalDelete = (record) => {
        axios
            .post("/api/dispense-delete",
                {
                    _id: record._id,
                    delete: this.props.auth.user.user_type !== "Technician" ? true : false,
                    email: this.props.auth.user.email
                }
            )
            .then(res => {
                if (res.status === 200) {
                    this.getData()
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            });
        this.getData();
    }


    onChange = e => {
        let id = e.target.id
        let value = e.target.value
        this.setState({ [e.target.id]: e.target.value }, () => this.checkUPC(id, value.trim()));
    };

    checkUPC = (id, value) => {
        if (id === "ndc" && value.length > 11) {
            this.getData()
        }
    }

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            from_date: undefined,
            to_date: undefined,
            ndc: undefined,
            drug_name: undefined,
            rx_group_associated: "",
            deaSelected: [],
            from_date: moment().subtract(7, "days").startOf('day').toDate(),
            to_date: moment().endOf('day').toDate(),
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "asc" }
            }
        }, () => this.getData())
    }

    exportToCSV = () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            "dispense_date": "Dispense Date",
            "ndc": "NDC",
            "drug_name": "Drug Name",
            "dea": "DEA",
            "qty_dispensed": "Qty Dispensed",
            "reverse_distribution": "Reverse Distribution",
            "pharmacy_account": "Pharmacy Account",
            "user_created": "User Created",
            "return_number": "Return Number",
            "reason": "Reason",
            "lot": "Lot Number",
            "expiration_date": "Expiration Date"
        };
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false })
        let filterData = {
            page_number: 1,
            page_size: 20000,
            sort_order: { column: "dispense_date", order: "desc" }
        }
        filterData.search = {
            ...(this.state.from_date !== '' && { from_date: this.state.from_date }),
            ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
            ...(this.state.deaSelected.length > 0 && { dea: this.state.deaSelected }),
            pharmacy_account: this.state.rx_group_associated !== undefined && this.state.rx_group_associated !== "" ? this.state.rx_group_associated : this.props.auth.user.final_user,
        }
        axios
            .post("/api/reverse-distribution", filterData)
            .then(res => {
                // add data rows in sheet array
                for (let i in res.data.records) {
                    let record = res.data.records[i]
                    if (record.is_reverse_distribution === true) {
                        delete record._id; delete record.id; delete record.__v; delete record.is_reverse_distribution;
                        record.dispense_date = record.dispense_date ? moment(record.dispense_date).add(5, 'hours').format('L') : ""
                        record.expiration_date = record.expiration_date ? moment(record.expiration_date).add(5, 'hours').format('L') : ""
                        records.push(record);
                    }
                }
                if (headers) {
                    records.unshift(headers);
                }
                // Convert Object to JSON
                // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
                // var universalBOM = "\uFEFF";
                let jsonObject = JSON.stringify(records);
                let csv = this.convertToCSV(jsonObject, headers);
                let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
                // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
                var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'application/csv;charset=UTF-8' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })


    }

    convertToCSV(objArray, headers) {
        const keys = Object.keys(headers);

        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in keys) {
                line += array[i][keys[index]] === undefined ? "," : array[i][keys[index]] + ",";
                line.trim(",")


            }
            str += line + '\r\n';
        }
        return str;
    }

    openDatepicker = (e) => {
        if (e.target.id === "toD") {
            this.toDatePicker.setOpen(true);
        } else {
            this.fromDatePicker.setOpen(true);
        }
    }

    onSelectMultiple = (selectedList, selectedItem) => {
        this.setState({ deaSelected: selectedList })
    }

    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }

    render() {
        if ((this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula === undefined || this.props.auth.user.expiration_tabula < moment().format()) && this.props.auth.user.superadmin !== true) {
            window.location.href = ("/choose-plan?solution=TabulaRx");
        }
        return (
            <>
                <div className="d-flex" id="wrapper">
                    {this.state.loading &&
                        <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                    <ReverseAddModal getData={this.getData} />
                    <ReverseUpdateModal record={this.state.currentRecord} getData={this.getData} />
                    <div id="page-content-wrapper">
                        {this.props.auth.user.tabulaRx || this.props.auth.user.superadmin || this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula >= moment().format() ? (

                            <div className="container-fluid">
                                {!this.props.auth.user.superadmin && !this.props.auth.user.multistore &&
                                    <button className="btn btn-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-reverse-modal"><FontAwesomeIcon icon={faPlus} /> Add New Return</button>}
                                <h1 className="mt-2 text-primary">Reverse Distribution: You can keep records of all expired medications including control substances</h1>
                                <button className="btn btn-warning"><FontAwesomeIcon icon={faBackward} /><Link to="/dashboard"> Go to Dashboard</Link></button>
                                <br></br>
                                <br></br>
                                <form>
                                    <div className="form-row">
                                        <GroupFilter onChangeSelect={this.onChangeSelect} rx_group_associated={this.state.rx_group_associated}/>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="npi">From Date</label>
                                            <DatePicker selected={this.state.from_date} onChange={(date) => this.setFromDate(date)} className="form-control" ref={(c) => this.fromDatePicker = c} />
                                            <i aria-hidden="true" className="fa fa-calendar" id="fromD" onClick={this.openDatepicker}></i>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="first_name">To Date</label>
                                            <DatePicker selected={this.state.to_date} maxDate={moment().toDate()} onChange={(date) => this.setToDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                            <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="ndc">Search Dispense by NDC or Scan Barcode</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ndc}
                                                id="ndc"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="drug_name">Drug Name</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.drug_name}
                                                id="drug_name"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="drug_name">Class</label>
                                            <Multiselect
                                                options={this.state.plainArray}
                                                isObject={false}
                                                onSelect={this.onSelectMultiple} // Function will trigger on select event
                                                onRemove={this.onSelectMultiple} // Function will trigger on remove event
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <button
                                            onClick={this.onSearch}
                                            type="button"
                                            className="btn btn-primary">
                                            Search
                                        </button>
                                        <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>
                                    </div>
                                </form>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={false}
                                    total_record={this.state.totalCount}
                                    loading={true}
                                    exportToCSV={this.exportToCSV}
                                    onChange={this.filter}
                                />
                            </div>
                        ) : (
                            <div className="container-fluid static-card">
                                <div className="card">
                                    <div className="card-body">
                                        {this.props.auth.user.expiration_tabula < moment().format() ? (
                                            <h6 class="font-weight-bold text-danger">Your Subscription is ended<br></br>
                                                <Link to="/subscribe"><button className="btn btn-success" onClick={this.Subscribe}>Subscribe Now</button></Link>
                                            </h6>
                                        ) : !this.props.auth.user.tabulaRx ? (
                                            <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="">Contact</Link> Us.</h6>
                                        ) : (
                                            <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="">Contact</Link> Us.</h6>
                                        )}
                                    </div>
                                </div>
                                <img className="static-image" src={dashboard} />
                            </div>
                        )
                        }
                    </div>
                    <ToastContainer />
                </div>
            </>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    multistoreData: state.multistoreData
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(ReverseDistribution);
