import React from "react";

class XpedicareVideo extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <div>
        <div className="modal fade" id="how-it-works" data-reset="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Step-by-step guide to getting started:
                </h4>
                <img
                  src={
                    "https://rxreachanalytis.s3.amazonaws.com/logo/XPedicareRx-24.png"
                  }
                  height={50}
                  style={{
                    marginLeft: "20px",
                    position: "absolute",
                    right: "40px",
                  }}
                />
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body center">
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div class="card">
                      <video controls>
                        <source
                          src="https://d3l9g5ay50k79j.cloudfront.net/xpedicare-video/1.+How+to+get+started_1.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="card-body">
                        <h5 class="card-title">1. How to get started</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="card">
                      <video controls>
                        <source
                          src="https://d3l9g5ay50k79j.cloudfront.net/xpedicare-video/2.Choosing+a+Plan_1.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="card-body">
                        <h5 class="card-title">2. Choosing a Plan</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div class="card">
                      <video controls>
                        <source
                          src="https://d3l9g5ay50k79j.cloudfront.net/xpedicare-video/3.How+to+access+Xped_1.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="card-body">
                        <h5 class="card-title">
                          3. How to access XPedicareRx24
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="card">
                      <video controls>
                        <source
                          src="https://d3l9g5ay50k79j.cloudfront.net/xpedicare-video/4.+How+to+get+verified_1.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="card-body">
                        <h5 class="card-title">4. How to get verified</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div class="card">
                      <video controls>
                        <source
                          src="https://d3l9g5ay50k79j.cloudfront.net/xpedicare-video/5.How+to+submit+verifiation+code+from+PMS_1.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="card-body">
                        <h5 class="card-title">
                          5. How to submit verifiation code from PMS
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="card">
                      <video controls>
                        <source
                          src="https://d3l9g5ay50k79j.cloudfront.net/xpedicare-video/6.+Checking+Status-_1.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="card-body">
                        <h5 class="card-title">6. Checking Status</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div class="card">
                      <video controls>
                        <source
                          src="https://d3l9g5ay50k79j.cloudfront.net/xpedicare-video/7.+How+to+send+links+for+Clinic_2.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="card-body">
                        <h5 class="card-title">
                          7. How to activate links for your Clinic
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="card">
                      <video controls>
                        <source
                          src="https://d3l9g5ay50k79j.cloudfront.net/xpedicare-video/8.+How+to+downlaod+an+Rx+in+XPedicareRx+24.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="card-body">
                        <h5 class="card-title">
                          8. How to download an Rx in XPedicareRx24
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default XpedicareVideo;
