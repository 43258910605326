import React from "react";
import { setCurrentUser } from "../../actions/authActions";
import axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class TermCondition extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      termscondition: false,
    };
  }

  toggleChange = (e) => {
    this.setState({ [e.target.id]: !this.state[e.target.id] });
  };

  acceptTerms = () => {
    const data = {
      company_name:
        this.props.userInfo.first_name + " " + this.props.userInfo.last_name,
      address: this.props.userInfo.street,
      cityState:
        this.props.userInfo.city +
        ", " +
        this.props.userInfo.state +
        " " +
        this.props.userInfo.zip,
      date: moment().format("MM-DD-YYYY"),
      city: this.props.userInfo.city,
      name: this.props.userInfo.name,
      phone: this.props.userInfo.phone,
      email: this.props.userInfo.email,
      solutions: this.props.solutions.join(", ")
    };
    this.setState({ loading: true });
    axios
      .post("/api/send-termPodcast", data, {
        // receive two parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        toast(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        this.setState({ loading: false,termscondition:false },()=>this.props.navigateTo());
      });
    
  };

  render() {
    return (
      <div>
        <div className="modal fade" id="term-condition" data-reset="false">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Accept Terms and Conditions</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="term-condition">
                  <p>
                    PSGA, Inc
                    <br />
                    500 SR 436, Suite 2066
                    <br />
                    Casselberry, FL 32707
                    <br />
                    Hello@PSGARX.com
                    <br />
                    888-442-8348
                  </p>
                  <br />
                  <p>Date:{moment().format("MM-DD-YYYY")}</p>
                  <br />
                  <p>
                    Client&#39;s Name:{" "}
                    {this.props.userInfo.first_name +
                      " " +
                      this.props.userInfo.last_name}
                    <br />
                    Client&#39;s Address: {this.props.userInfo.street}
                    <br />
                    City, State, ZIP:{" "}
                    {this.props.userInfo.city +
                      ", " +
                      this.props.userInfo.state +
                      " " +
                      this.props.userInfo.zip}
                  </p>
                  <p>
                    <strong>
                      Subject: Podcast Production Service Agreement
                    </strong>
                  </p>
                  <p>Dear Client,</p>
                  <p>
                    This Podcast Production Service Agreement (the
                    &quot;Agreement&quot;) is made between Pharmacy Services
                    Group of America, Inc., hereinafter referred to as
                    &quot;PSGA, Inc,&quot; and{" "}
                    <b>{this.props.solutions.join(", ")}</b>, hereinafter
                    referred to as the &quot;Client,&quot; collectively referred
                    to as the &quot;Parties,&quot; for the provision of podcast
                    production services.
                  </p>

                  <p>
                    <strong>Services</strong>
                  </p>

                  <p>
                    PSGA, Inc agrees to provide the following services to the
                    Client:
                  </p>
                  <ul>
                    <li>
                      Design: PSGA, Inc will create a customized podcast design,
                      including graphics, logos, and branding elements, that
                      aligns with the Client's pharmacy brand.
                    </li>
                    <li>
                      Production: PSGA, Inc will handle the entire podcast
                      production process, including but not limited to
                      recording, editing, and post-production. This service will
                      include access to professional voice actors, content
                      writers, and audio engineers to ensure high-quality
                      production.
                    </li>
                    <li>
                      Distribution: PSGA, Inc will distribute the podcast
                      episodes to all major streaming platforms, including but
                      not limited to Apple Podcasts, Spotify, Google Podcasts,
                      and more, to maximize the reach and accessibility of the
                      podcast.
                    </li>
                    <li>
                      AI Voice Cloning: PSGA, Inc will utilize AI technology to
                      create voice clones for the podcast, allowing for
                      consistent and automated voiceovers, introductions, and
                      outros.
                    </li>
                    <li>
                      Website design: create and publish custom sites for
                      clients.
                    </li>
                    <li>
                      Social Media Content Management: Create and distribute
                      social media content on behalf of the client on a weekly
                      basis.
                    </li>
                    <li>
                      Marketing Assets: based on the subscription selected,
                      users will have access to our marketing assets.
                    </li>
                  </ul>

                  <p>
                    <strong>Term</strong>
                  </p>

                  <p>
                    The initial term of this Agreement shall be for a minimum
                    period of six (6) months, commencing on{" "}
                    {moment().format("MM/DD/YYYY")}. After the initial term,
                    this Agreement will automatically renew on a month-to-month
                    basis unless either Party provides written notice of
                    termination at least thirty (30) days prior to the end of
                    the then-current term.
                  </p>

                  <p>
                    <strong>Fees</strong>
                  </p>
                  <p>
                    The Client agrees to pay PSGA, Inc the fees for the podcast
                    production services as posted on our website for the
                    specific plan (www.RxConnexion.com) at the time-of-service
                    engagement. The fees shall be billed monthly to the credit
                    card on file provided by the Client. Any changes in fees
                    will be communicated to the Client in writing with at least
                    thirty (30) days&#39; notice.
                  </p>
                  <p>
                    <strong>Ownership and Intellectual Property:</strong>
                  </p>
                  <p>
                    The Client acknowledges that any intellectual property
                    created or provided by PSGA, Inc as part of the podcast
                    production services, including but not limited to podcast
                    episodes, graphics, logos, and voice clones, shall remain
                    the property of PSGA, Inc unless otherwise agreed upon in
                    writing. The Client is granted a non-exclusive,
                    non-transferable license to use the podcast episodes for the
                    purposes of promoting their pharmacy business.
                  </p>

                  <p>
                    <strong>Confidentiality:</strong>
                  </p>
                  <p>
                    Both Parties acknowledge that they may have access to
                    confidential and proprietary information of the other Party
                    during the term of this Agreement. Each Party agrees to keep
                    such information strictly confidential and not to disclose
                    or use it for any purpose other than the performance of this
                    Agreement, unless required by law.
                  </p>

                  <p>
                    <strong>Termination:</strong>
                  </p>
                  <p>
                    Either Party may terminate this Agreement after the initial
                    six (6) month term by providing written notice to the other
                    Party at least thirty (30) days prior to the desired
                    termination date.
                  </p>

                  <p>
                    <strong>Indemnity:</strong>
                  </p>
                  <p>
                    Client agrees to indemnify, defend, and hold PSGA harmless
                    from any claims, demands, actions, liabilities, losses,
                    damages, costs, and expenses (including legal fees) incurred
                    by PSGA, arising out of or related to services received. Our
                    client assumes full responsibility for any harm, injury, or
                    loss caused by its actions or omissions, and PSGA shall not
                    be liable for any resulting consequences.
                  </p>

                  <p>
                    <strong>Governing Law:</strong>
                  </p>
                  <p>
                    This Agreement shall be governed by and construed in
                    accordance with the laws of Florida. Any disputes arising
                    under or in connection with this Agreement shall be subject
                    to the exclusive jurisdiction of the courts of Orange County
                    Florida.
                  </p>

                  <p>
                    <strong>Entire Agreement:</strong>
                  </p>
                  <p>
                    This Agreement constitutes the entire understanding between
                    the Parties and supersedes all prior agreements,
                    representations, or understandings, whether written or oral,
                    relating to the subject matter herein.<br></br>Please
                    indicate your acceptance of the terms and conditions
                    outlined in this Agreement by signing and returning a copy
                    of this letter within [insert number of days] from the date
                    of this letter.<br></br>We are excited to provide you with
                    our podcast production services and help promote your
                    pharmacy business through this engaging medium.
                  </p>

                  <p>Accepted and agreed:</p>
                  <p>
                    Client's Name:{" "}
                    {this.props.userInfo.first_name +
                      " " +
                      this.props.userInfo.last_name}
                    <br></br>
                    Client's Position: {this.props.userInfo.city}
                    <br></br>
                    Client's Company Name: {this.props.userInfo.name}
                    <br></br>
                    Client's Phone: {this.props.userInfo.phone}
                    <br></br>
                    Client's Email Address: {this.props.userInfo.email}
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <input
                  type="checkbox"
                  className="form-check float-left"
                  checked={this.state.termscondition}
                  onChange={this.toggleChange}
                  id="termscondition"
                />
                <b>
                  <p className="float-left">Accept Terms & Conditions</p>
                </b>
                <div className="float-right">
                  <button
                    type="button"
                    onClick={this.acceptTerms}
                    disabled={!this.state.termscondition}
                    className="btn btn-primary"
                  >
                    Continue
                  </button>
                </div>
              </div>
              <ClipLoader
                color="#e2e2e2"
                loading={this.state.loading}
                css={override}
                size={150}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setCurrentUser })(TermCondition);
