import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { connect } from "react-redux";
import $ from 'jquery';
import isEmpty from 'is-empty';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment"


class CompoundUpdate extends React.Component {

    constructor() {
        super();
        this.state = {
            date: "",
            rx: "",
            supplier: "",
            lot: "",
            ingre_name:"",
            amt_used: '',

            expiry_date: "",
            qty: "",
            formula: "",
            size: "",
            type: "",
            bud: "",
            compound_name: "",
            rows: [],
            id: '',
            comment: "",
            errors: {},
        };
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    componentWillReceiveProps(nextProps) {
        if(nextProps.record.ingrediants !==undefined){
            this.state.rows = nextProps.record.ingrediants
        }
        if(nextProps.record.id !==undefined){
            this.state.id = nextProps.record.id
        }
        if (nextProps.record) {
            this.setState({
                ...nextProps.record,
                date: moment().toDate()
                
            });
        }
    }
    

    onReportAdd = e => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.date === '') {
            errors.date = "This field is required"
        } else {
            delete errors.date
        }
        if (this.state.bud === '') {
            errors.bud = "This field is required"
        } else {
            delete errors.bud
        }
        if (this.state.qty === '') {
            errors.qty = "This field is required"
        } else {
            delete errors.qty
        }
        // if (this.state.ndc === '') {
        //     errors.ndc = "This field is required"
        // } else {
        //     delete errors.ndc
        // }
        // if (this.state.qty_dispensed === '') {
        //     errors.qty_dispensed = "This field is required"
        // } else if (isNaN(this.state.qty_dispensed)) {
        //     errors.qty_dispensed = "This field should be number"
        // } else {
        //     delete errors.qty_dispensed
        // }
        // if (this.state.reverse_distribution === '') {
        //     errors.reverse_distribution = "This field is required"
        // } else {
        //     delete errors.reverse_distribution
        // }
        if (isEmpty(errors)) {
            this.setState({ errors })
            const newDrug = {
                date: this.state.date,
                rx: this.state.rx,
                ingrediants: this.state.rows,
                bud: this.state.bud,
                qty: this.state.qty,
                // amt_used: this.state.amt_used,

                compound_name: this.state.compound_name,
                id: this.state.id,
                comment: this.state.comment,

                pharmacy_account: this.props.auth.user.final_user,

            };
            axios
            
                .post("/api/compound-update", newDrug)
                .then(res => {
                    this.props.getData();
                    $('#update-compound-modal').modal('hide');
                    this.setState({
                        rx: "",
                        date:"",
                        bud: "",
                        qty: "",
                        comment: "",
                        rows: [],
                        compound_name: "",
                    })
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }).catch(err =>
                    console.log(err)
                )
        } else {
            this.setState({ errors })
        }
    };

    handleDateChange = (date_to) => {
        this.setState({ date: date_to });
    }
    openDatepicker = (e) => {
        if (e.target.id === "toD") {
            this.toDatePicker.setOpen(true);
        }
    }
    handleChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        // let a= rows[idx]
        // a[name]=value
        // rows[idx] = a
        rows[idx] = {
            ...rows[idx],
            [name]: value,
            //   [mobile]: value
        };


        this.setState({
            rows

        });


    };
    handleAddRow = () => {
        const item = {
            supplier: "",
            amt_used:'',
            lot: "",
            expiry_date: "",
           
            formula: "",
            size: "",
            
            type: "",
            ingre_name:""

        };

        this.setState({
            rows: [...this.state.rows, item]
        });


    };
    handleRemoveRow = () => {
        this.setState({
            rows: this.state.rows.slice(0, -1)
        });
    };
    render() {
        const { errors } = this.state;
        return (
            <div>
            <div className="modal fade" id="update-compound-modal" data-reset="false">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Update Compound Log</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <form noValidate onSubmit={this.onReportAdd} id="update-compound">
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="date">DATE</label>
                                    </div>
                                    <div className="col-md-9">
                                        <DatePicker
                                            selected={this.state.date}
                                            onChange={this.handleDateChange}
                                            // showTimeSelect
                                            // dateFormat="Pp"
                                            ref={(c) => this.toDatePicker = c}
                                            className="form-control"
                                        />
                                        <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                        <span className="text-danger">{errors.date}</span>
                                    </div>
                                </div>


                                {/* <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="time">Time</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.time}
                                            id="time"
                                            type="text"
                                            error={errors.time}
                                            className={classnames("form-control", {
                                                invalid: errors.time
                                            })} />
                                        <span className="text-danger">{errors.time}</span>
                                    </div>
                                </div> */}
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="rx">Rx Number</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.rx}
                                            id="rx"
                                            type="text"
                                            error={errors.rx}
                                            className={classnames("form-control", {
                                                invalid: errors.rx
                                            })} />
                                        <span className="text-danger">{errors.rx}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="compound_name">Compound Name</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.compound_name}
                                            id="compound_name"
                                            type="text"
                                            error={errors.compound_name}
                                            className={classnames("form-control", {
                                                invalid: errors.compound_name
                                            })} />
                                        <span className="text-danger">{errors.compound_name}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="qty">RX Quantity</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.qty}
                                                id="qty"
                                                type="text"
                                                error={errors.qty}
                                                className={classnames("form-control", {
                                                    invalid: errors.qty
                                                })} />
                                            <span className="text-danger">{errors.qty}</span>
                                        </div>
                                    </div>
                                    
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="bud">B.U.D in days</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.bud}
                                                id="bud"
                                                type="text"
                                                error={errors.bud}
                                                className={classnames("form-control", {
                                                    invalid: errors.bud
                                                })} />
                                            <span className="text-danger">{errors.bud}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="comment">Comments & Calculations</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.comment}
                                                id="comment"
                                                type="text"
                                                error={errors.comment}
                                                className={classnames("form-control", {
                                                    invalid: errors.comment
                                                })} />
                                            <span className="text-danger">{errors.comment}</span>
                                        </div>
                                    </div>
                            </form>
                        </div>
                        <div className="row clearfix">
                            <div className="col-md-12 column">
                                <table
                                    className="table table-bordered table-hover"
                                    id="tab_logic"
                                >
                                    <thead>
                                        <tr>
                                            <th className="text-left">Ingredient</th>
                                            <th className="text-left">Amount Used</th>
                                            <th className="text-left">Supplier</th>
                                            <th className="text-left">Lot Number</th>
                                            <th className="text-left">Exp Date</th>
                                            {/* <th className="text-left">Quantity</th> */}
                                            <th className="text-left">Formula #</th>
                                            <th className="text-left">Size</th>
                                            <th className="text-left">Form</th>
                                            {/* <th className="text-left">B.U.D.</th> */}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.rows.map((item, idx) => (
                                            <tr id="addr0" key={idx}>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="ingre_name"
                                                        value={this.state.rows[idx].ingre_name}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="amt_used"
                                                        value={this.state.rows[idx].amt_used}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="supplier"
                                                        value={this.state.rows[idx].supplier}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="lot"
                                                        value={this.state.rows[idx].lot}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="expiry_date"
                                                        value={this.state.rows[idx].expiry_date}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control"
                                                    />
                                                </td>
                                                {/* <td>
                                                    <input
                                                        type="text"
                                                        name="qty"
                                                        value={this.state.rows[idx].qty}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control"
                                                    />
                                                </td> */}
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="formula"
                                                        value={this.state.rows[idx].formula}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="size"
                                                        value={this.state.rows[idx].size}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="type"
                                                        value={this.state.rows[idx].type}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control"
                                                    />
                                                </td>
                                                {/* <td>
                                                    <input
                                                        type="text"
                                                        name="bud"
                                                        value={this.state.rows[idx].bud}
                                                        onChange={this.handleChange(idx)}
                                                        className="form-control"
                                                    />
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <button
                                        onClick={this.handleAddRow}
                                        className="btn btn-success pull-left"
                                        style={{marginLeft:"20px"}}
                                    >
                                        Add Row
                                    </button>
                                    <button
                                        onClick={this.handleRemoveRow}
                                        className="pull-right btn btn-secondary"
                                        style={{marginRight:"20px"}}
                                    >
                                        Delete Row
                                    </button>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button
                                form="update-compound"
                                type="submit"
                                // data-toggle="modal" data-target="#add-drug-modal"
                                className="btn btn-primary">
                                update Compound log
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </div >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps
)(CompoundUpdate);
