export const availableSolutions = [
  {
    name: "TabulaRx",
    price: "69.99",
    class: "growth",
    tooltip: "Data Analytics/Compliance",
    subId: "subscriptionId",
    expiry: "expiration_tabula",
    free: true,
    essential: true,
  },
  {
    name: "Everything in Growth Plan",
    price: "89.99",
    class: "premium",
    tooltip: "Growth Plan",
    subId: "comparerx_subscriptionId",
    expiry: "expiration_comparerx",
    hidePrice: true,
    disabled: true,
  },

  {
    name: "Marketing",
    price: "149.99",
    class: "addon",
    tooltip: "Basic Access",
    subId: "marketing_subscriptionId",
    expiry: "expiration_marketing",
  },
  {
    name: "BonaRx",
    price: "9.99",
    class: "growth",
    tooltip: "Custom Savings Card",
    subId: "bonarx_subscriptionId",
    expiry: "expiration_bonarx",
  },
  {
    name: "RxMinder",
    price: "89.99",
    class: "addon",
    tooltip: "Education/Engagement",
    subId: "rxminder_subscriptionId",
    expiry: "expiration_rxminder",
    free: true,
  },

  {
    name: "Marketing+",
    price: "349.99",
    class: "addon",
    tooltip: "Basic Access and much more",
    subId: "marketingp_subscriptionId",
    expiry: "expiration_marketingp",
  },
  {
    name: "AlereRx",
    price: "9.99",
    class: "growth",
    tooltip: "Unlimited Telehealth",
    subId: "alererx_subscriptionId",
    expiry: "expiration_alererx",
  },
  {
    name: "RxCreator",
    price: "49.99",
    class: "premium",
    tooltip: "Digital Marketing",
    subId: "rxcreator_subscriptionId",
    expiry: "expiration_rxcreator",
    essential: true,
  },

  {
    name: "Compliance",
    price: "89.99",
    class: "addon",
    tooltip: "Regulatory",
    subId: "compliance_subscriptionId",
    expiry: "expiration_compliance",
  },
  {
    name: "RxReach",
    price: "19.99",
    class: "growth",
    tooltip: "Custom Communication",
    subId: "rxreach_subscriptionId",
    expiry: "expiration_rxreach",
  },

  {
    name: "RxPodcast",
    price: "89.99",
    class: "addon",
    tooltip: "Communication",
    subId: "rxpodcast_subscriptionId",
    expiry: "expiration_rxpodcast",
    hidePrice: true,
  },
  {
    name: "Audit",
    price: "89.99",
    class: "addon",
    tooltip: "Audit Review",
    subId: "audit_subscriptionId",
    expiry: "expiration_audit",
  },
  {
    name: "xpedicare24",
    price: "89.99",
    class: "premium",
    tooltip: "24/7 Access",
    subId: "xpedicare24_subscriptionId",
    expiry: "expiration_xpedicare24",
    essential: true,
  },
  {
    name: "DoorstepRx",
    price: "39.99",
    class: "addon",
    tooltip: "Delivery Platform",
    subId: "doorstep_subscriptionId",
    expiry: "expiration_doorstep",
    free: true,
  },

  {
    name: "MentoRx",
    price: "899.99",
    class: "addon",
    tooltip: "One-On-One Coaching",
    subId: "mentorx_subscriptionId",
    expiry: "expiration_mentorx",
  },
];
