import React from "react";
import classnames from "classnames";

import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import $ from "jquery";

import "react-toastify/dist/ReactToastify.css";
import isEmpty from "is-empty";

class SubUserAddModal extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      first_name: "",
      last_name: "",
      user_initials: "",
      user_type: "",
      phone: "",
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.user !== undefined &&
      nextProps.auth.user.data !== undefined &&
      nextProps.auth.user.data.message !== undefined
    ) {
      $("#add-subuser-modal").modal("hide");
      toast(nextProps.auth.user.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
  setFromDate = (date) => {
    this.setState({ assigned_date: date });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onUserAdd = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.email === "") {
      errors.email = "This field is required";
    } else {
      delete errors.email;
    }
    if (this.state.password === "") {
      errors.password = "This field is required";
    } else {
      delete errors.password;
    }
    if (this.state.password2 === "") {
      errors.password2 = "This field is required";
    } else if (this.state.password !== this.state.password2) {
      errors.password2 = "Password not match";
    } else {
      delete errors.password2;
    }
    if (this.state.name === "") {
      errors.name = "This field is required";
    } else {
      delete errors.name;
    }
    if (this.state.phone === "") {
      errors.phone = "This field is required";
    } else {
      delete errors.phone;
    }
    if (this.state.user_type === "") {
      errors.user_type = "This field is required";
    } else {
      delete errors.user_type;
    }
    if (isEmpty(errors)) {
      const newUser = {
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
        password2: this.state.password2,
        user_type: this.state.user_type,
        phone: this.state.phone,
        tabulaRx: true,
        parent_user: this.props.auth.user.final_user,
      };
      axios
        .post("/api/user-add", newUser)
        .then((res) => {
          if (res.data.success) {
            this.props.getData();
            $("#add-subuser-modal").modal("hide");
            this.setState({
              name: "",
              email: "",
              password: "",
              password2: "",
              first_name: "",
              last_name: "",
              phone: "",
              user_initials: "",
              user_type: "",
            });
            toast(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            const errorData = res.data
            delete errorData.success
            this.setState({ errors: errorData });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({ errors });
    }
  };

  toggleChange = (e) => {
    this.setState({ [e.target.id]: !this.state[e.target.id] });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="add-subuser-modal" data-reset="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add User</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.onUserAdd} id="add-subuser">
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Full Name</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.name}
                        id="name"
                        type="text"
                        error={errors.name}
                        className={classnames("form-control", {
                          invalid: errors.name,
                        })}
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="email">Email</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.email}
                        error={errors.email}
                        id="email"
                        type="email"
                        required
                        className={classnames("form-control", {
                          invalid: errors.email,
                        })}
                      />
                      <span className="text-danger">{errors.email}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Phone - required for OTP</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.phone}
                        id="phone"
                        type="text"
                        error={errors.phone}
                        className={classnames("form-control", {
                          invalid: errors.phone,
                        })}
                      />
                      <span className="text-danger">{errors.phone}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="password">Password</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        autoComplete={""}
                        onChange={this.onChange}
                        value={this.state.password}
                        error={errors.password}
                        id="password"
                        type="password"
                        className={classnames("form-control", {
                          invalid: errors.password,
                        })}
                      />
                      <span className="text-danger">{errors.password}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="password2">Confirm Password</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        autoComplete={""}
                        onChange={this.onChange}
                        value={this.state.password2}
                        id="password2"
                        type="password"
                        className={classnames("form-control", {
                          invalid: errors.password2,
                        })}
                      />
                      <span className="text-danger">{errors.password2}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="user_type">Type</label>
                    </div>
                    <div className="col-md-9">
                      <select
                        onChange={this.onChangeSelect}
                        id="user_type"
                        className="form-control"
                      >
                        <option value="">Please Select</option>
                        <option value="Admin">Admin</option>
                        <option value="Manager">Manager</option>
                        <option value="Technician">Technician</option>
                      </select>
                      <span className="text-danger">{errors.user_type}</span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="add-subuser"
                  type="submit"
                  className="btn btn-primary"
                >
                  Add User
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { addUser })(
  withRouter(SubUserAddModal)
);
