import React from "react";
import classnames from "classnames";
import axios from "axios";
import $ from "jquery";
import isEmpty from "is-empty";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class AddCompoud extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      // time: "",
      // temperture: "",
      rx: "",
      supplier: "",
      lot: "",
      ingre_name: "",
      expiry_date: "",
      qty: "",
      amt_used: "",
      formula: "",
      size: "",
      type: "",
      bud: "",
      comment: "",
      compound_name: "",
      rows: [],
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.record) {
    //     this.setState({
    //         ...nextProps.record
    //     })
    // }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.user !== undefined &&
      nextProps.auth.user.data !== undefined &&
      nextProps.auth.user.data.message !== undefined
    ) {
      $("#update-drug-modal").modal("hide");

      toast(nextProps.auth.user.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
  // componentDidMount() {
  //     $('#add-purchase-modal').modal('hide');
  // };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onDrugUpdate = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.date === "") {
      errors.date = "This field is required";
    } else {
      delete errors.date;
    }
    if (this.state.bud === "") {
      errors.bud = "This field is required";
    } else {
      delete errors.bud;
    }
    if (this.state.qty === "") {
      errors.qty = "This field is required";
    } else {
      delete errors.qty;
    }

    if (isEmpty(errors)) {
      const newDrug = {
        date: this.state.date,
        rx: this.state.rx,
        bud: this.state.bud,
        // amt_used: this.state.amt_used,
        qty: this.state.qty,
        ingrediants: this.state.rows,
        compound_name: this.state.compound_name,
        comment: this.state.comment,

        pharmacy_account: this.props.auth.user.final_user,
      };
      axios.post("/api/compound-add", newDrug).then(
        (response) => {
          this.props.getData();
          $("#add-compound-modal").modal("hide");
          this.setState({
            rx: "",
            date: "",
            bud: "",
            qty: "",
            comment: "",
            rows: [],
            compound_name: "",
          });
          // toast(response.data.message, {
          //     position: toast.POSITION.TOP_CENTER
          // });
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.setState({ errors });
    }

    //this.props.addUser(newUser, this.props.history);
  };
  handleDateChange = (date_to) => {
    this.setState({ date: date_to });
  };
  openDatepicker = (e) => {
    if (e.target.id === "toD") {
      this.toDatePicker.setOpen(true);
    }
  };
  handleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    // let a= rows[idx]
    // a[name]=value
    // rows[idx] = a
    rows[idx] = {
      ...rows[idx],
      [name]: value,
      //   [mobile]: value
    };

    this.setState({
      rows,
    });
  };
  handleAddRow = () => {
    const item = {
      supplier: "",
      amt_used: "",
      lot: "",
      expiry_date: "",
      formula: "",
      size: "",
      type: "",
      ingre_name: "",
    };

    this.setState({
      rows: [...this.state.rows, item],
    });
  };
  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, -1),
    });
  };
  render() {
    const { errors } = this.state;
    // console.log(this.state.rows);

    return (
      <div>
        <div className="modal fade" id="add-compound-modal" data-reset="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Compound Log</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <form noValidate onSubmit={this.onDrugUpdate} id="add-compound">
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="date">DATE</label>
                    </div>
                    <div className="col-md-9">
                      <DatePicker
                        selected={this.state.date}
                        onChange={this.handleDateChange}
                        // showTimeSelect
                        // dateFormat="Pp"
                        ref={(c) => (this.toDatePicker = c)}
                        className="form-control"
                      />
                      <i
                        aria-hidden="true"
                        className="fa fa-calendar"
                        id="toD"
                        onClick={this.openDatepicker}
                      ></i>
                      <span className="text-danger">{errors.date}</span>
                    </div>
                  </div>

                  {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="time">Time</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.time}
                                                id="time"
                                                type="text"
                                                error={errors.time}
                                                className={classnames("form-control", {
                                                    invalid: errors.time
                                                })} />
                                            <span className="text-danger">{errors.time}</span>
                                        </div>
                                    </div> */}
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="rx">Rx Number</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.rx}
                        id="rx"
                        type="text"
                        error={errors.rx}
                        className={classnames("form-control", {
                          invalid: errors.rx,
                        })}
                      />
                      <span className="text-danger">{errors.rx}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="compound_name">Compound Name</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.compound_name}
                        id="compound_name"
                        type="text"
                        error={errors.compound_name}
                        className={classnames("form-control", {
                          invalid: errors.compound_name,
                        })}
                      />
                      <span className="text-danger">
                        {errors.compound_name}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="qty">RX Quantity</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.qty}
                        id="qty"
                        type="text"
                        error={errors.qty}
                        className={classnames("form-control", {
                          invalid: errors.qty,
                        })}
                      />
                      <span className="text-danger">{errors.qty}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="bud">B.U.D in days</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.bud}
                        id="bud"
                        type="text"
                        error={errors.bud}
                        className={classnames("form-control", {
                          invalid: errors.bud,
                        })}
                      />
                      <span className="text-danger">{errors.bud}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="comment">Comments & Calculations</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.comment}
                        id="comment"
                        type="text"
                        error={errors.comment}
                        className={classnames("form-control", {
                          invalid: errors.comment,
                        })}
                      />
                      <span className="text-danger">{errors.comment}</span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="row clearfix">
                <div className="col-md-12 column">
                  <table
                    className="table table-bordered table-hover"
                    id="tab_logic"
                  >
                    <thead>
                      <tr>
                        <th className="text-left">Ingredient</th>
                        <th className="text-left">Amount Used</th>
                        <th className="text-left">Supplier</th>
                        <th className="text-left">Lot Number</th>
                        <th className="text-left">Exp Date</th>
                        {/* <th className="text-left">Quantity</th> */}
                        <th className="text-left">Formula #</th>
                        <th className="text-left">Package Size</th>
                        <th className="text-left">Form</th>
                        {/* <th className="text-left">B.U.D.</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.rows.map((item, idx) => (
                        <tr id="addr0" key={idx}>
                          <td>
                            <input
                              type="text"
                              name="ingre_name"
                              value={this.state.rows[idx].ingre_name}
                              onChange={this.handleChange(idx)}
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="amt_used"
                              value={this.state.rows[idx].amt_used}
                              onChange={this.handleChange(idx)}
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="supplier"
                              value={this.state.rows[idx].supplier}
                              onChange={this.handleChange(idx)}
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="lot"
                              value={this.state.rows[idx].lot}
                              onChange={this.handleChange(idx)}
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="expiry_date"
                              value={this.state.rows[idx].expiry_date}
                              onChange={this.handleChange(idx)}
                              className="form-control"
                              placeholder="MM/DD/YYYY"
                            />
                          </td>
                          {/* <td>
                                                        <input
                                                            type="text"
                                                            name="qty"
                                                            value={this.state.qty}
                                                            onChange={this.handleChange(idx)}
                                                            className="form-control"
                                                        />
                                                    </td> */}
                          <td>
                            <input
                              type="text"
                              name="formula"
                              value={this.state.rows[idx].formula}
                              onChange={this.handleChange(idx)}
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="size"
                              value={this.state.rows[idx].size}
                              onChange={this.handleChange(idx)}
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="type"
                              value={this.state.rows[idx].type}
                              onChange={this.handleChange(idx)}
                              className="form-control"
                            />
                          </td>
                          {/* <td>
                                                        <input
                                                            type="text"
                                                            name="bud"
                                                            value={this.state.rows[idx].bud}
                                                            onChange={this.handleChange(idx)}
                                                            className="form-control"
                                                        />
                                                    </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button
                    onClick={this.handleAddRow}
                    className="btn btn-success pull-left"
                    style={{ marginLeft: "20px" }}
                  >
                    Add Row
                  </button>
                  <button
                    onClick={this.handleRemoveRow}
                    className="pull-right btn btn-secondary"
                    style={{ marginRight: "20px" }}
                  >
                    Delete Row
                  </button>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="add-compound"
                  type="submit"
                  // data-toggle="modal" data-target="#add-drug-modal"
                  className="btn btn-primary"
                >
                  Add Compound log
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(AddCompoud);
