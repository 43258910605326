import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
class GroupFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredEmails: [],
      showGroup: false,
    };
  }

  filterGroup = () => {
    if (this.state.showGroup) {
      axios
        .post("/api/groups-filter", {
          type: this.props.type,
        })
        .then((res) => {
          if (res.status === 200) {
            let emails = res.data.emails;
            const filteredEmails = emails.map((each) => each._id);
            this.setState({ filteredEmails: filteredEmails });
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
      this.setState({ filteredEmails: [] });
    }
  };

  showGroupOnly = (e) => {
    const id = e.target.id;
    this.setState({ [e.target.id]: e.target.checked }, () =>
      this.filterGroup(id)
    );
  };

  render() {
    let groups = this.props.groups;
    if (this.state.filteredEmails.length) {
      groups = groups.filter((each) =>
        this.state.filteredEmails.includes(each.email)
      );
    }
    return (
      <>
        {this.props.auth.user.superadmin && groups.length ? (
          <div className="form-group col-md-4">
            <label>
              Group&nbsp;
              {this.props.hide_check !== true ? (
                <input
                  type="checkbox"
                  name="showGroup"
                  id="showGroup"
                  onChange={this.showGroupOnly}
                />
              ) : (
                ""
              )}
              {this.props.hide_check !== true ? (
                <span>(Show Email with records only)</span>
              ) : (
                ""
              )}
            </label>

            <select
              className="form-control"
              onChange={this.props.onChangeSelect}
              id="rx_group_associated"
            >
              <option key="group" value="">
                All
              </option>
              {groups.map((each) => {
                return (
                  <>
                    {each.rx_group !== "superadmin" &&
                      each.email !== undefined &&
                      each.tabulaRx === true && (
                        <option
                          {...(this.props.rx_group_associated ===
                            each.email && { selected: true })}
                          key={each.email}
                          value={each.email}
                        >
                          {each.name}-{each.email}
                        </option>
                      )}
                  </>
                );
              })}
            </select>
          </div>
        ) : null}
        {this.props.auth.user.multistore && this.props.multistoreData.length ? (
          <div className="form-group col-md-4">
            <label>Group</label>
            <select
              className="form-control"
              onChange={this.props.onChangeSelect}
              id="rx_group_associated"
            >
              <option key="group" value="">
                All
              </option>
              {this.props.multistoreData.map((each) => {
                return (
                  each.rx_group !== "superadmin" &&
                  each.email !== undefined && (
                    <option
                      {...(this.props.rx_group_associated === each.email && {
                        selected: true,
                      })}
                      key={each.email}
                      value={each.email}
                    >
                      {each.name}-{each.email}
                    </option>
                  )
                );
              })}
            </select>
          </div>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
  multistoreData: state.multistoreData,
});

export default connect(mapStateToProps)(GroupFilter);
