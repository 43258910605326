import React from "react";
import classnames from "classnames";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import isEmpty from "is-empty";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import XpedicareLinks from "./XpedicareLinks";

class XpedicareCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "_id", order: "desc" },
      },
      logo: undefined,
      rows: [],
      xpedicareRxcustom_page_name: undefined,
      xpedicareRxcustom_page_logo: undefined,
    };
  }

  componentDidMount() {
    let { filterData } = this.state;
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        this.setState({
          users: res.data.records[0],
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }
  setDate = (date, type) => {
    this.setState({ [type]: date });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onUserUpdate = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.name === "") {
      errors.name = "This field is required";
    } else {
      delete errors.name;
    }
    if (this.state.email === "") {
      errors.email = "This field is required";
    } else {
      delete errors.email;
    }
    if (this.state.first_name === "") {
      errors.first_name = "This field is required";
    } else {
      delete errors.first_name;
    }
    if (this.state.last_name === "") {
      errors.last_name = "This field is required";
    } else {
      delete errors.last_name;
    }
    if (this.state.zip === "") {
      errors.zip = "This field is required";
    } else {
      delete errors.zip;
    }
    if (this.state.phone === "") {
      errors.phone = "This field is required";
    } else {
      delete errors.phone;
    }
    if (isEmpty(errors)) {
      const formData = new FormData();
      formData.append("id", this.state.id);
      formData.append("name", this.state.name);
      formData.append("rx_bin", this.state.rx_bin);
      formData.append("rx_group", this.state.rx_group);
      formData.append("upline", this.state.upline);
      formData.append("first_name", this.state.first_name);
      formData.append("last_name", this.state.last_name);
      formData.append("street", this.state.street);
      formData.append("city", this.state.city);
      formData.append("state", this.state.state);
      formData.append("zip", this.state.zip);
      formData.append("website", this.state.website);
      formData.append("phone", this.state.phone);
      formData.append("fax", this.state.fax);
      formData.append("npi", this.state.npi);
      formData.append("rx_group_associated", this.state.rx_group_associated);
      formData.append("child", this.state.child);
      formData.append("multistore", this.state.multistore);
      formData.append(
        "xpedicareRxcustomArray",
        JSON.stringify(this.state.rows)
      );
      formData.append(
        "xpedicareRxcustom_page_name",
        this.state.xpedicareRxcustom_page_name
      );

      formData.append(
        "xpedicareRxcustom_page_logo",
        this.state.xpedicareRxcustom_page_logo
      );

      if (this.state.xpedicare_subscriptionId !== undefined) {
        formData.append(
          "xpedicare_subscriptionId",
          this.state.xpedicare_subscriptionId
        );
      }
      if (this.state.expiration_xpedicare !== undefined) {
        formData.append(
          "expiration_xpedicare",
          this.state.expiration_xpedicare
        );
      }

      formData.append(
        "software",
        this.state.software === "Others- Type Response"
          ? this.state.other_software
          : this.state.software
      );
      formData.append(
        "allow_dispensing_sheet",
        this.state.allow_dispensing_sheet
      );
      formData.append(
        "allow_dispensing_sheet_before",
        this.state.allow_dispensing_sheet_before
      );
      formData.append("rxminder_button_link", this.state.rxminder_button_link);
      formData.append("rxminder_button_text", this.state.rxminder_button_text);
      formData.append(
        "rxminder_button_link1",
        this.state.rxminder_button_link1
      );
      formData.append(
        "rxminder_button_text1",
        this.state.rxminder_button_text1
      );
      formData.append(
        "rxminder_button_link2",
        this.state.rxminder_button_link2
      );
      formData.append(
        "rxminder_button_text2",
        this.state.rxminder_button_text2
      );
      formData.append("custom_rxminder", this.state.custom_rxminder);
      formData.append("gdrive", this.state.gdrive);
      if (this.state.logo !== "") formData.append("logo", this.state.logo);

      axios
        .post("/api/user-update", formData, {})
        .then((res) => {
          this.props.getData();

          $("#update-user-modal").modal("hide");
        })

        .catch((err) => console.log(err));
    } else {
      this.setState({ errors });
    }
  };

  toggleChange = (e) => {
    this.setState({ [e.target.id]: !this.state[e.target.id] });
  };

  onLogoChange = (event) => {
    this.setState({
      [event.target.id]: event.target.files[0],
    });
  };
  handleChangeImage = (value, idx) => {
    const rows = [...this.state.rows];

    rows[idx] = {
      ...rows[idx],
      image: value,
    };

    this.setState({
      rows,
    });
  };

  handleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];

    rows[idx] = {
      ...rows[idx],
      [name]: value,
    };

    this.setState({
      rows,
    });
  };
  handleAddRow = () => {
    const item = {
      name: "",
      description: "",
      link: "",
    };

    this.setState({
      rows: [...this.state.rows, item],
    });
  };
  handleRemoveRow = (idx, e) => {
    this.setState({
      rows: this.state.rows.filter((v, i) => i !== idx),
    });
  };

  render() {
    const { errors } = this.state;
    console.log(this.state.users);
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">Xpedicare Update</h1>
            <button className="btn btn-warning">
              <FontAwesomeIcon icon={faBackward} />
              <Link to="/dashboard"> Go to Dashboard</Link>
            </button>
            <br></br>
            <br></br>
            <form
              noValidate
              onSubmit={this.onUserUpdate}
              id="update-user"
              encType="multipart/form-data"
            >
              <input
                onChange={this.onChange}
                value={this.state.id}
                id="user-update-id"
                type="text"
                className="d-none"
              />
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="xpedicareRxcustom_page_name">
                    Page Identifier(small letter)
                  </label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.onChange}
                    value={this.state.xpedicareRxcustom_page_name}
                    id="xpedicareRxcustom_page_name"
                    type="text"
                    error={errors.xpedicareRxcustom_page_name}
                    className={classnames("form-control", {
                      invalid: errors.xpedicareRxcustom_page_name,
                    })}
                  />
                  <span className="text-danger">
                    {errors.xpedicareRxcustom_page_name}
                  </span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="fax">Logo</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.onLogoChange}
                    id="logo"
                    type="file"
                    error={errors.logo}
                    className={classnames("form-control", {
                      invalid: errors.logo,
                    })}
                  />
                  <span className="text-danger">{errors.logo}</span>
                  {this.state.logo && <img src={this.state.logo} width={80} />}
                </div>
              </div>

              <div id="accordion">
                <XpedicareLinks
                  {...{
                    errors,
                    xpedicareRxcustom_page_name: this.state
                      .xpedicareRxcustom_page_name,
                    xpedicareRxcustom_page_logo: this.state
                      .xpedicareRxcustom_page_logo,
                    handleChange: this.handleChange,
                    handleChangeImage: this.handleChangeImage,
                    handleAddRow: this.handleAddRow,
                    handleRemoveRow: this.handleRemoveRow,
                    onChange: this.onChange,
                    rows: this.state.rows,
                  }}
                />
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    form="update-user"
                    type="submit"
                    className="btn btn-primary"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(withRouter(XpedicareCustom));
