import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "../../utils/ReactDatatable";
import { logoutUser } from "../../actions/authActions";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SubUserAddModal from "../partials/SubUserAddModal";
import SubUserUpdateModal from "../partials/SubUserUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { Collapse } from "react-collapse";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class SubUsers extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "name",
        text: "Name",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "email",
        text: "Email Address",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "phone",
        text: "Phone",
        className: "phone",
        align: "left",
        sortable: true,
      },
      {
        key: "user_type",
        text: "Type",
        className: "user_type",
        align: "left",
        sortable: true,
      },
    ];

    if (this.props.auth.user.superadmin) {
      this.columns.push({
        key: "parent_user",
        text: "Parent",
        className: "parent_user",
        align: "left",
        sortable: true,
      });
    }

    if (this.props.auth.user.user_type !== "Technician") {
      this.columns.push({
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <>
              {record.parent_user &&
              record.email !== this.props.auth.user.email ? (
                <Fragment>
                  <button
                    data-toggle="modal"
                    data-target="#update-subuser-modal"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.editRecord(record)}
                    style={{ marginRight: "5px" }}
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => this.deleteRecord(record)}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </Fragment>
              ) : (
                <></>
              )}
            </>
          );
        },
      });
    }

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "Users",
      no_data_text: "No user found!",
      button: {
        excel: false,
        print: false,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      toggleNav: true,
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "_id", order: "asc" },
      },
      currentRecord: {},
      first_name: undefined,
      last_name: undefined,
      user_initials: undefined,
      user_type: undefined,
      parent_user: undefined,
      loading: true,
      isFilterOpen: true,
      state: undefined,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.name !== "" && { name: this.state.name }),
      ...(this.state.parent_user !== "" && {
        parent_user: this.state.parent_user,
      }),
      ...(this.state.user_type !== "" && { user_type: this.state.user_type }),
      pharmacy_account: this.props.auth.user.final_user,
    };
    axios
      .post("/api/sub-user-data", filterData)
      .then((res) => {
        // let result = []
        // var a
        // for (let i = 0; i < res.data.records.length; i++) {
        //     if (res.data.records.is_true === true) {
        //         res.data.records[i].bonaRx = "Yes"
        //     }

        // }
        // const updateRecord = res.data.records.filter(each => each.email!==this.props.auth.user.email)
        res.data.userP[0].user_type = "Superadmin";
        this.setState({
          records: [...res.data.userP, ...res.data.records],
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  editRecord(record) {
    this.setState({ currentRecord: record });
  }

  deleteRecord(record) {
    confirmAlert({
      title: "Warning",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.finalDelete(record),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }
  finalDelete = (record) => {
    axios
      .post("/api/user-delete", { _id: record._id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getData();
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  resetSearch = () => {
    this.setState(
      {
        first_name: undefined,
        last_name: undefined,
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "_id", order: "asc" },
        },
      },
      () => this.getData()
    );
  };

  toggleChange = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  navBar = () => {
    this.setState({ toggleNav: !this.state.toggleNav });
  };

  exportToCSV = (from, to) => {
    let filterData = {
      page_number: 1,
      page_size: 2000,
      sort_order: { column: "_id", order: "asc" },
      search: {
        pharmacy_account: this.props.auth.user.final_user,
      },
    };
    axios
      .post("/api/sub-user-data", filterData)
      .then((res) => {
        toast.success("Dowloading...Please Wait!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        var headers = {
          email: "Email",
          name: "Name",
          phone: "Mobile",
          user_type: "Type",
          parent_user: "Parent User",
          date: "Date",
        };
        // // add columns in sheet array
        // for (let column of this.props.columns) {
        //     headers[column.key] = '"' + column.text + '"';
        // }
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false });
        for (let i in res.data.records) {
          let record = res.data.records[i];

          // record.name = '"' + record.name + '"'
          // record.street = '"' + record.street + '"'

          delete record.id;
          delete record.street;
          delete record.__v;
          delete record._id;
          if (record.date !== undefined || record.data !== "") {
            record.date = moment(record.date).format("L");
          }
          records.push(record);
        }
        if (headers) {
          records.unshift(headers);
        }
        // Convert Object to JSON
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);

    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : array[i][keys[index]] + ",";
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }

  showError = () => {
    alert("You cannot add more than 3 subusers. If you want to add more then please connect to Administator")
  }

  render() {
    console.log(this.state.records)
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/subscribe";
    }
    return (
      <div className="d-flex subuser-list" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}

        {!this.props.auth.user.superadmin && (
          <SubUserAddModal getData={this.getData} />
        )}
        <SubUserUpdateModal
          record={this.state.currentRecord}
          getData={this.getData}
        />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            {!this.props.auth.user.superadmin &&
              this.props.auth.user.user_type !== "Technician" && (
                <>
                  {this.state.records.length > 3 ? (
                    <button
                      className="btn btn-primary float-right mt-3 mr-2 a"
                      onClick={() => this.showError()}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add User
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary float-right mt-3 mr-2 a"
                      data-toggle="modal"
                      data-target="#add-subuser-modal"
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add User
                    </button>
                  )}
                </>
              )}
            <h1 className="mt-2 text-primary">My Users List</h1>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={this.state.isFilterOpen}
                  onClick={this.toggleChange}
                  id="isFilterOpen"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Show Filter
                </label>
              </div>
              <Collapse isOpened={this.state.isFilterOpen}>
                <form noValidate onSubmit={this.onSearch}>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="name">Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.name}
                        id="name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    {this.props.auth.user.superadmin && (
                      <div className="form-group col-md-3">
                        <label htmlFor="parent_user">Parent user</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.parent_user}
                          id="parent_user"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    )}
                    <div className="form-group col-md-3">
                      <label htmlFor="user_type">User Type</label>
                      <select
                        onChange={this.onChangeSelect}
                        id="user_type"
                        className="form-control"
                      >
                        <option value="">Please Select</option>
                        <option value="Admin">Admin</option>
                        <option value="Manager">Manager</option>
                        <option value="Technician">Technician</option>
                      </select>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Filter
                  </button>
                  <button
                    type="reset"
                    onClick={this.resetSearch}
                    className="btn btn-secordry"
                  >
                    Reset
                  </button>
                </form>
              </Collapse>
            </div>
            <br></br>

            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
              exportToCSV={this.exportToCSV}
              exportToPDF={this.exportToPDF}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps, { logoutUser })(SubUsers);
