import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { connect } from "react-redux";
import $ from 'jquery';
import isEmpty from 'is-empty';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class ReverseUpdateModal extends React.Component {

    constructor() {
        super();
        this.state = {
            dispense_date: "",
            ndc: "",
            drug_name: "",
            reverse_distribution: "",
            qty_dispensed: "",
            return_number: "",
            reason: "",
            showReturn: false,
            other_reason: "",
            errors: {},
            invoiceimage: "",
            lot: "",
            expiration_date: "",
        };
    }

    onChange = e => {
        const currentId = e.target.id
        this.setState({ [e.target.id]: e.target.value },
            () => this.checkNdc(currentId));
    };

    checkNdc = (currentId) => {
        if (currentId === "reason") {
            if (this.state.reason === "Others") {
                this.setState({
                    showReturn: true
                })
            } else {
                this.setState({
                    showReturn: false
                })
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record.reason !== "Expired" && nextProps.record.reason !== "Overstock" && nextProps.record.reason !== "Damaged" && nextProps.record.reason !== undefined) {
            nextProps.record.showReturn = true
            nextProps.record.other_reason = nextProps.record.reason
        }
        if (nextProps.record) {
            this.setState({
                ...nextProps.record,
                dispense_date: moment(nextProps.record.orig_dispense_date).toDate(),
                expiration_date: moment(nextProps.record.expiration_date).toDate()
            });
        }
    }

    onReportAdd = e => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.dispense_date === '') {
            errors.dispense_date = "This field is required"
        } else {
            delete errors.dispense_date
        }
        // if (this.state.ndc === '') {
        //     errors.ndc = "This field is required"
        // } else {
        //     delete errors.ndc
        // }
        if (this.state.qty_dispensed === '') {
            errors.qty_dispensed = "This field is required"
        } else if (isNaN(this.state.qty_dispensed)) {
            errors.qty_dispensed = "This field should be number"
        } else {
            delete errors.qty_dispensed
        }
        if (this.state.reverse_distribution === '') {
            errors.reverse_distribution = "This field is required"
        } else {
            delete errors.reverse_distribution
        }
        if (isEmpty(errors)) {
            this.setState({ errors })
            const formData = new FormData();
            if (this.state.invoiceimage !== "")
                formData.append("invoiceimage", this.state.invoiceimage);
            if (this.state.dispense_date !== "")
                formData.append("dispense_date", this.state.dispense_date);
            if (this.state.ndc !== "")
                formData.append("ndc", this.state.ndc);
            if (this.state.drug_name !== "")
                formData.append("drug_name", this.state.drug_name);
            if (this.state.dea !== "")
                formData.append("dea", this.state.dea);
            if (this.state.qty_dispensed !== "")
                formData.append("qty_dispensed", this.state.qty_dispensed);
            if (this.state.reverse_distribution !== "")
                formData.append("reverse_distribution", this.state.reverse_distribution);
            if (this.state.reason !== "")
                formData.append("reason", this.state.reason === "Others" ? this.state.other_reason : this.state.reason);
            if (this.state.return_number !== "")
                formData.append("return_number", this.state.return_number);
            if (this.state.lot !== "")
                formData.append("lot", this.state.lot);
            if (this.state.expiration_date !== "")
                formData.append("expiration_date", this.state.expiration_date);
            formData.append("_id", this.state._id);

            axios
                .post("/api/reverse-update", formData, {})
                .then(res => {
                    this.props.getData();
                    $('#update-reverse-modal').modal('hide');
                    this.setState({
                        dispense_date: "",
                        // ndc: "",
                        // drug_name: "",
                        reverse_distribution: "",
                        qty_dispensed: "",
                        reason: "",
                        return_number: "",
                        showReturn: false,
                        other_reason: "",
                        invoiceimage: "",
                        lot: "",
                        expiration_date: "",
                    })
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }).catch(err =>
                    console.log(err)
                )
        } else {
            this.setState({ errors })
        }
    };

    setExpirationDate = (date) => {
        this.setState({ expiration_date: date });
    }

    setReportDate = (date) => {
        if (moment().diff(date, 'days') > 14) {
            confirmAlert({
                title: 'Confirmation Required',
                message: 'You entered the date as ' + moment(date).add(5, 'hours').format('L') + '. Are you sure?',
                overlayClassName: "entereddate",
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => this.setState({ dispense_date: date })
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ dispense_date: date });
        }
    }

    openDatepicker = (e) => {
        this.toDatePicker.setOpen(true);
    }

    handleOnSelect = (item) => {
        // the item selected
        this.setState({
            ndc: item.ndc,
            drug_name: item.name
        })
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-reverse-modal" data-reset="false">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Return Record</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onReportAdd} id="update-reverse" encType="multipart/form-data">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <DatePicker maxDate={moment().toDate()} selected={this.state.dispense_date} placeholderText="MM/DD/YYYY" onChange={(date) => this.setReportDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                            <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                            <span className="text-danger">{errors.dispense_date}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="reverse_distribution">Reverse Distribution</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.reverse_distribution}
                                                error={errors.reverse_distribution}
                                                id="reverse_distribution"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.reverse_distribution
                                                })}
                                            />
                                            <span className="text-danger">{errors.reverse_distribution}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="qty_dispensed">Quantity Returned</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.qty_dispensed}
                                                id="qty_dispensed"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.qty_dispensed
                                                })}
                                            />
                                            <span className="text-danger">{errors.qty_dispensed}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="expiration_date1">Expiration Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <DatePicker selected={this.state.expiration_date} placeholderText="MM/DD/YYYY" onChange={(date) => this.setExpirationDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} disabled={this.state.drug_name === '' ? true : false} />
                                            <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                            <span className="text-danger">{errors.expiration_date}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="lot">Lot Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.lot}
                                                id="lot"
                                                type="text"
                                                disabled={this.state.drug_name === '' ? true : false}
                                                error={errors.lot}
                                                className={classnames("form-control", {
                                                    invalid: errors.lot
                                                })} />
                                            <span className="text-danger">{errors.lot}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="return_number">Return Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.return_number}
                                                id="return_number"
                                                type="text"
                                                disabled={this.state.drug_name === '' ? true : false}

                                                className={classnames("form-control", {
                                                    invalid: errors.return_number
                                                })}
                                            />
                                            <span className="text-danger">{errors.return_number}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="dea">Reason</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} value={this.state.reason} error={errors.reason} id="reason" className={classnames("form-control", { invalid: errors.reason })}>
                                                <option>Select Reason</option>
                                                <option value="Expired" selected={this.state.reason === "Expired"}>Expired</option>
                                                <option value="Overstock" selected={this.state.reason === "Overstock"}>Overstock</option>
                                                <option value="Damaged" selected={this.state.reason === "Damaged"}>Damaged</option>
                                                <option value="Others" selected={this.state.showReturn}>Others</option>
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.showReturn && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="other_reason">Other Reason</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.other_reason}
                                                id="other_reason"
                                                type="text"
                                                disabled={this.state.drug_name === '' ? true : false}

                                                className={classnames("form-control", {
                                                    invalid: errors.other_reason
                                                })}
                                            />
                                        </div>
                                    </div>}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="invoice_number">Invoice Upload</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onImageChange}
                                                // value={this.state.invoiceimage}
                                                id="invoiceimage"
                                                type="file"
                                                disabled={this.state.drug_name === '' ? true : false}

                                                error={errors.invoiceimage}
                                                className={classnames("form-control", {
                                                    invalid: errors.invoiceimage
                                                })} />
                                            <span className="text-danger">{errors.invoiceimage}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-reverse"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps
)(ReverseUpdateModal);
