import React, { Fragment } from 'react'
import { logoutUser } from "../../actions/authActions";
import { connect } from "react-redux";
import { updateUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toggle from 'react-toggle'
class Notifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            notifications: [],
            templates: [],
            errors: {},
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "date", order: "desc" }
            },
        };
    }

    componentDidMount() {
        this.getTemplates()
    };

    getData = () => {
        this.setState({ loading: true })
        let filterData = {}
        filterData.search = {
            _id: this.props.auth.user.id,
        }
        axios
            .post("/groupEmail/notifications", filterData)
            .then(res => {
                let result = res.data.records[0]
                let notificationArr = []
                this.state.templates.map(eachTemplate => {
                    let found = (result.notifications || []).find(each => each === eachTemplate._id)
                    console.log("found",found)
                    if (found === undefined) {
                        notificationArr.push(eachTemplate._id)
                    }
                })
                this.setState({
                    notifications: notificationArr,
                    loading: false
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })
    }

    getTemplates = () => {
        let filterData = this.state.filterData
        filterData.search = {}
        axios
            .post("/groupEmail/get-emailTemplates", filterData)
            .then(res => {
                this.setState({
                    templates: res.data.records,
                    loading: false,
                }, this.getData())
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onUserUpdate = e => {
        e.preventDefault();
        let finalArr = []
        this.state.templates.map(eachTemplate => {
            if(!this.state.notifications.includes(eachTemplate._id)) {
                finalArr.push(eachTemplate._id)
            }
        })
        const postData = {
            notifications: finalArr,
            pharmacy_account: this.props.auth.user.final_user,

        };
        axios

            .post("/groupEmail/notification-update", postData)
            .then(res => {
                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                this.getData()
            }).catch(err =>
                console.log(err)
            )
    }



    toggleChange = (id) => {
        if (this.state.notifications.includes(id)) {
            this.setState({ notifications: this.state.notifications.filter(each => each !== id) });
        } else {
            this.setState({ notifications: [...this.state.notifications, id] });
        }

    }

    render() {
        console.log(this.state.notifications)
        if ((this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula === undefined || this.props.auth.user.expiration_tabula < moment().format()) && this.props.auth.user.superadmin !== true) {
            window.location.href = ("/choose-plan?solution=TabulaRx");
        }
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid m-2">
                        <h1 className="mt-2 text-primary">Reminder Notifications</h1>
                        <form noValidate onSubmit={this.onUserUpdate} id="update-user">
                            <div className="row m-2">
                                {(this.state.templates || []).map((eachTemplate,i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div className="col-md-6 p-2">
                                                <label htmlFor="name">{eachTemplate.name}</label>
                                            </div>
                                            <div className="col-md-6 p-2">
                                                <Toggle
                                                    checked={this.state.notifications.includes(eachTemplate._id)}
                                                    icons={false}
                                                    onChange={() => this.toggleChange(eachTemplate._id)} />
                                            </div>
                                        </Fragment>
                                    )
                                })}
                            </div>
                            <button
                                form="update-user"
                                type="submit"
                                className="btn btn-primary">
                                Update
                            </button>
                        </form>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateUser, logoutUser }
)(withRouter(Notifications));
