import React from "react";
import { Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Chart from "react-google-charts";
import { Link } from "react-router-dom";

const Control = (props) => {
  return (
    <div className="row px-2 dashboard-controls">
      <Container>
        <Row>
          <div className="col-sm-12 p-sm-3">
            <div className="card ">
              <div className="card-body dashboard">
                <Row>
                  <div className="col-sm-4 p-sm-2">
                    <div
                      className="card text-white "
                      style={{ backgroundColor: "rgb(119 0 169)" }}
                    >
                      <Link to="/coupons">
                        <div className="card-body">
                          <h3 className="card-title"> Copay Cards Lookup</h3>

                          <h3 className="card-text">
                            Records: {props.couponCount}
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-4 p-sm-2">
                    <div
                      className="card text-white "
                      style={{ backgroundColor: "#9C59B8" }}
                    >
                      <Link to="/drugs">
                        <div className="card-body">
                          <h3 className="card-title">Drug Database</h3>
                          <h3 className="card-text">
                            Records: {props.drugTotalCount}
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-4 p-sm-2">
                    <div
                      className="card text-white "
                      style={{ backgroundColor: "rgb(182 118 209)" }}
                    >
                      <Link to="/mme-calculator">
                        <div className="card-body">
                          <h3 className="card-title">MME Calculator</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-4 p-sm-2">
                    <div
                      className="card text-white "
                      style={{ backgroundColor: "rgb(0 118 197)" }}
                    >
                      <Link to="/prescriber-rx">
                        <div className="card-body">
                          <h3 className="card-title">PrescriberRx™</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-4 p-sm-2">
                    <div
                      className="card text-white "
                      style={{ backgroundColor: "#2C97DE" }}
                    >
                      <Link to="/npis">
                        <div className="card-body">
                          <h3 className="card-title">Provider Database</h3>
                          <h3 className="card-text">
                            Records: {props.drugNpiCount}
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-4 p-sm-2">
                    <div
                      className="card text-white "
                      style={{ backgroundColor: "rgb(81 172 233)" }}
                    >
                      <Link to="/internal-physician">
                        <div className="card-body">
                          <h3 className="card-title">Provider Emails</h3>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="col-sm-4 p-sm-2">
                    <div
                      className="card text-white "
                      style={{ backgroundColor: "rgb(235 115 24)" }}
                    >
                      <Link to="/fridge-log">
                        <div className="card-body">
                          <h3 className="card-title">Refrigerator Temp Log</h3>
                          <p className="card-text text-120">
                            Last Updated: {props.refridgeratorLast}
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-4 p-sm-2">
                    <div
                      className="card text-white "
                      style={{ backgroundColor: "#E87F30" }}
                    >
                      <Link to="/rxarea-log">
                        <div className="card-body">
                          <h3 className="card-title">Area Temperature Log</h3>
                          <p className="card-text text-120">
                            Last Updated: {props.rxAreaLast}
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-4 p-sm-2">
                    <div
                      className="card text-white "
                      style={{ backgroundColor: "rgb(235 155 94)" }}
                    >
                      <a
                        target="_blank"
                        href="https://www.ehealthmedicareplans.com/ehi/medicare/muse-v2?type=MA&allid=rxr14507000&partnerAllid=true&planYear=2022#/quote-plans/MA/32250/10150/DUVAL/ssd104119847/1/p1/f2:drugs"
                      >
                        <div className="card-body">
                          <h3 className="card-title">
                            Medicare Part D AEP Enrollment
                          </h3>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-sm-4 p-sm-2">
                    <div
                      className="card text-white "
                      style={{ backgroundColor: "red" }}
                    >
                      <Link to="/purchase-delete-request">
                        <div className="card-body">
                          <h3 className="card-title">
                            Pending Purchase Deletes
                          </h3>
                          <h3 className="card-text">
                            Records: {props.pendingPurchase}
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-4 p-sm-2">
                    <div
                      className="card text-white "
                      style={{ backgroundColor: "red" }}
                    >
                      <Link to="/dispense-delete-request">
                        <div className="card-body">
                          <h3 className="card-title">
                            Pending Dispense Deletes
                          </h3>
                          <h3 className="card-text">
                            Records: {props.pendingDispense}
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-4 p-sm-2">
                    <div
                      className="card text-white "
                      style={{ backgroundColor: "rgb(235 155 94)" }}
                    >
                      <a target="_blank" href="https://www.rxreach.com/lookup">
                        <div className="card-body">
                          <h3 className="card-title">
                            Medicare Part D Formulary Lookup
                          </h3>
                        </div>
                      </a>
                    </div>
                  </div>
                  {/* <div className="form-group col-md-3  mr-4 mx-4 text-center py-2 px-4 font-weight-bold text-white bg-danger">
                                        {"Number of Patients MME >90: "}{mmeHigh}
                                    </div> */}
                </Row>
              </div>
            </div>
          </div>
          <div className="row">
            {props.claimArr.length > 1 ? (
              <div className="col-sm-4 p-sm-3">
                <div className="card ">
                  <div className="card-body">
                    <h5 className="left">Count by Age Group</h5>
                    <h5 className="right">Total: {props.claimArrCount} </h5>
                    <div>
                      <Chart
                        width={"100%"}
                        height={"300px"}
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={props.claimArr}
                        options={{
                          // Material design options
                          chart: {
                            title: "Rx Count by Age Group",
                          },
                          bubble: {
                            textStyle: {
                              fontSize: 8,
                            },
                          },
                          legend: { position: "none" },
                        }}
                        // For tests
                        rootProps={{ "data-testid": "2" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {props.dispenseArr.length ? (
              <div className="col-sm-4 p-sm-3">
                <div className="card ">
                  <div className="card-body">
                    <h5 className="left">Dispensed (Class 2 & 2N)</h5>
                    <h5 className="right">Total: {props.drugCount}</h5>
                    <div>
                      {props.dispenseArr.length < 2 ? (
                        <p>No data to display!</p>
                      ) : (
                        <Chart
                          width={"100%"}
                          height={"300px"}
                          chartType="PieChart"
                          loader={<div>Loading Chart</div>}
                          data={props.dispenseArr}
                          options={{
                            title: "",
                            slices: {
                              1: { color: "#F75851" },
                              0: { color: "#3B7CB5" },
                            },
                            is3D: true,
                            legend: "none",
                            pieSliceText: "label",
                          }}
                          rootProps={{ "data-testid": "1" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {props.dispenseArr2.length ? (
              <div className="col-sm-4 p-sm-3">
                <div className="card ">
                  <div className="card-body">
                    <h5 className="left">Dispensed (Class 3, 3N, 4, 5)</h5>
                    <h5 className="right">Total: {props.drugCount2}</h5>
                    <div>
                      {props.dispenseArr2.length < 2 ? (
                        <p>No data to display!</p>
                      ) : (
                        <Chart
                          width={"100%"}
                          height={"300px"}
                          chartType="PieChart"
                          loader={<div>Loading Chart</div>}
                          data={props.dispenseArr2}
                          options={{
                            title: "",
                            slices: {
                              1: { color: "#F75851" },
                              0: { color: "#3B7CB5" },
                            },
                            is3D: true,
                            legend: "none",
                            pieSliceText: "label",
                          }}
                          rootProps={{ "data-testid": "1" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {props.cashArr.length ? (
              <div className="col-sm-4 p-sm-3">
                <div className="card ">
                  <div className="card-body">
                    <h5 className="">Cash Sale</h5>
                    <div>
                      {props.cashArr[1][1] === 0 &&
                      props.cashArr[2][1] === 0 ? (
                        <p>No data to display!</p>
                      ) : (
                        <Chart
                          width={"100%"}
                          height={"300px"}
                          chartType="PieChart"
                          loader={<div>Loading Chart</div>}
                          data={props.cashArr}
                          options={{
                            title: "",
                            slices: {
                              1: { color: "#F75851" },
                              0: { color: "#3B7CB5" },
                            },
                            is3D: true,
                            legend: "none",
                          }}
                          rootProps={{ "data-testid": "1" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {props.purchaseArr.length ? (
              <div className="col-sm-4 p-sm-3">
                <div className="card ">
                  <div className="card-body">
                    <h5 className="left">Purchase (Class 2 & 2N)</h5>
                    <h5 className="right">Total: {props.purchaseCount}</h5>
                    <div>
                      {props.purchaseArr.length < 2 ? (
                        <p>No data to display!</p>
                      ) : (
                        <Chart
                          width={"100%"}
                          height={"300px"}
                          chartType="PieChart"
                          loader={<div>Loading Chart</div>}
                          data={props.purchaseArr}
                          options={{
                            title: "",
                            slices: {
                              1: { color: "#F75851" },
                              0: { color: "#3B7CB5" },
                            },
                            is3D: true,
                            legend: "none",
                            pieSliceText: "label",
                          }}
                          rootProps={{ "data-testid": "1" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {props.purchaseArr2.length ? (
              <div className="col-sm-4 p-sm-3">
                <div className="card ">
                  <div className="card-body">
                    <h5 className="left">Purchase (Class 3, 3N, 4, 5)</h5>
                    <h5 className="right">Total: {props.purchaseCount2}</h5>
                    <div>
                      {props.purchaseArr2.length < 2 ? (
                        <p>No data to display!</p>
                      ) : (
                        <Chart
                          width={"100%"}
                          height={"300px"}
                          chartType="PieChart"
                          loader={<div>Loading Chart</div>}
                          data={props.purchaseArr2}
                          options={{
                            title: "",
                            slices: {
                              1: { color: "#F75851" },
                              0: { color: "#3B7CB5" },
                            },
                            is3D: true,
                            legend: "none",
                            pieSliceText: "label",
                          }}
                          rootProps={{ "data-testid": "1" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {props.ahfsArr.length ? (
              <div className="col-sm-4 p-sm-3">
                <div className="card ">
                  <div className="card-body">
                    <h5 className="">Therapeutic Class</h5>
                    <div>
                      {props.ahfsArr.length < 2 ? (
                        <p>No data to display!</p>
                      ) : (
                        <Chart
                          width={"100%"}
                          height={"300px"}
                          chartType="PieChart"
                          loader={<div>Loading Chart</div>}
                          data={props.ahfsArr}
                          options={{
                            title: "",
                            slices: {
                              1: { color: "#F75851" },
                              0: { color: "#3B7CB5" },
                            },
                            is3D: true,
                            legend: "none",
                          }}
                          rootProps={{ "data-testid": "1" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {props.trendArr.length ? (
              <div className="col-sm-4 p-sm-3">
                <div className="card ">
                  <div className="card-body">
                    <h5 className="">Daily Trends</h5>
                    <div>
                      {props.trendArr.length < 2 ? (
                        <p>No data to display!</p>
                      ) : (
                        <Chart
                          width={"100%"}
                          height={"300px"}
                          chartType="Bar"
                          loader={<div>Loading Chart</div>}
                          data={props.trendArr}
                          options={{
                            chart: {
                              title: "",
                            },
                            legend: { position: "none" },
                          }}
                          rootProps={{ "data-testid": "1" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {props.MMEArr.length ? (
              <div className="col-sm-4 p-sm-3">
                <div className="card ">
                  <div className="card-body">
                    <h5 className="">MME Report</h5>
                    <div>
                      {props.MMEArr[1] &&
                      props.MMEArr[1][1] === 0 &&
                      props.MMEArr[3] &&
                      props.MMEArr[3][1] === 0 ? (
                        <p>No data to display!</p>
                      ) : (
                        <Chart
                          width={"100%"}
                          height={"300px"}
                          chartType="PieChart"
                          loader={<div>Loading Chart</div>}
                          data={props.MMEArr}
                          options={{
                            title: "",
                            slices: {
                              0: { color: "#ff0000" },
                              1: { color: "#c37e00" },
                              2: { color: "#008000" },
                            },
                            is3D: true,
                            legend: `<div className="float-right info" style={{ fontSize: "16px", marginTop: "20px" }}>
                                                    <div className="color-red"><FontAwesomeIcon icon={faCheckCircle} /> {"Red – MME >90"}</div>
                                                    <div className="color-orange"><FontAwesomeIcon icon={faCheckCircle} /> {"Orange – MME 60-90"}</div>
                                                    <div className="color-green"><FontAwesomeIcon icon={faCheckCircle} />Green - <60</div>
                                                </div>`,
                            pieSliceText: "label",
                          }}
                          rootProps={{ "data-testid": "1" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default Control;
