import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../utils/RxReach.png";
import rxr from "../../utils/rxr.jpg";

class Rxreach extends React.Component {
  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title m-3">
                <img src={logo} height={60} />
                <div className="card">
                  <div className="card-body">
                    <h1
                      className="card-title text-primary"
                      style={{ fontWeight: "bold", fontSize: "47px" }}
                    >
                      Automated Patient Communication Software to Help Improve
                      Engagement
                    </h1>
                    <div className="row">
                      <div className="col-md-8">
                        <p className="card-text" style={{ fontSize: "24px" }}>
                          RxReach’s outbound patient communications system helps
                          your pharmacy improve patient engagement and
                          streamline workflow through automated communications.
                          Our software provides real-time relevant messaging
                          that helps increase prescription sales, driving
                          profitability and patient satisfaction.<br></br>
                          <br></br>
                          RxReach is your complete custom communication platform
                          that includes SMS, MMS, Voice, Fax and email broadcast
                          for growing pharmacy with no monthly or setup fees.
                          Pay only for what you use as low as 5 Cents.
                        </p>
                      </div>
                      <div className="col-md-4" style={{ textAlign: "center" }}>
                        <img style={{ width: "100%" }} src={rxr} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p className="card-text" style={{ fontSize: "24px" }}>
                          <b>First time user? You must register</b>
                          <br></br>
                          <button
                            type="button"
                            style={{ marginTop: "10px" }}
                            className="btn btn-success btn-lg"
                          >
                            <a
                              href="https://admin.rxreach.com/account"
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              LOGIN/REGISTER
                            </a>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rxreach;
