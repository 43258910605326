import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { connect } from "react-redux";
import $ from 'jquery';
import isEmpty from 'is-empty';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from "moment"


class PurchaseUpdateModal extends React.Component {

    constructor() {
        super();
        this.state = {
            purchase_date: "",
            expiration_date: "",
            lot: "",
            ndc: "",
            drug_name: "",
            package_size: "",
            qty_received: "",
            form_number: "",
            invoice_number: "",
            supplier: "",
            notes: "",
            errors: {},
            invoiceimage: "",
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                ...nextProps.record,
                purchase_date: moment(nextProps.record.purchase_date).toDate(),
                expiration_date: nextProps.record.expiration_date!==""?moment(nextProps.record.expiration_date).toDate():""
            });
        }
        // if (nextProps.auth !== undefined
        //     && nextProps.auth.user !== undefined
        //     && nextProps.auth.user.data !== undefined
        //     && nextProps.auth.user.data.message !== undefined) {
        //     $('#update-report-modal').modal('hide');
        //     toast(nextProps.auth.user.data.message, {
        //         position: toast.POSITION.TOP_CENTER
        //     });
        // }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onReportUpdate = e => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.purchase_date === '') {
            errors.purchase_date = "This field is required"
        } else {
            delete errors.purchase_date
        }
        if (this.state.ndc === '') {
            errors.ndc = "This field is required"
        } else {
            delete errors.ndc
        }
        if (this.state.package_size === '') {
            errors.package_size = "This field is required"
        } else if (isNaN(this.state.package_size)) {
            errors.package_size = "This field should be number"
        } else if (parseInt(this.state.package_size)>1000) {
            errors.package_size = "Should not more than 1000"
        }else {
            delete errors.package_size
        }
        if (this.state.qty_received === '') {
            errors.qty_received = "This field is required"
        } else if (parseInt(this.state.qty_received)>99) {
            errors.qty_received = "Should not more than 99"
        } else if (isNaN(this.state.qty_received)) {
            errors.qty_received = "This field should be number"
        } else {
            delete errors.qty_received
        }
        if (this.state.form_number === '') {
            errors.form_number = "This field is required"
        } else {
            delete errors.form_number
        }
        if (this.state.invoice_number === '') {
            errors.invoice_number = "This field is required"
        } else {
            delete errors.invoice_number
        }
        if (this.state.supplier === '') {
            errors.supplier = "This field is required"
        } else {
            delete errors.supplier
        }
        if (isEmpty(errors)) {
            this.setState({ errors })
            // const newWaiver = {
            //     _id: this.state._id,
            //     purchase_date: this.state.purchase_date,
            //     expiration_date: this.state.expiration_date,
            //     lot: this.state.lot,
            //     ndc: this.state.ndc,
            //     drug_name: this.state.drug_name,
            //     package_size: this.state.package_size,
            //     total_qty: parseInt(this.state.qty_received)*parseInt(this.state.package_size),
            //     qty_received: this.state.qty_received,
            //     form_number: this.state.form_number,
            //     invoice_number: this.state.invoice_number,
            //     supplier: this.state.supplier,
            //     notes: this.state.notes,
            //     user: this.props.auth.user.id
            // };
            const formData = new FormData();
            if (this.state.invoiceimage !== "")
                formData.append("invoiceimage", this.state.invoiceimage);
            if (this.state.purchase_date !== "")
                formData.append("purchase_date", this.state.purchase_date);
            if (this.state.expiration_date !== "")
                formData.append("expiration_date", this.state.expiration_date);
            if (this.state.lot !== "")
                formData.append("lot", this.state.lot);
            if (this.state.ndc !== "")
                formData.append("ndc", this.state.ndc);
            if (this.state.drug_name !== "")
                formData.append("drug_name", this.state.drug_name);
            if (this.state.dea !== "")
                formData.append("dea", this.state.dea);
            if (this.state.package_size !== "")
                formData.append("package_size", this.state.package_size);
            if (this.state.qty_received !== "")
                formData.append("qty_received", this.state.qty_received);

            if (this.state.form_number !== "")
                formData.append("form_number", this.state.form_number);
            if (this.state.invoice_number !== "")
                formData.append("invoice_number", this.state.invoice_number);
            if (this.state.supplier !== "")
                formData.append("supplier", this.state.supplier);
            formData.append("total_qty", parseInt(this.state.qty_received) * parseInt(this.state.package_size));
            formData.append("pharmacy_account", this.props.auth.user.final_user);
            formData.append("user_created", this.props.auth.user.email);
            formData.append("_id", this.state._id);
            axios
                .post("/api/purchase-update", formData,{})
                .then(res => {
                    this.props.getData();
                    $('#update-purchase-modal').modal('hide');
                    this.setState({
                        purchase_date: "",
                        ndc: "",
                        drug_name: "",
                        package_size: "",
                        qty_received: "",
                        form_number: "",
                        invoice_number: "",
                        supplier: "",
                        notes: "",
                        lot: "",
                        expiration_date: ""
                    })
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }).catch(err =>
                    console.log(err)
                )
        } else {
            this.setState({ errors })
        }
    };

    onImageChange = event => {
        this.setState({
            [event.target.id]: event.target.files[0],
        });
    };

    setReportDate = (date) => {
        if(moment().diff(date, 'days')>14){
            confirmAlert({
                title: 'Confirmation Required',
                message: 'You entered the date as '+moment(date).add(5, 'hours').format('L')+'. Are you sure?',
                overlayClassName: "entereddate",
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => this.setState({ dispense_date: date })
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        }else{
            this.setState({ purchase_date: date });
        }
    }

    openDatepicker = (e) => {
        this.toDatePicker.setOpen(true);
    }
    
    setExpirationDate = (date) => {
        this.setState({ expiration_date: date });
    }

    handleOnSelect = (item) => {
        // the item selected
        this.setState({
            ndc: item.ndc,
            drug_name: item.name
        })
    }
    formatResult = (item) => {
        return item
        // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
    }

    render() {
        const { errors } = this.state;
        
        return (
            <div>
                <div className="modal fade" id="update-purchase-modal" data-reset="false">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                {/* <h4 className="modal-title">Update Purchase</h4> */}
                                <h5 className="modal-title">NDC: {this.state.ndc}</h5><br />
                                <h5 className="float-right-padding">Name: {this.state.drug_name}</h5>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onReportUpdate} id="add-report" encType="multipart/form-data">
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="searchDrug">Search by Name or NDC or UPC code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <ReactSearchAutocomplete
                                                items={this.props.ncDrugs}
                                                onSelect={this.handleOnSelect}
                                                autoFocus
                                                formatResult={this.formatResult}
                                            />
                                            <span className="text-danger">{errors.ndc}</span>
                                        </div>
                                    </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <DatePicker maxDate={moment().toDate()} selected={this.state.purchase_date} placeholderText="MM/DD/YYYY" onChange={(date) => this.setReportDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                            <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                            <span className="text-danger">{errors.purchase_date}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="package_size">Package Size</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.package_size}
                                                error={errors.package_size}
                                                id="package_size"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.package_size
                                                })}
                                            />
                                            <span className="text-danger">{errors.package_size}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="qty_received">No. Pkg Received</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.qty_received}
                                                id="qty_received"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.qty_received
                                                })}
                                            />
                                            <span className="text-danger">{errors.qty_received}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="form_number">PO/Form Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.form_number}
                                                id="form_number"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.form_number
                                                })}
                                            />
                                            <span className="text-danger">{errors.form_number}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="invoice_number">Invoice Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.invoice_number}
                                                id="invoice_number"
                                                type="text"
                                                error={errors.invoice_number}
                                                className={classnames("form-control", {
                                                    invalid: errors.invoice_number
                                                })} />
                                            <span className="text-danger">{errors.invoice_number}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="supplier">Supplier</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.supplier}
                                                id="supplier"
                                                type="text"
                                                error={errors.rx_bin}
                                                className={classnames("form-control", {
                                                    invalid: errors.supplier
                                                })} />
                                            <span className="text-danger">{errors.supplier}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="expiration_date1">Expiration Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <DatePicker selected={this.state.expiration_date} placeholderText="MM/DD/YYYY" onChange={(date) => this.setExpirationDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                            <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                            <span className="text-danger">{errors.expiration_date}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="lot">Lot Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.lot}
                                                id="lot"
                                                type="text"
                                                error={errors.lot}
                                                className={classnames("form-control", {
                                                    invalid: errors.lot
                                                })} />
                                            <span className="text-danger">{errors.lot}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="notes">Notes</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.notes}
                                                id="notes"
                                                type="text"
                                                error={errors.rx_bin}
                                                className={classnames("form-control", {
                                                    invalid: errors.notes
                                                })} />
                                            <span className="text-danger">{errors.notes}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="invoice_number">Invoice Upload</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onImageChange}
                                                // value={this.state.invoiceimage}
                                                id="invoiceimage"
                                                type="file"
                                                disabled={this.state.drug_name === '' ? true : false}

                                                error={errors.invoiceimage}
                                                className={classnames("form-control", {
                                                    invalid: errors.invoiceimage
                                                })} />
                                            <span className="text-danger">{errors.invoiceimage}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-report"
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={this.onReportUpdate}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps
)(PurchaseUpdateModal);
