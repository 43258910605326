import React, { Component, Fragment } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import Multiselect from 'multiselect-react-dropdown';
import "react-datepicker/dist/react-datepicker.css";
import '../../App.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import PulseLoader from "react-spinners/PulseLoader";
import GroupFilter from "./Filter/GroupFilter.js";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment"
import { Link } from "react-router-dom";
import dashboard from "../../utils/dashboard.png"
import DrugsUpdateModal from "../partials/DrugsUpdateModal";
import { logoutUser } from "../../actions/authActions";
class ReportsAhfs extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "ahfs_desc",
                text: "Therapeutic Class",
                className: "ahfs_desc",
                align: "left",
                sortable: true,
            },
            {
                key: "total",
                text: "Total Purchase",
                className: "total",
                align: "left",
                sortable: true,
            },
            {
                key: "qty_dispensed",
                text: "Total Dispense",
                className: "qty_dispensed",
                align: "left",
                sortable: true,
            }
        ];


        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Therapeutic_Class_Report",
            no_data_text: 'No result found!',
            button: {
                excel: false,
                print: false,
                csv: true,
                range: false
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.columns2 = [
            {
                key: "ndc",
                text: "NDC",
                className: "ndc",
                align: "left",
                sortable: true,
            }
        ];
        this.columns2.push({
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: record => {
                console.log("record",record)
                return (
                    <Fragment>
                        {record.ndc &&
                            <button
                                data-toggle="modal"
                                data-target="#update-drug-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>}
                    </Fragment>
                );
            }
        })

        this.config2 = {
            ...this.config,
            button: {
                excel: false,
                print: false,
                csv: true,
                range: false
            },
        };

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "retail", order: "desc" }
            },
            currentRecord: {
            },
            name: undefined,
            from_date: moment().subtract(90, "days").startOf('day').toDate(),
            to_date: moment().endOf('day').toDate(),
            rx_group_associated: undefined,
            ahfs_desc: undefined,
            reportByAhfsArr: [],
            loading: false,
            plainArray: ["2", "3", "4", "5", "2N", "3N", "NC"],
            deaSelected: [],
            undefinedNDC: [],
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
    };

    componentDidUpdate(prevProps) {
        if (this.props.multistoreData.length !== prevProps.multistoreData.length) {
            this.getData()
        }
    }

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            // ...(this.state.from_date !== '' && { from_date: this.state.from_date }),
            // ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
            ...(this.state.ndc !== '' && { ndc: this.state.ndc }),
            ...(this.state.drug_name !== '' && { drug_name: this.state.drug_name }),
            pharmacy_account: this.state.rx_group_associated !== undefined && this.state.rx_group_associated !== "" ? this.state.rx_group_associated : this.props.auth.user.final_user,
            ...(this.state.deaSelected.length > 0 && { dea: this.state.deaSelected }),
            ...(this.props.multistoreData.length > 0 && (this.state.rx_group_associated === undefined || this.state.rx_group_associated === "") && { multiStoreEmails: this.props.multistoreData.map(a => a.email) })
        }

        axios
            .post("/api/ahfs-report", filterData)
            .then(res => {
                let despenseArr = {}
                let drugsArr = {}
                for (let i = 0; i < res.data.dispense.length; i++) {
                    let each = res.data.dispense[i]
                    despenseArr[each._id] = each.qty_dispensed
                }
                for (let i = 0; i < res.data.drugs.length; i++) {
                    let each = res.data.drugs[i]
                    drugsArr[each.ndc] = each.ahfs_desc
                }
                let reportByAhfs = []
                let reportByAhfsArr = []
                let ndcDone = [];
                let undefinedNDC = []


                for (let i = 0; i < res.data.purchase.length; i++) {
                    let each = res.data.purchase[i]
                    ndcDone.push(each._id)
                    if (drugsArr[each._id] === undefined) {
                        undefinedNDC.push({ "ndc": each._id })
                    }
                    if (drugsArr[each._id] === "") {
                        console.log(each._id)
                    }
                    if (reportByAhfs[drugsArr[each._id]] !== undefined) {
                        if (each.total > 0) {
                            if (despenseArr[each._id]) {
                                reportByAhfs[drugsArr[each._id]].qty_dispensed = parseInt(reportByAhfs[drugsArr[each._id]].qty_dispensed) + parseInt(despenseArr[each._id])
                                reportByAhfs[drugsArr[each._id]].total = parseInt(reportByAhfs[drugsArr[each._id]].total) + parseInt(each.total)
                            }
                        }
                    } else {
                        reportByAhfsArr.push(drugsArr[each._id])
                        if (each.total > 0) {
                            reportByAhfs[drugsArr[each._id]] = {}
                            reportByAhfs[drugsArr[each._id]].ahfs_desc = drugsArr[each._id]
                            if (despenseArr[each._id]) {
                                reportByAhfs[drugsArr[each._id]].total = each.total
                                reportByAhfs[drugsArr[each._id]].qty_dispensed = despenseArr[each._id]
                            } else {
                                reportByAhfs[drugsArr[each._id]].total = each.total
                                reportByAhfs[drugsArr[each._id]].qty_dispensed = "0"
                            }
                        }
                    }
                }
                for (let i = 0; i < res.data.dispense.length; i++) {
                    let each = res.data.dispense[i]
                    if (drugsArr[each._id] === undefined) {
                        undefinedNDC.push({ "ndc": each._id })
                    }
                    if (drugsArr[each._id] === "") {
                        console.log(each._id)
                    }
                    if (!ndcDone.includes(each._id)) {

                        if (reportByAhfs[drugsArr[each._id]] === undefined) {
                            reportByAhfs[drugsArr[each._id]] = {}
                            reportByAhfs[drugsArr[each._id]].ahfs_desc = drugsArr[each._id]
                            reportByAhfs[drugsArr[each._id]].total = "0"
                            reportByAhfs[drugsArr[each._id]].qty_dispensed = each.qty_dispensed
                        } else {
                            reportByAhfs[drugsArr[each._id]].qty_dispensed = parseInt(reportByAhfs[drugsArr[each._id]].qty_dispensed) + parseInt(each.qty_dispensed)
                        }
                        ndcDone.push(each._id)
                    }
                }
                let records = Object.values(reportByAhfs)
                if (this.state.ahfs_desc !== undefined && this.state.ahfs_desc !== "") {
                    records = records.filter(each => each.ahfs_desc === this.state.ahfs_desc);
                }
                this.setState({
                    records,
                    reportByAhfsArr,
                    totalCount: res.data.count,
                    loading: false,
                    undefinedNDC
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })
    }

    editRecord(record) {
        this.setState({ loading: true })
        let filterData = {
            page_number: 1,
            page_size: 20,
            sort_order: { column: "ndc", order: "desc" }
        }
        filterData.search = {
            ...(record.ndc !== '' && { ndc: record.ndc }),

        }
        axios
            .post("/api/drugs", filterData)
            .then(res => {

                this.setState({
                    currentRecord: res.data.records[0],
                    loading: false,
                })

            })

            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })
    }

    onSearch = e => {
        this.setState({
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "retail", order: "desc" }
            }
        }, () => this.getData());
    }

    reset = () => {
        this.setState({
            ahfs_desc: undefined,
            from_date: undefined,
            to_date: undefined,
            rx_group_associated: "",
            deaSelected: [],
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "asc" }
            }
        }, () => this.getData());
    }

    onSelectMultiple = (selectedList, selectedItem) => {
        this.setState({ deaSelected: selectedList })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    setFromDate = (date) => {
        this.setState({ from_date: date });
    }
    setToDate = (date) => {
        this.setState({ to_date: date });
    }

    exportToCSV = (from, to) => {
        let filterData = {}
        this.setState({ loading: true })
        filterData.search = {
            ...(from !== '' && { from_date: from }),
            ...(to !== '' && { to_date: to }),
        }
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            ahfs_desc: "Therapeutic Class",
            total: "Total Purchase",
            qty_dispensed: "Total Dispense",
            // balance: "Qty in Hand"
        };
        // // add columns in sheet array
        // for (let column of this.props.columns) {
        //     headers[column.key] = '"' + column.text + '"';
        // }
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false })
        for (let i in this.state.records) {
            let record = this.state.records[i]
            // if(record.ahfs_desc === ' ' || record.ahfs_desc === undefined){
            //     record.ahfs_desc = ' '
            // }
            // else{
            //     record.ahfs_desc = record.ahfs_desc
            // }
            record.ahfs_desc = '"' + record.ahfs_desc + '"'

            records.push(record);
        }
        if (headers) {
            records.unshift(headers);
        }
        // Convert Object to JSON
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    convertToCSV(objArray, headers) {
        const keys = Object.keys(headers);
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in keys) {
                line += array[i][keys[index]] === undefined ? "," : array[i][keys[index]] + ",";
                line.trim(",")


            }
            str += line + '\r\n';
        }
        return str;
    }

    openDatepicker = (e) => {
        if (e.target.id === "toD") {
            this.toDatePicker.setOpen(true);
        } else {
            this.fromDatePicker.setOpen(true);
        }
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        if ((this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula === undefined || this.props.auth.user.expiration_tabula < moment().format()) && this.props.auth.user.superadmin !== true) {
            window.location.href = ("/choose-plan?solution=TabulaRx");
        }
        const { records, reportByAhfsArr } = this.state

        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <DrugsUpdateModal record={this.state.currentRecord} getData={this.getData} />
                <div id="page-content-wrapper">
                    {this.props.auth.user.tabulaRx || this.props.auth.user.superadmin || this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula >= moment().format() ? (

                        <div className="container-fluid">
                            <h1 className="mt-2 text-primary">Therapeutic Class Report</h1>
                            <button className="btn btn-warning"><FontAwesomeIcon icon={faBackward} /><Link to="/dashboard"> Go to Dashboard</Link></button>
                            <br></br>
                            <br></br>
                            <div>
                                <form noValidate >
                                    <div className="form-row">
                                        <GroupFilter onChangeSelect={this.onChangeSelect} rx_group_associated={this.state.rx_group_associated} />
                                        <div className="form-group col-md-3">
                                            <label htmlFor="ahfs_desc">Therapeutic Class</label>
                                            <select onChange={this.onChangeSelect} id="ahfs_desc" className="form-control">
                                                <option value="">Please Select</option>
                                                <>
                                                    {
                                                        reportByAhfsArr.map(each => {
                                                            return (
                                                                <option value={each}>{each}</option>
                                                            )
                                                        })
                                                    }
                                                </>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="drug_name">Class</label>
                                            <Multiselect
                                                selectedValues={this.state.deaSelected}
                                                options={this.state.plainArray}
                                                isObject={false}
                                                onSelect={this.onSelectMultiple} // Function will trigger on select event
                                                onRemove={this.onSelectMultiple} // Function will trigger on remove event
                                            />
                                        </div>
                                    </div>
                                    <button
                                        onClick={this.onSearch}
                                        type="button"
                                        className="btn btn-primary">
                                        Search
                                    </button>
                                    <button type="reset" className="btn btn-secondary" onClick={this.reset}>Clear</button>
                                </form>
                            </div>
                            <br></br>
                            <ReactDatatable
                                config={this.config}
                                records={records}
                                columns={this.columns}
                                dynamic={false}
                                total_record={this.state.totalCount}
                                loading={true}
                                exportToCSV={this.exportToCSV}
                                exportToPDF={this.exportToPDF}
                            />
                            <br></br>
                            {this.props.auth.user.superadmin &&
                                (<>
                                    <h1 className="mt-2 text-primary">NDC Missing</h1>
                                    <ReactDatatable
                                        config={this.config2}
                                        records={this.state.undefinedNDC}
                                        columns={this.columns2}
                                        dynamic={false}
                                        total_record={this.state.undefinedNDC.length}
                                        loading={true}
                                        exportToCSV={this.exportToCSV}
                                        exportToPDF={this.exportToPDF}
                                    />
                                </>)}
                        </div>
                    ) : (
                        <div className="container-fluid static-card">
                            <div className="card">
                                <div className="card-body">
                                    {this.props.auth.user.expiration_tabula < moment().format() ? (
                                        <h6 class="font-weight-bold text-danger">Your Subscription is ended<br></br>
                                            <Link to="/subscribe"><button className="btn btn-success" onClick={this.Subscribe}>Subscribe Now</button></Link>
                                        </h6>
                                    ) : !this.props.auth.user.tabulaRx ? (
                                        <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="">Contact</Link> Us.</h6>
                                    ) : (
                                        <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="">Contact</Link> Us.</h6>
                                    )}
                                </div>
                            </div>
                            <img className="static-image" src={dashboard} />
                        </div>
                    )
                    }
                </div>

                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    multistoreData: state.multistoreData,
});

export default connect(
    mapStateToProps, { logoutUser }
)(ReportsAhfs);
