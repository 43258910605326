import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import moment from "moment";
import { logoutUser } from "../../actions/authActions";
import GroupFilter from "./Filter/GroupFilter.js";
import { confirmAlert } from "react-confirm-alert";
import CustomAlert from "./View/CustomAlert";
class Transaction extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "transaction_id",
        text: "Transaction Id",
        className: "transaction_id",
        align: "left",
        sortable: true,
      },
      {
        key: "amount",
        text: "Amount",
        className: "amount",
        align: "left",
        sortable: true,
        currency: true,
      },
      {
        key: "date",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
      },
      {
        key: "type",
        text: "Type",
        className: "type",
        align: "left",
        sortable: true,
      },
      {
        key: "message",
        text: "Message",
        className: "message",
        align: "left",
        sortable: true,
      },
    ];

    if (this.props.auth.user.superadmin) {
      this.columns.push({
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: false,
      });
    }

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "Transaction Ledger",
      no_data_text: "No Transaction found!",
      button: {
        excel: false,
        print: false,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      _id: "",
      errors: {},
      records: [],
      totalCount: 0,
      rx_group_associated: undefined,

      cardError: true,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "_id", order: "desc" },
      },
      valid: true,
    };
  }

  componentDidMount() {
    // this.getData();
    if (this.props.auth.user.superadmin) {
      this.setState(
        {
          valid: false,
        },
        () => this.askPin()
      );
    } else {
      this.getData();
    }
  }

  handleCheckPin = () => {
    if (this.state.pin === "1613") {
      // this.fetchData();
      this.setState({ valid: true }, () => this.getData());
    } else {
      alert("Pin Invalid");
    }
  };

  askPin() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlert
            onClose={onClose}
            onChange={this.onChange}
            handleCheckPin={this.handleCheckPin}
          />
        );
      },
    });
  }

  getData = () => {
    if (this.state.valid) {
      this.setState({ loading: true });
      let filterData = this.state.filterData;
      filterData.search = {
        email:
          this.state.rx_group_associated !== undefined &&
          this.state.rx_group_associated !== ""
            ? this.state.rx_group_associated
            : this.props.auth.user.final_user,
      };
      axios
        .post("/api/transactions", filterData)
        .then((res) => {
          let result = [];
          for (let i = 0; i < res.data.records.length; i++) {
            let each = res.data.records[i];
            each.date = each.date
              ? moment(each.date).add(5, "hours").format("L")
              : "";
            each.amount = each.amount
              ? Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(each.amount)
              : "";
            result.push(each);
          }
          this.setState({
            loading: false,
            records: result,
            totalCount: res.data.count,
          });
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
      this.askPin();
    }
  };
  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };
  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  exportToCSV = () => {
    if (this.state.valid) {
      toast.success("Dowloading...Please Wait!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      var headers = {
        transaction_id: "Transaction Id",
        amount: "Amount",
        date: "Date",
        type: "Type",
        message: "Message",
        email: "Email",
      };
      var records = [];
      // add data rows in sheet array
      this.setState({ loading: false });
      let filterData = {
        page_number: 1,
        page_size: 20000,
        sort_order: { column: "date", order: "desc" },
      };
      filterData.search = {
        email:
          this.state.rx_group_associated !== undefined &&
          this.state.rx_group_associated !== ""
            ? this.state.rx_group_associated
            : this.props.auth.user.final_user,
      };
      axios
        .post("/api/transactions", filterData)
        .then((res) => {
          let result = [];
          for (let i = 0; i < res.data.records.length; i++) {
            let each = res.data.records[i];
            each.date = each.date
              ? moment(each.date).add(5, "hours").format("L")
              : "";
            each.amount = each.amount
              ? Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(each.amount)
              : "";
            records.push(each);
          }

          if (headers) {
            records.unshift(headers);
          }
          // Convert Object to JSON
          // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
          // var universalBOM = "\uFEFF";
          let jsonObject = JSON.stringify(records);
          let csv = this.convertToCSV(jsonObject, headers);
          let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
          let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
          } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              let url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", exportedFilenmae);
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
      this.askPinCsv();
    }
  };

  handleCheckPinCsv = () => {
    if (this.state.pin === "1613") {
      // this.fetchData();
      this.setState({ valid: true }, () => this.exportToCSV());
    } else {
      alert("Pin Invalid");
    }
  };

  askPinCsv() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlert
            onClose={onClose}
            onChange={this.onChange}
            handleCheckPin={this.handleCheckPinCsv}
          />
        );
      },
    });
  }

  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);

    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : `"${array[i][keys[index]]}",`;
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }
  resetSearch = () => {
    this.setState(
      {
        rx_group_associated: undefined,
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "_id", order: "desc" },
        },
      },
      () => this.getData()
    );
  };

  render() {
    // if((this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula === undefined ||this.props.auth.user.expiration_tabula < moment().format()) && this.props.auth.user.superadmin!==true) {
    //     window.location.href = ("/choose-plan?solution=TabulaRx");
    // }
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">Transactions</h1>
            {this.props.auth.user.superadmin && (
              <Fragment>
                <GroupFilter
                  onChangeSelect={this.onChangeSelect}
                  rx_group_associated={this.state.rx_group_associated}
                  hide_check={true}
                />
                <button
                  onClick={this.onSearch}
                  type="button"
                  className="btn btn-primary"
                >
                  Search
                </button>
                <button
                  type="reset"
                  onClick={this.resetSearch}
                  className="btn btn-secordry"
                >
                  Reset
                </button>

                {/* <button
              type="reset"
              onClick={resetSearch}
              className="btn btn-secordry"
            >
              Reset
            </button> */}
              </Fragment>
            )}

            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
              exportToCSV={this.exportToCSV}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  pharmacy: state.pharmacy,
  groups: state.groups,
});

export default connect(mapStateToProps, { logoutUser })(Transaction);
