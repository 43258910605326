import React from 'react'
import classnames from "classnames";
import axios from "axios";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import $ from 'jquery';
import isEmpty from 'is-empty';
import _ from 'lodash';

class AddNdcQuick extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            notFound: [],
            loading: false,
            errors: {},
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.notFound.length) {
            //Change in props
            return {
                notFound: props.notFound
            };
        }
        return null; // No change to state
    }

    componentDidMount() {
    };


    onChangeNdc = e => {
        const { notFound } = this.state
        const currentNdc = (e.target.id)
        notFound.map(each => {
            if (each[this.props.ndcIndex] === currentNdc) {
                each[this.props.nameIndex] = e.target.value
            }
        })
        this.setState({ notFound });
    };
    onChangeDea = e => {
        const { notFound } = this.state
        const currentNdc = (e.target.id)
        notFound.map(each => {
            if (each[this.props.ndcIndex] === currentNdc) {
                each[this.props.deaIndex] = e.target.value
            }
        })
        this.setState({ notFound });
    };
    onChangeCost = e => {
        const { notFound } = this.state
        const currentNdc = (e.target.id)
        notFound.map(each => {
            if (each[this.props.ndcIndex] === currentNdc) {
                each['cost'] = e.target.value
            }
        })
        this.setState({ notFound });
    };

    addCurrentNdc = (each, index) => {
        let { notFound } = this.state
        axios
            .post("/api/drug-add", {
                ndc: each[this.props.ndcIndex],
                name: each[this.props.nameIndex],
                dea: each[this.props.deaIndex],
                acq_cost: each['cost'],
                pharmacy_account: this.props.auth.user.final_user
            }, {})
            .then(res => {
                notFound[index]['done'] = true
                this.setState({ notFound })
                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }).catch(err => {
                console.log(err)
                notFound[index]['done'] = true
                this.setState({ notFound })
            }
            )
    }

    render() {
        const { errors } = this.state;
        let doneCount = this.state.notFound.find(each => each['done']===undefined)
        return (
            <div>
                <div className="modal fade" id="quick-drug-modal" data-reset="false">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">NDC Not Found Please Add</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">NDC</th>
                                            <th scope="col">Drug Name + Strength</th>
                                            <th scope="col">DEA</th>
                                            <th scope="col">Acquisition Cost($)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.notFound.map((each, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{each[this.props.ndcIndex]}</th>
                                                    <td><input type="text" className="form-control" defaultValue={each[this.props.nameIndex]} id={each[this.props.ndcIndex]} onChange={this.onChangeNdc} disabled={each['done']}/></td>
                                                    <td>
                                                        <select onChange={this.onChangeDea} defaultValue={each[this.props.deaIndex]} id={each[this.props.ndcIndex]} className="form-control" disabled={each['done']}>
                                                            <option value="">Please Select</option>
                                                            <option selected={each[this.props.deaIndex] == "2"} value="2">2</option>
                                                            <option selected={each[this.props.deaIndex] == "3"} value="3">3</option>
                                                            <option selected={each[this.props.deaIndex] == "4"} value="4">4</option>
                                                            <option selected={each[this.props.deaIndex] == "5"} value="5">5</option>
                                                            <option selected={each[this.props.deaIndex] == "2N"} value="2N">2N</option>
                                                            <option selected={each[this.props.deaIndex] == "3N"} value="3N">3N</option>
                                                            <option selected={each[this.props.deaIndex] == "NC"} value="NC">NC</option>
                                                        </select>
                                                    </td>
                                                    <td><input type="number" className="form-control" defaultValue={each['cost']} id={each[this.props.ndcIndex]} onChange={this.onChangeCost} disabled={each['done']}/></td>
                                                    <td><button type="button" className="btn btn-primary" disabled={each['cost'] === undefined || each['cost'] === "" || each[this.props.deaIndex] === "" || each[this.props.nameIndex] === "" || each['done']} onClick={() => this.addCurrentNdc(each, index)}>Add</button></td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    disabled={doneCount!==undefined}
                                    onClick={this.props.checkDuplicate}
                                    className="btn btn-primary">
                                    Proceed
                                </button>
                                <button
                                    type="button"
                                    onClick={() => window.location.reload()}
                                    className="btn btn-secondary">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(
    mapStateToProps
)(AddNdcQuick);
