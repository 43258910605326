import React from "react";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import Papa from "papaparse";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import AddNdcQuick from "../partials/AddNdcQuick";
import { logoutUser } from "../../actions/authActions";
import $ from "jquery";
import bins from "../../utils/bins";
class UploadDispense extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      ncDrugs: [],
      loading: false,
      errors: {},
      notFound: [],
      found: [],
      filterData: {
        page_number: 1,
        page_size: 99999,
        sort_order: {
          order: "asc",
          column: "_id",
        },
        search: {},
      },
      drugNameById: {},
      drugDeaById: {},
      indexes: {},
    };
  }

  componentDidMount() {
    // if (this.props.auth.user.software === "Rx30") {
    this.getDrugData();
    // }
  }

  getDrugData() {
    this.setState({ loading: true });
    let filtereData = {};
    if (this.props.auth.user.software !== "Cerner") {
      filtereData = {
        dea: { dea: { $ne: "NC" } },
      };
    }
    axios
      .post("/api/nc-drugs", filtereData)
      .then((res) => {
        let drugNameById = [];
        let drugDeaById = [];
        for (let i in res.data.records) {
          let each = res.data.records[i];
          drugNameById[each.ndc] = each.name;
          drugDeaById[each.ndc] = each.dea;
        }
        this.setState({
          ncDrugs: res.data.records,
          drugNameById,
          drugDeaById,
          loading: false,
        });
      })

      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  onImageChange = (event) => {
    this.setState({
      [event.target.id]: event.target.files[0],
    });
  };

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  upload = () => {
    if (this.state.file.type !== "text/csv") {
      alert("Please Upload CSV file only.");
      return false;
    } else if (this.state.file === "" || this.state.file === undefined) {
      alert("Please Upload CSV file.");
      return false;
    } else {
      this.setState({ loading: true });
      const formData = new FormData();
      if (this.state.file !== "")
        formData.append("dispense_file", this.state.file);
      formData.append("email", this.props.auth.user.final_user);
      const headers = {
        user: this.props.auth.user.final_user,
      };
      axios
        .post("/api/dispense-upload-file", formData, {
          headers: headers,
        })
        .then((res) => {})
        .catch((err) => console.log(err));
      if (this.props.auth.user.software === "Rx30") {
        this.fromRx30();
      } else if (this.props.auth.user.software === "QS1") {
        this.fromQS1();
      } else if (this.props.auth.user.software === "Micromerchant(PrimeRx)") {
        this.fromMicromerchant();
      } else if (this.props.auth.user.software === "Liberty") {
        this.fromLiberty();
      } else if (this.props.auth.user.software === "Cerner") {
        this.fromCerner();
      } else if (this.props.auth.user.software === "ComputerRx") {
        this.fromComputerRx();
      } else if (this.props.auth.user.software === "PioneerRx") {
        this.fromPioneerRx();
      } else if (this.props.auth.user.software === "DigitalRx") {
        this.fromDigitalRx();
      } else {
        confirmAlert({
          title: "Please Relogin",
          message: "You Need to relogin to upload the dispense sheet.",
          buttons: [
            {
              label: "Ok",
              onClick: () => window.location.reload(),
            },
          ],
        });
      }
    }
  };

  fromQS1 = () => {
    let { ncDrugs } = this.state;
    const checkNDCQS1 = this.checkNDCQS1;
    Papa.parse(this.state.file, {
      complete: function (results) {
        if (results.data.length < 2) {
          alert("Invalid CSV file.");
          return false;
        } else {
          let notFound = [];
          let found = [];
          let errorItem = [];
          for (let i in results.data) {
            let each = results.data[i];
            each["index"] = parseInt(i) + 1;
            if (each["index"] == 1 && each[26] !== "NDC") {
              confirmAlert({
                title: "Invalid File",
                message: "Uploaded file is not matching to QS1 software format",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => window.location.reload(),
                  },
                ],
              });
              break;
            } else {
              // results.data.map((each, index) => {
              if (
                (each[26] !== "NDC" &&
                  each[26] !== undefined &&
                  each[26] !== "") ||
                parseInt(each[26]) > 0
              ) {
                each[26] = each[26].replaceAll("-", "");
                // console.log(each)
                let drugFound = ncDrugs.find((eachD) => eachD.ndc == each[26]);
                if (/[a-zA-Z]/g.test(each[26])) {
                  errorItem.push(each["index"]);
                } else if (
                  drugFound === undefined &&
                  !isNaN(parseInt(each[13])) &&
                  [2, 3, 4, 5].includes(parseInt(each[13]))
                ) {
                  notFound.push(each);
                } else if (
                  !isNaN(parseInt(each[13])) &&
                  [2, 3, 4, 5].includes(parseInt(each[13]))
                ) {
                  found.push(each);
                }
              }
            }
          }
          // console.log("errorItem")
          // console.log(errorItem)
          // console.log("notFound")
          // console.log(notFound)
          // console.log("found")
          // console.log(found)
          if (errorItem.length > 0 && errorItem !== undefined) {
            confirmAlert({
              title: "Error in CSV",
              message:
                "Row no. " +
                errorItem.toString() +
                " contains errors and will be skipped. The data must be entered manually.",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => checkNDCQS1(notFound, found),
                },
              ],
            });
          } else {
            checkNDCQS1(notFound, found);
          }
        }
      },
    });
  };

  checkNDCQS1 = (notFound, found) => {
    if (found.length === 0) {
      this.setState({ loading: false });
      confirmAlert({
        title: "Warning",
        message: "No records to upload",
        buttons: [
          {
            label: "Ok",
            onClick: () => (window.location.href = "/dispense"),
          },
        ],
      });
    } else {
      if (notFound.length > 0 && notFound !== undefined) {
        this.setState(
          {
            notFound,
            found,
            loading: false,
          },
          () => $("#quick-drug-moda-button").trigger("click")
        );
      } else if (found.length > 0 && found !== undefined) {
        this.setState(
          {
            found,
          },
          () => this.checkDuplicateQS1()
        );
      }
    }
  };

  checkDuplicateQS1 = () => {
    $("#quick-drug-modal").modal("hide");
    let { found } = this.state;
    let foundArr = [];
    if (found.length > 0) {
      for (let i in found) {
        let each = found[i];
        foundArr.push({
          $and: [
            { ndc: each[26] },
            { dispense_date: each[0] },
            { rx_number: each[4] },
            { pharmacy_account: this.props.auth.user.final_user },
            { deleted: { $ne: true } },
          ],
        });
      }
      axios
        .post("/api/dispense-check", foundArr)
        .then((res) => {
          let responsed = res.data.records;
          let copyFound = JSON.parse(JSON.stringify(found));
          if (responsed.length > 0) {
            let duplicates = [];
            for (let i in found) {
              let each = found[i];
              let matchedItem = responsed.filter((eachR) => {
                if (
                  eachR["ndc"] === each[26] &&
                  eachR["rx_number"] === each[4] &&
                  moment(eachR["dispense_date"]).isSame(each[0])
                ) {
                  return each;
                }
              });
              if (matchedItem.length > 0) {
                duplicates.push(each["index"]);
                copyFound = copyFound.filter(
                  (item) =>
                    item["ndc"] === each[26] &&
                    item["rx_number"] === each[4] &&
                    moment(item["dispense_date"]).isSame(each[0])
                );
              }
            }
            confirmAlert({
              title: "Duplicate entries",
              message:
                "Row " +
                duplicates.toString() +
                " was previously added and will not be uploaded",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => this.saveEntriesQS1(copyFound),
                },
              ],
            });
          } else {
            this.saveEntriesQS1(copyFound);
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
    }
  };

  saveEntriesQS1 = (found) => {
    let saveData = [];
    // console.log(found)
    // if (found.length > 0) {
    for (let i in found) {
      let each = found[i];
      saveData.push({
        ndc: each[26],
        dispense_date: each[0],
        rx_number: each[4],
        drug_name: each[7],
        dea: each[13],
        qty_dispensed: parseInt(each[6]),
        day_supply: each[38],
        patient_name: each[5].charAt(0).toUpperCase(),
        patient_zip: each[74],
        patient_dob: each[54],
        payment_type: each[42] !== "" ? "N" : "Y",
        pharmacy_account: this.props.auth.user.final_user,
        user_created: this.props.auth.user.email,
        doctor_firstname: each[127],
        doctor_lastname: each[128],
        doctor_npi: each[65],
        doctor_dea: each[41],
        doctor_add1: each[33],
        doctor_city: each[34],
        doctor_state: each[75],
        doctor_zip: each[65],
      });
    }
    axios
      .post("/api/dispense-upload-save", saveData)
      .then((res) => {
        confirmAlert({
          title: "Success",
          message: "Sheet uplaoded Successfully",
          buttons: [
            {
              label: "Ok",
              onClick: () => (window.location.href = "/dispense"),
            },
          ],
        });
      })

      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    // } else {
    //     // console.log("dsgdhsjhgfh")
    //     confirmAlert({
    //         title: 'No items to save',
    //         message: "No items to save",
    //         buttons: [
    //             {
    //                 label: 'Ok',
    //                 onClick: () => window.location.reload()
    //             },
    //         ]
    //     });
    // }
  };

  updateState = (obj) => {
    this.setState(obj);
  };

  fromRx30 = () => {
    let { ncDrugs } = this.state;
    const saveIndexesRx30 = this.saveIndexesRx30;
    const updateState = this.updateState;
    Papa.parse(this.state.file, {
      complete: function (results) {
        if (results.data.length < 2) {
          alert("Invalid CSV file.");
          return false;
        } else {
          let notFound = [];
          let found = [];
          let errorItem = [];
          let notFoundArr = [];
          let missingIndex = [];
          let indexes = [];
          let headerRow = results.data[0];
          let rx_number = headerRow.findIndex((each) => each === "RXNBR");
          if (rx_number >= 0) {
            indexes["rx_number"] = rx_number;
          } else {
            missingIndex.push("RXNBR");
          }
          let dispense_date = headerRow.findIndex(
            (each) => each === "FILLDATE"
          );
          if (dispense_date >= 0) {
            indexes["dispense_date"] = dispense_date;
          } else {
            missingIndex.push("FILLDATE");
          }
          let patient_dob = headerRow.findIndex((each) => each === "PATDOB");
          if (patient_dob >= 0) {
            indexes["patient_dob"] = patient_dob;
          } else {
            missingIndex.push("PATDOB");
          }
          let doctor_npi = headerRow.findIndex((each) => each === "DR NPI");
          if (doctor_npi >= 0) {
            indexes["doctor_npi"] = doctor_npi;
          } else {
            missingIndex.push("DR NPI");
          }
          let drug_name = headerRow.findIndex((each) => each === "DRUG NAME");
          if (drug_name >= 0) {
            indexes["drug_name"] = drug_name;
          } else {
            missingIndex.push("DRUG NAME");
          }
          let ndc = headerRow.findIndex((each) => each === "NDC");
          if (ndc >= 0) {
            indexes["ndc"] = ndc;
          } else {
            missingIndex.push("NDC");
          }
          let qty_dispensed = headerRow.findIndex((each) => each === "QTY DSP");
          if (qty_dispensed >= 0) {
            indexes["qty_dispensed"] = qty_dispensed;
          } else {
            missingIndex.push("QTY DSP");
          }
          let dea = headerRow.findIndex((each) => each === "DEACLASS");
          if (dea >= 0) {
            indexes["dea"] = dea;
          } else {
            missingIndex.push("DEACLASS");
          }
          let day_supply = headerRow.findIndex((each) => each === "DS");
          if (day_supply >= 0) {
            indexes["day_supply"] = day_supply;
          } else {
            missingIndex.push("DS");
          }
          let patient_zip = headerRow.findIndex((each) => each === "PATZIP");
          if (patient_zip >= 0) {
            indexes["patient_zip"] = patient_zip;
          } else {
            missingIndex.push("PATZIP");
          }
          let patient_fname = headerRow.findIndex(
            (each) => each === "PATNAMEFIRST"
          );
          if (patient_fname >= 0) {
            indexes["patient_fname"] = patient_fname;
          } else {
            missingIndex.push("PATNAMEFIRST");
          }
          let patient_lname = headerRow.findIndex(
            (each) => each === "PATNAMELAST"
          );
          if (patient_lname >= 0) {
            indexes["patient_lname"] = patient_lname;
          } else {
            missingIndex.push("PATNAMELAST");
          }
          let doctor_zip = headerRow.findIndex((each) => each === "DOCZIP");
          if (doctor_zip >= 0) {
            indexes["doctor_zip"] = doctor_zip;
          } else {
            missingIndex.push("DOCZIP");
          }
          let payment_type = headerRow.findIndex((each) => each === "P1 BIN");
          if (payment_type >= 0) {
            indexes["payment_type"] = payment_type;
          } else {
            missingIndex.push("P1 BIN");
          }
          let doctor_fname = headerRow.findIndex(
            (each) => each === "DOCNAMEFIRST"
          );
          if (doctor_fname >= 0) {
            indexes["doctor_fname"] = doctor_fname;
          }
          let doctor_lname = headerRow.findIndex(
            (each) => each === "DOCNAMELAST"
          );
          if (doctor_lname >= 0) {
            indexes["doctor_lname"] = doctor_lname;
          }
          let doctor_add1 = headerRow.findIndex((each) => each === "DOCADD1");
          if (doctor_add1 >= 0) {
            indexes["doctor_add1"] = doctor_add1;
          }
          let doctor_add2 = headerRow.findIndex((each) => each === "DOCADD2");
          if (doctor_add2 >= 0) {
            indexes["doctor_add2"] = doctor_add2;
          }
          let doctor_city = headerRow.findIndex((each) => each === "DOCCITY");
          if (doctor_city >= 0) {
            indexes["doctor_city"] = doctor_city;
          }
          let doctor_state = headerRow.findIndex((each) => each === "DOCST");
          if (doctor_state >= 0) {
            indexes["doctor_state"] = doctor_state;
          }
          let doctor_dea = headerRow.findIndex((each) => each === "DEANBR");
          if (doctor_dea >= 0) {
            indexes["doctor_dea"] = doctor_dea;
          }
          let pharmacy_initial = headerRow.findIndex((each) => each === "RPH");
          if (pharmacy_initial >= 0) {
            indexes["pharmacy_initial"] = pharmacy_initial;
          }

          if (missingIndex.length > 0) {
            updateState({ loading: false });
            confirmAlert({
              title: "Columns Missing",
              message:
                missingIndex.toString() +
                " are missing in the file, Please add these columns and upload again.",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => window.location.reload(),
                },
              ],
            });
          } else {
            for (let i in results.data) {
              let each = results.data[i];
              each["index"] = parseInt(i) + 1;

              if (
                each["index"] == 1 &&
                each[indexes["ndc"]] !== "NDC" &&
                each[indexes["ndc"]] !== "" &&
                parseInt(each[indexes["ndc"]]) > 0
              ) {
                updateState({ loading: false });
                confirmAlert({
                  title: "Invalid File",
                  message:
                    "Uploaded file is not matching to Rx30 software format",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => window.location.reload(),
                    },
                  ],
                });
                break;
              } else {
                // results.data.map((each, index) => {
                if (
                  each[indexes["ndc"]] !== "NDC" &&
                  each[indexes["ndc"]] !== undefined &&
                  each[indexes["ndc"]] > 0 &&
                  each[indexes["ndc"]].substring(0, 5) != "99999"
                ) {
                  each[indexes["ndc"]] = each[indexes["ndc"]].padStart(11, "0");
                  // console.log(each)
                  let drugFound = ncDrugs.find(
                    (eachD) =>
                      eachD.ndc === each[indexes["ndc"]].padStart(11, "0")
                  );
                  // console.log(drugFound)
                  if (
                    /[a-zA-Z]/g.test(each[indexes["ndc"]]) ||
                    parseInt(each[indexes["dea"]]) > 5 ||
                    /[a-zA-Z]/g.test(each[indexes["dea"]])
                  ) {
                    if (
                      [
                        "Brand",
                        "Generic",
                        "OTC Brand",
                        "OTC Generic",
                        "Medical Equip",
                      ].includes(each[56]) &&
                      ["0", "2", "3", "4", "5"].includes(each[57])
                    ) {
                      each.splice(45, 1);
                      found.push(each);
                    } else if (
                      [
                        "Brand",
                        "Generic",
                        "OTC Brand",
                        "OTC Generic",
                        "Medical Equip",
                      ].includes(each[57]) &&
                      ["0", "2", "3", "4", "5"].includes(each[58])
                    ) {
                      each.splice(45, 2);
                      found.push(each);
                    } else if (
                      [
                        "Brand",
                        "Generic",
                        "OTC Brand",
                        "OTC Generic",
                        "Medical Equip",
                      ].includes(each[58]) &&
                      ["0", "2", "3", "4", "5"].includes(each[59])
                    ) {
                      each.splice(45, 3);
                      found.push(each);
                    } else if (
                      [
                        "Brand",
                        "Generic",
                        "OTC Brand",
                        "OTC Generic",
                        "Medical Equip",
                      ].includes(each[59]) &&
                      ["0", "2", "3", "4", "5"].includes(each[60])
                    ) {
                      each.splice(45, 4);
                      found.push(each);
                    } else if (
                      [
                        "Brand",
                        "Generic",
                        "OTC Brand",
                        "OTC Generic",
                        "Medical Equip",
                      ].includes(each[60]) &&
                      ["0", "2", "3", "4", "5"].includes(each[61])
                    ) {
                      each.splice(45, 5);
                      found.push(each);
                    } else {
                      errorItem.push(each["index"]);
                    }
                    // console.log(/[a-zA-Z]/g.test(each[54]))
                    // console.log(parseInt(each[56]) > 5)
                  } else if (
                    drugFound === undefined &&
                    each[indexes["dea"]] != "0" &&
                    !notFoundArr.includes(each[indexes["ndc"]])
                  ) {
                    notFound.push(each);
                    found.push(each);
                    notFoundArr.push(each[indexes["ndc"]]);
                  } else if (each[indexes["dea"]] != "0") {
                    found.push(each);
                  }
                  // return errorItem
                }
              }
            }
            if (errorItem.length > 0 && errorItem !== undefined) {
              updateState({ loading: false });
              confirmAlert({
                title: "Error in CSV",
                // message: 'Row no. ' + errorItem.toString()+" contains errors. You may fix and Re Upload or skips those Row(s).",
                message:
                  "Row no. " +
                  errorItem.toString() +
                  " contains errors and will be skipped. The data must be entered manually.",
                buttons: [
                  // {
                  //     label: 'Fix',
                  //     onClick: () => window.location.reload()
                  // },
                  {
                    label: "Ok",
                    onClick: () => saveIndexesRx30(notFound, found, indexes),
                  },
                ],
              });
            } else {
              console.log("notFound", notFound);
              // console.log(found)
              saveIndexesRx30(notFound, found, indexes);
            }
          }

          // console.log(notFound)
          // this.setState({notFound})
        }
      },
    });
  };

  saveIndexesRx30 = (notFound, found, indexes) => {
    this.setState(
      {
        indexes,
      },
      () => this.checkNDC(notFound, found)
    );
  };

  checkNDC = (notFound, found) => {
    if (found.length === 0) {
      this.setState({ loading: false });
      confirmAlert({
        title: "Warning",
        message: "No records to upload",
        buttons: [
          {
            label: "Ok",
            onClick: () => (window.location.href = "/dispense"),
          },
        ],
      });
    } else {
      if (notFound.length > 0 && notFound !== undefined) {
        this.setState(
          {
            notFound,
            found,
            loading: false,
          },
          () => $("#quick-drug-moda-button").trigger("click")
        );
      } else if (found.length > 0 && found !== undefined) {
        this.setState(
          {
            found,
          },
          () => this.checkDuplicate()
        );
      }
    }
  };

  checkDuplicate = () => {
    $("#quick-drug-modal").modal("hide");
    let { found, indexes } = this.state;
    console.log("checkDuplicate");
    console.log(found);
    let foundArr = [];
    if (found.length > 0) {
      for (let i in found) {
        let each = found[i];
        foundArr.push({
          $and: [
            { ndc: each[indexes["ndc"]] },
            { dispense_date: each[indexes["dispense_date"]] },
            { rx_number: each[indexes["rx_number"]] },
            { pharmacy_account: this.props.auth.user.final_user },
            { deleted: { $ne: true } },
          ],
        });
      }
      axios
        .post("/api/dispense-check", foundArr)
        .then((res) => {
          let responsed = res.data.records;
          let copyFound = JSON.parse(JSON.stringify(found));
          if (responsed.length > 0) {
            // console.log("responsed")
            // console.log(responsed)
            // console.log("found")
            // console.log(found)
            let duplicates = [];
            for (let i in found) {
              let each = found[i];
              responsed.filter((eachR) => {
                if (
                  eachR["ndc"] === each[indexes["ndc"]].padStart(11, "0") &&
                  eachR["rx_number"] === each[indexes["rx_number"]] &&
                  moment(eachR["dispense_date"]).isSame(
                    each[indexes["dispense_date"]],
                    "date"
                  )
                ) {
                  each["duplicate"] = true;
                  duplicates.push(each["index"]);
                }
              });
            }
            let finalFilter = found.filter(
              (eachF) => eachF["duplicate"] !== true
            );
            this.setState({ loading: false });
            let showStr = duplicates
              .filter((v, i, a) => a.indexOf(v) == i)
              .toString();
            if (showStr.split("").length > 100) {
              showStr = showStr.substring(0, 100) + "...";
            }
            confirmAlert({
              title: "Duplicate entries",
              message:
                "Row " +
                showStr +
                " was previously added and will not be uploaded",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => this.saveEntries(finalFilter),
                },
              ],
            });
          } else {
            this.saveEntries(copyFound);
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
    }
  };

  saveEntries = (found) => {
    let saveData = [];
    const { indexes } = this.state;
    console.log(found);
    // console.log(indexes)
    // if (found.length > 0) {
    for (let i in found) {
      let each = found[i];
      console.log("name---" + each[indexes["patient_fname"]]);
      saveData.push({
        ndc: each[indexes["ndc"]].padStart(11, "0"),
        dispense_date: each[indexes["dispense_date"]],
        rx_number: each[indexes["rx_number"]],
        drug_name: each[indexes["drug_name"]],
        dea: each[indexes["dea"]],
        qty_dispensed: parseInt(each[indexes["qty_dispensed"]]),
        day_supply: each[indexes["day_supply"]],
        patient_name:
          each[indexes["patient_lname"]].charAt(0).toUpperCase() +
          each[indexes["patient_fname"]].charAt(0).toUpperCase(),
        patient_zip: each[indexes["patient_zip"]],
        patient_dob: each[indexes["patient_dob"]],
        payment_type: bins.includes(each[indexes["payment_type"]]) ? "Y" : "N",
        pharmacy_account: this.props.auth.user.final_user,
        user_created: this.props.auth.user.email,
        doctor_firstname: each[indexes["doctor_fname"]],
        doctor_lastname: each[indexes["doctor_lname"]],
        doctor_npi: each[indexes["doctor_npi"]],
        doctor_dea: each[indexes["doctor_dea"]],
        doctor_add1: each[indexes["doctor_add1"]],
        doctor_add2: each[indexes["doctor_add2"]],
        doctor_city: each[indexes["doctor_city"]],
        doctor_state: each[indexes["doctor_state"]],
        doctor_zip: each[indexes["doctor_zip"]],
        pharmacy_initial: each[indexes["pharmacy_initial"]],
      });
    }
    console.log(saveData);
    axios
      .post("/api/dispense-upload-save", saveData)
      .then((res) => {
        this.setState({ loading: false });
        confirmAlert({
          title: "Success",
          message: "Sheet uploaded Successfully",
          buttons: [
            {
              label: "Ok",
              onClick: () => (window.location.href = "/dispense"),
            },
          ],
        });
      })

      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  reset = () => {
    this.setState({
      file: "",
    });
  };

  back = () => {
    window.location.href = "/dispense";
  };

  //MicroMerchant
  fromMicromerchant = () => {
    let { ncDrugs, drugDeaWise } = this.state;
    const checkNDCMicromerchant = this.checkNDCMicromerchant;
    Papa.parse(this.state.file, {
      complete: function (results) {
        if (results.data.length < 2) {
          alert("Invalid CSV file.");
          return false;
        } else {
          let notFound = [];
          let found = [];
          let errorItem = [];
          console.log(results.data);
          for (let i in results.data) {
            let each = results.data[i];
            if (each[0] == "") {
              continue;
            }
            each["index"] = parseInt(i) + 1;
            if (
              (each["index"] == 1 && each[2] !== "NDC" && each[2] !== "") ||
              parseInt(each[2]) > 0
            ) {
              confirmAlert({
                title: "Invalid File",
                message:
                  "Uploaded file is not matching to Micromerchant software format",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => window.location.reload(),
                  },
                ],
              });
              break;
            } else {
              // results.data.map((each, index) => {
              if (each[2] !== "NDC" && each[3] !== undefined) {
                each[2] = each[2].replaceAll("-", "");
                each[4] = each[4].split(" ")[0];
                // console.log(each)
                let drugFound = ncDrugs.find((eachD) => eachD.ndc == each[2]);
                if (/[a-zA-Z]/g.test(each[2])) {
                  errorItem.push(each["index"]);
                } else if (
                  drugFound === undefined &&
                  !isNaN(parseInt(each[3])) &&
                  [2, 3, 4, 5].includes(parseInt(each[3]))
                ) {
                  notFound.push(each);
                  found.push(each);
                } else if (
                  !isNaN(parseInt(each[3])) &&
                  [2, 3, 4, 5].includes(parseInt(each[3]))
                ) {
                  found.push(each);
                }
              }
            }
          }
          if (errorItem.length > 0 && errorItem !== undefined) {
            this.setState({ loading: false });
            confirmAlert({
              title: "Error in CSV",
              message:
                "Row no. " +
                errorItem.toString() +
                " contains errors and will be skipped. The data must be entered manually.",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => checkNDCMicromerchant(notFound, found),
                },
              ],
            });
          } else {
            checkNDCMicromerchant(notFound, found);
          }
        }
      },
    });
  };

  checkNDCMicromerchant = (notFound, found) => {
    if (found.length === 0) {
      this.setState({ loading: false });
      confirmAlert({
        title: "Warning",
        message: "No records to upload",
        buttons: [
          {
            label: "Ok",
            onClick: () => (window.location.href = "/dispense"),
          },
        ],
      });
    } else {
      this.setState({ loading: true });
      if (notFound.length > 0 && notFound !== undefined) {
        this.setState(
          {
            notFound,
            found,
            loading: false,
          },
          () => $("#quick-drug-moda-button").trigger("click")
        );
      } else if (found.length > 0 && found !== undefined) {
        this.setState(
          {
            found,
          },
          () => this.checkDuplicateMicromerchant()
        );
      }
    }
  };

  checkDuplicateMicromerchant = () => {
    $("#quick-drug-modal").modal("hide");
    let { found } = this.state;
    console.log("checkDuplicateMicromerchant");
    console.log(found);
    let foundArr = [];
    if (found.length > 0) {
      for (let i in found) {
        let each = found[i];
        foundArr.push({
          $and: [
            { ndc: each[2] },
            { dispense_date: each[4] },
            { rx_number: each[5] },
            { pharmacy_account: this.props.auth.user.final_user },
            { deleted: { $ne: true } },
          ],
        });
      }
      axios
        .post("/api/dispense-check", foundArr)
        .then((res) => {
          let responsed = res.data.records;
          let copyFound = JSON.parse(JSON.stringify(found));
          if (responsed.length > 0) {
            let duplicates = [];
            for (let i in found) {
              let each = found[i];
              responsed.filter((eachR) => {
                if (
                  eachR["ndc"] === each[2] &&
                  eachR["rx_number"] === each[5] &&
                  moment(eachR["dispense_date"]).isSame(each[4], "date")
                ) {
                  each["duplicate"] = true;
                  duplicates.push(each["index"]);
                }
              });
              // if (matchedItem.length > 0) {
              //     duplicates.push(each['index'])
              //     copyFound.splice(i , 1)

              // }
            }
            let finalFilter = found.filter(
              (eachF) => eachF["duplicate"] !== true
            );
            this.setState({ loading: false });
            confirmAlert({
              title: "Duplicate entries",
              message:
                "Row " +
                duplicates.filter((v, i, a) => a.indexOf(v) == i).toString() +
                " was previously added and will not be uploaded",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => this.getNpiDetailMicromerchant(finalFilter),
                },
              ],
            });
          } else {
            this.getNpiDetailMicromerchant(copyFound);
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
    }
  };

  getNpiDetailMicromerchant = (found) => {
    console.log("getNpiDetailMicromerchant");
    console.log(found);
    if (found.length > 0) {
      let npiArr = [];
      for (let i in found) {
        let each = found[i];
        npiArr.push(each[13]);
      }
      let resultNpiDetails = {};
      this.setState({ loading: true });
      axios
        .post("/api/doctors-by-npi", npiArr)
        .then((res) => {
          for (let i = 0; i < res.data.records.length; i++) {
            let eachNpi = res.data.records[i];
            resultNpiDetails[eachNpi.npi] = eachNpi;
          }
          this.saveEntriesMicromerchant(found, resultNpiDetails);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
    }
  };

  saveEntriesMicromerchant = (found, resultNpiDetails) => {
    const { drugNameById } = this.state;
    let saveData = [];
    for (let i in found) {
      let each = found[i];
      let nameArr = each[8].split(".");
      let paitentName = "";
      if (nameArr.length === 2) {
        paitentName =
          nameArr[0].trim().charAt(0).toUpperCase() +
          nameArr[1].trim().charAt(0).toUpperCase();
      } else {
        paitentName = nameArr[0].trim().charAt(0).toUpperCase();
      }
      saveData.push({
        ndc: each[2],
        dispense_date: each[4],
        rx_number: each[5],
        drug_name:
          drugNameById[each[2]] !== undefined ? drugNameById[each[2]] : each[0],
        dea: each[3],
        qty_dispensed: parseInt(each[6]),
        day_supply: each[7],
        patient_name: paitentName,
        patient_zip: each[10],
        patient_dob: each[9].split(" ")[0],
        pharmacy_account: this.props.auth.user.final_user,
        user_created: this.props.auth.user.email,
        doctor_firstname: resultNpiDetails[each[13]].first_name,
        doctor_lastname: resultNpiDetails[each[13]].last_name,
        doctor_npi: each[13],
        doctor_add1: resultNpiDetails[each[13]].address_first_line,
        doctor_city: resultNpiDetails[each[13]].address_city,
        doctor_state: resultNpiDetails[each[13]].address_state,
        doctor_zip: each[15],
        payment_type: bins.includes(each[16]) ? "Y" : "N",
      });
    }
    console.log(saveData);
    axios
      .post("/api/dispense-upload-save", saveData)
      .then((res) => {
        this.setState({ loading: false });
        confirmAlert({
          title: "Success",
          message: "Sheet uplaoded Successfully",
          buttons: [
            {
              label: "Ok",
              onClick: () => (window.location.href = "/dispense"),
            },
          ],
        });
      })

      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };
  //MicroMerchant end

  updateState = (obj) => {
    this.setState(obj);
  };

  //Cerner Start
  fromCerner = () => {
    let { ncDrugs, indexes } = this.state;
    const saveIndexesCerner = this.saveIndexesCerner;
    const updateState = this.updateState;
    Papa.parse(this.state.file, {
      complete: function (results) {
        if (results.data.length < 2) {
          alert("Invalid CSV file.");
          return false;
        } else {
          let notFound = [];
          let found = [];
          let errorItem = [];
          let missingIndex = [];
          let headerRow = results.data[0];
          let rx_number = headerRow.findIndex(
            (each) => each === "RxsInf.Rx No"
          );
          if (rx_number >= 0) {
            indexes["rx_number"] = rx_number;
          } else {
            missingIndex.push("RxsInf.Rx No");
          }
          let dispense_date = headerRow.findIndex(
            (each) => each === "RxsInf.Date Filled"
          );
          if (dispense_date >= 0) {
            indexes["dispense_date"] = dispense_date;
          } else {
            missingIndex.push("RxsInf.Date Filled");
          }
          let patient_dob = headerRow.findIndex(
            (each) => each === "Patient.Birth"
          );
          if (patient_dob >= 0) {
            indexes["patient_dob"] = patient_dob;
          } else {
            missingIndex.push("Patient.Birth");
          }
          let doctor_npi = headerRow.findIndex(
            (each) => each === "Doctors.NPI"
          );
          if (doctor_npi >= 0) {
            indexes["doctor_npi"] = doctor_npi;
          } else {
            missingIndex.push("Doctors.NPI");
          }
          let drug_name = headerRow.findIndex(
            (each) => each === "Drugs.Drug Name"
          );
          if (drug_name >= 0) {
            indexes["drug_name"] = drug_name;
          } else {
            missingIndex.push("Drugs.Drug Name");
          }
          let ndc = headerRow.findIndex((each) => each === "Drugs.NDC");
          if (ndc >= 0) {
            indexes["ndc"] = ndc;
          } else {
            missingIndex.push("Drugs.NDC");
          }
          let qty_dispensed = headerRow.findIndex(
            (each) => each === "RxsInf.Qty Dispensed"
          );
          if (qty_dispensed >= 0) {
            indexes["qty_dispensed"] = qty_dispensed;
          } else {
            missingIndex.push("RxsInf.Qty Dispensed");
          }
          let day_supply = headerRow.findIndex(
            (each) => each === "RxsInf.Days Supply"
          );
          if (day_supply >= 0) {
            indexes["day_supply"] = day_supply;
          } else {
            missingIndex.push("RxsInf.Days Supply");
          }
          let patient_zip = headerRow.findIndex(
            (each) => each === "Patient.Zip"
          );
          if (patient_zip >= 0) {
            indexes["patient_zip"] = patient_zip;
          } else {
            missingIndex.push("Patient.Zip");
          }
          let patient_fname = headerRow.findIndex(
            (each) => each === "Patient.First Name"
          );
          if (patient_fname >= 0) {
            indexes["patient_fname"] = patient_fname;
          } else {
            missingIndex.push("Patient.First Name");
          }
          let patient_lname = headerRow.findIndex(
            (each) => each === "Patient.Last Name"
          );
          if (patient_lname >= 0) {
            indexes["patient_lname"] = patient_lname;
          } else {
            missingIndex.push("Patient.Last Name");
          }
          let doctor_zip = headerRow.findIndex(
            (each) => each === "Doctors.Zip"
          );
          if (doctor_zip >= 0) {
            indexes["doctor_zip"] = doctor_zip;
          } else {
            missingIndex.push("Doctors.Zip");
          }
          let doctor_dea = headerRow.findIndex(
            (each) => each === "Doctors.DEA"
          );
          if (doctor_dea >= 0) {
            indexes["doctor_dea"] = doctor_dea;
          } else {
            missingIndex.push("Doctors.DEA");
          }
          let payment_type = headerRow.findIndex(
            (each) => each === "RxsInf.AnsiBin"
          );
          if (payment_type >= 0) {
            indexes["payment_type"] = payment_type;
          } else {
            missingIndex.push("RxsInf.AnsiBin");
          }
          let pharmacy_initial = headerRow.findIndex(
            (each) => each === "Phrmcst.Initial"
          );
          if (pharmacy_initial >= 0) {
            indexes["pharmacy_initial"] = pharmacy_initial;
          }
          if (missingIndex.length > 0) {
            updateState({ loading: false });
            confirmAlert({
              title: "Columns Missing",
              message:
                missingIndex.toString() +
                " are missing in the file, Please add these columns and upload again.",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => window.location.reload(),
                },
              ],
            });
          } else {
            for (let i in results.data) {
              let each = results.data[i];
              if (each[0] == "") {
                continue;
              }
              each["index"] = parseInt(i) + 1;
              // results.data.map((each, index) => {
              if (
                each[indexes["ndc"]] !== "Drugs.NDC" &&
                each[indexes["ndc"]] !== undefined &&
                each[indexes["ndc"]] !== "" &&
                parseInt(each[indexes["ndc"]]) > 0
              ) {
                each[indexes["ndc"]] = each[indexes["ndc"]].padStart(11, "0");
                // console.log(each)
                let drugFound = ncDrugs.find(
                  (eachD) => eachD.ndc == each[indexes["ndc"]]
                );
                if (/[a-zA-Z]/g.test(each[indexes["ndc"]])) {
                  errorItem.push(each["index"]);
                } else if (drugFound === undefined) {
                  notFound.push(each);
                  found.push(each);
                } else if (
                  ["2N", "2", "3", "3N", "4", "5"].includes(drugFound.dea)
                ) {
                  found.push(each);
                } else {
                }
              }
            }

            if (errorItem.length > 0 && errorItem !== undefined) {
              this.setState({ loading: false });
              confirmAlert({
                title: "Error in CSV",
                message:
                  "Row no. " +
                  errorItem.toString() +
                  " contains errors and will be skipped. The data must be entered manually.",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => saveIndexesCerner(notFound, found, indexes),
                  },
                ],
              });
            } else {
              saveIndexesCerner(notFound, found, indexes);
            }
          }
        }
      },
    });
  };

  saveIndexesCerner = (notFound, found, indexes) => {
    this.setState(
      {
        indexes,
      },
      () => this.checkNDCCerner(notFound, found)
    );
  };

  checkNDCCerner = (notFound, found) => {
    console.log("checkNDCCerner");
    console.log(this.state.found);
    if (found.length === 0) {
      this.setState({ loading: false });
      confirmAlert({
        title: "Warning",
        message: "No records to upload",
        buttons: [
          {
            label: "Ok",
            onClick: () => (window.location.href = "/dispense"),
          },
        ],
      });
    } else {
      this.setState({ loading: true });
      if (notFound.length > 0 && notFound !== undefined) {
        this.setState(
          {
            notFound,
            found,
            loading: false,
          },
          () => $("#quick-drug-moda-button").trigger("click")
        );
      } else if (found.length > 0 && found !== undefined) {
        this.setState(
          {
            found,
          },
          () => this.checkDuplicateCerner()
        );
      }
    }
  };

  checkDuplicateCerner = () => {
    $("#quick-drug-modal").modal("hide");
    let { found, indexes, notFound } = this.state;
    console.log("checkDuplicateCerner");
    console.log(found);
    let foundArr = [];
    let nonControlled = [];
    if (notFound.length > 0) {
      let filtereData = {};
      filtereData = {
        ndc: { ndc: { $in: notFound.map((each) => each[indexes["ndc"]]) } },
      };
      axios
        .post("/api/nc-drugs", filtereData)
        .then((res) => {
          for (let i in res.data.records) {
            let each = res.data.records[i];
            if (!["2N", "2", "3", "3N", "4", "5"].includes(each.dea)) {
              nonControlled.push(each.ndc);
            }
          }

          if (found.length > 0) {
            for (let i in found) {
              let each = found[i];
              if (nonControlled.includes(each[indexes["ndc"]])) {
                each["nonControlled"] = true;
              }
              foundArr.push({
                $and: [
                  { ndc: each[indexes["ndc"]] },
                  { dispense_date: each[indexes["dispense_date"]] },
                  { rx_number: each[indexes["rx_number"]] },
                  { pharmacy_account: this.props.auth.user.final_user },
                  { deleted: { $ne: true } },
                ],
              });
            }
            axios
              .post("/api/dispense-check", foundArr)
              .then((res) => {
                let responsed = res.data.records;
                if (responsed.length > 0) {
                  let duplicates = [];
                  for (let i in found) {
                    let each = found[i];
                    responsed.filter((eachR) => {
                      if (
                        eachR["ndc"] === each[indexes["ndc"]] &&
                        eachR["rx_number"] === each[indexes["rx_number"]] &&
                        moment(eachR["dispense_date"]).isSame(
                          each[indexes["dispense_date"]]
                        )
                      ) {
                        each["duplicate"] = true;
                        duplicates.push(each["index"]);
                      }
                    });
                  }
                  let finalFilter = found.filter(
                    (eachF) => eachF["duplicate"] !== true
                  );
                  this.setState({ loading: false });
                  confirmAlert({
                    title: "Duplicate entries",
                    message:
                      "Row " +
                      duplicates
                        .filter((v, i, a) => a.indexOf(v) == i)
                        .toString() +
                      " was previously added and will not be uploaded",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => this.getNpiDetailCerner(finalFilter),
                      },
                    ],
                  });
                } else {
                  this.getNpiDetailCerner(found);
                }
              })
              .catch(function (error) {
                if (error.response) {
                  if (error.response.status === 403) {
                    window.location.href = "./login";
                  }
                }
              });
          } else {
          }
        })

        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
      if (found.length > 0) {
        for (let i in found) {
          let each = found[i];
          if (nonControlled.includes(each[indexes["ndc"]])) {
            each["nonControlled"] = true;
          }
          foundArr.push({
            $and: [
              { ndc: each[indexes["ndc"]] },
              { dispense_date: each[indexes["dispense_date"]] },
              { rx_number: each[indexes["rx_number"]] },
              { pharmacy_account: this.props.auth.user.final_user },
              { deleted: { $ne: true } },
            ],
          });
        }
        axios
          .post("/api/dispense-check", foundArr)
          .then((res) => {
            let responsed = res.data.records;
            let copyFound = JSON.parse(JSON.stringify(found));
            if (responsed.length > 0) {
              let duplicates = [];
              for (let i in found) {
                let each = found[i];
                responsed.filter((eachR) => {
                  if (
                    eachR["ndc"] === each[indexes["ndc"]] &&
                    eachR["rx_number"] === each[indexes["rx_number"]] &&
                    moment(eachR["dispense_date"]).isSame(
                      each[indexes["dispense_date"]]
                    )
                  ) {
                    each["duplicate"] = true;
                    duplicates.push(each["index"]);
                  }
                });
              }
              let finalFilter = found.filter(
                (eachF) => eachF["duplicate"] !== true
              );
              this.setState({ loading: false });
              confirmAlert({
                title: "Duplicate entries",
                message:
                  "Row " +
                  duplicates.filter((v, i, a) => a.indexOf(v) == i).toString() +
                  " was previously added and will not be uploaded",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => this.getNpiDetailCerner(finalFilter),
                  },
                ],
              });
            } else {
              this.getNpiDetailCerner(copyFound);
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status === 403) {
                window.location.href = "./login";
              }
            }
          });
      } else {
      }
    }
  };

  getNpiDetailCerner = (found) => {
    console.log("getNpiDetailCerner");
    console.log(found);
    let { indexes } = this.state;
    if (found.length > 0) {
      let npiArr = [];
      for (let i in found) {
        let each = found[i];
        npiArr.push(each[indexes["doctor_npi"]]);
      }
      let resultNpiDetails = {};
      this.setState({ loading: true });
      axios
        .post("/api/doctors-by-npi", npiArr)
        .then((res) => {
          for (let i = 0; i < res.data.records.length; i++) {
            let eachNpi = res.data.records[i];
            resultNpiDetails[eachNpi.npi] = eachNpi;
          }
          this.saveEntriesCerner(found, resultNpiDetails);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
      confirmAlert({
        title: "Nothing to upload",
        message: "There are no row to upload",
        buttons: [
          {
            label: "Ok",
            onClick: () => window.location.reload(),
          },
        ],
      });
    }
  };

  saveEntriesCerner = (found, resultNpiDetails) => {
    const { drugNameById, indexes, drugDeaById } = this.state;
    let saveData = [];
    for (let i in found) {
      let each = found[i];
      if (each.nonControlled) {
        continue;
      }
      saveData.push({
        ndc: each[indexes["ndc"]],
        dispense_date: each[indexes["dispense_date"]],
        rx_number: each[indexes["rx_number"]],
        drug_name:
          drugNameById[each[indexes["ndc"]]] !== undefined
            ? drugNameById[each[indexes["ndc"]]]
            : each[indexes["drug_name"]],
        dea: drugDeaById[each[indexes["ndc"]]],
        qty_dispensed: parseInt(each[indexes["qty_dispensed"]]),
        day_supply: each[indexes["day_supply"]],
        patient_name:
          each[indexes["patient_fname"]].charAt(0).toUpperCase() +
          each[indexes["patient_lname"]].charAt(0).toUpperCase(),
        patient_zip: each[indexes["patient_zip"]],
        patient_dob: each[indexes["patient_dob"]],
        pharmacy_account: this.props.auth.user.final_user,
        user_created: this.props.auth.user.email,
        doctor_firstname:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].first_name
            : "",
        doctor_lastname:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].last_name
            : "",
        doctor_npi: each[indexes["doctor_npi"]],
        doctor_add1:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_first_line
            : "",
        doctor_city:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_city
            : "",
        doctor_state:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_state
            : "",
        doctor_zip: each[indexes["doctor_zip"]],
        doctor_dea: each[indexes["doctor_dea"]],
        payment_type: bins.includes(each[indexes["payment_type"]]) ? "Y" : "N",
        pharmacy_initial: each[indexes["pharmacy_initial"]],
      });
    }
    console.log(saveData);
    axios
      .post("/api/dispense-upload-save", saveData)
      .then((res) => {
        this.setState({ loading: false });
        confirmAlert({
          title: "Success",
          message: "Sheet uplaoded Successfully",
          buttons: [
            {
              label: "Ok",
              onClick: () => (window.location.href = "/dispense"),
            },
          ],
        });
      })

      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  //Liberty Start
  fromLiberty = () => {
    let { ncDrugs, indexes } = this.state;
    const saveIndexesLiberty = this.saveIndexesLiberty;
    const updateState = this.updateState;
    Papa.parse(this.state.file, {
      complete: function (results) {
        if (results.data.length < 2) {
          alert("Invalid CSV file.");
          return false;
        } else {
          let notFound = [];
          let found = [];
          let errorItem = [];
          let missingIndex = [];
          let headerRow = results.data[0];
          let rx_number = headerRow.findIndex((each) => each === "Script");
          if (rx_number >= 0) {
            indexes["rx_number"] = rx_number;
          } else {
            missingIndex.push("Script");
          }
          let dispense_date = headerRow.findIndex(
            (each) => each === "Date Dispensed"
          );
          if (dispense_date >= 0) {
            indexes["dispense_date"] = dispense_date;
          } else {
            missingIndex.push("Date Dispensed");
          }
          let patient_dob = headerRow.findIndex(
            (each) => each === "Customer DOB"
          );
          if (patient_dob >= 0) {
            indexes["patient_dob"] = patient_dob;
          } else {
            missingIndex.push("Customer DOB");
          }
          let doctor_npi = headerRow.findIndex(
            (each) => each === "Prescriber NPI"
          );
          if (doctor_npi >= 0) {
            indexes["doctor_npi"] = doctor_npi;
          } else {
            missingIndex.push("Prescriber NPI");
          }
          let drug_name = headerRow.findIndex((each) => each === "Drug Name");
          if (drug_name >= 0) {
            indexes["drug_name"] = drug_name;
          } else {
            missingIndex.push("Drug Name");
          }
          let ndc = headerRow.findIndex((each) => each === "Drug NDC");
          if (ndc >= 0) {
            indexes["ndc"] = ndc;
          } else {
            missingIndex.push("Drug NDC");
          }
          let qty_dispensed = headerRow.findIndex((each) => each === "Qty");
          if (qty_dispensed >= 0) {
            indexes["qty_dispensed"] = qty_dispensed;
          } else {
            missingIndex.push("Qty");
          }
          let day_supply = headerRow.findIndex((each) => each === "Day Supply");
          if (day_supply >= 0) {
            indexes["day_supply"] = day_supply;
          } else {
            missingIndex.push("Day Supply");
          }
          let patient_zip = headerRow.findIndex(
            (each) => each === "Customer Zip"
          );
          if (patient_zip >= 0) {
            indexes["patient_zip"] = patient_zip;
          } else {
            missingIndex.push("Customer Zip");
          }
          let customer_id = headerRow.findIndex(
            (each) => each === "Customer Id"
          );
          if (customer_id >= 0) {
            indexes["customer_id"] = customer_id;
          } else {
            missingIndex.push("Customer Id");
          }
          let doctor_zip = headerRow.findIndex(
            (each) => each === "Prescriber Zip"
          );
          if (doctor_zip >= 0) {
            indexes["doctor_zip"] = doctor_zip;
          } else {
            missingIndex.push("Prescriber Zip");
          }
          let doctor_dea = headerRow.findIndex(
            (each) => each === "Prescriber DEA"
          );
          if (doctor_dea >= 0) {
            indexes["doctor_dea"] = doctor_dea;
          } else {
            missingIndex.push("Prescriber DEA");
          }
          let payment_type = headerRow.findIndex((each) => each === "Bin");
          if (payment_type >= 0) {
            indexes["payment_type"] = payment_type;
          } else {
            missingIndex.push("Bin");
          }
          let deaClass = headerRow.findIndex((each) => each === "Sch");
          if (deaClass >= 0) {
            indexes["dea"] = deaClass;
          } else {
            missingIndex.push("Sch");
          }
          let pharmacy_initial = headerRow.findIndex(
            (each) => each === "Rph Initials"
          );
          if (pharmacy_initial >= 0) {
            indexes["pharmacy_initial"] = pharmacy_initial;
          }
          if (missingIndex.length > 0) {
            updateState({ loading: false });
            confirmAlert({
              title: "Columns Missing",
              message:
                missingIndex.toString() +
                " - missing in the file, Please add these columns and upload again.",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => window.location.reload(),
                },
              ],
            });
          } else {
            let alreadyInNotFound = [];
            for (let i in results.data) {
              let each = results.data[i];
              if (each[0] == "") {
                continue;
              }
              each["index"] = parseInt(i) + 1;

              // results.data.map((each, index) => {
              if (
                each[indexes["ndc"]] !== "Drug NDC" &&
                each[indexes["ndc"]] !== undefined &&
                each[indexes["ndc"]] !== "" &&
                parseInt(each[indexes["ndc"]]) > 0
              ) {
                each[indexes["ndc"]] = each[indexes["ndc"]]
                  .padStart(11, "0")
                  .replaceAll("-", "");

                let drugFound = ncDrugs.find(
                  (eachD) => eachD.ndc == each[indexes["ndc"]]
                );
                // console.log(drugFound)
                if (/[a-zA-Z]/g.test(each[indexes["ndc"]])) {
                  errorItem.push(each["index"]);
                } else if (
                  drugFound === undefined &&
                  !isNaN(parseInt(each[indexes["dea"]])) &&
                  [2, 3, 4, 5].includes(parseInt(each[indexes["dea"]])) &&
                  !alreadyInNotFound.includes(each[indexes["ndc"]])
                ) {
                  notFound.push(each);
                  found.push(each);
                  alreadyInNotFound.push(each[indexes["ndc"]]);
                } else if (
                  !isNaN(parseInt(each[indexes["dea"]])) &&
                  [2, 3, 4, 5].includes(parseInt(each[indexes["dea"]]))
                ) {
                  found.push(each);
                }
              }
            }

            console.log("found");
            console.log(found);
            console.log("notFound");
            console.log(notFound);
            console.log("errorItem");
            console.log(errorItem);

            if (errorItem.length > 0 && errorItem !== undefined) {
              this.setState({ loading: false });
              confirmAlert({
                title: "Error in CSV",
                message:
                  "Row no. " +
                  errorItem.toString() +
                  " contains errors and will be skipped. The data must be entered manually.",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => saveIndexesLiberty(notFound, found, indexes),
                  },
                ],
              });
            } else {
              saveIndexesLiberty(notFound, found, indexes);
            }
          }
        }
      },
    });
  };

  saveIndexesLiberty = (notFound, found, indexes) => {
    this.setState(
      {
        indexes,
      },
      () => this.checkNDCLiberty(notFound, found)
    );
  };

  checkNDCLiberty = (notFound, found) => {
    if (found.length === 0) {
      this.setState({ loading: false });
      confirmAlert({
        title: "Warning",
        message: "No records to upload",
        buttons: [
          {
            label: "Ok",
            onClick: () => (window.location.href = "/dispense"),
          },
        ],
      });
    } else {
      console.log("checkNDCLiberty");
      console.log(found);
      this.setState({ loading: true });
      if (notFound.length > 0 && notFound !== undefined) {
        this.setState(
          {
            notFound,
            found,
            loading: false,
          },
          () => $("#quick-drug-moda-button").trigger("click")
        );
      } else if (found.length > 0 && found !== undefined) {
        this.setState(
          {
            found,
          },
          () => this.checkDuplicateLiberty()
        );
      }
    }
  };

  checkDuplicateLiberty = () => {
    $("#quick-drug-modal").modal("hide");
    let { found, indexes, notFound } = this.state;
    console.log("checkDuplicateLiberty");
    console.log(found);
    let foundArr = [];
    let nonControlled = [];
    if (notFound.length > 0) {
      let filtereData = {};
      filtereData = {
        ndc: { ndc: { $in: notFound.map((each) => each[indexes["ndc"]]) } },
      };
      axios
        .post("/api/nc-drugs", filtereData)
        .then((res) => {
          for (let i in res.data.records) {
            let each = res.data.records[i];
            if (!["2N", "2", "3", "3N", "4", "5"].includes(each.dea)) {
              nonControlled.push(each.ndc);
            }
          }

          if (found.length > 0) {
            for (let i in found) {
              let each = found[i];
              if (nonControlled.includes(each[indexes["ndc"]])) {
                each["nonControlled"] = true;
              }
              foundArr.push({
                $and: [
                  { ndc: each[indexes["ndc"]] },
                  { dispense_date: each[indexes["dispense_date"]] },
                  { rx_number: each[indexes["rx_number"]] },
                  { pharmacy_account: this.props.auth.user.final_user },
                  { deleted: { $ne: true } },
                ],
              });
            }
            axios
              .post("/api/dispense-check", foundArr)
              .then((res) => {
                let responsed = res.data.records;
                if (responsed.length > 0) {
                  let duplicates = [];
                  for (let i in found) {
                    let each = found[i];
                    responsed.filter((eachR) => {
                      if (
                        eachR["ndc"] === each[indexes["ndc"]] &&
                        eachR["rx_number"] === each[indexes["rx_number"]] &&
                        moment(eachR["dispense_date"]).isSame(
                          each[indexes["dispense_date"]]
                        )
                      ) {
                        each["duplicate"] = true;
                        duplicates.push(each["index"]);
                      }
                    });
                  }
                  let finalFilter = found.filter(
                    (eachF) => eachF["duplicate"] !== true
                  );
                  this.setState({ loading: false });
                  confirmAlert({
                    title: "Duplicate entries",
                    message:
                      "Row " +
                      duplicates
                        .filter((v, i, a) => a.indexOf(v) == i)
                        .toString() +
                      " was previously added and will not be uploaded",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => this.getNpiDetailLiberty(finalFilter),
                      },
                    ],
                  });
                } else {
                  this.getNpiDetailLiberty(found);
                }
              })
              .catch(function (error) {
                if (error.response) {
                  if (error.response.status === 403) {
                    window.location.href = "./login";
                  }
                }
              });
          } else {
          }
        })

        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
      if (found.length > 0) {
        for (let i in found) {
          let each = found[i];
          if (nonControlled.includes(each[indexes["ndc"]])) {
            each["nonControlled"] = true;
          }
          foundArr.push({
            $and: [
              { ndc: each[indexes["ndc"]] },
              { dispense_date: each[indexes["dispense_date"]] },
              { rx_number: each[indexes["rx_number"]] },
              { pharmacy_account: this.props.auth.user.final_user },
              { deleted: { $ne: true } },
            ],
          });
        }
        axios
          .post("/api/dispense-check", foundArr)
          .then((res) => {
            let responsed = res.data.records;
            let copyFound = JSON.parse(JSON.stringify(found));
            if (responsed.length > 0) {
              let duplicates = [];
              for (let i in found) {
                let each = found[i];
                responsed.filter((eachR) => {
                  if (
                    eachR["ndc"] === each[indexes["ndc"]] &&
                    eachR["rx_number"] === each[indexes["rx_number"]] &&
                    moment(eachR["dispense_date"]).isSame(
                      each[indexes["dispense_date"]]
                    )
                  ) {
                    each["duplicate"] = true;
                    duplicates.push(each["index"]);
                  }
                });
              }
              let finalFilter = found.filter(
                (eachF) => eachF["duplicate"] !== true
              );
              this.setState({ loading: false });
              confirmAlert({
                title: "Duplicate entries",
                message:
                  "Row " +
                  duplicates.filter((v, i, a) => a.indexOf(v) == i).toString() +
                  " was previously added and will not be uploaded",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => this.getNpiDetailLiberty(finalFilter),
                  },
                ],
              });
            } else {
              this.getNpiDetailLiberty(copyFound);
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status === 403) {
                window.location.href = "./login";
              }
            }
          });
      } else {
      }
    }
  };

  getNpiDetailLiberty = (found) => {
    console.log("getNpiDetailLiberty");
    console.log(found);
    let { indexes } = this.state;
    if (found.length > 0) {
      let npiArr = [];
      for (let i in found) {
        let each = found[i];
        npiArr.push(each[indexes["doctor_npi"]]);
      }
      let resultNpiDetails = {};
      this.setState({ loading: true });
      axios
        .post("/api/doctors-by-npi", npiArr)
        .then((res) => {
          for (let i = 0; i < res.data.records.length; i++) {
            let eachNpi = res.data.records[i];
            resultNpiDetails[eachNpi.npi] = eachNpi;
          }
          this.saveEntriesLiberty(found, resultNpiDetails);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
      confirmAlert({
        title: "Nothing to upload",
        message: "There are no row to upload",
        buttons: [
          {
            label: "Ok",
            onClick: () => window.location.reload(),
          },
        ],
      });
    }
  };

  saveEntriesLiberty = (found, resultNpiDetails) => {
    const { drugNameById, indexes, drugDeaById } = this.state;
    let saveData = [];
    for (let i in found) {
      let each = found[i];
      if (each.nonControlled) {
        continue;
      }
      saveData.push({
        ndc: each[indexes["ndc"]],
        dispense_date: each[indexes["dispense_date"]],
        rx_number: each[indexes["rx_number"]],
        drug_name:
          drugNameById[each[indexes["ndc"]]] !== undefined
            ? drugNameById[each[indexes["ndc"]]]
            : each[indexes["drug_name"]],
        dea: drugDeaById[each[indexes["ndc"]]],
        qty_dispensed: parseInt(each[indexes["qty_dispensed"]]),
        day_supply: each[indexes["day_supply"]],
        patient_name: each[indexes["customer_id"]],
        patient_zip: each[indexes["patient_zip"]],
        patient_dob: each[indexes["patient_dob"]],
        pharmacy_account: this.props.auth.user.final_user,
        user_created: this.props.auth.user.email,
        doctor_firstname:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].first_name
            : "",
        doctor_lastname:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].last_name
            : "",
        doctor_npi: each[indexes["doctor_npi"]],
        doctor_add1:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_first_line
            : "",
        doctor_city:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_city
            : "",
        doctor_state:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_state
            : "",
        doctor_zip: each[indexes["doctor_zip"]],
        doctor_dea: each[indexes["doctor_dea"]],
        payment_type: each[indexes["payment_type"]] === "" ? "Y" : "N",
        pharmacy_initial: each[indexes["pharmacy_initial"]],
      });
    }
    console.log(saveData);
    axios
      .post("/api/dispense-upload-save", saveData)
      .then((res) => {
        this.setState({ loading: false });
        confirmAlert({
          title: "Success",
          message: "Sheet uplaoded Successfully",
          buttons: [
            {
              label: "Ok",
              onClick: () => (window.location.href = "/dispense"),
            },
          ],
        });
      })

      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  //ComputerRx Start
  fromComputerRx = () => {
    let { ncDrugs, indexes } = this.state;
    const saveIndexesComputerRx = this.saveIndexesComputerRx;
    const updateState = this.updateState;
    Papa.parse(this.state.file, {
      complete: function (results) {
        if (results.data.length < 2) {
          alert("Invalid CSV file.");
          return false;
        } else {
          let notFound = [];
          let found = [];
          let errorItem = [];
          let missingIndex = [];
          let headerRow = results.data[0];
          let rx_number = headerRow.findIndex((each) => each === "Rx Number");
          if (rx_number >= 0) {
            indexes["rx_number"] = rx_number;
          } else {
            missingIndex.push("Rx Number");
          }
          let dispense_date = headerRow.findIndex((each) => each === "Date");
          if (dispense_date >= 0) {
            indexes["dispense_date"] = dispense_date;
          } else {
            missingIndex.push("Date");
          }
          let patient_dob = headerRow.findIndex(
            (each) => each === "Patient DOB"
          );
          if (patient_dob >= 0) {
            indexes["patient_dob"] = patient_dob;
          } else {
            missingIndex.push("Patient DOB");
          }
          let doctor_npi = headerRow.findIndex((each) => each === "Doctor NPI");
          if (doctor_npi >= 0) {
            indexes["doctor_npi"] = doctor_npi;
          } else {
            missingIndex.push("Doctor NPI");
          }
          let doctor_dea = headerRow.findIndex((each) => each === "Doctor DEA");
          if (doctor_dea >= 0) {
            indexes["doctor_dea"] = doctor_dea;
          } else {
            missingIndex.push("Doctor DEA");
          }
          let drug_name = headerRow.findIndex((each) => each === "Drug");
          if (drug_name >= 0) {
            indexes["drug_name"] = drug_name;
          } else {
            missingIndex.push("Drug");
          }
          let ndc = headerRow.findIndex((each) => each === "NDC");
          if (ndc >= 0) {
            indexes["ndc"] = ndc;
          } else {
            missingIndex.push("NDC");
          }
          let qty_dispensed = headerRow.findIndex((each) => each === "Quan.");
          if (qty_dispensed >= 0) {
            indexes["qty_dispensed"] = qty_dispensed;
          } else {
            missingIndex.push("Quan.");
          }
          let day_supply = headerRow.findIndex((each) => each === "Day Supply");
          if (day_supply >= 0) {
            indexes["day_supply"] = day_supply;
          } else {
            missingIndex.push("Day Supply");
          }
          let patient_zip = headerRow.findIndex(
            (each) => each === "Patient Zip"
          );
          if (patient_zip >= 0) {
            indexes["patient_zip"] = patient_zip;
          } else {
            missingIndex.push("Patient Zip");
          }
          let customer_id = headerRow.findIndex((each) => each === "Patient");
          if (customer_id >= 0) {
            indexes["customer_id"] = customer_id;
          } else {
            missingIndex.push("Patient");
          }
          let doctor_zip = headerRow.findIndex((each) => each === "Doctor Zip");
          if (doctor_zip >= 0) {
            indexes["doctor_zip"] = doctor_zip;
          } else {
            missingIndex.push("Doctor Zip");
          }
          let payment_type = headerRow.findIndex(
            (each) => each === "Primary Payer BIN"
          );
          if (payment_type >= 0) {
            indexes["payment_type"] = payment_type;
          } else {
            missingIndex.push("Primary Payer BIN");
          }
          let deaClass = headerRow.findIndex((each) => each === "Sched");
          if (deaClass >= 0) {
            indexes["dea"] = deaClass;
          } else {
            missingIndex.push("Sched");
          }
          let pharmacy_initial = headerRow.findIndex(
            (each) => each === "Pharmacist"
          );
          if (pharmacy_initial >= 0) {
            indexes["pharmacy_initial"] = pharmacy_initial;
          }
          if (missingIndex.length > 0) {
            updateState({ loading: false });
            confirmAlert({
              title: "Columns Missing",
              message:
                missingIndex.toString() +
                " - missing in the file, Please add these columns and upload again.",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => window.location.reload(),
                },
              ],
            });
          } else {
            let alreadyInNotFound = [];
            for (let i in results.data) {
              let each = results.data[i];
              if (each[0] == "") {
                continue;
              }
              each["index"] = parseInt(i) + 1;

              // results.data.map((each, index) => {
              if (
                each[indexes["ndc"]] !== "NDC" &&
                each[indexes["ndc"]] !== undefined &&
                each[indexes["ndc"]] !== "" &&
                parseInt(each[indexes["ndc"]]) > 0
              ) {
                each[indexes["ndc"]] = each[indexes["ndc"]]
                  .padStart(11, "0")
                  .replaceAll("-", "");

                let drugFound = ncDrugs.find(
                  (eachD) => eachD.ndc == each[indexes["ndc"]]
                );
                // console.log(drugFound)
                if (/[a-zA-Z]/g.test(each[indexes["ndc"]])) {
                  errorItem.push(each["index"]);
                } else if (
                  drugFound === undefined &&
                  !isNaN(parseInt(each[indexes["dea"]])) &&
                  [2, 3, 4, 5].includes(parseInt(each[indexes["dea"]])) &&
                  !alreadyInNotFound.includes(each[indexes["ndc"]])
                ) {
                  notFound.push(each);
                  found.push(each);
                  alreadyInNotFound.push(each[indexes["ndc"]]);
                } else if (
                  !isNaN(parseInt(each[indexes["dea"]])) &&
                  [2, 3, 4, 5].includes(parseInt(each[indexes["dea"]]))
                ) {
                  found.push(each);
                }
              }
            }
            if (errorItem.length > 0 && errorItem !== undefined) {
              updateState({ loading: false });
              confirmAlert({
                title: "Error in CSV",
                message:
                  "Row no. " +
                  errorItem.toString() +
                  " contains errors and will be skipped. The data must be entered manually.",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () =>
                      saveIndexesComputerRx(notFound, found, indexes),
                  },
                ],
              });
            } else {
              saveIndexesComputerRx(notFound, found, indexes);
            }
          }
        }
      },
    });
  };

  saveIndexesComputerRx = (notFound, found, indexes) => {
    this.setState(
      {
        indexes,
      },
      () => this.checkNDCComputerRx(notFound, found)
    );
  };

  checkNDCComputerRx = (notFound, found) => {
    console.log("checkNDCComputerRx");
    console.log(found);
    if (found.length === 0) {
      this.setState({ loading: false });
      confirmAlert({
        title: "Warning",
        message: "No records to upload",
        buttons: [
          {
            label: "Ok",
            onClick: () => (window.location.href = "/dispense"),
          },
        ],
      });
    } else {
      this.setState({ loading: true });
      if (notFound.length > 0 && notFound !== undefined) {
        this.setState(
          {
            notFound,
            found,
            loading: false,
          },
          () => $("#quick-drug-moda-button").trigger("click")
        );
      } else if (found.length > 0 && found !== undefined) {
        this.setState(
          {
            found,
          },
          () => this.checkDuplicateComputerRx()
        );
      }
    }
  };

  checkDuplicateComputerRx = () => {
    $("#quick-drug-modal").modal("hide");
    let { found, indexes, notFound } = this.state;
    console.log("checkDuplicateComputerRx");
    console.log(found);
    let foundArr = [];
    let nonControlled = [];
    if (notFound.length > 0) {
      let filtereData = {};
      filtereData = {
        ndc: { ndc: { $in: notFound.map((each) => each[indexes["ndc"]]) } },
      };
      axios
        .post("/api/nc-drugs", filtereData)
        .then((res) => {
          for (let i in res.data.records) {
            let each = res.data.records[i];
            if (!["2N", "2", "3", "3N", "4", "5"].includes(each.dea)) {
              nonControlled.push(each.ndc);
            }
          }

          if (found.length > 0) {
            for (let i in found) {
              let each = found[i];
              if (nonControlled.includes(each[indexes["ndc"]])) {
                each["nonControlled"] = true;
              }
              foundArr.push({
                $and: [
                  { ndc: each[indexes["ndc"]] },
                  { dispense_date: each[indexes["dispense_date"]] },
                  { rx_number: each[indexes["rx_number"]] },
                  { pharmacy_account: this.props.auth.user.final_user },
                  { deleted: { $ne: true } },
                ],
              });
            }
            axios
              .post("/api/dispense-check", foundArr)
              .then((res) => {
                let responsed = res.data.records;
                if (responsed.length > 0) {
                  let duplicates = [];
                  for (let i in found) {
                    let each = found[i];
                    responsed.filter((eachR) => {
                      if (
                        eachR["ndc"] === each[indexes["ndc"]] &&
                        eachR["rx_number"] === each[indexes["rx_number"]] &&
                        moment(eachR["dispense_date"]).isSame(
                          each[indexes["dispense_date"]]
                        )
                      ) {
                        each["duplicate"] = true;
                        duplicates.push(each["index"]);
                      }
                    });
                  }
                  let finalFilter = found.filter(
                    (eachF) => eachF["duplicate"] !== true
                  );
                  this.setState({ loading: false });
                  confirmAlert({
                    title: "Duplicate entries",
                    message:
                      "Row " +
                      duplicates
                        .filter((v, i, a) => a.indexOf(v) == i)
                        .toString() +
                      " was previously added and will not be uploaded",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => this.getNpiDetailComputerRx(finalFilter),
                      },
                    ],
                  });
                } else {
                  this.getNpiDetailComputerRx(found);
                }
              })
              .catch(function (error) {
                if (error.response) {
                  if (error.response.status === 403) {
                    window.location.href = "./login";
                  }
                }
              });
          } else {
          }
        })

        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
      if (found.length > 0) {
        for (let i in found) {
          let each = found[i];
          if (nonControlled.includes(each[indexes["ndc"]])) {
            each["nonControlled"] = true;
          }
          foundArr.push({
            $and: [
              { ndc: each[indexes["ndc"]] },
              { dispense_date: each[indexes["dispense_date"]] },
              { rx_number: each[indexes["rx_number"]] },
              { pharmacy_account: this.props.auth.user.final_user },
              { deleted: { $ne: true } },
            ],
          });
        }
        axios
          .post("/api/dispense-check", foundArr)
          .then((res) => {
            let responsed = res.data.records;
            let copyFound = JSON.parse(JSON.stringify(found));
            if (responsed.length > 0) {
              let duplicates = [];
              for (let i in found) {
                let each = found[i];
                responsed.filter((eachR) => {
                  if (
                    eachR["ndc"] === each[indexes["ndc"]] &&
                    eachR["rx_number"] === each[indexes["rx_number"]] &&
                    moment(eachR["dispense_date"]).isSame(
                      each[indexes["dispense_date"]]
                    )
                  ) {
                    each["duplicate"] = true;
                    duplicates.push(each["index"]);
                  }
                });
              }
              let finalFilter = found.filter(
                (eachF) => eachF["duplicate"] !== true
              );
              this.setState({ loading: false });
              confirmAlert({
                title: "Duplicate entries",
                message:
                  "Row " +
                  duplicates.filter((v, i, a) => a.indexOf(v) == i).toString() +
                  " was previously added and will not be uploaded",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => this.getNpiDetailComputerRx(finalFilter),
                  },
                ],
              });
            } else {
              this.getNpiDetailComputerRx(copyFound);
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status === 403) {
                window.location.href = "./login";
              }
            }
          });
      } else {
      }
    }
  };

  getNpiDetailComputerRx = (found) => {
    console.log("getNpiDetailComputerRx");
    console.log(found);
    let { indexes } = this.state;
    if (found.length > 0) {
      let npiArr = [];
      for (let i in found) {
        let each = found[i];
        npiArr.push(each[indexes["doctor_npi"]]);
      }
      let resultNpiDetails = {};
      this.setState({ loading: true });
      axios
        .post("/api/doctors-by-npi", npiArr)
        .then((res) => {
          for (let i = 0; i < res.data.records.length; i++) {
            let eachNpi = res.data.records[i];
            resultNpiDetails[eachNpi.npi] = eachNpi;
          }
          this.saveEntriesComputerRx(found, resultNpiDetails);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
      confirmAlert({
        title: "Nothing to upload",
        message: "There are no row to upload",
        buttons: [
          {
            label: "Ok",
            onClick: () => window.location.reload(),
          },
        ],
      });
    }
  };

  saveEntriesComputerRx = (found, resultNpiDetails) => {
    const { drugNameById, indexes, drugDeaById } = this.state;
    let saveData = [];
    for (let i in found) {
      let each = found[i];
      if (each.nonControlled) {
        continue;
      }
      let customerNameArr = each[indexes["customer_id"]].split(", ");
      let customerName =
        customerNameArr[1].charAt(0).toUpperCase() +
        customerNameArr[0].charAt(0).toUpperCase();
      saveData.push({
        ndc: each[indexes["ndc"]],
        dispense_date: each[indexes["dispense_date"]],
        rx_number: each[indexes["rx_number"]],
        drug_name:
          drugNameById[each[indexes["ndc"]]] !== undefined
            ? drugNameById[each[indexes["ndc"]]]
            : each[indexes["drug_name"]],
        dea: drugDeaById[each[indexes["ndc"]]],
        qty_dispensed: parseInt(each[indexes["qty_dispensed"]]),
        day_supply: each[indexes["day_supply"]],
        patient_name: customerName,
        patient_zip: each[indexes["patient_zip"]],
        patient_dob: each[indexes["patient_dob"]],
        pharmacy_account: this.props.auth.user.final_user,
        user_created: this.props.auth.user.email,
        doctor_firstname:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].first_name
            : "",
        doctor_lastname:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].last_name
            : "",
        doctor_npi: each[indexes["doctor_npi"]],
        doctor_add1:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_first_line
            : "",
        doctor_city:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_city
            : "",
        doctor_state:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_state
            : "",
        doctor_zip: each[indexes["doctor_zip"]],
        doctor_dea: each[indexes["doctor_dea"]],
        payment_type: each[indexes["payment_type"]] === "" ? "Y" : "N",
        pharmacy_initial: each[indexes["pharmacy_initial"]],
      });
    }
    console.log(saveData);
    axios
      .post("/api/dispense-upload-save", saveData)
      .then((res) => {
        this.setState({ loading: false });
        confirmAlert({
          title: "Success",
          message: "Sheet uplaoded Successfully",
          buttons: [
            {
              label: "Ok",
              onClick: () => (window.location.href = "/dispense"),
            },
          ],
        });
      })

      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  //Start PioneerRx
  fromPioneerRx = () => {
    let { ncDrugs, indexes } = this.state;
    const saveIndexesPioneerRx = this.saveIndexesPioneerRx;
    const updateState = this.updateState;
    Papa.parse(this.state.file, {
      complete: function (results) {
        if (results.data.length < 2) {
          alert("Invalid CSV file.");
          return false;
        } else {
          let notFound = [];
          let found = [];
          let errorItem = [];
          let missingIndex = [];
          let headerRow = results.data[0];
          let rx_number = headerRow.findIndex((each) => each === "Rx Number");
          if (rx_number >= 0) {
            indexes["rx_number"] = rx_number;
          } else {
            missingIndex.push("Rx Number");
          }
          let dispense_date = headerRow.findIndex(
            (each) => each === "Date Filled"
          );
          if (dispense_date >= 0) {
            indexes["dispense_date"] = dispense_date;
          } else {
            missingIndex.push("Date Filled");
          }
          let patient_dob = headerRow.findIndex(
            (each) => each === "Patient Date of Birth"
          );
          if (patient_dob >= 0) {
            indexes["patient_dob"] = patient_dob;
          } else {
            missingIndex.push("Patient Date of Birth");
          }
          let doctor_npi = headerRow.findIndex(
            (each) => each === "Prescriber NPI"
          );
          if (doctor_npi >= 0) {
            indexes["doctor_npi"] = doctor_npi;
          } else {
            missingIndex.push("Prescriber NPI");
          }
          let drug_name = headerRow.findIndex(
            (each) => each === "Dispensed Item Name"
          );
          if (drug_name >= 0) {
            indexes["drug_name"] = drug_name;
          } else {
            missingIndex.push("Dispensed Item Name");
          }
          let ndc = headerRow.findIndex(
            (each) => each === "Dispensed Item NDC"
          );
          if (ndc >= 0) {
            indexes["ndc"] = ndc;
          } else {
            missingIndex.push("Dispensed Item NDC");
          }
          let qty_dispensed = headerRow.findIndex(
            (each) => each === "Dispensed Quantity"
          );
          if (qty_dispensed >= 0) {
            indexes["qty_dispensed"] = qty_dispensed;
          } else {
            missingIndex.push("Dispensed Quantity");
          }
          let day_supply = headerRow.findIndex(
            (each) => each === "Days Supply"
          );
          if (day_supply >= 0) {
            indexes["day_supply"] = day_supply;
          } else {
            missingIndex.push("Days Supply");
          }
          let patient_zip = headerRow.findIndex(
            (each) => each === "Patient Primary Zip Code"
          );
          if (patient_zip >= 0) {
            indexes["patient_zip"] = patient_zip;
          } else {
            missingIndex.push("Patient Primary Zip Code");
          }
          let customer_id = headerRow.findIndex(
            (each) => each === "Patient Full Name"
          );
          if (customer_id >= 0) {
            indexes["customer_id"] = customer_id;
          } else {
            missingIndex.push("Patient Full Name");
          }
          let doctor_zip = headerRow.findIndex(
            (each) => each === "Prescriber Primary Zip"
          );
          if (doctor_zip >= 0) {
            indexes["doctor_zip"] = doctor_zip;
          } else {
            missingIndex.push("Prescriber Primary Zip");
          }
          let payment_type = headerRow.findIndex(
            (each) => each === "Primary Third Party Bin"
          );
          if (payment_type >= 0) {
            indexes["payment_type"] = payment_type;
          } else {
            missingIndex.push("Primary Third Party Bin");
          }
          let deaClass = headerRow.findIndex((each) => each === "Dea Schedule");
          if (deaClass >= 0) {
            indexes["dea"] = deaClass;
          } else {
            missingIndex.push("Dea Schedule");
          }
          let pharmacy_initial = headerRow.findIndex(
            (each) => each === "Pharmacist Initials"
          );
          if (pharmacy_initial >= 0) {
            indexes["pharmacy_initial"] = pharmacy_initial;
          }
          if (missingIndex.length > 0) {
            updateState({ loading: false });
            confirmAlert({
              title: "Columns Missing",
              message:
                missingIndex.toString() +
                " - missing in the file, Please add these columns and upload again.",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => window.location.reload(),
                },
              ],
            });
          } else {
            let alreadyInNotFound = [];
            for (let i in results.data) {
              let each = results.data[i];
              if (each[0] == "") {
                continue;
              }
              each["index"] = parseInt(i) + 1;
              // results.data.map((each, index) => {
              if (
                each[indexes["ndc"]] !== "Dispensed Item NDC" &&
                each[indexes["ndc"]] !== undefined &&
                each[indexes["ndc"]] !== "" &&
                parseInt(each[indexes["ndc"]]) > 0
              ) {
                each[indexes["ndc"]] = each[indexes["ndc"]]
                  .padStart(11, "0")
                  .replaceAll("-", "");

                let drugFound = ncDrugs.find(
                  (eachD) => eachD.ndc == each[indexes["ndc"]]
                );

                if (/[a-zA-Z]/g.test(each[indexes["ndc"]])) {
                  errorItem.push(each["index"]);
                  console.log(each[indexes["ndc"]]);
                } else if (
                  drugFound === undefined &&
                  !isNaN(parseInt(each[indexes["dea"]])) &&
                  [2, 3, 4, 5].includes(parseInt(each[indexes["dea"]])) &&
                  !alreadyInNotFound.includes(each[indexes["ndc"]])
                ) {
                  notFound.push(each);
                  found.push(each);
                  alreadyInNotFound.push(each[indexes["ndc"]]);
                } else if (
                  !isNaN(parseInt(each[indexes["dea"]])) &&
                  [2, 3, 4, 5].includes(parseInt(each[indexes["dea"]]))
                ) {
                  found.push(each);
                }
              }
            }
            console.log("errorItem");
            console.log(found);
            if (errorItem.length > 0 && errorItem !== undefined) {
              updateState({ loading: false });
              confirmAlert({
                title: "Error in CSV",
                message:
                  "Row no. " +
                  errorItem.toString() +
                  " contains errors and will be skipped. The data must be entered manually.",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () =>
                      saveIndexesPioneerRx(notFound, found, indexes),
                  },
                ],
              });
            } else {
              saveIndexesPioneerRx(notFound, found, indexes);
            }
          }
        }
      },
    });
  };

  saveIndexesPioneerRx = (notFound, found, indexes) => {
    this.setState(
      {
        indexes,
      },
      () => this.checkNDCPioneerRx(notFound, found)
    );
  };

  checkNDCPioneerRx = (notFound, found) => {
    console.log("checkNDCPioneerRx");
    console.log(found);
    if (found.length === 0) {
      this.setState({ loading: false });
      confirmAlert({
        title: "Warning",
        message: "No records to upload",
        buttons: [
          {
            label: "Ok",
            onClick: () => (window.location.href = "/dispense"),
          },
        ],
      });
    } else {
      this.setState({ loading: true });
      if (notFound.length > 0 && notFound !== undefined) {
        this.setState(
          {
            notFound,
            found,
            loading: false,
          },
          () => $("#quick-drug-moda-button").trigger("click")
        );
      } else if (found.length > 0 && found !== undefined) {
        this.setState(
          {
            found,
          },
          () => this.checkDuplicatePioneerRx()
        );
      }
    }
  };

  checkDuplicatePioneerRx = () => {
    $("#quick-drug-modal").modal("hide");
    let { found, indexes, notFound } = this.state;
    console.log("checkDuplicatePioneerRx");
    console.log(found);
    let foundArr = [];
    let nonControlled = [];
    this.setState({
      loading: true,
    });
    if (notFound.length > 0) {
      let filtereData = {};
      filtereData = {
        ndc: { ndc: { $in: notFound.map((each) => each[indexes["ndc"]]) } },
      };
      axios
        .post("/api/nc-drugs", filtereData)
        .then((res) => {
          for (let i in res.data.records) {
            let each = res.data.records[i];
            if (!["2N", "2", "3", "3N", "4", "5"].includes(each.dea)) {
              nonControlled.push(each.ndc);
            }
          }

          if (found.length > 0) {
            for (let i in found) {
              let each = found[i];
              if (nonControlled.includes(each[indexes["ndc"]])) {
                each["nonControlled"] = true;
              }
              foundArr.push({
                $and: [
                  { ndc: each[indexes["ndc"]] },
                  { dispense_date: each[indexes["dispense_date"]] },
                  { rx_number: each[indexes["rx_number"]] },
                  { pharmacy_account: this.props.auth.user.final_user },
                  { deleted: { $ne: true } },
                ],
              });
            }
            axios
              .post("/api/dispense-check", foundArr)
              .then((res) => {
                let responsed = res.data.records;
                if (responsed.length > 0) {
                  let duplicates = [];
                  for (let i in found) {
                    let each = found[i];
                    responsed.filter((eachR) => {
                      if (
                        eachR["ndc"] === each[indexes["ndc"]] &&
                        eachR["rx_number"] === each[indexes["rx_number"]] &&
                        moment(eachR["dispense_date"]).isSame(
                          each[indexes["dispense_date"]]
                        )
                      ) {
                        each["duplicate"] = true;
                        duplicates.push(each["index"]);
                      }
                    });
                  }
                  let finalFilter = found.filter(
                    (eachF) => eachF["duplicate"] !== true
                  );
                  this.setState({ loading: false });
                  confirmAlert({
                    title: "Duplicate entries",
                    message:
                      "Row " +
                      duplicates
                        .filter((v, i, a) => a.indexOf(v) == i)
                        .toString() +
                      " was previously added and will not be uploaded",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => this.getNpiDetailPioneerRx(finalFilter),
                      },
                    ],
                  });
                } else {
                  this.getNpiDetailPioneerRx(found);
                }
              })
              .catch(function (error) {
                if (error.response) {
                  if (error.response.status === 403) {
                    window.location.href = "./login";
                  }
                }
              });
          } else {
          }
        })

        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
      if (found.length > 0) {
        for (let i in found) {
          let each = found[i];
          if (nonControlled.includes(each[indexes["ndc"]])) {
            each["nonControlled"] = true;
          }
          foundArr.push({
            $and: [
              { ndc: each[indexes["ndc"]] },
              { dispense_date: each[indexes["dispense_date"]] },
              { rx_number: each[indexes["rx_number"]] },
              { pharmacy_account: this.props.auth.user.final_user },
              { deleted: { $ne: true } },
            ],
          });
        }
        axios
          .post("/api/dispense-check", foundArr)
          .then((res) => {
            let responsed = res.data.records;
            let copyFound = JSON.parse(JSON.stringify(found));
            if (responsed.length > 0) {
              let duplicates = [];
              for (let i in found) {
                let each = found[i];
                responsed.filter((eachR) => {
                  if (
                    eachR["ndc"] === each[indexes["ndc"]] &&
                    eachR["rx_number"] === each[indexes["rx_number"]] &&
                    moment(eachR["dispense_date"]).isSame(
                      each[indexes["dispense_date"]]
                    )
                  ) {
                    each["duplicate"] = true;
                    duplicates.push(each["index"]);
                  }
                });
              }
              let finalFilter = found.filter(
                (eachF) => eachF["duplicate"] !== true
              );
              this.setState({ loading: false });
              confirmAlert({
                title: "Duplicate entries",
                message:
                  "Row " +
                  duplicates.filter((v, i, a) => a.indexOf(v) == i).toString() +
                  " was previously added and will not be uploaded",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => this.getNpiDetailPioneerRx(finalFilter),
                  },
                ],
              });
            } else {
              this.getNpiDetailPioneerRx(copyFound);
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status === 403) {
                window.location.href = "./login";
              }
            }
          });
      } else {
      }
    }
  };

  getNpiDetailPioneerRx = (found) => {
    console.log("getNpiDetailPioneerRx");
    console.log(found);
    let { indexes } = this.state;
    if (found.length > 0) {
      let npiArr = [];
      for (let i in found) {
        let each = found[i];
        npiArr.push(each[indexes["doctor_npi"]]);
      }
      let resultNpiDetails = {};
      this.setState({ loading: true });
      axios
        .post("/api/doctors-by-npi", npiArr)
        .then((res) => {
          for (let i = 0; i < res.data.records.length; i++) {
            let eachNpi = res.data.records[i];
            resultNpiDetails[eachNpi.npi] = eachNpi;
          }
          this.saveEntriesPioneerRx(found, resultNpiDetails);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
      confirmAlert({
        title: "Nothing to upload",
        message: "There are no row to upload",
        buttons: [
          {
            label: "Ok",
            onClick: () => window.location.reload(),
          },
        ],
      });
    }
  };

  saveEntriesPioneerRx = (found, resultNpiDetails) => {
    const { drugNameById, indexes, drugDeaById } = this.state;
    let saveData = [];
    for (let i in found) {
      let each = found[i];
      if (each.nonControlled) {
        continue;
      }
      let customerNameArr = each[indexes["customer_id"]].split(", ");
      let customerName =
        customerNameArr[1].charAt(0).toUpperCase() +
        customerNameArr[0].charAt(0).toUpperCase();
      saveData.push({
        ndc: each[indexes["ndc"]],
        dispense_date: each[indexes["dispense_date"]],
        rx_number: each[indexes["rx_number"]],
        drug_name:
          drugNameById[each[indexes["ndc"]]] !== undefined
            ? drugNameById[each[indexes["ndc"]]]
            : each[indexes["drug_name"]],
        dea: drugDeaById[each[indexes["ndc"]]],
        qty_dispensed: parseInt(each[indexes["qty_dispensed"]]),
        day_supply: each[indexes["day_supply"]],
        patient_name: customerName,
        patient_zip: each[indexes["patient_zip"]],
        patient_dob: each[indexes["patient_dob"]],
        pharmacy_account: this.props.auth.user.final_user,
        user_created: this.props.auth.user.email,
        doctor_firstname:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].first_name
            : "",
        doctor_lastname:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].last_name
            : "",
        doctor_npi: each[indexes["doctor_npi"]],
        doctor_add1:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_first_line
            : "",
        doctor_city:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_city
            : "",
        doctor_state:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_state
            : "",
        doctor_zip: each[indexes["doctor_zip"]],
        payment_type: bins.includes(each[indexes["payment_type"]]) ? "Y" : "N",
        pharmacy_initial:
          indexes["pharmacy_initial"] !== undefined
            ? each[indexes["pharmacy_initial"]]
            : "",
      });
    }
    console.log(saveData);
    axios
      .post("/api/dispense-upload-save", saveData)
      .then((res) => {
        this.setState({ loading: false });
        confirmAlert({
          title: "Success",
          message: "Sheet uplaoded Successfully",
          buttons: [
            {
              label: "Ok",
              onClick: () => (window.location.href = "/dispense"),
            },
          ],
        });
      })

      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  ////Start DigitalRx
  fromDigitalRx = () => {
    let { ncDrugs, indexes } = this.state;
    const saveIndexesDigitalRx = this.saveIndexesDigitalRx;
    const updateState = this.updateState;
    Papa.parse(this.state.file, {
      complete: function (results) {
        if (results.data.length < 2) {
          alert("Invalid CSV file.");
          return false;
        } else {
          let notFound = [];
          let found = [];
          let errorItem = [];
          let missingIndex = [];
          let headerRow = results.data[0];
          let rx_number = headerRow.findIndex((each) => each === "RxNumber");
          if (rx_number >= 0) {
            indexes["rx_number"] = rx_number;
          } else {
            missingIndex.push("RxNumber");
          }
          let dispense_date = headerRow.findIndex((each) => each === "RxDate");
          if (dispense_date >= 0) {
            indexes["dispense_date"] = dispense_date;
          } else {
            missingIndex.push("RxDate");
          }
          let patient_dob = headerRow.findIndex((each) => each === "PatDOB");
          if (patient_dob >= 0) {
            indexes["patient_dob"] = patient_dob;
          } else {
            missingIndex.push("PatDOB");
          }
          let doctor_npi = headerRow.findIndex((each) => each === "DocNPI");
          if (doctor_npi >= 0) {
            indexes["doctor_npi"] = doctor_npi;
          } else {
            missingIndex.push("DocNPI");
          }
          let drug_name = headerRow.findIndex((each) => each === "DrugName");
          if (drug_name >= 0) {
            indexes["drug_name"] = drug_name;
          } else {
            missingIndex.push("DrugName");
          }
          let ndc = headerRow.findIndex((each) => each === "DrugNDc");
          if (ndc >= 0) {
            indexes["ndc"] = ndc;
          } else {
            missingIndex.push("DrugNDc");
          }
          let qty_dispensed = headerRow.findIndex((each) => each === "Qty");
          if (qty_dispensed >= 0) {
            indexes["qty_dispensed"] = qty_dispensed;
          } else {
            missingIndex.push("Qty");
          }
          let day_supply = headerRow.findIndex((each) => each === "DaysSupply");
          if (day_supply >= 0) {
            indexes["day_supply"] = day_supply;
          } else {
            missingIndex.push("DaysSupply");
          }
          let patient_zip = headerRow.findIndex((each) => each === "PatZip");
          if (patient_zip >= 0) {
            indexes["patient_zip"] = patient_zip;
          } else {
            missingIndex.push("PatZip");
          }
          let patient_fname = headerRow.findIndex(
            (each) => each === "PatientName"
          );
          if (patient_fname >= 0) {
            indexes["customer_id"] = patient_fname;
          } else {
            missingIndex.push("PatientName");
          }
          let doctor_zip = headerRow.findIndex((each) => each === "DocZip");
          if (doctor_zip >= 0) {
            indexes["doctor_zip"] = doctor_zip;
          } else {
            missingIndex.push("DocZip");
          }
          let payment_type = headerRow.findIndex((each) => each === "ANSI_BIN");
          if (payment_type >= 0) {
            indexes["payment_type"] = payment_type;
          } else {
            missingIndex.push("ANSI_BIN");
          }
          let deaClass = headerRow.findIndex((each) => each === "DrugDea");
          if (deaClass >= 0) {
            indexes["dea"] = deaClass;
          } else {
            missingIndex.push("DrugDea");
          }
          let pharmacy_initial = headerRow.findIndex((each) => each === "RPH");
          if (pharmacy_initial >= 0) {
            indexes["pharmacy_initial"] = pharmacy_initial;
          }
          if (missingIndex.length > 0) {
            updateState({ loading: false });
            confirmAlert({
              title: "Columns Missing",
              message:
                missingIndex.toString() +
                " - missing in the file, Please add these columns and upload again.",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => window.location.reload(),
                },
              ],
            });
          } else {
            let alreadyInNotFound = [];
            for (let i in results.data) {
              let each = results.data[i];
              if (each[0] == "") {
                continue;
              }
              each["index"] = parseInt(i) + 1;
              // results.data.map((each, index) => {
              if (
                each[indexes["ndc"]] !== "DrugNDC" &&
                each[indexes["ndc"]] !== undefined &&
                each[indexes["ndc"]] !== "" &&
                parseInt(each[indexes["ndc"]]) > 0
              ) {
                each[indexes["ndc"]] = each[indexes["ndc"]]
                  .padStart(11, "0")
                  .replaceAll("-", "");
                each[indexes["dispense_date"]] = moment(
                  each[indexes["dispense_date"]]
                ).format("MM/DD/YYYY");

                let drugFound = ncDrugs.find(
                  (eachD) => eachD.ndc == each[indexes["ndc"]]
                );
                console.log("drugFound", drugFound);
                if (/[a-zA-Z]/g.test(each[indexes["ndc"]])) {
                  errorItem.push(each["index"]);
                  console.log(each[indexes["ndc"]]);
                } else if (
                  drugFound === undefined &&
                  !isNaN(parseInt(each[indexes["dea"]])) &&
                  [2, 3, 4, 5].includes(parseInt(each[indexes["dea"]])) &&
                  !alreadyInNotFound.includes(each[indexes["ndc"]])
                ) {
                  notFound.push(each);
                  found.push(each);
                  alreadyInNotFound.push(each[indexes["ndc"]]);
                } else if (
                  !isNaN(parseInt(each[indexes["dea"]])) &&
                  [2, 3, 4, 5].includes(parseInt(each[indexes["dea"]]))
                ) {
                  found.push(each);
                }
              }
            }
            console.log("found");
            console.log(found);
            if (errorItem.length > 0 && errorItem !== undefined) {
              updateState({ loading: false });
              confirmAlert({
                title: "Error in CSV",
                message:
                  "Row no. " +
                  errorItem.toString() +
                  " contains errors and will be skipped. The data must be entered manually.",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () =>
                      saveIndexesDigitalRx(notFound, found, indexes),
                  },
                ],
              });
            } else {
              saveIndexesDigitalRx(notFound, found, indexes);
            }
          }
        }
      },
    });
  };

  saveIndexesDigitalRx = (notFound, found, indexes) => {
    this.setState(
      {
        indexes,
      },
      () => this.checkNDCDigitalRx(notFound, found)
    );
  };

  checkNDCDigitalRx = (notFound, found) => {
    console.log("checkNDCDigitalRx");
    // console.log(found)
    if (found.length === 0) {
      this.setState({ loading: false });
      confirmAlert({
        title: "Warning",
        message: "No records to upload",
        buttons: [
          {
            label: "Ok",
            onClick: () => (window.location.href = "/dispense"),
          },
        ],
      });
    } else {
      this.setState({ loading: true });
      if (notFound.length > 0 && notFound !== undefined) {
        this.setState(
          {
            notFound,
            found,
            loading: false,
          },
          () => $("#quick-drug-moda-button").trigger("click")
        );
      } else if (found.length > 0 && found !== undefined) {
        this.setState(
          {
            found,
          },
          () => this.checkDuplicateDigitalRx()
        );
      }
    }
  };

  checkDuplicateDigitalRx = () => {
    $("#quick-drug-modal").modal("hide");
    let { found, indexes, notFound } = this.state;
    console.log("checkDuplicateDigitalRx");
    console.log(found);
    let foundArr = [];
    let nonControlled = [];
    this.setState({
      loading: true,
    });
    if (notFound.length > 0) {
      let filtereData = {};
      filtereData = {
        ndc: { ndc: { $in: notFound.map((each) => each[indexes["ndc"]]) } },
      };
      axios
        .post("/api/nc-drugs", filtereData)
        .then((res) => {
          for (let i in res.data.records) {
            let each = res.data.records[i];
            if (!["2N", "2", "3", "3N", "4", "5"].includes(each.dea)) {
              nonControlled.push(each.ndc);
            }
          }

          if (found.length > 0) {
            for (let i in found) {
              let each = found[i];
              if (nonControlled.includes(each[indexes["ndc"]])) {
                each["nonControlled"] = true;
              }
              foundArr.push({
                $and: [
                  { ndc: each[indexes["ndc"]] },
                  { dispense_date: each[indexes["dispense_date"]] },
                  { rx_number: each[indexes["rx_number"]] },
                  { pharmacy_account: this.props.auth.user.final_user },
                  { deleted: { $ne: true } },
                ],
              });
            }
            axios
              .post("/api/dispense-check", foundArr)
              .then((res) => {
                let responsed = res.data.records;
                if (responsed.length > 0) {
                  let duplicates = [];
                  for (let i in found) {
                    let each = found[i];
                    responsed.filter((eachR) => {
                      if (
                        eachR["ndc"] === each[indexes["ndc"]] &&
                        eachR["rx_number"] === each[indexes["rx_number"]] &&
                        moment(eachR["dispense_date"]).isSame(
                          each[indexes["dispense_date"]]
                        )
                      ) {
                        each["duplicate"] = true;
                        duplicates.push(each["index"]);
                      }
                    });
                  }
                  let finalFilter = found.filter(
                    (eachF) => eachF["duplicate"] !== true
                  );
                  this.setState({ loading: false });
                  confirmAlert({
                    title: "Duplicate entries",
                    message:
                      "Row " +
                      duplicates
                        .filter((v, i, a) => a.indexOf(v) == i)
                        .toString() +
                      " was previously added and will not be uploaded",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => this.getNpiDetailDigitalRx(finalFilter),
                      },
                    ],
                  });
                } else {
                  this.getNpiDetailDigitalRx(found);
                }
              })
              .catch(function (error) {
                if (error.response) {
                  if (error.response.status === 403) {
                    window.location.href = "./login";
                  }
                }
              });
          } else {
          }
        })

        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
      if (found.length > 0) {
        for (let i in found) {
          let each = found[i];
          if (nonControlled.includes(each[indexes["ndc"]])) {
            each["nonControlled"] = true;
          }
          foundArr.push({
            $and: [
              { ndc: each[indexes["ndc"]] },
              { dispense_date: each[indexes["dispense_date"]] },
              { rx_number: each[indexes["rx_number"]] },
              { pharmacy_account: this.props.auth.user.final_user },
              { deleted: { $ne: true } },
            ],
          });
        }
        axios
          .post("/api/dispense-check", foundArr)
          .then((res) => {
            let responsed = res.data.records;
            let copyFound = JSON.parse(JSON.stringify(found));
            if (responsed.length > 0) {
              let duplicates = [];
              for (let i in found) {
                let each = found[i];
                responsed.filter((eachR) => {
                  if (
                    eachR["ndc"] === each[indexes["ndc"]] &&
                    eachR["rx_number"] === each[indexes["rx_number"]] &&
                    moment(eachR["dispense_date"]).isSame(
                      each[indexes["dispense_date"]]
                    )
                  ) {
                    each["duplicate"] = true;
                    duplicates.push(each["index"]);
                  }
                });
              }
              let finalFilter = found.filter(
                (eachF) => eachF["duplicate"] !== true
              );
              this.setState({ loading: false });
              confirmAlert({
                title: "Duplicate entries",
                message:
                  "Row " +
                  duplicates.filter((v, i, a) => a.indexOf(v) == i).toString() +
                  " was previously added and will not be uploaded",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => this.getNpiDetailDigitalRx(finalFilter),
                  },
                ],
              });
            } else {
              this.getNpiDetailDigitalRx(copyFound);
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status === 403) {
                window.location.href = "./login";
              }
            }
          });
      } else {
      }
    }
  };

  getNpiDetailDigitalRx = (found) => {
    console.log("getNpiDetailDigitalRx");
    // console.log(found)
    let { indexes } = this.state;
    if (found.length > 0) {
      let npiArr = [];
      for (let i in found) {
        let each = found[i];
        npiArr.push(each[indexes["doctor_npi"]]);
      }
      let resultNpiDetails = {};
      this.setState({ loading: true });
      axios
        .post("/api/doctors-by-npi", npiArr)
        .then((res) => {
          for (let i = 0; i < res.data.records.length; i++) {
            let eachNpi = res.data.records[i];
            resultNpiDetails[eachNpi.npi] = eachNpi;
          }
          this.saveEntriesDigitalRx(found, resultNpiDetails);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    } else {
      confirmAlert({
        title: "Nothing to upload",
        message: "There are no row to upload",
        buttons: [
          {
            label: "Ok",
            onClick: () => window.location.reload(),
          },
        ],
      });
    }
  };

  saveEntriesDigitalRx = (found, resultNpiDetails) => {
    const { drugNameById, indexes, drugDeaById } = this.state;
    let saveData = [];
    for (let i in found) {
      let each = found[i];
      if (each.nonControlled) {
        continue;
      }
      let customerNameArr = each[indexes["customer_id"]].split(",");
      let customerName =
        customerNameArr.length > 1
          ? customerNameArr[1].charAt(0).toUpperCase() +
            customerNameArr[0].charAt(0).toUpperCase()
          : customerNameArr[0].charAt(0).toUpperCase();
      saveData.push({
        ndc: each[indexes["ndc"]],
        dispense_date: each[indexes["dispense_date"]],
        rx_number: each[indexes["rx_number"]],
        drug_name:
          drugNameById[each[indexes["ndc"]]] !== undefined
            ? drugNameById[each[indexes["ndc"]]]
            : each[indexes["drug_name"]],
        dea: drugDeaById[each[indexes["ndc"]]],
        qty_dispensed: parseInt(each[indexes["qty_dispensed"]]),
        day_supply: each[indexes["day_supply"]],
        patient_name: customerName,
        patient_zip: each[indexes["patient_zip"]],
        patient_dob: each[indexes["patient_dob"]],
        pharmacy_account: this.props.auth.user.final_user,
        user_created: this.props.auth.user.email,
        doctor_firstname:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].first_name
            : "",
        doctor_lastname:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].last_name
            : "",
        doctor_npi: each[indexes["doctor_npi"]],
        doctor_add1:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_first_line
            : "",
        doctor_city:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_city
            : "",
        doctor_state:
          resultNpiDetails[each[indexes["doctor_npi"]]] !== undefined
            ? resultNpiDetails[each[indexes["doctor_npi"]]].address_state
            : "",
        doctor_zip: each[indexes["doctor_zip"]],
        payment_type: each[indexes["payment_type"]] == "0" ? "N" : "Y",
        pharmacy_initial:
          indexes["pharmacy_initial"] !== undefined
            ? each[indexes["pharmacy_initial"]]
            : "",
      });
    }
    console.log("saveData", saveData);
    axios
      .post("/api/dispense-upload-save", saveData)
      .then((res) => {
        this.setState({ loading: false });
        confirmAlert({
          title: "Success",
          message: "Sheet uplaoded Successfully",
          buttons: [
            {
              label: "Ok",
              onClick: () => (window.location.href = "/dispense"),
            },
          ],
        });
      })

      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  render() {
    const { errors, indexes } = this.state;
    let ndcProps = {
      ndcIndex:
        this.props.auth.user.software === "Rx30"
          ? 54
          : this.props.auth.user.software === "Micromerchant"
          ? 2
          : this.props.auth.user.software === "Cerner" ||
            this.props.auth.user.software === "Liberty" ||
            this.props.auth.user.software === "ComputerRx" ||
            this.props.auth.user.software === "PioneerRx" ||
            this.props.auth.user.software === "DigitalRx"
          ? indexes["ndc"]
          : "",
      deaIndex:
        this.props.auth.user.software === "Rx30"
          ? 56
          : this.props.auth.user.software === "Micromerchant"
          ? 3
          : this.props.auth.user.software === "Cerner" ||
            this.props.auth.user.software === "Liberty" ||
            this.props.auth.user.software === "ComputerRx" ||
            this.props.auth.user.software === "PioneerRx" ||
            this.props.auth.user.software === "DigitalRx"
          ? indexes["dea"]
          : "",
      nameIndex:
        this.props.auth.user.software === "Rx30"
          ? 53
          : this.props.auth.user.software === "Micromerchant"
          ? 0
          : this.props.auth.user.software === "Cerner" ||
            this.props.auth.user.software === "Liberty" ||
            this.props.auth.user.software === "ComputerRx" ||
            this.props.auth.user.software === "PioneerRx" ||
            this.props.auth.user.software === "DigitalRx"
          ? indexes["drug_name"]
          : "",
      checkDuplicate:
        this.props.auth.user.software === "Rx30"
          ? this.checkDuplicate
          : this.props.auth.user.software === "Micromerchant"
          ? this.checkDuplicateMicromerchant
          : this.props.auth.user.software === "Cerner"
          ? this.checkDuplicateCerner
          : this.props.auth.user.software === "Liberty"
          ? this.checkDuplicateLiberty
          : this.props.auth.user.software === "ComputerRx"
          ? this.checkDuplicateComputerRx
          : this.props.auth.user.software === "PioneerRx"
          ? this.checkDuplicatePioneerRx
          : this.props.auth.user.software === "DigitalRx"
          ? this.checkDuplicateDigitalRx
          : "",
    };
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <AddNdcQuick
          notFound={this.state.notFound}
          checkDuplicate={this.checkDuplicate}
          {...ndcProps}
        />
        <button
          style={{ display: "none" }}
          data-toggle="modal"
          data-target="#quick-drug-modal"
          id="quick-drug-moda-button"
        ></button>
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">Upload Dispense Sheet</h1>
            <br></br>
            <div className="form-row">
              <div className="form-group col-md-3">
                <label htmlFor="rx">Select File (CSV file only)</label>
                <input
                  onChange={this.onImageChange}
                  id="file"
                  type="file"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group col-md-3">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={this.back}
                style={{ marginRight: "10px" }}
              >
                Back
              </button>
              <button
                onClick={this.upload}
                type="button"
                accept="text/csv"
                className="btn btn-primary"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(UploadDispense);
