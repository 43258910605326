import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../../utils/RxReach.png";
import rxr from "../../utils/rxr.jpg";

class Coaching extends React.Component {

  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title m-3">
                <div className="card">
                  <div className="card-body">
                    <h1 className="card-title text-primary" style={{ fontWeight: "bold", fontSize: "47px" }}>Mission</h1>

                    <div className="row">
                      <div className="col-md-12">
                        <p className="card-text" style={{ fontSize: "24px" }}>Our  mission is to provide assistance for the community pharmacy owners to accomplish progressive operations such as accreditations, controlled substance compliance, policy and procedure implementation, SOP, employee handbook and revenue and profit optimization and programs for revenue expansion.
                          <br></br>
                          <br></br>
                          Our solutions and mission is to help community pharmacies grow their business while thinking outside of the box in order to compete and grow while improving access, compliance and outcome for the benefit of the patient.
                          <div style={{ textAlign: "right" }}>
                            <button
                              type="button"
                              style={{ marginRight: "63px" }}
                              className="btn btn-success btn-lg">
                              <a href="https://calendly.com/rxconnexion/30min?month=2023-07" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>CLICK HERE TO SCHEDULE A CALL</a>
                            </button>
                          </div>

                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

export default Coaching;