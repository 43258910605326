import React from "react";
import $ from "jquery";
class RxPodcastVideo extends React.Component {
  close = () => {
    $("#rxpodcast-modal").modal("hide");
  };

  render() {
    return (
      <div>
        <div className="modal fade" id="rxpodcast-modal" data-reset="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header" style={{ display: "block" }}>
                <div style={{ float: "left" }}>
                  <img
                    height="80"
                    alt="logo"
                    src={
                      "https://d3l9g5ay50k79j.cloudfront.net/logo/RxPodcast.png"
                    }
                  />
                </div>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h3 className="modal-title" style={{ clear: "both" }}>
                  Be the star of your own show with RxPodcast!
                </h3>
              </div>
              <div className="modal-body center">
                <video width="80%" height="80%" controls>
                  <source
                    src="https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/rxpodcast.mp4"
                    type="video/mp4"
                  />
                  <source
                    src="https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/rxpodcast.mp4"
                    type="video/ogg"
                  />
                </video>
                <br></br>
              </div>
              <div className="modal-footer">
                <button
                  onClick={this.close}
                  type="button"
                  className="btn btn-success btn-lg"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RxPodcastVideo;
