import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from 'jquery';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import AddCredits from "../partials/AddCredits";
import moment from "moment"
import { logoutUser } from "../../actions/authActions";

class PriceCompare extends React.Component {

    constructor() {
        super();

        this.state = {
            cardNumber: "",
            expiry: "",
            cvv: "",
            _id: "",
            current_points: 0,
            errors: {},
            records: [],
            totalCount: 0,
            cardError: true,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "desc" }
            },
        };
    }

    componentDidMount() {
        this.getData()
       
    };

    

    getData() {
        this.setState({ loading: true })
        let filterData = {
            page_number: 1,
            page_size: 20,
            sort_order: { column: "_id", order: "desc" }
        }
        filterData.search = {
            email: this.props.auth.user.final_user
        }
        axios
            .post("/api/credits", filterData)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    each.type = each.type===1?"Credit":"Debit"
                    each.date = each.date ? moment(each.date).add(5, 'hours').format('L') : ""
                    result.push(each)
                }
                this.setState({
                    loading: false,
                    records: result,
                    totalCount: res.data.count
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })
    }

    render() {
        if((this.props.auth.user.expiration_tabula === '' || this.props.auth.user.expiration_tabula === undefined ||this.props.auth.user.expiration_tabula < moment().format()) && this.props.auth.user.superadmin!==true) {
            window.location.href = ("/choose-plan?solution=TabulaRx");
        }
        const { errors } = this.state;
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">Credits</h1>
                        <p className="help-text font-weight-bold h6">Available Points: {this.state.current_points} </p>
                        <button className="btn btn-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-credits"><FontAwesomeIcon icon={faPlus} />Add Credit</button>
                        <AddCredits sendPoints={this.sendPoints}/>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onChange={this.filter}
                            dynamic={true}
                            total_record={this.state.totalCount}
                            loading={true}
                        />
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    pharmacy: state.pharmacy,
    groups: state.groups,
});

export default connect(
    mapStateToProps, 
    {logoutUser}
)(PriceCompare);
