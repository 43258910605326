import React, { Component, Fragment } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { Collapse } from 'react-collapse';
class OpenAi extends Component {

    constructor(props) {
        super(props);

        this.state = {
            records: [],
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "dea", order: "asc" }
            },
            search: undefined,
            loading: false,
            isFilterOpen: true
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        //this.getData()
    };

    getData = () => {
        this.setState({ loading: true })
        let filterData = {}
        filterData.search = this.state.search
        axios
            .post("/openai/search", filterData)
            .then(res => {
                this.setState({
                    records: res.data.message,
                    loading: false
                })
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "./login"
                    }
                }
            })
    }


    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            brand: undefined,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "dea", order: "asc" }
            },
            records: [],
            totalCount: undefined
        })
    }

    toggleChange = () => {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen,
        });
    }


    render() {
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                       
                        <h1 className="mt-2 text-primary">Drug Copay Card Lookup</h1>
                        
                        <div>
                            <Collapse isOpened={this.state.isFilterOpen}>
                                <form noValidate onSubmit={this.onSearch}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3"></div>
                                        <div className="form-group col-md-5">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.search}
                                                id="search"
                                                type="text"
                                                className="form-control"
                                                placeholder="Search By Brand"
                                            />
                                        </div>

                                        <div className="form-group col-md-1">
                                            <button type="submit" className="btn btn-primary">Search</button>
                                        </div>
                                        <div className="form-group col-md-3"></div>
                                    </div>
                                </form>
                            </Collapse>
                        </div>
                        <br></br>
                        {this.state.records}
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(OpenAi);
