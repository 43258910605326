import React from "react";
import DatePicker from "react-datepicker";

const TabulaRx = (props) => {
  const { errors } = props;
  return (
    <>
      <div className="card">
        <div className="card-header" id="TabulaRxH">
          <h5 className="mb-0">
            <button
              type="button"
              className="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#TabulaRx"
              aria-expanded="false"
              aria-controls="TabulaRx"
            >
              TabulaRx
            </button>
          </h5>
        </div>
        <div
          id="TabulaRx"
          className="collapse"
          aria-labelledby="TabulaRx"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="bonarx">Exp Date</label>
              </div>
              <div className="col-md-9">
                <DatePicker
                  selected={props.expiration_tabula}
                  onChange={(date) => props.setDate(date, "expiration_tabula")}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="subscriptionId">Subscription Id</label>
              </div>
              <div className="col-md-9">
                <input
                  onChange={props.onChange}
                  value={props.subscriptionId}
                  id="subscriptionId"
                  type="text"
                  error={errors.subscriptionId}
                  className={"form-control"}
                />
                <span className="text-danger">{errors.subscriptionId}</span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="software">Software</label>
              </div>
              <div className="col-md-9">
                <select
                  name="software"
                  id="software"
                  onChange={props.onChange}
                  className="form-control"
                >
                  <option>Please Select</option>
                  {props.softwares.map((each) => {
                    return (
                      <option
                        key={each}
                        value={each}
                        selected={props.software === each}
                      >
                        {each}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {props.software === "Others- Type Response" ? (
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="software">Enter Software</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={props.onChange}
                    value={props.other_software}
                    id="other_software"
                    type="text"
                    error={errors.other_software}
                    className={"form-control"}
                  />
                  <span className="text-danger">{errors.other_software}</span>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="allow_dispensing_sheet">
                  Allow upload dispensing sheet
                </label>
              </div>
              <div className="col-md-9">
                <input
                  type="checkbox"
                  class="form-check-input"
                  checked={props.allow_dispensing_sheet}
                  onClick={props.toggleChange}
                  id="allow_dispensing_sheet"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="bonarx">MultiStore</label>
              </div>
              <div className="col-md-9">
                <input
                  type="checkbox"
                  class="form-check-input"
                  checked={props.multistore}
                  onClick={props.toggleChange}
                  id="multistore"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabulaRx;
