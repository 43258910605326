import axios from "axios";
import React from "react";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
  SET_PHARMACY,
  SET_GROUPS,
  SET_MULTISTOREDATA,
  SET_FILTER,
} from "./types";

export const registerUser = ({
  userData,
  history,
  hideLoading,
  onRegisterSucces,
}) => (dispatch) => {
  axios
    .post("/api/register", userData)
    .then((res) => {
      hideLoading();
      if (res.data.email) {
        confirmAlert({
          title: "Error!",
          message: `Email already exists`,
          buttons: [
            {
              label: "Ok",
              onClick: () => {},
            },
          ],
          overlayClassName: "confirm-plan",
        });
      } else {
        console.log("here");
        const { token } = res.data;
        localStorage.setItem("jwtToken", token);
        setAuthToken(token);
        const decoded = jwt_decode(token);
        console.log("decoded", decoded);
        dispatch(setCurrentUser(decoded));
        onRegisterSucces(res.data);
      }
    })

    .catch((err) => {
      hideLoading();
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const loginUser = (token) => (dispatch) => {
  localStorage.setItem("jwtToken", token);
  setAuthToken(token);
  const decoded = jwt_decode(token);
  if (
    decoded.email === "david.bigoness@animalmedexpress.com" ||
    decoded.email === "cortney@animalmedexpress.com"
  ) {
    window.location.href =
      "https://animalmedrx.com?goto=dashboard&reference=" +
      localStorage.getItem("jwtToken");
  } else {
    dispatch(setCurrentUser(decoded));
    dispatch(setGroups());
  }
};

export const refreshUser = (userData, type) => (dispatch) => {
  axios
    .post("/api/refreshuser", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      if (type === "landing") {
        window.location.href = "http://tabula.psgarx.com?landing";
      } else if (type === "tabula") {
        window.location.href =
          "http://tabula.psgarx.com?goto=dashboard&reference=" +
          localStorage.getItem("jwtToken");
      } else if (type === "rxminder") {
        window.location.href =
          "https://rxminder.psgarx.com?reference=" +
          localStorage.getItem("jwtToken");
      } else {
        window.location.href =
          "https://doorstep.psgarx.com?reference=" +
          localStorage.getItem("jwtToken");
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

export const setPharmacies = (pharmacy) => (dispatch) => {
  dispatch({
    type: SET_PHARMACY,
    payload: pharmacy,
  });
};

export const setGroups = () => (dispatch) => {
  axios
    .post("/api/group-data")
    .then((res) => {
      if (res.data.records !== undefined) {
        dispatch({
          type: SET_GROUPS,
          payload: res.data.records,
        });
      }
    })
    .catch((error) => {
      // dispatch({
      //     type: GET_ERRORS,
      //     payload: "Error"
      // })
      if (error.response) {
        if (error.response.status === 403) {
          window.location.href = "./login";
        }
      }
    });
};

export const setmultistoreData = (email) => (dispatch) => {
  axios
    .post("/api/multistore-data", { email: email })
    .then((res) => {
      if (res.data.records !== undefined) {
        dispatch({
          type: SET_MULTISTOREDATA,
          payload: res.data.records,
        });
      }
    })
    .catch((error) => {
      // dispatch({
      //     type: GET_ERRORS,
      //     payload: "Error"
      // })
      if (error.response) {
        if (error.response.status === 403) {
          window.location.href = "./login";
        }
      }
    });
};

export const logoutUser = (params = "") => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  window.location.href = `/login${params}`;
};

export const setFilter = (filters) => (dispatch) => {
  dispatch({
    type: SET_FILTER,
    payload: filters,
  });
};
