import React, { useEffect, useState } from "react";
import classnames from "classnames";
import axios from "axios";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const AddVendor = (props) => {
  const [vendor_file, setVendor_file] = useState(null);
  const [name, setName] = useState("");
  const onVendorAdd = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (vendor_file !== "") formData.append("vendor_file", vendor_file);
    if (name !== "") formData.append("name", name);
    formData.append("pharmacy_account", props.auth.user.final_user);

    axios.post("/api/comparerx-add", formData).then((res) => {
      $("#add-comparerx-modal").modal("hide");
      toast(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
  };
  return (
    <div>
      <div className="modal fade" id="add-comparerx-modal" data-reset="false">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Vendor</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form
                noValidate
                onSubmit={onVendorAdd}
                id="add-comparerx"
                encType="multipart/form-data"
              >
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="name">Vendor Name</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                      id="name"
                      type="text"
                      className={classnames("form-control")}
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="name">Vendor File</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      onChange={(e) => {
                        console.log(e.target.files[0]);
                        setVendor_file(e.target.files[0]);
                      }}
                      id="vendor_file"
                      type="file"
                      accept=".csv"
                      className={classnames("form-control")}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                form="add-comparerx"
                type="submit"
                className="btn btn-primary"
              >
                Add Vendor
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(AddVendor));
